import React from 'react';
import { FILTER_TYPE } from 'consts/table';
import { FilterField } from 'models/filter';
import { SelectOption } from 'models/table';
import { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Select from 'components/Select';
import Autocomplete from 'components/Autocomplete';
import DateInterval from 'components/DateInterval';

interface Props {
   filters: any;
   fields: FilterField[];
   onFilterChange: (event: any, field: string) => void;
   onClearFilterClick?: () => void;
   onApplyFilterClick?: () => void;
   isLoading?: boolean;
   options?: {
      eventTypes?: SelectOption[];
      sport?: SelectOption[];
      ages?: SelectOption[];
      gender?: SelectOption[];
      group?: SelectOption[];
      tournamentSeasons?: SelectOption[];
      aggregationType?: SelectOption[];
   };
}

// 13 it's enter button key code in Event object.
export const ENTER_BUTTON_KEY = 13;

export default function Filters(props: Props) {
   const {
      filters,
      fields,
      options = {},
      isLoading,
      onFilterChange,
      onApplyFilterClick,
      onClearFilterClick
   } = props;

   const isApplyClickHandlerExist = typeof onApplyFilterClick !== 'undefined';
   const isClearClickHandlerExist = typeof onClearFilterClick !== 'undefined';
   const isActionsExist = isApplyClickHandlerExist || isClearClickHandlerExist;

   const onEnterPress = (event: any) => {
      const isEnterButtonPressed = event.keyCode === ENTER_BUTTON_KEY;

      if (isEnterButtonPressed && isApplyClickHandlerExist) {
         onApplyFilterClick();
      }
   };

   const filtersRow = fields.map(item => {
      const { type, text, field } = item;

      switch (true) {
         case type === FILTER_TYPE.TEXT: {
            return (
               <Grid item xs={12} sm={6} md={4} lg={3} key={text} sx={{ mb: isActionsExist ? 2 : 0 }}>
                  <TextField
                     label={text}
                     value={filters[field]}
                     variant="outlined"
                     sx={{ width: '100%' }}
                     onChange={(event) => onFilterChange(event, field)}
                     onKeyDown={onEnterPress}
                  />
               </Grid>
            );
         }

         case type === FILTER_TYPE.SELECT: {
            return (
               <Grid item xs={12} sm={6} md={4} lg={3} key={text} sx={{ mb: isActionsExist ? 2 : 0 }}>
                  <Select
                     options={options[field as keyof typeof options]}
                     value={filters[field]}
                     inputLabel={text}
                     field={field}
                     onChange={onFilterChange}
                     onEnterPress={onEnterPress}
                  />
               </Grid>
            );
         }

         case type === FILTER_TYPE.MULTISELECT: {
            return (
               <Grid item xs={12} sm={6} md={4} lg={3} key={text} sx={{ mb: isActionsExist ? 2 : 0 }}>
                  <Select
                     options={options[field as keyof typeof options]}
                     value={filters[field]}
                     inputLabel={text}
                     field={field}
                     isMultiple
                     onChange={onFilterChange}
                     onEnterPress={onEnterPress}
                  />
               </Grid>
            );
         }

         case type === FILTER_TYPE.AUTOCOMPLETE: {
            return (
               <Grid item xs={12} sm={6} md={4} lg={3} key={text} sx={{ mb: isActionsExist ? 2 : 0 }}>
                  <Autocomplete
                     options={options[field as keyof typeof options]}
                     value={filters[field]}
                     label={text}
                     field={field}
                     onChange={onFilterChange}
                  />
               </Grid>
            );
         }

         case type === FILTER_TYPE.DATE_INTERVAL: {
            return (
               <Grid item xs={12} sm={6} md={4} lg={3} key={text} sx={{ mb: isActionsExist ? 2 : 0 }}>
                  <DateInterval
                     value={filters[field]}
                     field={field}
                     label={text}
                     onChange={onFilterChange}
                  />
               </Grid>
            );
         }

         default: {
            return (
               <></>
            );
         }
      }
   });

   return (
      <Box>
         <Grid container spacing={3} >
            {filtersRow}
         </Grid>

         {isActionsExist &&
            <Box sx={{ mt: 2 }}>
               {isClearClickHandlerExist &&
                  <Button
                     variant="outlined"
                     disabled={isLoading}
                     sx={{ mr: 2 }}
                     onClick={onClearFilterClick}
                  >
                     Clear
                  </Button>
               }

               {isApplyClickHandlerExist &&
                  <Button
                     variant="contained"
                     disabled={isLoading}
                     disableElevation
                     onClick={onApplyFilterClick}
                  >
                     Apply
                  </Button>
               }
            </Box>
         }
      </Box>
   )
}
