import React, { useEffect, useState } from 'react';
import * as propz from 'propz';
import { SchoolEvent } from 'models/event';
import { School } from 'models/school';
import { getRivalPoints, getRivalsByEvent } from 'helpers/rivals';
import { EVENT_TYPES } from 'consts/event';
import { KIND_SERVER_TO_CLIENT_MAPPING } from 'consts/school';
import { SUBTYPE_OF_INDIVIDUAL_TOURNAMENTS } from 'consts/tournament';
import { SCORING } from 'consts/sport';
import {
   isDistanceOrTimeTournamentEvent,
   isEventStatusFinished,
   isHousesEventForTeamSport,
   isIndividualSportEvent, isInterSchoolsEventForIndividualSport,
   isInterSchoolsEventForTeamSport,
   isInternalEventForTeamSport,
   isMultipartyEvent,
   isNonTeamSportEvent,
   isTeamOrTwoOnTwoSportEvent
} from 'helpers/event';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TeamResultsTableRow from './TeamResultsTableRow';

const RESULT_COLUMNS: TableColumn[] = [
   {
      text: '№',
      value: 'index',
      align: 'left'
   },
   {
      text: 'Team name',
      value: 'teamName',
      align: 'left'
   },
   {
      text: 'Rank',
      value: 'rank',
      align: 'center'
   },
   {
      text: 'Results',
      value: 'results',
      align: 'right'
   },
   {
      text: 'Points',
      value: 'points',
      align: 'right'
   },
   {
      text: '',
      value: 'abbreviation',
      align: 'center'
   }
];

type ColumnValueType = 'index' | 'teamName' | 'rank' | 'points';

interface TableCellData {
   index: number;
   teamName: string;
   rank: string;
   points: string;
   abbreviation?: JSX.Element;
}

interface TableColumn {
   text: string;
   value: string;
   align: string;
}

interface Props {
   event: SchoolEvent;
   school: School;
}

export default function TeamResultsTable(props: Props) {
   const { event, school } = props;
   const { eventType } = event;
   const { id: activeSchoolId, kind } = school;

   const [rivals, setRivals] = useState([]);

   useEffect(() => {
      const rivals = getRivalsByEvent(activeSchoolId, event);

      if (isTeamOrTwoOnTwoSportEvent(event)) {
         rivals.forEach(rival => {
            rival.isTeamScoreWasChanged = false;
            rival.isIndividualScoreAvailable = getInitValueForIsIndividualScoreAvailable(rival);

            initResultsForRival(rival, event);
         });
      } else if (isNonTeamSportEvent(event)) {
         if (
            EVENT_TYPES.EXTERNAL_SCHOOLS === eventType ||
            EVENT_TYPES.INTERNAL_HOUSES === eventType
         ) {
            rivals.forEach(rival => {
               if (isIndividualSportEvent(event)) {
                  rival.isIndividualScoreAvailable = true;
               } else {
                  rival.isIndividualScoreAvailable = false;
               }
               rival.isTeamScoreWasChanged = false;
               
               rival.score = getExtraScoreForRival(rival);
            });
         }
      };

      const rivalsSorted: any = getRivalsSort(rivals, event);
      setRivals(rivalsSorted);
      
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const getExtraScoreForRival = (rival: any) => {
      let extraScoreRival = 0;
      
      rival.players.forEach((player: any) => {
         const extraScorePlayer = propz.get(player, ['extraScore']);
         
         if (typeof extraScorePlayer !== 'undefined') {
            extraScoreRival += extraScorePlayer;
         }
      });
      return extraScoreRival;
   };

   const getInitValueForIsIndividualScoreAvailable = (rival: any) => {
      const isTournamentEvent = typeof event.tournamentId !== 'undefined';
      const isSchool = kind === KIND_SERVER_TO_CLIENT_MAPPING.SCHOOL;
      const isTeamSport = isTeamOrTwoOnTwoSportEvent(event);
      const isFinishedEvent = isEventStatusFinished(event);
      const teamId = propz.get(rival, ['team', 'id']);
      const isTeamIdExist = typeof teamId !== 'undefined';
      
      switch (true) {
         case isTournamentEvent && isSchool:
            // schools can edit only individual score in tournament events
            return true;
         case isTeamSport && !isFinishedEvent:
         case isTeamSport && isFinishedEvent && !isTeamIdExist:
            return false;
         case isTeamSport && isFinishedEvent && isTeamIdExist:
            const isIndividualScoreExist = event.results.individualScore.some(scoreData => scoreData.teamId === teamId );
            const isTeamScoreExist = event.results.teamScore.some(scoreData => scoreData.teamId === teamId);
            return isIndividualScoreExist && isTeamScoreExist;
         default:
            console.error('Fallback, check conditions');
            return false;
      }
   };

   const initResultsForRival = (rival: any, event: SchoolEvent) => {
      if (isInterSchoolsEventForTeamSport(event)) {
         if (typeof rival.team === 'undefined') {
            const schoolId = rival.school.id;
            const currentSchoolScoreData = event.results.schoolScore.find(scoreData => scoreData.schoolId === schoolId);
            
            if (typeof currentSchoolScoreData === 'undefined') {
               event.results.schoolScore.push({
                  schoolId: schoolId,
                  score: 0
               } as any);
            }
         } else {
            initTeamResultsForRival(rival, event);
         }
      } else if (isHousesEventForTeamSport(event)) {
         if (typeof rival.team === 'undefined') {
            const houseId = rival.house.id;
            const currentHouseScoreData = event.results.houseScore.find(scoreData => scoreData.houseId === houseId);
            
            if (typeof currentHouseScoreData === 'undefined') {
               event.results.houseScore.push({
                  houseId: houseId,
                  score: 0
               } as any);
            }
         } else {
            initTeamResultsForRival(rival, event);
         }
      } else if (isInternalEventForTeamSport(event)) {
         initTeamResultsForRival(rival, event);
      }
   };

   const initTeamResultsForRival = (rival: any, event: SchoolEvent) => {
      const teamId = rival.team.id;
      const currentTeamScoreData = event.results.teamScore.find(scoreData => scoreData.teamId === teamId);
      
      if (typeof currentTeamScoreData === 'undefined') {
         event.results.teamScore.push({
            teamId: teamId,
            score: 0
         } as any);
      }
   };

   const getExtraPoints = (event: SchoolEvent, rival: any) => {
      const schoolResults = event.results.schoolScore;
      const teamResults = event.results.teamScore;
      const individualResults = event.results.individualScore;
      const teamId = propz.get(rival, ['team','id'], undefined);
      const schoolId = rival.school.id;
      
      const schoolScoreData = schoolResults.find(scoreData => scoreData.schoolId === schoolId);
      const teamScoreData = teamResults.find(scoreData => scoreData.teamId === teamId);
      const individualScoreData = individualResults.find((scoreData: any) => scoreData.schoolId === schoolId);

      let points = 0;
      
      switch (true){
         case typeof individualScoreData !== 'undefined':
            points = propz.get(individualScoreData, ['richScore', 'points'], 0);
            break;

         case typeof teamId !== 'undefined' && typeof teamScoreData !== 'undefined':
            points = propz.get(teamScoreData, ['richScore', 'points'], 0);
            break;

         case typeof schoolScoreData !== 'undefined':
            points = propz.get(schoolScoreData, ['richScore', 'points'], 0);
            break;
      }
      
      return points;
   };

   const sortByExtraPoints = (rivals: any, event: SchoolEvent, eventScoring: any, preferTeamPoints?: any) => {
      return [...rivals].sort((rival1: any, rival2: any) => {
         const extraPoints1 = getExtraPoints(event, rival1);
         const extraPoints2 = getExtraPoints(event, rival2);

         if (extraPoints1 === extraPoints2) {
            const score1 = getRivalPoints(event, rival1, preferTeamPoints);
            const score2 = getRivalPoints(event, rival2, preferTeamPoints);

            switch (eventScoring) {
               case SCORING.LESS_SCORES:
               case SCORING.LESS_TIME:
               case SCORING.LESS_RESULT:
                  return score1 - score2;

               case SCORING.MORE_SCORES:
               case SCORING.MORE_TIME:
               case SCORING.MORE_RESULT:
               case SCORING.FIRST_TO_N_POINTS:
                  return score2 - score1;

               default:
                  return 0;
            }
         } else {
            return extraPoints2 - extraPoints1;
         }
      });
   };

   const sortByScore = (rivals: any, event: SchoolEvent, eventScoring: any) => {
      switch (eventScoring){
         case SCORING.LESS_SCORES:
         case SCORING.LESS_TIME:
         case SCORING.LESS_RESULT:
            return [...rivals].sort((rival1, rival2) => {
               const score1 = getRivalPoints(event, rival1);
               const score2 = getRivalPoints(event, rival2);

               return score1 - score2;
            });
         case SCORING.MORE_SCORES:
         case SCORING.MORE_TIME:
         case SCORING.MORE_RESULT:
         case SCORING.FIRST_TO_N_POINTS:
            return [...rivals].sort((rival1, rival2) => {
               const score1 = getRivalPoints(event, rival1);
               const score2 = getRivalPoints(event, rival2);

               return score2 - score1;
            });
      }
   };

   const sortRivals = (
      rivals: any,
      event: SchoolEvent,
      isMultiparty: boolean,
      isInterSchoolsEventForIndividualSport: boolean,
      isInterSchoolsEventForTeamSport: boolean,
      eventScoring: any,
      isPseudoLevelOrRunTime: boolean
   ) => {
      let result;
      
      const rivalsCopy = [...rivals];
      
      const isDistanceOrTimeTournamentEventCondition = isDistanceOrTimeTournamentEvent(event);
      const isTeamSport = isTeamOrTwoOnTwoSportEvent(event);

      const preferTeamPoints = isPseudoLevelOrRunTime && isTeamSport;

      switch (true){
         case isMultiparty && (isInterSchoolsEventForIndividualSport || isInterSchoolsEventForTeamSport):
            if (isDistanceOrTimeTournamentEventCondition) {
               if (isPseudoLevelOrRunTime) {
                  const rivalsSorted = sortByExtraPoints(rivals, event, eventScoring, preferTeamPoints);
                  const rivalsZeroScore: any = rivalsSorted.filter(rival => {
                     const rivalScore = getRivalPoints(event, rival, preferTeamPoints);
                     return rivalScore === 0;
                  });
                  const rivalsNonZeroScore: any = rivalsSorted.filter(rival => {
                     const rivalScore = getRivalPoints(event, rival, preferTeamPoints);
                     return rivalScore !== 0;
                  });

                  result = [].concat(rivalsNonZeroScore, rivalsZeroScore);
               } else {
                  const rivalsZeroScore = rivals.filter((rival: any) => {
                     const rivalScore = getRivalPoints(event, rival, preferTeamPoints);
                     return rivalScore === 0;
                  });
                  const rivalsNonZeroScore = rivals.filter((rival: any) => {
                     const rivalScore = getRivalPoints(event, rival, preferTeamPoints);
                     return rivalScore !== 0;
                  });

                  result = [].concat(...sortByExtraPoints(rivalsNonZeroScore, event, eventScoring), rivalsZeroScore);
               }
            } else {
               result = sortByExtraPoints(rivalsCopy, event, eventScoring);
            }
            break;

         default:
            result = sortByScore(rivalsCopy, event, eventScoring);
            break;
      }
      
      return result;
   };

   const getRivalsSort = (rivals: any, event: SchoolEvent) => {
      let result;

      const tournamentSubType = propz.get(event, ['tournament', 'subType']);
      
      const isMultiparty = isMultipartyEvent(event);
      const isInterSchoolsEventForIndividualSportCondition = isInterSchoolsEventForIndividualSport(event);
      const isInterSchoolsEventForTeamSportCondition = isInterSchoolsEventForTeamSport(event);
      
      const scoring = event.sport.scoring;
      
      const isSchoolUnion = kind === KIND_SERVER_TO_CLIENT_MAPPING.SCHOOL_UNION;
      const isPseudoLevelRunTime = tournamentSubType === SUBTYPE_OF_INDIVIDUAL_TOURNAMENTS.PSEUDO_TWO_LEVEL_RUNTIME;
      const isPseudoLevel = tournamentSubType === SUBTYPE_OF_INDIVIDUAL_TOURNAMENTS.PSEUDO_TWO_LEVEL;
      const isPseudoLevelOrRunTime = isPseudoLevelRunTime || isPseudoLevel;

      switch (true){
         case isSchoolUnion && !isMultiparty:
            //for school union on public site, rivals must be order as same as generated names
            const schoolNameLast = event.generatedNames[activeSchoolId].split(' v ')[1];
            const school = event.invitedSchools.find(school => school.name.indexOf(schoolNameLast) === -1);
            
            const rivalsSortByGeneratedNames: any = [];
            
            rivals.forEach((rival: any) => {
               if (typeof school !== 'undefined' && rival.school.id === school.id) {
                  rivalsSortByGeneratedNames.unshift(rival);
               } else {
                  rivalsSortByGeneratedNames.push(rival);
               }
            });
            
            result = [...rivalsSortByGeneratedNames];
            break;

         default:
            result = sortRivals(
               rivals,
               event,
               isMultiparty,
               isInterSchoolsEventForIndividualSportCondition,
               isInterSchoolsEventForTeamSportCondition,
               scoring,
               isPseudoLevelOrRunTime
            );

            break;
      }
      
      return result;
   };

   const getColumns = () => {
      const columns = RESULT_COLUMNS.map(column => {
         const { align, text, value } = column;
   
         return (
            <TableCell
               sx={{
                  textAlign: align,
                  width: value === 'index' ? '30px' : 'auto'
               }}
               key={value}
            >
               {text}
            </TableCell>
         );
      });

      // Empty column cell for collapse icon
      const columnsWithEmptyFirstCell = [
         <TableCell sx={{ width: '30px' }} key={'collapseColumnCell'}></TableCell>,
         ...columns
      ];

      return (
         <TableRow>
            {columnsWithEmptyFirstCell}
         </TableRow>
      );
   }

   const rows = rivals.map((item, index) => {
      return (
         <TeamResultsTableRow
            rival={item}
            school={school}
            event={event}
            index={index}
            columns={RESULT_COLUMNS}
            key={index}
         />
      )
   })

   const renderTable = () => {
      const columns = getColumns();

      return (
         <TableContainer>
            <Table aria-label="collapsible table">
               <TableHead>
                  {columns}
               </TableHead>

               <TableBody>
                  {rows}
               </TableBody>
            </Table>
         </TableContainer>
      );
   }

   return renderTable();
}