import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as propz from 'propz';
import moment from 'moment';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { Tournament, TournamentTag } from 'models/tournament';
import { GENDER_TYPE } from 'types/common';
import { SPORT_GENDER_TYPE } from 'types/sport';
import { KIND_SERVER_TO_CLIENT_MAPPING } from 'consts/school';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'consts/sport';
import { PUBLIC_SITE_STATUS_OPTIONS } from 'consts/tournament';
import { getSeason, getTournamentBookingLink } from 'helpers/tournament';
import { getAgesGroupFormatted } from 'helpers/accessors';
import { getTournamentPublicSiteLink } from 'helpers/link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TournamentIcon from 'components/SvgIcons/TournamentIcon';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';

interface Props {
   school: School;
   tournament: Tournament;
   onTournamentInfoClick: (tournament: Tournament) => void;
};

interface TournamentImgProps {
   backgroundImage: string;
}

const CardWrapper = styled(Box)(
   sx({
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      p: 2,
      border: 1,
      borderColor: '#d7d7d7',
      borderRadius: '4px',
      flex: {
         sx: '0 1 auto',
         md: '0 1 300px'
      },
      overflow: 'hidden'
   })
);

const TournamentName = styled(Typography)(
   sx({
      mb: 2,
      fontWeight: 'bold',
      textAlign: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      color: '#1976d2',
      borderBottom: '1px solid #1976d2'
   })
);

const SportName = styled(Typography)(
   sx({
      mb: 1,
      textAlign: 'center'
   })
);

const CardSectionWrapper = styled(Box)(
   sx({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
   })
);

const InfoButtonWrapper = styled(Box)(
   sx({
      display: 'flex',
      flexWrap: 'wrap',
      gap: 1
   })
);

const CardSectionTitle = styled(Typography)(
   sx({
      textAlign: 'center',
      color: '#000'
   })
);

const CardSectionValue = styled(Typography)(
   sx({
      textAlign: 'center',
      color: '#9e9e9e'
   })
);

const CardDateSectionTitle = styled(Typography)(
   sx({
      fontSize: '14px',
      fontStyle: 'italic',
      textAlign: 'center',
      color: '#000'
   })
);

const CardDateSectionValue = styled(Typography)(
   sx({
      fontSize: '14px',
      fontStyle: 'italic',
      textAlign: 'center',
      color: '#9e9e9e'
   })
);

const InfoButton = styled(Button)(
   sx({
      '& .MuiButton-startIcon': {
         mr: 0
      }
   })
);

const TournamentImgAndBookingWrapper = styled(Box)(
   sx({
      position: 'relative'
   })
);

const TournamentImg = styled(Box, {
   shouldForwardProp: (prop) => prop !== 'backgroundImage'
})<TournamentImgProps>(
   ({ backgroundImage }) => sx({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100px',
      width: '100%',
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundColor: 'rgba(0,0,0,0.1)',
      transform: 'scale(1.15)',
      mb: 2
   })
);

const BookingButton = styled(Button)(
   sx({
      position: 'absolute',
      bottom: '50%',
      left: '-20px',
      transform: 'translateY(50%)'
   })
);

const TournamentIconWrapper = styled(Box)(
   sx({
      opacity: '0.4'
   })
);

interface TournamentTagsDisplayProps {
   tags: TournamentTag[];
   expanded: boolean;
   setExpanded: (expanded: boolean) => void;
}

const TournamentTagsDisplay: React.FC<TournamentTagsDisplayProps> = ({ tags, expanded, setExpanded }) => {
   return (
      <Box sx={{ 
         display: 'flex', 
         flexWrap: 'wrap', 
         justifyContent: 'flex-end', 
         mt: 0, 
         mb: 1,
         alignItems: 'center',
      }}>
         {tags.filter(tag => tag.isPublic).slice(0, expanded ? tags.length : 2).map(tag => (
            <Box
               key={tag.id}
               sx={{
                  background: `${tag.tagColor}`,
                  border: `1px solid ${tag.tagColor}`,
                  color: 'white',
                  borderRadius: '4px',
                  p: '4px 8px',
                  m: 0.5,
                  textAlign: 'center',
                  fontSize: '0.65rem',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                  boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                  maxWidth: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
               }}
            >
               {tag.tagName}
            </Box>
         ))}
         {tags.length > 2 && (
            <Button 
               onClick={() => setExpanded(!expanded)} 
               sx={{
                  background: '#1976d2',
                  color: 'white', 
                  textTransform: 'uppercase', 
                  p: '2px 6px',
                  fontSize: '0.65rem',
                  minHeight: 'auto',
                  minWidth: 'auto',
                  lineHeight: 'normal',
                  ml: 0.5,
                  border: '1px solid #1976d2',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
               }}
            >
               {expanded ? '- Less' : `+${tags.length - 2}`}
            </Button>
         )}
      </Box>
   );
};

export default function TournamentCard(props: Props) {
   const navigate = useNavigate();
   const [expanded, setExpanded] = useState(false); 


   const { school, tournament, onTournamentInfoClick } = props;
   const { id, waitingListCount } = tournament; 
   const tournamentTags: TournamentTag[] = propz.get(tournament, ['tournamentTags'], []).filter((tag: TournamentTag) => tag.isPublic); 

   const isSchoolUnion = school.kind === KIND_SERVER_TO_CLIENT_MAPPING.SCHOOL_UNION;

   const now = new Date();

   const name = propz.get(tournament, ['name'], '');
   const startTime = propz.get(tournament, ['startTime'], '');
   const endTime = propz.get(tournament, ['endTime'], '');
   const photos = propz.get(tournament, ['photos'], []);
   const tournamentPhoto = photos[0];
   const tournamentPhotoUrl = propz.get(tournamentPhoto, ['picUrl'], '');
   const isTournamentPhotoUrlExist = tournamentPhotoUrl !== '';
   const publicSiteStatus = propz.get(tournament, ['publicSite', 'status'], '');
   const isPublicSiteAvailable = publicSiteStatus === PUBLIC_SITE_STATUS_OPTIONS.PUBLIC_AVAILABLE;
   const tournamentWebsiteLink = getTournamentPublicSiteLink(tournament);
   const gender: GENDER_TYPE = propz.get(tournament, ['gender']);
   const genderFormatted = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[gender as SPORT_GENDER_TYPE];
   const sportName = propz.get(tournament, ['sport', 'name'], '');
   const formattedStartTime = moment(startTime).format('D MMMM YYYY h:mm a');
   const formattedEndTime = moment(endTime).format('D MMMM YYYY h:mm a');
   const isAgeGroupsOnWebSiteVisible = propz.get(tournament, ['isAgeGroupsOnWebSiteVisible'], false);
   const season = getSeason(startTime);

   const isUpcomingTournament = now.getTime() < new Date(startTime).getTime();

   const autoEnrollmentTeamsSettings = propz.get(tournament, ['autoEnrollmentTeamsSettings'], []);
   const isAutoEnrollmentToWaitingList = propz.get(tournament, ['isAutoEnrollmentToWaitingList'], false);

   const isUnlimitedNumberOfTeams = autoEnrollmentTeamsSettings.some((setting) => {
      const maximumNumberTeams = propz.get(setting, ['maximumNumberTeams']);

      return maximumNumberTeams === 0;
   });

   const isSpacesLeft = isUnlimitedNumberOfTeams || autoEnrollmentTeamsSettings.some((setting) => {
      const currentNumberTeams = propz.get(setting, ['currentNumberTeams']);
      const maximumNumberTeams = propz.get(setting, ['maximumNumberTeams']);

      const spacesLeft = maximumNumberTeams - currentNumberTeams;

      return spacesLeft > 0;
   });

   const entriesOpen = propz.get(tournament, ['entriesOpen'], '');
   const deadlineForEntries = propz.get(tournament, ['deadlineForEntries'], '');

   const isEntriesOpenExist = entriesOpen !== '';
   const isDeadlineForEntriesExist = deadlineForEntries !== '';
   const isEntriesOpenDatePassed = new Date(entriesOpen) < now;
   const isDeadlineForEntriesPassed = new Date(deadlineForEntries) < now;

   const isBookingButtonShow = isSchoolUnion &&
      isUpcomingTournament &&
      isEntriesOpenExist &&
      isEntriesOpenDatePassed &&
      isDeadlineForEntriesExist &&
      !isDeadlineForEntriesPassed;

   const bookOnlineButtonText = isSpacesLeft && !isAutoEnrollmentToWaitingList
      ? 'Book online'
      : `Book onto WL (${waitingListCount})`; 

   const onBookOnlineClick = (tournamentId: string) => {
      const url = getTournamentBookingLink(tournamentId);

      window.open(url, '_blank');
   };

   const onTournamentNameClick = () => {
      navigate(`/tournament/${id}`)
   };

   return (
      <Tooltip title={name} placement='top' enterDelay={500}>
         <CardWrapper>
            <TournamentName onClick={onTournamentNameClick}>{name}</TournamentName>
            <SportName fontWeight='bold'>{sportName}</SportName>
            {tournamentTags.length > 0 && (
               <TournamentTagsDisplay tags={tournamentTags} expanded={expanded} setExpanded={setExpanded} />
            )}
            <Box sx={{ height: tournamentTags.length > 0 ? '0px' : '32px', mb: 2 }} /> {/* Adjusted space */}
            <Box>
               <TournamentImgAndBookingWrapper>
                  <TournamentImg backgroundImage={tournamentPhotoUrl} >
                     {!isTournamentPhotoUrlExist &&
                        <TournamentIconWrapper>
                           <TournamentIcon width={50} height={50} />
                        </TournamentIconWrapper>
                     }
                  </TournamentImg>

                  {isBookingButtonShow &&
                     <BookingButton
                        onClick={() => onBookOnlineClick(id)}
                        color='error'
                        variant='contained'
                     >
                        {bookOnlineButtonText}
                     </BookingButton>
                  }
               </TournamentImgAndBookingWrapper>


               <CardSectionWrapper>
                  <CardSectionTitle>Season: </CardSectionTitle>
                  <CardSectionValue>{season}</CardSectionValue>
               </CardSectionWrapper>

               <CardSectionWrapper>
                  <CardSectionTitle>Gender: </CardSectionTitle>
                  <CardSectionValue>{genderFormatted}</CardSectionValue>
               </CardSectionWrapper>

               {isAgeGroupsOnWebSiteVisible &&
                  <CardSectionWrapper>
                     <CardSectionTitle>Ages: </CardSectionTitle>
                     <CardSectionValue>
                        {getAgesGroupFormatted(tournament, school)}
                     </CardSectionValue>
                  </CardSectionWrapper>
               }
            </Box>

            <Box sx={{ my: 2 }}>
               <CardSectionWrapper>
                  <CardDateSectionTitle>Start: </CardDateSectionTitle>
                  <CardDateSectionValue>{formattedStartTime}</CardDateSectionValue>
               </CardSectionWrapper>

               <CardSectionWrapper>
                  <CardDateSectionTitle>End: </CardDateSectionTitle>
                  <CardDateSectionValue>{formattedEndTime}</CardDateSectionValue>
               </CardSectionWrapper>
            </Box>

            <InfoButtonWrapper sx={{justifyContent: isPublicSiteAvailable ? 'space-between' : 'flex-end'}}>
               {isPublicSiteAvailable &&
                  <Button
                     variant="outlined"
                     size="small"
                     href={tournamentWebsiteLink}
                     target='_blank'
                     title='Tournament website'
                  >
                     <OpenInNewIcon fontSize="small" />
                  </Button>
               }

               <InfoButton
                  variant='outlined'
                  startIcon={<InfoOutlinedIcon />}
                  onClick={() => onTournamentInfoClick(tournament)}
               />
            </InfoButtonWrapper>
         </CardWrapper>
      </Tooltip>
   )
}