import * as propz from 'propz';
import { SchoolEvent, SchoolEventIndividualScore } from 'models/event';
import { SchoolHouse } from 'models/house';
import { EVENT_TYPES } from 'consts/event';
import {
   isEventStatusFinished,
   isIndividualSportEvent,
   isMultipartyEvent,
   isResultsModePlaces,
   isResultsModePoints,
   isTeamSportEvent
} from './event';
import { getHouseScoreByHouseId, getTeamScoreByTeamId } from './eventView';
import { isLessScoringSport } from './sport/sport';
import { sortByNameAsc } from './common';
import { isCricket } from './sport/cricket';

export function sortHouses(event: SchoolEvent, house1: any, house2: any) {
   const isEventFinished = isEventStatusFinished(event);
   const isEventCricket = isCricket(event);
   const isMultiparty = isMultipartyEvent(event);

   const { teamsData } = event;
   const team1 = teamsData.find(teamData => teamData.houseId === house1.id);
   const isTeamExist1 = typeof team1 !== 'undefined';

   const team2 = teamsData.find(teamData => teamData.houseId === house2.id);
   const isTeamExist2 = typeof team2 !== 'undefined';

   const score1 = isTeamExist1 ? getTeamScoreByTeamId(event, team1.id) : getHouseScoreByHouseId(event, house1.id);
   const score2 = isTeamExist2 ? getTeamScoreByTeamId(event, team2.id) : getHouseScoreByHouseId(event, house2.id);

   switch (true) {
      case isEventCricket && !isMultiparty:
         return sortByNameAsc(house1, house2);
      case isEventFinished && isMultiparty:
         const isPlaces = isResultsModePlaces(event);
         const isPoints = isResultsModePoints(event);
         switch (true) {
            case isPlaces:
               return score1 - score2;
            case isPoints:
               return score2 - score1;
            default:
               console.error('Can not find results mode');
               return 0;
         }
      case isEventFinished && !isMultiparty:
         const isLessScoringEventSport = isLessScoringSport(event.sport);

         return isLessScoringEventSport ? score1 - score2 : score2 - score1;
      default:
         return sortByNameAsc(house1, house2);
   }
}

export function getHousePoints(events: SchoolEvent[], house: SchoolHouse) {
   const { id: houseId } = house;
   let points = 0;

   events.forEach(event => {
      const { eventType, individualsData, teamsData } = event;
      const individualScore: SchoolEventIndividualScore[] = propz.get(event, ['results', 'individualScore'], []);

      switch (eventType) {
         case EVENT_TYPES.INTERNAL_TEAMS:
            const houseStudents = individualsData.filter(student => student.houseId === houseId);

            houseStudents.forEach(student => {
               const currentScore = individualScore.find(score => score.userId === student.userId);
               const richScore = propz.get(currentScore, ['richScore', 'points'], 0);

               points += richScore;
            });

            break;

         case EVENT_TYPES.INTERNAL_HOUSES:
            let currentPoint = 0;

            if (isTeamSportEvent(event)) {
               const team = teamsData.find(team => team.houseId === houseId);
               const isTeamExist = typeof team !== 'undefined';
               
               if (isTeamExist) {
                  currentPoint = getTeamScoreByTeamId(event, team.id);
               }
            }

            if (isIndividualSportEvent(event)) {
               currentPoint = getHouseScoreByHouseId(event, houseId);
            }

            points += currentPoint;

            break;
      }
   });

   return points;
};
