import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { SCREEN_SIZE } from 'consts/responsive';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

interface SchoolInfoProps {
   school: School;
}

interface SchoolInfoCardProps {
   isTabletScreenSize: boolean;
}

interface SchoolLogoProps {
   picUrl: string;
}

const SchoolInfoCard = styled(Card, {
   shouldForwardProp: (prop) => prop !== 'isTabletScreenSize'
})<SchoolInfoCardProps>(
   ({ isTabletScreenSize }) => sx({
      display: 'flex',
      alignItems: 'center',
      boxShadow: 'none',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      p: {
         xs: 1,
         sm: 3
      },
      flexDirection: isTabletScreenSize ? 'column' : 'row'
   })
);

const SchoolLogo = styled(Card, {
   shouldForwardProp: (prop) => prop !== 'picUrl'
})<SchoolLogoProps>(
   ({ picUrl }) => sx({
      minWidth: '150px',
      width: '150px',
      height: '150px',
      backgroundImage: `url(${picUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      boxShadow: 'none'
   })
);

export default function SchoolInfo(props: SchoolInfoProps) {
   const { school } = props;
   const { name, pic, description } = school;

   const isPhoneScreenSize: boolean = useMediaQuery(SCREEN_SIZE.PHONE);
   const isTabletScreenSize: boolean = useMediaQuery(SCREEN_SIZE.TABLET);

   return (
      <SchoolInfoCard isTabletScreenSize={isTabletScreenSize}>
         <SchoolLogo picUrl={pic} />

         <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
               <Typography
                  variant={isPhoneScreenSize ? 'h6' : 'h5'}
                  color='primary'
                  sx={{
                     fontWeight: 'bold',
                     textTransform: 'uppercase',
                     letterSpacing: '2px',
                     mb: 2
                  }}
               >
                  {name}
               </Typography>
               <Typography variant={isPhoneScreenSize ? 'body2' : 'subtitle1'} color='text.secondary' component='div'>
                  {description}
               </Typography>
            </CardContent>
         </Box>
      </SchoolInfoCard>
   );
};
