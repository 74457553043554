import axios from 'axios';
import { LIMIT_ALL, DEFAULT_SKIP } from 'consts/table';

export function getTournamentTeams(activeSchoolId: string, tournamentId: string) {
   const config = {
      params: {
         filter: {
            where: { isCreator: true }, // passing `isCreator: true` forces server to return all teams taking part in this tournament
            limit: LIMIT_ALL,
            skip: DEFAULT_SKIP
         }
      }
   };

   return axios
      .get(`${window.apiBase}/public/schools/${activeSchoolId}/tournaments/${tournamentId}/teams`, config)
      .then((response) => {
         return response.data;
      });
};