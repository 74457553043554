import * as React from 'react';
import FixturesIcon from '@mui/icons-material/MoveToInbox';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import LeaguesIcon from '@mui/icons-material/EmojiEvents';
import NewsIcon from '@mui/icons-material/Feed';
import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import ResultsIcon from 'components/SvgIcons/ResultsIcon';
import TournamentIcon from 'components/SvgIcons/TournamentIcon';
import ResourcesIcon from '@mui/icons-material/InsertDriveFile';
import ChallengeIcon from 'components/SvgIcons/ChallengeIcon';
import FlagIcon from '@mui/icons-material/Flag';

export const DRAWER_WIDTH = 170;

export interface Navigation {
   text: string;
   icon: React.ReactElement;
   to: { pathname: string };
}

export const SCHOOL_NAVIGATION: Navigation[] = [
   {
      text: 'Home',
      icon: <HomeIcon />,
      to: { pathname: '/' }
   },
   {
      text: 'Calendar',
      icon: <CalendarIcon />,
      to: { pathname: '/calendar' }
   },
   {
      text: 'Fixtures',
      icon: <FixturesIcon />,
      to: { pathname: '/fixtures' }
   },
   {
      text: 'Results',
      icon: <ResultsIcon />,
      to: { pathname: '/results' }
   },
   {
      text: 'Tournaments',
      icon: <TournamentIcon />,
      to: { pathname: '/tournaments' }
   },
   {
      text: 'Club events',
      icon: <GroupsIcon />,
      to: { pathname: '/clubEvents' }
   },
   {
      text: 'Leagues',
      icon: <LeaguesIcon />,
      to: { pathname: '/leagues' }
   },
   {
      text: 'Challenges',
      icon: <FlagIcon />,
      to: { pathname: '/challenges' }
   },
   {
      text: 'News',
      icon: <NewsIcon />,
      to: { pathname: '/news' }
   }
];

export const SCHOOL_UNION_NAVIGATION: Navigation[] = [
   {
      text: 'Home',
      icon: <HomeIcon />,
      to: { pathname: '/' }
   },
   {
      text: 'Upcoming events',
      icon: <FixturesIcon />,
      to: { pathname: '/upcomingTournaments' }
   },
   {
      text: 'Results',
      icon: <ResultsIcon />,
      to: { pathname: '/tournamentResults' }
   },
   {
      text: 'Resources',
      icon: <ResourcesIcon />,
      to: { pathname: '/files' }
   }
];

export const SCHOOL_LEAGUE_NAVIGATION: Navigation[] = [
   {
      text: 'Home',
      icon: <HomeIcon />,
      to: { pathname: '/' }
   },
   {
      text: 'Fixtures',
      icon: <FixturesIcon />,
      to: { pathname: '/fixtures' }
   },
   {
      text: 'Results',
      icon: <ResultsIcon />,
      to: { pathname: '/results' }
   }
];

export const SCHOOL_CHALLENGE_NAVIGATION: Navigation[] = [
   {
      text: 'Home',
      icon: <HomeIcon />,
      to: { pathname: '/' }
   },
   {
      text: 'Ongoing challenges',
      icon: <ChallengeIcon />,
      to: { pathname: '/ongoing' }
   },
   {
      text: 'Past challenges',
      icon: <FlagIcon />,
      to: { pathname: '/past' }
   }
];

export const SCHOOL_TOURNAMENT_NAVIGATION: Navigation[] = [
   {
      text: 'Home',
      icon: <HomeIcon />,
      to: { pathname: '/' },
   },
   {
      text: 'Winners',
      icon: <LeaguesIcon />,
      to: { pathname: '/winners' },
   },
   {
      text: 'Events schedule',
      icon: <CalendarIcon />,
      to: { pathname: '/schedule' },
   },
   {
      text: 'Results',
      icon: <ResultsIcon />,
      to: { pathname: '/results' },
   },
];

export const SCHOOL_FOOTBALL_TOURNAMENT_NAVIGATION: Navigation[] = [
   {
      text: 'Home',
      icon: <HomeIcon />,
      to: { pathname: '/' },
   },
   {
      text: 'Events schedule',
      icon: <CalendarIcon />,
      to: { pathname: '/schedule' },
   },
   {
      text: 'Results',
      icon: <ResultsIcon />,
      to: { pathname: '/results' },
   },
];
