import React, { useEffect, useState, useCallback } from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import * as propz from 'propz';
import { parse } from 'query-string';
import { Promise } from 'bluebird';
import { SchoolEvent } from 'models/event';
import { School } from 'models/school';
import { Sport } from 'models/sport';
import { FilterField } from 'models/filter';
import { ACTIVITIES_BLOCK_TYPE } from 'consts/common';
import { DEFAULT_LIMIT } from 'consts/table';
import { FILTER_TYPE } from 'consts/table';
import { SchoolForm } from 'consts/form';
import { getSchoolSports } from 'services/school';
import { getSchoolEvents, getSchoolEventsCount } from 'services/school';
import { getAllSchoolForms } from 'services/forms';
import { getFilters, checkIsFilterExist, getSearchFilter } from 'helpers/filters';
import { getFilterForSchoolResultsWithoutClubsAndTournaments } from 'helpers/filters';
import {
   getSelectOptionForAge,
   getSelectOptionForClubGender,
   getSelectOptionForSport,
   getSelectOptionForEventType
} from 'helpers/select';
import { getServerFieldSectionWhere } from 'helpers/table';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ScrollToTop from 'components/ScrollToTop';
import Loader from 'components/loader';
import EventCard from 'components/EventCard';
import Filters from 'components/Filters';
import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';
import EmptyDataIcon from 'components/EmptyDataIcon';

interface Props {
   school: School;
}

const FILTER_FIELDS: FilterField[] = [
   {
      text: 'Sports / Activities',
      field: 'sport',
      type: FILTER_TYPE.AUTOCOMPLETE
   },
   {
      text: 'Ages',
      field: 'eventAges',
      type: FILTER_TYPE.MULTISELECT
   },
   {
      text: 'Gender',
      field: 'gender',
      type: FILTER_TYPE.SELECT
   },
   {
      text: 'Start date',
      field: 'startTime',
      type: FILTER_TYPE.DATE_INTERVAL
   },
   {
      text: 'Kind',
      field: 'eventType',
      type: FILTER_TYPE.MULTISELECT
   }
];

const EmptyItems = styled(Box)(
   sx({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      opacity: '0.4',
      mt: 5
   })
);

const EmptyItemsText = styled(Typography)(
   sx({
      fontWeight: 'bold',
      textAlign: 'center'
   })
);

export default function Results(props: Props) {
   const location = useLocation();
   const navigate = useNavigate();

   const [searchParams, setSearchParams] = useSearchParams();

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [isFetching, setIsFetching] = useState<boolean>(false);
   const [isShowFilters, setIsShowFilters] = useState<boolean>(false);
   const [items, setItems] = useState<SchoolEvent[]>([]);
   const [allItemsCount, setAllItemsCount] = useState<number>(0);
   const [sports, setSports] = useState<Sport[]>([]);
   const [forms, setForms] = useState<SchoolForm[]>([]);
   const [currentPage, setCurrentPage] = useState<number>(1);
   const [filters, setFilters] = useState({});

   const { school } = props;
   const { id: schoolId } = school;

   const itemsCount = items.length;

   const ageGroupName = propz.get(school, ['ageGroupsNaming'], '');
   const filterButtonIcon = isShowFilters ? <FilterAltOffIcon /> : <FilterAltIcon />;
   const filterButtonText = isShowFilters ? 'Hide Filters' : 'Show Filters';

   const isResultsExist = items.length > 0;

   const options = {
      eventAges: getSelectOptionForAge(forms, ageGroupName),
      gender: getSelectOptionForClubGender(),
      sport: getSelectOptionForSport(sports),
      eventType: getSelectOptionForEventType()
   };

   const onScroll = useCallback((event: Event) => {
      const { target } = event;
      
      const scrollHeight = propz.get(target, ['scrollHeight']);
      const scrollTop = propz.get(target, ['scrollTop']);
      const windowInnerHeight = window.innerHeight;

      const isBottomOfPage = scrollHeight - (scrollTop + windowInnerHeight) < 100;

      if (isBottomOfPage && itemsCount < allItemsCount) {
         setIsFetching(true);
      };
   }, [itemsCount, allItemsCount]);

   useEffect(() => {
      setIsLoading(true);

      const search = parse(location.search);
      const filters = getFilters(FILTER_FIELDS, search);

      const where = getServerFieldSectionWhere(filters);

      const promises = [
         getSchoolEvents(schoolId, getFilterForSchoolResultsWithoutClubsAndTournaments(where)),
         getSchoolEventsCount(schoolId, getFilterForSchoolResultsWithoutClubsAndTournaments(where)),
         getSchoolSports(school),
         getAllSchoolForms(schoolId)
      ];

      Promise.all(promises).then(([events, eventsCountObj, sports, forms]) => {
         setItems(events);
         setAllItemsCount(eventsCountObj.count);
         setSports(sports);
         setForms(forms);
         setFilters(filters);
         setIsLoading(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchParams]);

   useEffect(() => {
      if (isFetching) {
         const where = getServerFieldSectionWhere(filters);

         const queryFilter = {
            ...getFilterForSchoolResultsWithoutClubsAndTournaments(where),
            skip: currentPage * DEFAULT_LIMIT
         };

         getSchoolEvents(schoolId, queryFilter)
            .then((events: SchoolEvent[]) => {
               setItems([...items, ...events]);
               setCurrentPage(prevState => prevState + 1);
            })
            .finally(() => setIsFetching(false));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isFetching]);

   useEffect(() => {
      const content = document.querySelector('#main-content');
      const isContentExist = content !== null;
      isContentExist && content.addEventListener('scroll', onScroll);

      return function () {
         isContentExist && content.removeEventListener('scroll', onScroll);
      }
   }, [onScroll]);

   const onFilterChange = (event: any, filterField: string): void => {
      const filterValue = propz.get(event, ['target', 'value']);

      const nextFilters = {
         ...filters,
         [filterField]: filterValue
      };

      setCurrentPage(1);
      setFilters(nextFilters);
   };

   const onApplyFilterClick = (): void => {
      let search = [];

      const isFilterExist = checkIsFilterExist(filters);

      if (isFilterExist) {
         search.push(getSearchFilter(filters));
      }

      const searchParams: any = parse(search.join('&'));

      setSearchParams(searchParams);
   };

   const onClearFilterClick = (): void => {
      setSearchParams('');

      const search = parse(location.search);
      const filters = getFilters(FILTER_FIELDS, search);

      setFilters(filters);
   };

   const onEventClick = (event: SchoolEvent): void => {
      const {id: eventId} = event;

      navigate(`/event/${eventId}`);
   };

   if (isLoading) {
      return <Loader />;
   }

   return (
      <PageWrapper>
         <div id="back-to-top-anchor"></div>
         <PageTitle text="Results" />
         
         <Button
            variant="outlined"
            startIcon={filterButtonIcon}
            sx={{ my: 2 }}
            onClick={() => setIsShowFilters(!isShowFilters)}
         >
            {filterButtonText}
         </Button>

         {isShowFilters &&
            <Box>
               <Filters
                  fields={FILTER_FIELDS}
                  filters={filters}
                  options={options}
                  onFilterChange={onFilterChange}
                  onApplyFilterClick={onApplyFilterClick}
                  onClearFilterClick={onClearFilterClick}
               />

               <Divider sx={{ my: 3 }} />
            </Box>
         }

         {isResultsExist
            ? <Grid container spacing={3} >
                  {items.map(event => {
                     const { id } = event

                     return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                           <EventCard
                              event={event}
                              type={ACTIVITIES_BLOCK_TYPE.RESULTS}
                              schoolId={schoolId}
                              onEventClick={onEventClick}
                           />
                        </Grid>
                     );
                  })
               }
            </Grid>
            : <EmptyItems>
               <EmptyDataIcon />

               <EmptyItemsText>
                  There are no results to display.
               </EmptyItemsText>
            </EmptyItems>
         }

         {(isFetching && itemsCount < allItemsCount) &&
            <Box sx={{ mt: 3 }}>
               <Loader />
            </Box>
         }

         <ScrollToTop {...props}>
            <Fab color="primary" size="small" aria-label="scroll back to top">
               <KeyboardArrowUpIcon />
            </Fab>
         </ScrollToTop>
      </PageWrapper>
   )
}
