import axios from 'axios';
import { LIMIT_ALL, DEFAULT_SKIP } from 'consts/table';

export function getAllSchoolForms(activeSchoolId: string, where?: any) {
   let config;

   if (typeof where !== 'undefined') {
      config = {
         params: {
            filter: {
               where: {
                  ...where
               },
               limit: LIMIT_ALL,
               skip: DEFAULT_SKIP
            }
         }
      };
   } else {
      config = {
         params: {
            filter: {
               limit: LIMIT_ALL,
               skip: DEFAULT_SKIP
            }
         }
      };
   }

   return axios.get(`${window.apiBase}/public/schools/${activeSchoolId}/forms`, config)
      .then(response => {
         return response.data;
      });
}
