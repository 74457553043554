import { SchoolEvent, SchoolEventTeamData } from 'models/event';
import {
   isEventStatusFinished,
   isMultipartyEvent,
   isResultsModePlaces,
   isResultsModePoints
} from './event';
import { getTeamScoreByTeamId } from './eventView';
import { isLessScoringSport } from './sport/sport';
import { isCricket } from './sport/cricket';
import { sortByNameAsc } from './common';

export function sortTeams(event: SchoolEvent, team1: SchoolEventTeamData, team2: SchoolEventTeamData) {
   const isEventFinished = isEventStatusFinished(event);
   const isEventCricket = isCricket(event);
   const isMultiparty = isMultipartyEvent(event);
   const score1 = getTeamScoreByTeamId(event, team1.id);
   const score2 = getTeamScoreByTeamId(event, team2.id);

   switch (true) {
      case isEventCricket && !isMultiparty:
         return sortByNameAsc(team1, team2);

      case isEventFinished && !isMultiparty:
         const isLessScoringEventSport = isLessScoringSport(event.sport);
         return isLessScoringEventSport ? score1 - score2 : score2 - score1;

      case isEventFinished && isMultiparty:
         const isPlaces = isResultsModePlaces(event);
         const isPoints = isResultsModePoints(event);
         switch (true) {
            case isPlaces:
               return score1 - score2;

            case isPoints:
               return score2 - score1;

            default:
               console.error('Can not find results mode');
               return 0;
         }

      default:
         return sortByNameAsc(team1, team2);
   }
}
