import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import * as propz from 'propz';
import Moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { SchoolEvent } from 'models/event';
import { Participant } from 'models/event';
import { EVENT_TABS } from 'consts/event';
import { CLUB_EVENT_TABS } from 'consts/club';
import { EVENT_STATUS } from 'consts/event';
import { EVENT_VIEW_TABS } from 'consts/eventView';
import { DEFAULT_SKIP, LIMIT_ALL } from 'consts/table';
import { SCREEN_SIZE } from 'consts/responsive';
import { getSchoolEvent, getSchoolsPublic } from 'services/school';
import {
   getEventGeneratedName,
   getAllSchoolIdsFromEvent,
   isIndividualSportEvent,
   isTournamentEvent,
   isTeamOrTwoOnTwoSportEvent
} from 'helpers/event';
import { getEventParticipants } from 'helpers/event';
import { isClubEvent } from 'helpers/event';
import {
   isAthleticSwimmingTournamentSubtype,
   isIndividualTournament,
   isRegularIndividualTournamentSubtype
} from 'helpers/tournament';
import EventParticipants from './Tabs/EventParticipants';
import EventDetails from './Tabs/EventDetails';
import EventGallery from './Tabs/EventGallery';
import EventMatchReport from './Tabs/EventMatchReport';
import EventFeed from './Tabs/EventFeed';
import EventResults from './Tabs/EventResults';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Loader from 'components/loader';
import TabsMUI from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PageWrapper from 'components/PageWrapper';

interface Props {
   school: School;
}

const GoBackButton = styled(Button)(
   sx({
      mt: 1,

      '& .MuiButton-startIcon': {
         mr: 0
      }
   })
);

const EventNameTitle = styled(Typography)(
   sx({
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: '1px',
      my: {
         xs: 2,
         sm: 3
      },
      lineHeight: {
         xs: 1,
         sm: 1.6
      }
   })
);

const TabsWrapper = styled(Box)(
   sx({
      mt: {
         sm: 4,
         xs: 2
      }
   })
);

const Tabs = styled(TabsMUI)(
   sx({
      mb: 2,

      '& .MuiTabs-flexContainer': {
         borderBottom: '2px solid #ccc'
      },

      '& .Mui-selected': {
         background: '#1976d229'
      }
   })
);

export default function SchoolEventView(props: Props) {
   const navigate = useNavigate();
   const location = useLocation();
   const pageParams = useParams();

   const isTabletScreenSize = useMediaQuery(SCREEN_SIZE.TABLET);

   const { school } = props;

   const locationState = propz.get(location, ['state']);
   const isLocationStateExist = locationState !== null;
   const locationPrevPath = isLocationStateExist ? propz.get(location, ['state', 'prevPath'], '/') : '/';
   const selectedDate = isLocationStateExist ? propz.get(locationState, ['selectedDate'], '') : new Date();

   const [event, setEvent] = useState<SchoolEvent>();
   const [participants, setParticipants] = useState<Participant[]>([]);
   const [isLoading, setIsLoading] = useState<boolean>(true);
   const [tabIndex, setTabIndex] = useState(0);

   const isEventExist = typeof event !== 'undefined';
   const isTournamentEventCondition = isEventExist && isTournamentEvent(event);
   const tournament = propz.get(event, ['tournament']);
   const isIndividualTournamentCondition = isIndividualTournament(tournament);
   const isIndividualEvent = isEventExist ? isIndividualSportEvent(event) : false;
   const isTeamEvent = isEventExist ? isTeamOrTwoOnTwoSportEvent(event) : false;
   const isAthleticSwimmingTournamentSubtypeCondition = isAthleticSwimmingTournamentSubtype(tournament);
   const isRegularIndividualTournamentSubtypeCondition = isRegularIndividualTournamentSubtype(tournament);
   const resultsTabIndex = EVENT_TABS.findIndex((item) => item === EVENT_VIEW_TABS.RESULTS) || 0;

   const eventId = propz.get(pageParams, ['id'], '');
   const schoolId = propz.get(school, ['id'], '');

   const eventName: string = isEventExist ? getEventGeneratedName(event, schoolId) : '';
   const sportName: string = propz.get(event, ['sport', 'name'], '');
   const eventStartTime = propz.get(event, ['startTime'], '');
   const eventStartTimeFormatted = Moment(eventStartTime).format('dddd, MMMM Do YYYY, hh:mm a');

   const eventStatus = propz.get(event, ['status'], '');
   const isEventCancelled = isEventExist && eventStatus === EVENT_STATUS.CANCELED;
   const reasonForCancellation = propz.get(event, ['reasonForCancellation'], '');

   const isShowMatchReportTab = !isTournamentEventCondition && !isIndividualTournamentCondition;

   const eventTabsFiltered = EVENT_TABS.filter((tab) => {
      return tab === 'Match report' ? isShowMatchReportTab : true;
   });

   const tabs = isEventExist && isClubEvent(event) ? CLUB_EVENT_TABS : eventTabsFiltered;

   const tabsToRender = tabs.filter((item) => {
      return (
         isIndividualEvent ||
         isAthleticSwimmingTournamentSubtypeCondition ||
         isRegularIndividualTournamentSubtypeCondition
      )
         ? true
         : item !== EVENT_VIEW_TABS.RESULTS;
   });

   useEffect(() => {
      if (isIndividualEvent || isTeamEvent) {
         setTabIndex(resultsTabIndex);
      }
   }, [isIndividualEvent, resultsTabIndex, isTeamEvent]);

   useEffect(() => {
      setIsLoading(true);

      getSchoolEvent(schoolId, eventId)
         .then((event) => {
            setEvent(event);

            const allSchoolIds = getAllSchoolIdsFromEvent(event);

            const filter = {
               where: { id: { $in: allSchoolIds } },
               view: {
                  type: 'id_name_pic'
               },
               skip: DEFAULT_SKIP,
               limit: LIMIT_ALL
            };

            return getSchoolsPublic(filter);
         })
         .then((schools) => {
            let participants: Participant[] = [];

            if (isEventExist) {
               participants = getEventParticipants(event, school, schools);
            }

            setParticipants(participants);
            setIsLoading(false);
         });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isEventExist]);

   const renderTabs = () => {
      return tabsToRender.map((tab, index) => (
         <Tab
            sx={{ flex: '1 1 auto' }}
            key={`vertical-tab-${index}`}
            label={tab}
            id={`vertical-tab-${index}`}
            aria-controls={`vertical-tabpanel-${index}`}
         />
      ));
   };

   const renderTabContent = () => {
      const currentTab = tabsToRender[tabIndex];

      let tabContent;

      switch (true) {
         case currentTab === EVENT_VIEW_TABS.TEAMS:
            tabContent = isEventExist && (
               <EventParticipants event={event} participants={participants} activeSchoolId={schoolId} />
            );

            break;

         case currentTab === EVENT_VIEW_TABS.RESULTS:
            tabContent = isEventExist && <EventResults event={event} school={school} />;

            break;

         case currentTab === EVENT_VIEW_TABS.DETAILS:
            tabContent = isEventExist && <EventDetails event={event} activeSchoolId={schoolId} school={school} />;

            break;

         case currentTab === EVENT_VIEW_TABS.GALLERY:
            tabContent = isEventExist && <EventGallery event={event} activeSchoolId={schoolId} />;

            break;

         case currentTab === EVENT_VIEW_TABS.MATCH_REPORT:
            tabContent = isEventExist && <EventMatchReport event={event} activeSchoolId={schoolId} />;

            break;

         case currentTab === EVENT_VIEW_TABS.EVENT_FEED:
            tabContent = isEventExist && <EventFeed event={event} />;

            break;
      }

      return (
         <Box role="tabpanel" id={`vertical-tabpanel-${tabIndex}`} aria-labelledby={`vertical-tab-${tabIndex}`}>
            {tabContent}
         </Box>
      );
   };

   const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
      setTabIndex(newValue);
   };

   const onGoBackClick = () => {
      const locationStateOrDefault = location.state ?? {};
  
      const locationFilters = propz.get(locationStateOrDefault, ['filters'], {});
      const locationSelectedSchool = propz.get(locationStateOrDefault, ['selectedSchool'], null);
  
      if (location.state) {
          navigate(locationPrevPath, {
              state: {
                  ...locationStateOrDefault as Record<string, any>, 
                  selectedDate: new Date(selectedDate),
                  filters: locationFilters,
                  selectedSchool: locationSelectedSchool
              }
          });
      } else {
          navigate(-1);
      }
  };  
  
   if (isLoading) {
      return <Loader />;
   }

   return (
      <PageWrapper>
         <GoBackButton
            variant="contained"
            startIcon={<ArrowBackIcon />}
            size={isTabletScreenSize ? 'small' : 'medium'}
            onClick={onGoBackClick}
         />

         <EventNameTitle
            variant={isTabletScreenSize ? 'h6' : 'h5'}
            sx={{
               color: isEventCancelled ? '#ff5454' : '',
               textDecoration: isEventCancelled ? 'line-through' : ''
            }}
         >
            {eventName}
         </EventNameTitle>

         {isEventCancelled && (
            <Typography sx={{ color: '#ff5454' }}>Cancelled. Reason: {reasonForCancellation}</Typography>
         )}

         <Typography color="primary">Sport: {sportName}</Typography>

         <Typography variant={isTabletScreenSize ? 'subtitle1' : 'h6'} color="primary" sx={{ fontWeight: 'bold' }}>
            {eventStartTimeFormatted}
         </Typography>

         <TabsWrapper>
            <Tabs
               variant="scrollable"
               allowScrollButtonsMobile={isTabletScreenSize}
               value={tabIndex}
               onChange={onTabChange}
               aria-label="tabs"
            >
               {renderTabs()}
            </Tabs>

            {renderTabContent()}
         </TabsWrapper>
      </PageWrapper>
   );
}
