import React, { useState, useEffect } from 'react';
import * as propz from 'propz';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, experimental_sx as sx } from '@mui/system';
import { SUBTYPE_OF_TOURNAMENT } from 'consts/tournament';
import { School } from 'models/school';
import { SchoolEvent } from 'models/event';
import { Tournament } from 'models/tournament';
import { SCREEN_SIZE } from 'consts/responsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, Pagination, Navigation } from 'swiper';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MuiSchoolIcon from '@mui/icons-material/School';
import Button from '@mui/material/Button';
import DialogMUI from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentMUI from '@mui/material/DialogContent';
import DialogTitleMUI from '@mui/material/DialogTitle';
import SchoolResultsTable from './SchoolResultsTable';
import FootballSchoolResultsTable from './FootballSchoolResultsTable';
import PageTitle from 'components/PageTitle';
import 'swiper/swiper-bundle.min.css';

const MOBILE_SLIDES_COUNT = 1;
const DESKTOP_SLIDES_COUNT = 3;

interface Props {
   activeSchool: School;
   schools: School[];
   events: SchoolEvent[];
   tournament: Tournament;
   teams?: any[];
   selectedSchool: School | undefined;
   onSelectSchoolClick: (school: School) => void;
   onSearch: (value: string) => void;
   onCloseClick: () => void;
};

const Dialog = styled(DialogMUI)(
   sx({
      '& .MuiDialog-container': {
         alignItems: 'initial'
      }
   })
);

const DialogContent = styled(DialogContentMUI)(
   sx({
      p: 0,
      borderTop: 0
   })
);

const DialogTitle = styled(DialogTitleMUI)(
   sx({
      background: '#2b8ded'
   })
);

const Slider = styled(Swiper)(
   sx({
      overflow: 'hidden',

      '& .swiper-wrapper': {
         pb: '40px'
      },

      '& .swiper-button-next, .swiper-button-prev': {
         height: 'calc(100% - 64px)',
         transition: 'background .25s',
         padding: '0 8px',
         top: 0,
         mt: 0,
         background: '#c7d0f082',
         backdropFilter: 'blur(1px)',

         '&:after': {
            fontSize: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
         }
      },

      '& .swiper-button-prev': {
         left: 0,
         borderTopLeftRadius: '6px',
         borderBottomLeftRadius: '6px'
      },

      '& .swiper-button-next': {
         right: 0,
         borderTopRightRadius: '6px',
         borderBottomRightRadius: '6px'
      },
   })
);

const Slide = styled(SwiperSlide)(
   sx({
      mt: '0 !important',
      mb: 3
   })
);

const SlideWrapper = styled(Box)(
   sx({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      p: 3,
      border: '1px solid #ccc',
      boxSizing: 'border-box',
      borderRadius: '6px',
      textAlign: 'center',
      fontSize: '18px',
      background: '#fff',
      transition: 'border-color 0.15s',

      '& img': {
         height: '50px',
         mb: 3
      },
   })
);

const SchoolIcon = styled(MuiSchoolIcon)(
   sx({
      mb: 3,
      height: '50px',
      fontSize: '50px'
   })
);

export default function SchoolSlider(props: Props) {
   const {
      activeSchool,
      schools,
      events,
      tournament,
      onSearch,
      selectedSchool,
      onSelectSchoolClick,
      onCloseClick,
      teams = []
   } = props;

   const [isSchoolResultsModalOpen, setIsSchoolResultsModalOpen] = useState<boolean>(false);

   const tournamentSubType = propz.get(tournament, ['subType'], '');
   const isFootballTournament = tournamentSubType === SUBTYPE_OF_TOURNAMENT.FOOTBALL;
   const isSelectedItemExist = typeof selectedSchool !== 'undefined';

   const isTabletOrPhoneScreenSize = useMediaQuery(SCREEN_SIZE.TABLET);

   useEffect(() => {
      if (isSelectedItemExist) {
         setIsSchoolResultsModalOpen(true);
      }
   }, [isSelectedItemExist])

   const getSchoolSlides = () => {
      return schools.map((item, index) => {
         const { id, pic: schoolLogoUrl } = item;
         const isSchoolLogoExist = typeof schoolLogoUrl !== 'undefined' && schoolLogoUrl !== '';

         return (
            <Slide key={index}>
               <SlideWrapper>
                  {isSchoolLogoExist
                     ? <img src={schoolLogoUrl} alt="School logo" />
                     : <SchoolIcon color='primary' />
                  }
                  <Typography>{item.name}</Typography>

                  <Button
                     variant="outlined"
                     sx={{ mt: 2 }}
                     fullWidth
                     onClick={() => onViewResultsClick(item)}
                  >
                     View results
                  </Button>
               </SlideWrapper>
            </Slide>
         );
      })
   };

   const onViewResultsClick = (item: School) => {
      onSelectSchoolClick(item);
      setIsSchoolResultsModalOpen(true);
   }

   const renderSchoolResultsModal = () => {
      const schoolName = propz.get(selectedSchool, ['name'], '');

      const table = isSelectedItemExist && (isFootballTournament
         ? <FootballSchoolResultsTable
            events={events}
            teams={teams}
            activeSchool={activeSchool}
            school={selectedSchool}
            tournament={tournament}
         />
         : <SchoolResultsTable
            events={events}
            activeSchool={activeSchool}
            school={selectedSchool}
            tournament={tournament}
         />)

      return (
         <Dialog
            open={isSchoolResultsModalOpen}
            onClose={onSchoolResultsModalClose}
            scroll='paper'
            fullWidth={true}
            maxWidth='lg'
         >
            <DialogTitle id="scroll-dialog-title" color={'white'}>
               {`${schoolName} results`}
            </DialogTitle>

            <DialogContent dividers={true}>
               {table}
            </DialogContent>

            <DialogActions>
               <Button onClick={onSchoolResultsModalClose}>Close</Button>
            </DialogActions>
         </Dialog>
      );
   };

   const onSchoolResultsModalClose = () => {
      setIsSchoolResultsModalOpen(false);
      onCloseClick();
   };

   return (
      <>
         {renderSchoolResultsModal()}
         <PageTitle text='Schools' />

         <TextField
            label='School name'
            variant="outlined"
            onChange={(event) => onSearch(event.target.value)}
            sx={{ my: 2 }}
         />

         <Slider
            className='school-slider'
            slidesPerView={isTabletOrPhoneScreenSize
               ? MOBILE_SLIDES_COUNT
               : DESKTOP_SLIDES_COUNT
            }
            navigation={!isTabletOrPhoneScreenSize}
            grid={{rows: 2, fill: 'row'}}
            spaceBetween={30}
            pagination={{
              dynamicBullets: true
            }}
            modules={[Grid, Pagination, Navigation]}
         >
            {getSchoolSlides()}
         </Slider>
      </>
   );
};
