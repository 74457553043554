export const EVENT_RESULTS_VIEW = {
   NO_RESULTS_YET: 'No results yet',
   VIEW_RESULTS: 'View results',
   VIEW_DETAILS: 'View details'
};

export const EVENT_SIGN = {
   CLUB: 'C',
   TOURNAMENT: 'T',
   DEFAULT: 'F'
};

export const CALENDAR_FILTER = {
   ALL: 'ALL',
   SPORT_EVENTS: 'SPORT_EVENTS',
   CLUB_EVENTS: 'CLUB_EVENTS',
   TOURNAMENTS: 'TOURNAMENTS',
   TOURNAMENT_EVENTS: 'TOURNAMENT_EVENTS'
};

export const CALENDAR_FILTER_SERVER_TO_CLIENT_MAPPING = {
   ALL: 'All',
   TOURNAMENTS: 'Tournaments',
   TOURNAMENT_EVENTS: 'Tournament events',
   SPORT_EVENTS: 'Sport events',
   CLUB_EVENTS: 'Club events'
};

export const CALENDAR_MIN_DATE = '2010-01-01';
export const CALENDAR_MAX_DATE = '2030-01-01';

export const DEFAULT_TIME_MASK = 'hh:mm:ss.ccc';
export const DEFAULT_DISTANCE_MASK = 'kk mmm.cc';

export const SCHOOL_YEAR_MONTHS = [
   {
      index: 8,
      name: 'Sep',
      isDisabled: false
   },
   {
      index: 9,
      name: 'Oct',
      isDisabled: false
   },
   {
      index: 10,
      name: 'Nov',
      isDisabled: false
   },
   {
      index: 11,
      name: 'Dec',
      isDisabled: false
   },
   {
      index: 0,
      name: 'Jan',
      isDisabled: false
   },
   {
      index: 1,
      name: 'Feb',
      isDisabled: false
   },
   {
      index: 2,
      name: 'Mar',
      isDisabled: false
   },
   {
      index: 3,
      name: 'Apr',
      isDisabled: false
   },
   {
      index: 4,
      name: 'May',
      isDisabled: false
   },
   {
      index: 5,
      name: 'Jun',
      isDisabled: false
   },
   {
      index: 6,
      name: 'Jul',
      isDisabled: false
   },
   {
      index: 7,
      name: 'Aug',
      isDisabled: false
   },
];
