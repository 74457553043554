import Moment from 'moment';
import { FilterField } from 'models/filter';
import { FILTER_TYPE } from 'consts/table';
import { DEFAULT_LIMIT, HOME_PAGE_LIMIT, DEFAULT_SKIP } from 'consts/table';
import { EVENT_STATUS } from 'consts/event';
import { EVENT_TYPES } from 'consts/event';

export interface IFilter {
    where: {
        domain?: string;
        kind?: IIN;
        isAllTournaments?: boolean;
    }
}

interface IIN {
    $in: string[]
}

export function getFilterForSchoolAndSchoolUnion(domain: string): IFilter {
    return {
        where: {
            domain: domain,
            kind: {
                $in: ['School', 'SchoolUnion']
            }
        }
    }
}

export function getFilterForTournaments(domain: string): IFilter {
    return {
        where: {
            domain: domain,
            isAllTournaments: true
        }
    }
}

export function getFilterForChampionships(domain: string): IFilter {
   return {
      where: {
         domain: domain
      }
   }
}

export function getFilterForSchoolLeagues(domain: string): IFilter {
    return {
        where: {
            domain: domain
        }
    }
}

export function getFilterForSchoolFixturesForHomePage(activeSchoolId: string) {
   const filter = getFilterForSchoolFixtures(activeSchoolId, {});

   return {
      ...filter,
      limit: HOME_PAGE_LIMIT
   }
}

export function getFilterForSchoolFixtures(activeSchoolId: string, where: any = {}) {
   const today = new Date().setHours(0, 0, 1);

   return {
      where: {
         startTime: {
            $gte: new Date(today)
         },

         clubId: { $exists: false },
         tournamentId: { $exists: false },

         $or: [
            { $and: [
                  { inviterSchoolId: { $ne: activeSchoolId }},
                  { status: {
                        $nin: [
                           EVENT_STATUS.INVITES_SENT,
                           EVENT_STATUS.COLLECTING_INVITE_RESPONSE,
                           EVENT_STATUS.REJECTED
                        ]
                     }
                  },
                  { schoolLeagueId: { $exists: false } },
               ]
            },
            { $and: [
                  { inviterSchoolId: activeSchoolId },
                  {
                     status: {
                        $in: [
                           EVENT_STATUS.ACCEPTED,
                           EVENT_STATUS.CANCELED,
                           EVENT_STATUS.INVITES_SENT,
                           EVENT_STATUS.COLLECTING_INVITE_RESPONSE
                        ]
                     },
                  },
                  { schoolLeagueId: { $exists: false } }
               ]
            },

            { $and: [
                  { schoolLeagueId: { $exists: true } },
                  { eventType: { $in: [EVENT_TYPES.INTERNAL_TEAMS] } },
                  { embeddedTeams: { $ne: [] } }
               ]
            }
         ],
         ...where
      },
      limit: DEFAULT_LIMIT,
      skip: DEFAULT_SKIP
   }
}

export function getFilterForSchoolResultsForHomePage() {
   const filter = getFilterForSchoolResultsWithoutClubsAndTournaments({});

   return {
      ...filter,
      limit: HOME_PAGE_LIMIT
   }
}

export function getFilterForSchoolResultsWithoutClubsAndTournaments(where: any) {
   return {
      where: {
         status: {
            $in: [EVENT_STATUS.FINISHED]
         },
         clubId: { $exists: false },
         tournamentId: { $exists: false },

         $or: [
            { schoolLeagueId: { $exists: false } },
            { $and: [
                  { schoolLeagueId: { $exists: true } },
                  { eventType: { $in: [EVENT_TYPES.INTERNAL_TEAMS] } },
                  { embeddedTeams: { $ne: [] } }
               ]
            }
         ],
         ...where
      },
      order: 'startTime DESC',
      limit: DEFAULT_LIMIT,
      skip: DEFAULT_SKIP
   }
}

export function getFilterForSchoolTournaments(where: any) {
   return {
      where: {
         ...where
      },
      order: 'startTime DESC',
      limit: DEFAULT_LIMIT,
      skip: DEFAULT_SKIP
   }
}

export function getFilterForSchoolNews(where: any) {
   return {
       where,
       limit: DEFAULT_LIMIT,
       skip: DEFAULT_SKIP,
       order: 'date DESC'
   }
}

export function getFilters(columns: FilterField[], search: any): any {
    const filters: any = {};

    const columnsWithFilter = columns.filter(column => column.type !== FILTER_TYPE.NONE);

    columnsWithFilter.forEach(filter => {
        const { type, field } = filter;

        const isFilterTypeMultiSelect = type === FILTER_TYPE.MULTISELECT
            || type === FILTER_TYPE.CHECKBOX
            || type === FILTER_TYPE.AUTOCOMPLETE;
        const searchField = search[field];
        const isSearchFieldUndefined = typeof searchField === 'undefined';
        const isSearchFieldString = typeof searchField === 'string';
        const isSearchFieldArray = Array.isArray(searchField);

        switch (true) {
            case field === 'eventStartTime' && isSearchFieldUndefined:
                const newDateFormatted = Moment(new Date()).format('DD-MM-YYYY');

                filters[field] = {
                    to: newDateFormatted,
                    from: newDateFormatted
                };
                break;
            case isFilterTypeMultiSelect && isSearchFieldUndefined:
                filters[field] = [];
                break;
            case isFilterTypeMultiSelect && isSearchFieldString:
                filters[field] = [searchField];
                break;
            case isFilterTypeMultiSelect && !isSearchFieldString:
                filters[field] = searchField;
                break;
            case isSearchFieldString:
                //query-parse insert space instead plus symbol, so convert it back
                if (field === 'phone' || field === 'email') {
                    filters[field] = searchField.replace(' ', '+');
                } else {
                    filters[field] = searchField;
                }
                break;
            case isSearchFieldArray:
                const toIndex = (searchField as string[]).findIndex(field => field.indexOf('to') !== -1);
                const fromIndex = (searchField as string[]).findIndex(field => field.indexOf('from') !== -1);

                const to = searchField[toIndex];
                const from = searchField[fromIndex];

                filters[field] = {
                    to: '',
                    from: ''
                };

                if (typeof to !== 'undefined') {
                    filters[field].to = to.split('_')[1];
                }

                if (typeof from !== 'undefined') {
                    filters[field].from = from.split('_')[1];
                }
                break;
            case field === 'birthday':
            case field === 'createdAt':
            case field === 'updatedAt':
            case field === 'sentAt':
            case field === 'date':
            case field === 'finishDate':
            case field === 'startDate':
            case field === 'startTime':
            case field === 'endTime':
            case field === 'eventStartTime':
            case field === 'clubStartDate':
            case field === 'statusSetAt':
                filters[field] = {
                    to: '',
                    from: ''
                };
                break;
            default:
                filters[field] = '';
        }
    });

    return filters;
}

export function checkIsFilterExist(filters: any): boolean {
    let result = [];
    for (let key in filters) {
        const filterValue = filters[key];
        const isFilterValueArray = Array.isArray(filterValue);
        const isFilterValueString = typeof filterValue === 'string';
        const isFilterValueArrayOrString = isFilterValueArray || isFilterValueString;
        const isFilterValueObject = typeof filterValue === 'object';

        //ex: birthday: {to: '...', from: '...'}
        if (isFilterValueObject && Object.keys(filterValue).length > 0) {
            for (let innerKey in filterValue) {
                result.push(filterValue[innerKey] !== '');
            }
        }

        if (isFilterValueArrayOrString && filterValue.length > 0) {
            result.push(true);
        }
    }

    return result.some(check => check === true);
}

export function getSearchFilter(filters: any): string {
    let search = [];

    for (let field in filters) {
        const fieldValue = filters[field];
        const isFieldValueString = typeof fieldValue === 'string';
        const isFieldValueArray = Array.isArray(fieldValue);
        const isFieldValueObject = typeof fieldValue === 'object';

        switch (true) {
            case fieldValue !== '' && isFieldValueString:
                search.push(`${field}=${fieldValue}`);
                break;
            case fieldValue !== '' && isFieldValueArray:
                const subSearch = fieldValue.map((value: any) => `${field}=${value}`).join('&');
                search.push(subSearch);
                break;
            case fieldValue !== '' && isFieldValueObject:
                let addInSearch = false;

                for (let innerField in fieldValue) {
                    const innerFieldValue = fieldValue[innerField];
                    if (innerFieldValue !== '') {
                        addInSearch = true;
                    }
                }

                for (let innerField in fieldValue) {
                    const innerFieldValue = fieldValue[innerField];
                    if (addInSearch) {
                        search.push(`${field}=${innerField}_${innerFieldValue}`);
                    }
                }
                break;
        }
    }

    return search.filter(val => val !== '').join('&');
}
