import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Promise } from 'bluebird';
import * as propz from 'propz';
import { parse } from 'query-string';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { Tournament } from 'models/tournament';
import { FilterField } from 'models/filter';
import { FILTER_TYPE, DEFAULT_LIMIT, DEFAULT_SKIP } from 'consts/table';
import {
   getAllSchoolUnionPastTournaments,
   getSchoolUnionPastTournaments,
   getSchoolUnionPastTournamentsCount
} from 'services/schoolUnion/tournaments';
import {
   getFilters,
   checkIsFilterExist,
   getSearchFilter
} from 'helpers/filters';
import { getServerFieldSectionWhere } from 'helpers/table';
import {
   getSelectOptionForTournamentAvailableSports,
   getSelectOptionForTournamentSeasons,
   getSelectOptionForAges
} from 'helpers/select';
import { getTournamentBookingLink } from 'helpers/tournament';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DialogMUI from '@mui/material/Dialog';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Filters from 'components/Filters';
import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';
import EmptyDataIcon from 'components/EmptyDataIcon';
import TournamentCard from 'components/TournamentCard';
import TournamentInfo from 'components/TournamentInfo';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ScrollToTop from 'components/ScrollToTop';
import Loader from 'components/loader';

interface Props {
   school: School;
}

const FILTER_FIELDS: FilterField[] = [
   {
      text: 'Name',
      field: 'name',
      type: FILTER_TYPE.TEXT
   },
   {
      text: 'Sport',
      field: 'sport',
      type: FILTER_TYPE.AUTOCOMPLETE
   },
   {
      text: 'Season',
      field: 'tournamentSeasons',
      type: FILTER_TYPE.MULTISELECT
   },
   {
      text: 'Age',
      field: 'tournamentAges',
      type: FILTER_TYPE.MULTISELECT
   },
   {
      text: 'Start date',
      field: 'startTime',
      type: FILTER_TYPE.DATE_INTERVAL
   }
];

const EmptyItems = styled(Box)(
   sx({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      opacity: '0.4',
      mt: 5
   })
);

const EmptyItemsText = styled(Typography)(
   sx({
      fontWeight: 'bold',
      textAlign: 'center'
   })
);

const Dialog = styled(DialogMUI)(
   sx({
      '& .MuiDialog-container': {
         alignItems: 'initial'
      }
   })
);

export default function TournamentResults(props: Props) {
   const location = useLocation();

   const [searchParams, setSearchParams] = useSearchParams();

   const { school } = props;
   const { id: activeSchoolId } = school;

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [isFetching, setIsFetching] = useState<boolean>(false);
   const [isShowFilters, setIsShowFilters] = useState<boolean>(false);
   const [pastTournaments, setPastTournaments] = useState<Tournament[]>([]);
   const [allPastTournamentsCount, setAllPastTournamentsCount] = useState<number>(0);
   const [allPastTournaments, setAllPastTournaments] = useState<Tournament[]>([]);
   const [filters, setFilters] = useState({});
   const [selectedItem, setSelectedItem] = useState<Tournament>();
   const [isTournamentModalOpen, setIsTournamentModalOpen] = useState(false);
   const [currentPage, setCurrentPage] = useState<number>(1);

   const pastTournamentsCount = pastTournaments.length;
   const isPastTournamentsExist = pastTournaments.length > 0;

   const isSelectedItemExist = typeof selectedItem !== 'undefined';

   const ageGroupsNaming = propz.get(school, ['ageGroupsNaming']);

   const options = {
      sport: getSelectOptionForTournamentAvailableSports(allPastTournaments),
      tournamentSeasons: getSelectOptionForTournamentSeasons(allPastTournaments),
      tournamentAges: getSelectOptionForAges(ageGroupsNaming)
   };

   const filterButtonIcon = isShowFilters ? <FilterAltOffIcon /> : <FilterAltIcon />;
   const filterButtonText = isShowFilters ? 'Hide Filters' : 'Show Filters';

   const onScroll = useCallback((event: Event) => {
      if (pastTournamentsCount >= allPastTournamentsCount) {
         return; 
      }

      const { target } = event;
      const scrollHeight = propz.get(target, ['scrollHeight']);
      const scrollTop = propz.get(target, ['scrollTop']);
      const windowInnerHeight = window.innerHeight;

      const isBottomOfPage = scrollHeight - (scrollTop + windowInnerHeight) < 100;

      if (isBottomOfPage && !isFetching) {
         setIsFetching(true);
      }
   }, [pastTournamentsCount, allPastTournamentsCount, isFetching]);

   useEffect(() => {
      setIsLoading(true);

      const search = parse(location.search);
      const filters = getFilters(FILTER_FIELDS, search);

      const where = getServerFieldSectionWhere(filters);
      const queryFilter = {
         where
      }

      const promises = [
         getSchoolUnionPastTournaments(activeSchoolId, queryFilter),
         getSchoolUnionPastTournamentsCount(activeSchoolId, where)
      ];
      Promise.all(promises).then(([pastTournaments, tournamentsCountObj]) => {
         setPastTournaments(pastTournaments);
         setAllPastTournamentsCount(tournamentsCountObj.count);
         setFilters(filters);
         setIsLoading(false);
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchParams]);

   useEffect(() => {
      if (isFetching && pastTournamentsCount < allPastTournamentsCount) {
         const where = getServerFieldSectionWhere(filters);
         const filter = {
            where,
            limit: DEFAULT_LIMIT,
            skip: currentPage * DEFAULT_LIMIT,
            order: 'startTime DESC'
         };

         getSchoolUnionPastTournaments(activeSchoolId, filter)
            .then((tournaments) => {
               setPastTournaments(prevTournaments => [...prevTournaments, ...tournaments]);
               setCurrentPage(prevState => prevState + 1);
            })
            .finally(() => setIsFetching(false));
      }
   }, [isFetching, pastTournamentsCount, allPastTournamentsCount, activeSchoolId, filters, currentPage]);

   useEffect(() => {
      const content = document.querySelector('#main-content');
      const isContentExist = content !== null;
      
      isContentExist && content.addEventListener('scroll', onScroll);

      return function () {
         isContentExist && content.removeEventListener('scroll', onScroll);
      }
   }, [onScroll]);

   const onBookOnlineClick = (tournamentId: string) => {
      const url = getTournamentBookingLink(tournamentId);

      window.open(url, '_blank');
   };

   const bottomRef = useRef(null);

   useEffect(() => {
      const currentRef = bottomRef.current; 
   
      const observer = new IntersectionObserver(
         (entries) => {
            if (entries[0].isIntersecting && pastTournamentsCount < allPastTournamentsCount) {
               setIsFetching(true);
            }
         },
         { threshold: 1.0 }
      );
   
      if (currentRef) {
         observer.observe(currentRef);
      }
   
      return () => {
         if (currentRef) {
            observer.unobserve(currentRef);
         }
      };
   }, [pastTournamentsCount, allPastTournamentsCount]);
   

   const renderTournamentInfoModal = () => {
      return (
         <Dialog
            open={isTournamentModalOpen}
            onClose={closeModal}
            scroll='paper'
            fullWidth={true}
            maxWidth='sm'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
         >
            {isSelectedItemExist &&
               <TournamentInfo
                  tournament={selectedItem}
                  school={school}
                  onClose={closeModal}
                  onBookOnlineClick={onBookOnlineClick}
               />
            }
         </Dialog>
      );
   };

   const onFilterChange = (event: any, filterField: string): void => {
      const filterValue = propz.get(event, ['target', 'value']);

      const nextFilters = {
         ...filters,
         [filterField]: filterValue
      };

      setCurrentPage(1);
      setFilters(nextFilters);
   };

   const onApplyFilterClick = (): void => {
      let search = [];

      const isFilterExist = checkIsFilterExist(filters);

      if (isFilterExist) {
         search.push(getSearchFilter(filters));
      }

      const searchParams: any = parse(search.join('&'));

      setSearchParams(searchParams);
   };

   const onClearFilterClick = (): void => {
      setSearchParams('');

      const search = parse(location.search);
      const filters = getFilters(FILTER_FIELDS, search);

      setFilters(filters);
   };

   const onTournamentInfoClick = (tournament: Tournament) => {
      setSelectedItem(tournament)
      setIsTournamentModalOpen(true);
   };

   const closeModal = () => {
      setIsTournamentModalOpen(false);
   };

   if (isLoading) {
      return (
         <Loader />
      );
   };

   return (
      <PageWrapper>
         <div id="back-to-top-anchor"></div>
         {renderTournamentInfoModal()}

         <PageTitle text="Results" />

         <Button
            variant="outlined"
            startIcon={filterButtonIcon}
            sx={{ my: 2 }}
            onClick={() => setIsShowFilters(!isShowFilters)}
         >
            {filterButtonText}
         </Button>

         {isShowFilters &&
            <Box>
               <Filters
                  fields={FILTER_FIELDS}
                  filters={filters}
                  options={options}
                  onFilterChange={onFilterChange}
                  onApplyFilterClick={onApplyFilterClick}
                  onClearFilterClick={onClearFilterClick}
               />

               <Divider sx={{ my: 3 }} />
            </Box>
         }

         {isPastTournamentsExist
            ? <Grid container spacing={3} >
                  {pastTournaments.map(item => {
                     const { id } = item;

                     return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                           <TournamentCard
                              tournament={item}
                              school={school}
                              onTournamentInfoClick={onTournamentInfoClick}
                           />
                        </Grid>
                     );
                  })}
                  <div ref={bottomRef}></div>
               </Grid>
            : <EmptyItems>
               <EmptyDataIcon />

               <EmptyItemsText>
                  There are no tournaments to display.
               </EmptyItemsText>
            </EmptyItems>
         }

         {(isFetching && pastTournamentsCount < allPastTournamentsCount) &&
            <Box sx={{ mt: 3 }}>
               <Loader />
            </Box>
         }

         <ScrollToTop {...props}>
            <Fab color="primary" size="small" aria-label="scroll back to top">
               <KeyboardArrowUpIcon />
            </Fab>
         </ScrollToTop>
      </PageWrapper>
   );
};