import React from 'react';

export default function ChallengeIcon() {
   return (
      <svg
         version="1.1"
         id="Capa_1"
         xmlns="http://www.w3.org/2000/svg"
         width="24px"
         height="24px"
         x="0px"
         y="0px"
         viewBox="0 0 512 512"
         fill="#000000b3"
      >
         <g>
            <g>
               <path
                  d="M156.585,214.35c-1.74-1.47-3.99-2.35-6.44-2.35c-5.52,0-10,4.48-10,10c0,3.46,1.76,6.51,4.43,8.3
               c1.59,1.08,3.51,1.7,5.57,1.7c5.52,0,10-4.48,10-10C160.145,218.93,158.765,216.19,156.585,214.35z"
               />
            </g>
         </g>
         <g>
            <g>
               <path
                  d="M511.287,77.389c-1.275-3.622-4.512-6.194-8.328-6.618l-55.688-6.187l-6.187-55.688c-0.425-3.825-3.007-7.066-6.64-8.336
               c-3.632-1.269-7.671-0.342-10.387,2.385c-1.215,1.219,11.364-11.406-79.317,79.607C309.979,62.54,270.572,52,230.145,52
               c-127.113,0-230,102.871-230,230c0,127.112,102.871,230,230,230c127.113,0,230-102.871,230-230
               c0-40.427-10.54-79.833-30.551-114.595c72.715-72.983,79.057-79.349,79.339-79.631c-0.042,0.042-0.172,0.173,0.01-0.01
               C511.651,85.042,512.563,81.01,511.287,77.389z M423.51,31.845L423.51,31.845c-0.022,0.022-0.093,0.094-0.119,0.12
               C423.406,31.949,423.443,31.912,423.51,31.845z M423.51,31.845l4.236,38.128c-10.324,10.338-60.806,60.891-65.479,65.571
               l-4.22-38.013C417.82,37.554,423.318,32.038,423.51,31.845z M171.349,200.373c2.708,4.813,8.805,6.521,13.619,3.813
               c14.78-8.314,29.135-12.186,45.177-12.186c20.616,0,40.188,6.937,56.09,19.681l-28.55,28.59c-8.102-5.379-17.6-8.27-27.539-8.27
               c-27.57,0-50,22.43-50,50c0,27.57,22.43,50,50,50s50-22.43,50-50c0-9.968-2.906-19.488-8.308-27.6l28.548-28.589
               c12.795,15.92,19.76,35.527,19.76,56.189c0,49.626-40.374,90-90,90c-49.626,0-90-40.374-90-90c0-8.6,1.043-14.849,2.61-21.585
               c1.251-5.379-2.096-10.754-7.475-12.005c-5.377-1.251-10.753,2.096-12.005,7.475c-2.223,9.559-3.13,17.125-3.13,26.115
               c0,60.654,49.346,110,110,110s110-49.346,110-110c0-26.015-9.01-50.665-25.549-70.421l28.321-28.362
               c28.332,32.274,41.409,74.717,36.009,117.952C369.371,375.755,305.41,432,230.145,432c-82.71,0-150-67.29-150-150
               s67.29-150,150-150c36.617,0,71.267,13.104,98.628,37.083l-28.327,28.366C280.711,180.975,256.103,172,230.145,172
               c-19.348,0-37.333,4.826-54.983,14.754C170.349,189.462,168.641,195.559,171.349,200.373z M223.078,289.076
               c3.888,3.883,10.22,3.917,14.142-0.01l19.988-20.017c1.921,3.992,2.937,8.393,2.937,12.951c0,16.542-13.458,30-30,30
               s-30-13.458-30-30s13.458-30,30-30c4.541,0,8.929,1.009,12.912,2.918l-19.989,20.016
               C219.166,278.842,219.17,285.173,223.078,289.076z M440.145,282c0,115.794-94.206,210-210,210s-210-94.206-210-210
               s94.205-210,210-210c38.34,0,75.669,10.375,108.277,30.038l5.735,51.64l-1.226,1.227C311.77,127.171,272.099,112,230.145,112
               c-93.738,0-170,76.262-170,170s76.262,170,170,170c85.299,0,157.789-63.75,168.623-148.321
               c6.174-49.442-8.964-97.959-41.684-134.65l1.076-1.077l51.945,5.768C429.769,206.329,440.145,243.658,440.145,282z
                  M414.616,154.098l-38.365-4.261c19-19.024,22.282-22.31,22.477-22.505c-0.248,0.249-7.262,7.271,43.165-43.223l38.242,4.248
               C478.679,89.819,491.484,76.971,414.616,154.098z"
               />
            </g>
         </g>
         <g></g>
         <g></g>
         <g></g>
         <g></g>
         <g></g>
         <g></g>
         <g></g>
         <g></g>
         <g></g>
         <g></g>
         <g></g>
         <g></g>
         <g></g>
         <g></g>
         <g></g>
      </svg>
   );
}
