import React, { useState } from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { SchoolEventIndividualScore, SchoolEventIndividualData } from 'models/event';
import { SchoolHouse } from 'models/house';
import { getIndividualBasicData } from 'helpers/score';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainerMUI from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const TABLE_DATA_LIMIT = 20;

const TABLE_COLUMNS: TableColumn[] = [
   {
      text: 'Name',
      value: 'name',
      align: 'left'
   },
   {
      text: 'Age',
      value: 'age',
      align: 'center'
   },
   {
      text: 'Gender',
      value: 'gender',
      align: 'center'
   },
   {
      text: 'House',
      value: 'houseName',
      align: 'center'
   },
   {
      text: 'Active Challenges',
      value: 'challengesCount',
      align: 'center'
   },
   {
      text: 'Completed Challenges',
      value: 'challengesWithResultCount',
      align: 'center'
   },
   {
      text: 'Points',
      value: 'points',
      align: 'right'
   }
];

type ColumnValue = 'name' | 'age' | 'gender' | 'houseName' | 'challengesCount' | 'challengesWithResultCount' | 'points';

interface TableColumn {
   text: string;
   value: ColumnValue;
   align: string;
}

interface Props {
   school: School;
   results: SchoolEventIndividualScore[];
   participants: SchoolEventIndividualData[];
   houses: SchoolHouse[];
}

const TableWrapper = styled(Box)(
   sx({
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '6px',
      overflowY: 'hidden',
      maxHeight: '600px'
   })
);

const TableContainer = styled(TableContainerMUI)(
   sx({
      maxHeight: '600px'
   })
);

const TableHeadCell = styled(TableCell)(
   sx({
      fontWeight: 'bold'
   })
);

export default function LeaderboardTable(props: Props) {
   const { results, houses, school, participants } = props;
   const { ageGroupsNaming } = school;

   const [isShowAll, setIsShowAll] = useState(false);

   const scores: any = isShowAll
      ? getIndividualBasicData(participants, results, houses, ageGroupsNaming)
      : getIndividualBasicData(participants, results, houses, ageGroupsNaming).splice(0, TABLE_DATA_LIMIT);

   const columns = TABLE_COLUMNS.map((column) => {
      const { text, value, align } = column;

      return (
         <TableHeadCell key={value} sx={{ textAlign: align }}>
            {text}
         </TableHeadCell>
      );
   });

   const rows = scores.map((score: any, index: number) => {
      const rowCells = TABLE_COLUMNS.map((column: TableColumn) => {
         const { value, align } = column;

         const isScoreValueExist = typeof score[value] !== 'undefined' && score[value] !== '';
         const tableCellContent = isScoreValueExist ? score[value] : '-';

         return (
            <TableCell key={value} sx={{ textAlign: align }}>
               {tableCellContent}
            </TableCell>
         );
      });

      return (
         <TableRow key={score.id}>
            <TableCell>{index + 1}</TableCell>

            {rowCells}
         </TableRow>
      );
   });

   const onShowAllChange = () => {
      setIsShowAll((prevState) => !prevState);
   };

   return (
      <>
         <Box>
            <FormControlLabel control={<Checkbox checked={isShowAll} onChange={onShowAllChange} />} label="Show all" />
         </Box>

         <TableWrapper>
            <TableContainer>
               <Table stickyHeader>
                  <TableHead>
                     <TableRow>
                        <TableHeadCell>#</TableHeadCell>

                        {columns}
                     </TableRow>
                  </TableHead>

                  <TableBody>{rows}</TableBody>
               </Table>
            </TableContainer>
         </TableWrapper>
      </>
   );
}
