export const EVENT_VIEW_TABS = {
   TEAMS: 'Teams',
   RESULTS: 'Results',
   DETAILS: 'Details',
   GALLERY: 'Gallery',
   MATCH_REPORT: 'Match report',
   EVENT_FEED: 'Event feed'
};

export const DEFAULT_EVENT_DETAIL_ROWS = [
   { title: 'Name', field: 'name' },
   { title: 'Location', field: 'location' },
   { title: 'Ages', field: 'ages' },
   { title: 'Gender', field: 'gender' }
];

export const EVENT_DETAIL_ROWS = [
   { title: 'Event Description', field: 'description' },
   { title: 'Short Event Description', field: 'shortDescription' },
   { title: 'Kit notes', field: 'kitNotes' },
   { title: 'Comments', field: 'comments' },
   // { title: 'Members of staff', field: 'membersOfStaff' },
   // { title: 'Coach', field: 'coaches' },
   { title: 'Team departs', field: 'teamDeparts' },
   { title: 'Team returns', field: 'teamReturns' },
   { title: 'Meet time', field: 'meetTime' },
   { title: 'Tea time', field: 'teaTime' },
   { title: 'Lunch time', field: 'lunchTime' },
   // { title: 'Sport description', field: 'sportDescription' }
];

export const CLUB_EVENT_DETAIL_ROWS = [
   // { title: 'Activity', field: 'activity' },
   { title: 'Event Description', field: 'description' },
   { title: 'Short Event Description', field: 'shortDescription' },
   { title: 'Clothing/Kit notes', field: 'kitNotes' },
   { title: 'Comments', field: 'comments' },
   // { title: 'Leader/Coach', field: 'coaches' },
   // { title: 'Members of staff', field: 'membersOfStaff' },
   { title: 'Group departs', field: 'teamDeparts' },
   { title: 'Group returns', field: 'teamReturns' },
   { title: 'Meet time', field: 'meetTime' },
   { title: 'Tea time', field: 'teaTime' },
   { title: 'Lunch time', field: 'lunchTime' },
   // { title: 'Sport description', field: 'sportDescription' }
];
