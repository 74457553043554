import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface Props {
   text: string;
}

export default function Error(props: Props) {
   const { text } = props;

   return (
      <Box component='div'>
         <Typography variant='h3' textAlign='center'>{text}</Typography>
      </Box>
   )
}
