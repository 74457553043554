import React, { EventHandler } from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import { default as SelectMUI } from '@mui/material/Select';
import { SelectChangeEvent } from '@mui/material/Select';
import { SelectOption } from '../../models/table';
import { ENTER_BUTTON_KEY } from '../Filters/Filters';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';

interface Props {
   value: any;
   onChange: (event: SelectChangeEvent, field: string) => void;
   inputLabel: string;
   field: string;
   onEnterPress?: (event: any) => void;
   options?: SelectOption[];
   isMultiple?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
         width: 250,
         mt: 2
      },
   },
};

const ChipWrapper = styled(Box)(
   sx({
      display: 'flex',
      flexWrap: 'wrap',
      gap: 0.5,
      '& .MuiChip-root': {
         height: '23px'
      }
   })
);

export default function Select(props: Props) {
   const {
      value = '',
      field,
      options = [],
      onChange,
      onEnterPress,
      inputLabel,
      isMultiple
   } = props;

   const optionsFormatted = options.map(option => {
      const {text, value} = option;

      return {
         text: String(text),
         value: String(value)
      }
   });

   const valueFormatted = isMultiple
      ? value.map((item: any) => String(item))
      : String(value);

   const isOnEnterPressExist = typeof onEnterPress !== 'undefined';

   const selectOptions = optionsFormatted.map(option => {
      const { value: optionValue, text } = option;

      return (
         <MenuItem key={optionValue} value={optionValue}>
            {isMultiple && <Checkbox checked={value.includes(optionValue)} />}
            <ListItemText primary={text} />
         </MenuItem>
      );
   });

   const renderSelectValue = (selected: string | string[]) => {
      const selectedOption: SelectOption | undefined = optionsFormatted.find(option => option.value === selected);
      const isSelectedOptionsExist = typeof selectedOption !== 'undefined';

      return (
         <>
            {isMultiple &&
               <ChipWrapper>
                  {Array.isArray(selected) &&
                     selected.map((selectedValue: string) => {
                        const selectedOption: SelectOption | undefined = optionsFormatted.find(option => option.value === selectedValue);
                        const isSelectedOptionsExist = typeof selectedOption !== 'undefined';

                        return <Chip key={selectedValue} label={isSelectedOptionsExist && selectedOption.text} />
                     })
                  }
               </ChipWrapper>
            }
            {!isMultiple && isSelectedOptionsExist && selectedOption.text}
         </>
      )
   };

   const onOpenPress = (event: React.KeyboardEvent) => {
   const isEnterButtonPressed = event.keyCode === ENTER_BUTTON_KEY;

   if (isEnterButtonPressed && isOnEnterPressExist) onEnterPress(event);
};

   return (
      <FormControl fullWidth>
         <InputLabel id={`${inputLabel}-select-label`}>{inputLabel}</InputLabel>

         <SelectMUI
            labelId={`${inputLabel}-select-label`}
            id={`${inputLabel}-select`}
            value={valueFormatted}
            label={inputLabel}
            multiple={isMultiple}
            renderValue={(selected) => renderSelectValue(selected)}
            onChange={(event) => onChange(event, field)}
            onOpen={(event: any) => onOpenPress(event)}
            MenuProps={MenuProps}
         >
            {selectOptions}
         </SelectMUI>
      </FormControl>
   )
}
