import React, { useState } from 'react';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { SelectChangeEvent } from '@mui/material/Select';
import { CLUB_SCHEDULE_HOURS_INTERVALS } from 'consts/club';
import { getSelectOptionForClubsScheduleHoursIntervals } from 'helpers/select';
import {
   Scheduler,
   DayView,
   WeekView,
   Appointments,
   Toolbar,
   ViewSwitcher,
   TodayButton,
   DateNavigator
} from '@devexpress/dx-react-scheduler-material-ui';
import { ViewState } from '@devexpress/dx-react-scheduler';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Select from 'components/Select';
import Loader from 'components/loader';

const SCHEDULE_HEIGHT = 460;
const START_DAY_HOUR = 7;
const END_DAY_HOUR = 20;

interface Props {
   isLoading: boolean;
   items: any;
   onCurrentDateChange: (date: Date) => void;
   onEventClick: (id: string) => void;
};

const Wrapper = styled(Box)(
   sx({
      position: 'relative',
      borderBottom: '1px solid #e0e0e0'
   })
);

const SchedulerOverlay = styled(Box)(
   sx({
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: '10',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0, 0.15)'
   })
);

export default function Schedule(props: Props) {
   const { items, onCurrentDateChange, onEventClick, isLoading } = props;

   const [hoursInterval, setHoursInterval] = useState(CLUB_SCHEDULE_HOURS_INTERVALS.TWO_HOURS);
   const [isShowWeekend, setIsShowWeekend] = useState(false);

   const now = new Date();
   const currentDate = now.toISOString();

   const excludedDays = isShowWeekend ? [] : [0, 6];
   const hoursIntervalOptions = getSelectOptionForClubsScheduleHoursIntervals();

   const onHoursIntervalChange = (event: SelectChangeEvent, field?: string) => {
      const value = propz.get(event, ['target', 'value'], '');

      setHoursInterval(value);
   }

   const AppointmentComponent = (props: any) => {
      return <Appointments.Appointment {...props} onClick={(event) => onEventClick(event.data.id)} />;
   };

   return (
      <Wrapper>
         <Grid container spacing={3} sx={{ px: 3 }}>
            <Grid item xs={8}>
               <FormControlLabel
                  control={
                     <Checkbox checked={isShowWeekend} onChange={(event) => setIsShowWeekend(!isShowWeekend)} />
                  }
                  label="Show weekend"
               />
            </Grid>

            <Grid item xs={4}>
               <Select
                  value={hoursInterval}
                  field='hoursInterval'
                  inputLabel='Hours Interval'
                  options={hoursIntervalOptions}
                  onChange={onHoursIntervalChange}
               />
            </Grid>
         </Grid>

         <Scheduler data={items} height={SCHEDULE_HEIGHT} firstDayOfWeek={1}>
            {isLoading &&
               <SchedulerOverlay>
                  <Loader />
               </SchedulerOverlay>
            }

            <ViewState
               defaultCurrentDate={currentDate}
               defaultCurrentViewName="Week"
               onCurrentDateChange={onCurrentDateChange}
            />

            <DayView
               startDayHour={START_DAY_HOUR}
               endDayHour={END_DAY_HOUR}
               cellDuration={Number(hoursInterval)}
            />
            
            <WeekView
               startDayHour={START_DAY_HOUR}
               endDayHour={END_DAY_HOUR}
               cellDuration={Number(hoursInterval)}
               excludedDays={excludedDays}
            />

            <Toolbar />
            <DateNavigator />
            <TodayButton />
            <ViewSwitcher />
            <Appointments appointmentComponent={AppointmentComponent} />
         </Scheduler>
      </Wrapper>
   )
};
