import React, { useState, useEffect } from 'react';
import * as propz from 'propz';
import { Promise } from 'bluebird';
import { useLocation, useSearchParams } from 'react-router-dom';
import { parse } from 'query-string';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { FilterField } from 'models/filter';
import { SchoolLeague } from 'models/league';
import { FILTER_TYPE } from 'consts/table';
import { LEAGUE_AGGREGATION_TYPE } from 'consts/league';
import { getSchoolLeagues } from 'services/league';
import { getSchoolSports } from 'services/school';
import { getAllSchoolForms } from 'services/forms';
import { getFilters, checkIsFilterExist, getSearchFilter } from 'helpers/filters';
import { getServerFieldSectionWhere } from 'helpers/table';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Filters from 'components/Filters';
import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';
import LeagueCard from '../Leagues/LeagueCard';
import EmptyDataIcon from 'components/EmptyDataIcon';
import Loader from 'components/loader';

const FILTER_FIELDS: FilterField[] = [
   {
      text: 'Name',
      field: 'name',
      type: FILTER_TYPE.TEXT
   }
];

interface Props {
   school: School;
};

const EmptyItems = styled(Box)(
   sx({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      opacity: '0.4',
      mt: 5
   })
);

const EmptyItemsText = styled(Typography)(
   sx({
      fontWeight: 'bold',
      textAlign: 'center'
   })
);

export default function Challenges(props: Props) {
   const location = useLocation();

   const [searchParams, setSearchParams] = useSearchParams();

   const { school } = props;
   const { id: schoolId } = school;

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [isShowFilters, setIsShowFilters] = useState<boolean>(false);
   const [items, setItems] = useState<SchoolLeague[]>([]);
   const [filters, setFilters] = useState({});

   const isChallengesExist = items.length > 0;

   const filterButtonIcon = isShowFilters ? <FilterAltOffIcon /> : <FilterAltIcon />;
   const filterButtonText = isShowFilters ? 'Hide Filters' : 'Show Filters';

   useEffect(() => {
      setIsLoading(true);

      const search = parse(location.search);
      const filters = getFilters(FILTER_FIELDS, search);

      const isFilterExist = checkIsFilterExist(filters);

      let where = {
         aggregationType: {
            $in: [LEAGUE_AGGREGATION_TYPE.INDIVIDUAL]
         }
      };

      if (isFilterExist) {
         where = {
            ...where,
            ...getServerFieldSectionWhere(filters)
         }
      };

      const promises = [
         getSchoolLeagues(schoolId, { where }),
         getSchoolSports(school),
         getAllSchoolForms(schoolId)
      ];

      Promise.all(promises)
         .then(([leagues, sports, forms]) => {
            setItems(leagues);
            setFilters(filters);
            setIsLoading(false);
         });

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchParams]);

   const onFilterChange = (event: any, filterField: string): void => {
      const filterValue = propz.get(event, ['target', 'value']);

      const nextFilters = {
         ...filters,
         [filterField]: filterValue
      };

      setFilters(nextFilters);
   };

   const onApplyFilterClick = (): void => {
      let search = [];

      const isFilterExist = checkIsFilterExist(filters);

      if (isFilterExist) {
         search.push(getSearchFilter(filters));
      }

      const searchParams: any = parse(search.join('&'));

      setSearchParams(searchParams);
   };

   const onClearFilterClick = (): void => {
      setSearchParams('');

      const search = parse(location.search);
      
      const filters = getFilters(FILTER_FIELDS, search);

      setFilters(filters);
   };

   if (isLoading) {
      return <Loader />
   }

   return (
      <PageWrapper>
         <PageTitle text="Challenges" />

         <Button
            variant="outlined"
            startIcon={filterButtonIcon}
            sx={{ my: 2 }}
            onClick={() => setIsShowFilters(!isShowFilters)}
         >
            {filterButtonText}
         </Button>

         {isShowFilters &&
            <Box>
               <Filters
                  fields={FILTER_FIELDS}
                  filters={filters}
                  onFilterChange={onFilterChange}
                  onApplyFilterClick={onApplyFilterClick}
                  onClearFilterClick={onClearFilterClick}
               />

               <Divider sx={{ my: 3 }} />
            </Box>
         }

         {isChallengesExist
            ? <Grid container spacing={3} >
                  {items.map(item => {
                     const { id } = item;

                     return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                           <LeagueCard league={item} />
                        </Grid>
                     );
                  })
               }
            </Grid>
            : <EmptyItems>
               <EmptyDataIcon />

               <EmptyItemsText>
                  There are no leagues to display.
               </EmptyItemsText>
            </EmptyItems>
         }
      </PageWrapper>
   );
};
