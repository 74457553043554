import React from 'react';
import * as propz from 'propz';
import { SchoolEvent } from 'models/event';
import { convertPoints, plainPointsToDistanceString, plainPointsToTimeString } from 'helpers/score';
import { SPORT_POINTS_TYPE } from 'consts/sport';
import { DEFAULT_DISTANCE_MASK, DEFAULT_TIME_MASK } from 'consts/calendar';

interface Props {
   event: SchoolEvent;
   plainPoints: number;
   presence?: number;
}

export default function DefaultScore(props: Props) {
   const { event, plainPoints, presence } = props;

   const isPresenceExist = typeof presence !== 'undefined';

   const renderScore = () => {
      const pointsType = propz.get(event, ['sport', 'points', 'display']);
      const pointsMask = propz.get(event, ['sport', 'points', 'inputMask']);

      let result;
      let mask;

      switch (pointsType) {
         case SPORT_POINTS_TYPE.PLAIN:
            result = convertPoints(plainPoints, pointsType).str;
            break;

         case SPORT_POINTS_TYPE.TIME:
            mask = pointsMask ? pointsMask : DEFAULT_TIME_MASK;
            result = plainPointsToTimeString(plainPoints, mask);
            break;

         case SPORT_POINTS_TYPE.DISTANCE:
            mask = pointsMask ? pointsMask : DEFAULT_DISTANCE_MASK;
            result = plainPointsToDistanceString(plainPoints, mask);
            break;

         case SPORT_POINTS_TYPE.PRESENCE_ONLY:
            result = isPresenceExist && convertPoints(presence, pointsType).str;
            break;
      }

      return (
         <div>
            {result}
         </div>
      );
   };

   return renderScore();
};
