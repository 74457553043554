import * as propz from 'propz';
import { Tournament } from 'models/tournament';

export const TV_PREFIX = 'tv_';

export function getDomainParts() {
   return document.location.host.split('.').slice();
}

export function getProtocol() {
   return `${document.location.protocol}//`;
}

export function getTournamentPublicSiteLink(tournament: Tournament): string {
   const tournamentDomain = propz.get(tournament, ['domain'], '');
   const domainParts = getDomainParts();

   if (typeof tournamentDomain !== 'undefined') {
      domainParts.splice(0, 1, tournamentDomain);
      return getProtocol() + domainParts.join('.');
   } else {
      return '';
   }
}

export function getPublicSiteLink(domain: string): string {
   const domainParts = getDomainParts();

   if (typeof domain !== 'undefined') {
      domainParts.splice(0, 1, domain);
      return getProtocol() + domainParts.join('.');
   } else {
      return '';
   }
}

export function getTournamentTVPublicSiteLink(tournament: Tournament): string {
   const tournamentDomain = propz.get(tournament, ['domain'], '');
   const domainParts = getDomainParts();

   if (typeof tournamentDomain !== 'undefined') {
      domainParts.splice(0, 1, tournamentDomain);
      return getProtocol() + TV_PREFIX + domainParts.join('.');
   } else {
      return '';
   }
}
