export const EVENT_STATUS = {
   DRAFT: 'DRAFT', // can be filled with any data, visible to creator only
   SENDING_INVITES: 'SENDING_INVITES', // in process of generating and sending invites
   INVITES_SENT: 'INVITES_SENT', // all invites are delivered to recipients
   COLLECTING_INVITE_RESPONSE: 'COLLECTING_INVITE_RESPONSE', // got at least one decision from invite in case of multiple invites sent
   ACCEPTED: 'ACCEPTED', // every invite recipient accepted invite
   REJECTED: 'REJECTED', // every invite recipient rejected invite
   FINISHED: 'FINISHED', // event finished
   ON_HOLD: 'ON_HOLD', // event temporary paused
   CANCELED: 'CANCELED' // event canceled because of some reason
};

export const EVENT_SUB_TYPES_SERVER_TO_CLIENT_MAPPING = {
   FIXTURE_EVENT: 'Fixture',
   CLUB_EVENT: 'Club',
   TOURNAMENT_EVENT: 'Tournament'
};

export const EVENT_STATUS_SERVER_TO_CLIENT_MAPPING: any = {
   DRAFT: 'Draft',
   SENDING_INVITES: 'Sending invites',
   INVITES_SENT: 'Invites sent',
   COLLECTING_INVITE_RESPONSE: 'Collecting invite response',
   ACCEPTED: 'Accepted',
   REJECTED: 'Rejected',
   FINISHED: 'Finished',
   ON_HOLD: 'On hold',
   CANCELED: 'Cancelled'
};

export const EVENT_TYPES = {
   EXTERNAL_SCHOOLS: 'EXTERNAL_SCHOOLS',
   INTERNAL_HOUSES: 'INTERNAL_HOUSES',
   INTERNAL_TEAMS: 'INTERNAL_TEAMS'
};

export const EVENT_TYPES_SERVER_TO_CLIENT_MAPPING = {
   EXTERNAL_SCHOOLS: 'Inter-schools',
   INTERNAL_HOUSES: 'House',
   INTERNAL_TEAMS: 'Internal'
};

export const EVENT_RESULTS_MODE = {
   POINTS: 'POINTS',
   RESULTS: 'RESULTS',
   PLACES: 'PLACES'
};

export const EVENT_RESULTS_MODE_SERVER_TO_CLIENT_MAPPING = {
   POINTS: 'Points',
   RESULTS: 'Results',
   PLACES: 'Places'
};

export const EVENT_TABS = [
   'Teams',
   'Results',
   'Details',
   'Gallery',
   'Match report',
  //  'Event feed' use when event feed is implemented
];
