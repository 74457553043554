import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import SquadInTouchLogo from '../SquadInTouchLogo/SquadInTouchLogo';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SCREEN_SIZE } from 'consts/responsive';

interface FooterWrapperProps {
   isPhoneScreenSize: boolean;
}

const FooterWrapper = styled(Box, {
   shouldForwardProp: (prop) => prop !== 'isPhoneScreenSize'
})<FooterWrapperProps>(
   ({ isPhoneScreenSize }) => ({
      padding: '24px',
      marginTop: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      ...(isPhoneScreenSize && {
         flexDirection: 'column',
         gap: '20px',
         alignItems: 'center'
      })
   }),
)

export default function Footer() {
   const isPhoneScreenSize: boolean = useMediaQuery(SCREEN_SIZE.PHONE);

   return (
      <FooterWrapper isPhoneScreenSize={isPhoneScreenSize}>
         <SquadInTouchLogo />

         <Box component='div' sx={{ ...(isPhoneScreenSize && { textAlign: 'center' }) }}>
            <Typography variant='body1' mb={1}>
               Powered by Squad In Touch
            </Typography>

            <Link href="https://squadintouch.co.uk/">
               <Typography variant='body1'>
                  www.squadintouch.co.uk
               </Typography>
            </Link>
         </Box>
      </FooterWrapper>
   )
}
