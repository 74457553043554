import React, { useState } from 'react';
import * as propz from 'propz';
import Moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, experimental_sx as sx } from '@mui/system';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import { Tournament } from 'models/tournament';
import { SCREEN_SIZE } from 'consts/responsive';
import { PUBLIC_SITE_STATUS_OPTIONS } from 'consts/tournament';
import { getTournamentPublicSiteLink } from 'helpers/link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TournamentIcon from 'components/SvgIcons/TournamentIcon';
import 'swiper/swiper-bundle.min.css';

const SWIPER_THUMB_SLIDE_DEFAULT_WIDTH = 120;

interface Props {
   tournaments: Tournament[];
   onTournamentInfoClick: (tournament: Tournament) => void;
   onBookOnlineClick: (tournamentId: string) => void;
};

interface TournamentSlideButtonsWrapperProps {
   isPublicSiteAvailable: boolean;
}

const MainSwiper = styled(Swiper)(
   sx({
      width: '100%',
      height: '400px',
      borderRadius: '6px',

      '& .swiper-button-next, .swiper-button-prev': {
         transition: '.25s',
         height: '100%',
         top: 0,
         padding: '0 8px',
         mt: 0,
         background: '#c7d0f082',
         backdropFilter: 'blur(2px)',

         '&:after': {
            fontSize: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
         }
      },

      '& .swiper-button-prev': {
         left: 0,
      },

      '& .swiper-button-next': {
         right: 0
      }
   })
);

const MainSwiperSlide = styled(SwiperSlide)(
   sx({
      display: 'flex',
      flexDirection: 'column',
      p: 2,
      boxSizing: 'border-box',
      transition: 'transform 0.25s',
      userSelect: 'none',
      textAlign: 'center',
      border: '1px solid #ccc',
      borderRadius: '5px',

      ':not(.swiper-slide-active)': {
         borderColor: 'red',
         transform: 'scale(0.88)',
         position: 'relative',
         overflow: 'hidden',
         border: '1px solid #cccccca6',

         '&:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            background: '#cccccca6'
         }
      },

      '& img': {
         width: '100%',
         height: '150px',
         objectFit: 'cover',
         borderRadius: '4px'
      }
   })
);

const SwiperThumb = styled(Swiper)(
   sx({
      mt: 4
   })
);

const SwiperThumbSlide = styled(SwiperSlide)(
   sx({
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      overflow: 'hidden',
      boxSizing: 'border-box',
      cursor: 'pointer',

      ':not(.swiper-slide-thumb-active)': {
         '&:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            background: '#000',
            opacity: '0.5',
            zIndex: 10
         }
      },
   
      '&.swiper-slide-thumb-active': {
         borderColor: '#1976D2'
      },
      
      '& img': {
         width: '100%',
         height: '100%',
         objectFit: 'cover'
      }
   })
);

const SwiperThumbSlideText = styled(Typography)(
   sx({
      position: 'absolute',
      bottom: '2px',
      right: '2px',
      lineHeight: 1,
      color: '#606060',
      fontSize: '12px',
      background: '#fff',
      borderRadius: '4px',
      padding: '2px',
      zIndex: 11
   })
);

const TournamentTitle = styled(Typography)(
   sx({
      flex: '0 0 auto',
      mb: 2
   })
);

const TournamentImageWrapper = styled(Box)(
   sx({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      flex: '1 0 auto'
   })
);

const TournamentSlideButtonsAndInfoWrapper = styled(Box)(
   sx({
      flex: '0 0 auto'
   })
);

const TournamentSlideButtonsWrapper = styled(Box, {
   shouldForwardProp: (prop) => prop !== 'isPublicSiteAvailable'
})<TournamentSlideButtonsWrapperProps>(
   ({ isPublicSiteAvailable }) => sx({
      display: 'flex',
      justifyContent: isPublicSiteAvailable ? 'space-between' : 'flex-end',
      width: '100%'
   })
);

const InfoButton = styled(Button)(
   sx({
      '& .MuiButton-startIcon': {
         mr: 0,
         zIndex: -1
      }
   })
);

const TournamentInfoWrapper = styled(Box)(
   sx({
      flex: '0 0 auto',
      display: 'flex',
      justifyContent: 'space-between'
   })
);

const TournamentInfo = styled(Box)(
   sx({
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
   })
);

const TournamentInfoText = styled(Typography)(
   sx({
      fontSize: '14px',
      color: '#797979'
   })
);

const BookingButton = styled(Button)(
   sx({
      position: 'absolute',
      bottom: '50%',
      left: 0,
      transform: 'translateY(50%)'
   })
);

export default function TournamentSlider(props: Props) {
   const { tournaments, onTournamentInfoClick, onBookOnlineClick } = props;
   const tournamentsCount = tournaments.length;

   const now = new Date();

   const isTabletOrPhoneScreenSize = useMediaQuery(SCREEN_SIZE.TABLET);

   const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
   const [swiperWidth, setSwiperWidth] = useState<number>(0);

   const slidesPerView = isTabletOrPhoneScreenSize ? 1 : 2

   let thumbSlidesPerView = tournamentsCount > 10
      ? Math.ceil(swiperWidth / SWIPER_THUMB_SLIDE_DEFAULT_WIDTH)
      : tournamentsCount;

   const isNavigationShow = !isTabletOrPhoneScreenSize && tournamentsCount > 1;
   const isLoopActive = tournamentsCount > 1;

   const getMainSlides = () => {
      return tournaments.map(item => {
         const { id: tournamentId } = item;
         const photos = propz.get(item, ['photos'], []);
         const tournamentPhoto = photos[0];
         const tournamentPhotoUrl = propz.get(tournamentPhoto, ['picUrl'], '');
         const isTournamentPhotoUrlExist = tournamentPhotoUrl !== '';

         const tournamentWebsiteLink = getTournamentPublicSiteLink(item);
         const publicSiteStatus = propz.get(item, ['publicSite', 'status'], '');
         const isPublicSiteAvailable = publicSiteStatus === PUBLIC_SITE_STATUS_OPTIONS.PUBLIC_AVAILABLE;
         const startTime = propz.get(item, ['startTime'], '');
         const tournamentStartTime = Moment(startTime).format('YYYY-MM-DD · HH:mm a');
         const postcode = propz.get(item, ['venue', 'postcodeData', 'postcode'], '');
         const placeData = propz.get(item, ['venue', 'placeData']);
         const placeDataName = propz.get(item, ['venue', 'placeData', 'name'], '');
         const isPlaceDataExist = typeof placeData !== 'undefined';
         const location = isPlaceDataExist
            ? `${placeDataName} ${postcode}`
            : postcode;

         const autoEnrollmentTeamsSettings = propz.get(item, ['autoEnrollmentTeamsSettings'], []);
         const isAutoEnrollmentToWaitingList = propz.get(item, ['isAutoEnrollmentToWaitingList'], false);

         const isSpacesLeft = autoEnrollmentTeamsSettings.some((setting) => {
            const currentNumberTeams = propz.get(setting, ['currentNumberTeams']);
            const maximumNumberTeams = propz.get(setting, ['maximumNumberTeams']);

            const spacesLeft = maximumNumberTeams - currentNumberTeams;

            return spacesLeft > 0;
         });

         const isSomeMaximumNumberTeamsEqualsZero = autoEnrollmentTeamsSettings.some((setting) => {
            const maximumNumberTeams = propz.get(setting, ['maximumNumberTeams']);
      
            return maximumNumberTeams === 0;
         });

         const entriesOpen = propz.get(item, ['entriesOpen'], '');
         const deadlineForEntries = propz.get(item, ['deadlineForEntries'], '');

         const isEntriesOpenExist = entriesOpen !== '';
         const isDeadlineForEntriesExist = deadlineForEntries !== '';
         const isEntriesOpenDatePassed = new Date(entriesOpen) < now;
         const isDeadlineForEntriesPassed = new Date(deadlineForEntries) < now;

         const isBookOnlineButtonShow = isEntriesOpenExist &&
            isEntriesOpenDatePassed &&
            isDeadlineForEntriesExist &&
            !isDeadlineForEntriesPassed;

         // **1. Extract waitingListCount with a default value of 0**
         const waitingListCount = propz.get(item, ['waitingListCount'], 0);

         // **2. Update bookOnlineButtonText to include waitingListCount**
         const bookOnlineButtonText = (!isSpacesLeft || isAutoEnrollmentToWaitingList) && !isSomeMaximumNumberTeamsEqualsZero
            ? `Book onto WL (${waitingListCount})` // Changed here
            : 'Book online';

         return (
            <MainSwiperSlide key={item.id}>
               <TournamentTitle>{item.name}</TournamentTitle>

               <TournamentImageWrapper>
                  {isTournamentPhotoUrlExist
                     ? <img src={tournamentPhotoUrl} alt="tournament_picture" />
                     : <TournamentIcon width={70} height={115} />
                  }

                  {isBookOnlineButtonShow &&
                     <BookingButton
                        onClick={() => onBookOnlineClick(tournamentId)}
                        color='error'
                        variant='contained'
                     >
                        {bookOnlineButtonText}
                     </BookingButton>
                  }
               </TournamentImageWrapper>

               <TournamentSlideButtonsAndInfoWrapper>
                  <TournamentSlideButtonsWrapper isPublicSiteAvailable={isPublicSiteAvailable}>
                     {isPublicSiteAvailable &&
                        <Button
                           variant="outlined"
                           size="small"
                           href={tournamentWebsiteLink}
                           target='_blank'
                        >
                           Tournament website
                        </Button>
                     }
                     
                     <InfoButton
                        size="small"
                        variant="outlined"
                        startIcon={<InfoOutlinedIcon />}
                        onClick={() => onTournamentInfoClick(item)}
                     />
                  </TournamentSlideButtonsWrapper>

                  <Divider sx={{ my: 2 }} />

                  <TournamentInfoWrapper>
                     <TournamentInfo>
                        <AccessTimeIcon color="info" />
                        <TournamentInfoText>{tournamentStartTime}</TournamentInfoText>
                     </TournamentInfo>

                     <TournamentInfo>
                        <LocationOnIcon color="info" />
                        <TournamentInfoText>{location}</TournamentInfoText>
                     </TournamentInfo>
                  </TournamentInfoWrapper>
               </TournamentSlideButtonsAndInfoWrapper>
            </MainSwiperSlide>
         )
      });
   };

   const getThumbSlides = () => {
      return tournaments.map((item, index) => {
         const photos = propz.get(item, ['photos'], []);
         const tournamentPhoto = photos[0];
         const tournamentPhotoUrl = propz.get(tournamentPhoto, ['picUrl'], '');
         const isTournamentPhotoUrlExist = tournamentPhotoUrl !== '';
         
         return (
            <SwiperThumbSlide key={item.id}>
               {isTournamentPhotoUrlExist
                  ? <img src={tournamentPhotoUrl} alt="tournament_picture" />
                  : <TournamentIcon />
               }

               <SwiperThumbSlideText>{index + 1}</SwiperThumbSlideText>
            </SwiperThumbSlide>
         );
      });
   };

   return (
      <>
         <MainSwiper
            slidesPerView={slidesPerView}
            modules={[Navigation, Thumbs]}
            thumbs={{swiper: thumbsSwiper}}
            spaceBetween={10}
            centeredSlides
            navigation={isNavigationShow}
            loop={isLoopActive}
            loopedSlides={tournamentsCount}
         >
            {getMainSlides()}
         </MainSwiper>

         {tournamentsCount > 3 &&
            <SwiperThumb
               onSwiper={setThumbsSwiper}
               slidesPerView={thumbSlidesPerView}
               modules={[FreeMode, Thumbs]}
               direction={'horizontal'}
               spaceBetween={10}
               onInit={(swiper) => setSwiperWidth(swiper.width)}
               freeMode
            >
               {getThumbSlides()}
            </SwiperThumb>
         }
      </>
   );
}
