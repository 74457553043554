import React, { useState, useEffect, useCallback } from 'react';
import * as propz from 'propz';
import { Promise } from 'bluebird';
import { parse } from 'query-string';
import { styled, experimental_sx as sx } from '@mui/system';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { School } from 'models/school';
import { Tournament } from 'models/tournament';
import { FilterField } from 'models/filter';
import { Sport } from 'models/sport';
import { FILTER_TYPE } from 'consts/table';
import { DEFAULT_LIMIT } from 'consts/table';
import { getSchoolTournaments, getSchoolTournamentsCount } from 'services/school';
import { getSchoolSports } from 'services/school';
import { getFilters, checkIsFilterExist, getSearchFilter } from 'helpers/filters';
import { getServerFieldSectionWhere } from 'helpers/table';
import { getFilterForSchoolTournaments } from 'helpers/filters';
import { getSelectOptionForAllTournamentSeasons, getSelectOptionForSport } from 'helpers/select';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TournamentCard from 'components/TournamentCard';
import Filters from 'components/Filters';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DialogMUI from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentMUI from '@mui/material/DialogContent';
import DialogTitleMUI from '@mui/material/DialogTitle';
import ScrollToTop from 'components/ScrollToTop';
import Loader from 'components/loader';
import TournamentDetails from './TournamentDetails';
import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';
import EmptyDataIcon from 'components/EmptyDataIcon';

interface Props {
   school: School;
}

const FILTER_FIELDS: FilterField[] = [
   {
      text: 'Sports / Activities',
      field: 'sport',
      type: FILTER_TYPE.AUTOCOMPLETE
   },
   {
      text: 'Name',
      field: 'name',
      type: FILTER_TYPE.TEXT
   },
   {
      text: 'Season',
      field: 'tournamentSeasons',
      type: FILTER_TYPE.MULTISELECT
   },
   {
      text: 'Start date',
      field: 'startTime',
      type: FILTER_TYPE.DATE_INTERVAL
   }
];

const Dialog = styled(DialogMUI)(
   sx({
      '& .MuiDialog-container': {
         alignItems: 'initial'
      }
   })
);

const DialogContent = styled(DialogContentMUI)(
   sx({
      p: 0,
      borderTop: 0
   })
);

const DialogTitle = styled(DialogTitleMUI)(
   sx({
      background: '#2b8ded'
   })
);

const EmptyItems = styled(Box)(
   sx({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      opacity: '0.4',
      mt: 5
   })
);

const EmptyItemsText = styled(Typography)(
   sx({
      fontWeight: 'bold',
      textAlign: 'center'
   })
);

export default function Tournaments(props: Props) {
   const location = useLocation();

   const [searchParams, setSearchParams] = useSearchParams();

   const [isLoading, setIsLoading] = useState(false);
   const [isFetching, setIsFetching] = useState<boolean>(false);
   const [items, setItems] = useState<Tournament[]>([]);
   const [selectedItem, setSelectedItem] = useState<Tournament>();
   const [allItemsCount, setAllItemsCount] = useState<number>(0);
   const [sports, setSports] = useState<Sport[]>([]);
   const [isShowFilters, setIsShowFilters] = useState(false);
   const [isTournamentModalOpen, setIsTournamentModalOpen] = useState(false);
   const [currentPage, setCurrentPage] = useState<number>(1);
   const [filters, setFilters] = useState({});

   const isSelectedItemExist = typeof selectedItem !== 'undefined';
   const itemsCount = items.length;

   const { school } = props;
   const { id: schoolId } = school;

   const filterButtonIcon = isShowFilters ? <FilterAltOffIcon /> : <FilterAltIcon />;
   const filterButtonText = isShowFilters ? 'Hide Filters' : 'Show Filters';

   const isTournamentsExist = items.length > 0;

   const options = {
      sport: getSelectOptionForSport(sports),
      tournamentSeasons: getSelectOptionForAllTournamentSeasons()
   };

   const onScroll = useCallback((event: Event) => {
      const { target } = event;

      const scrollHeight = propz.get(target, ['scrollHeight']);
      const scrollTop = propz.get(target, ['scrollTop']);
      const windowInnerHeight = window.innerHeight;

      const isBottomOfPage = scrollHeight - (scrollTop + windowInnerHeight) < 100;

      if (isBottomOfPage && itemsCount < allItemsCount) {
         setIsFetching(true);
      };
   }, [itemsCount, allItemsCount]);

   useEffect(() => {
      setIsLoading(true);

      const search = parse(location.search);
      const filters = getFilters(FILTER_FIELDS, search);

      const where = getServerFieldSectionWhere(filters);

      const promises = [
         getSchoolTournaments(schoolId, getFilterForSchoolTournaments(where)),
         getSchoolTournamentsCount(schoolId, getFilterForSchoolTournaments(where)),
         getSchoolSports(school)
      ];

      Promise.all(promises)
         .then(([tournaments, tournamentsCountObj, sports]) => {
            setItems(tournaments);
            setAllItemsCount(tournamentsCountObj.count);
            setFilters(filters);
            setSports(sports);
            setIsLoading(false);
         })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchParams]);

   useEffect(() => {
      if (isFetching) {
         const where = getServerFieldSectionWhere(filters);

         const queryFilter = {
            ...getFilterForSchoolTournaments(where),
            skip: currentPage * DEFAULT_LIMIT
         };

         getSchoolTournaments(schoolId, queryFilter)
            .then((tournaments: Tournament[]) => {
               setItems([...items, ...tournaments]);
               setCurrentPage(prevState => prevState + 1);
            })
            .finally(() => setIsFetching(false));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isFetching]);

   useEffect(() => {
      const content = document.querySelector('#main-content');
      const isContentExist = content !== null;
      
      isContentExist && content.addEventListener('scroll', onScroll);

      return function () {
         isContentExist && content.removeEventListener('scroll', onScroll);
      }
   }, [onScroll]);

   const onModalClose = () => {
      setIsTournamentModalOpen(false);
   };

   const onFilterChange = (event: any, filterField: string): void => {
      const filterValue = propz.get(event, ['target', 'value']);

      const nextFilters = {
         ...filters,
         [filterField]: filterValue
      };

      setCurrentPage(1);
      setFilters(nextFilters);
   };

   const onApplyFilterClick = (): void => {
      let search = [];

      const isFilterExist = checkIsFilterExist(filters);

      if (isFilterExist) {
         search.push(getSearchFilter(filters));
      }

      const searchParams: any = parse(search.join('&'));

      setSearchParams(searchParams);
   };

   const onClearFilterClick = (): void => {
      setSearchParams('');

      const search = parse(location.search);
      const filters = getFilters(FILTER_FIELDS, search);

      setFilters(filters);
   };

   const renderTournamentInfoModal = () => {
      const tournamentName = propz.get(selectedItem, ['name'], '');

      return (
         <Dialog
            open={isTournamentModalOpen}
            onClose={onModalClose}
            scroll='paper'
            fullWidth={true}
            maxWidth='sm'
         >
            <DialogTitle id="scroll-dialog-title" color={'white'}>
               {tournamentName}
            </DialogTitle>

            <DialogContent dividers={true}>
               {isSelectedItemExist &&
                  <TournamentDetails tournament={selectedItem} school={school} />
               }
            </DialogContent>

            <DialogActions>
               <Button onClick={onModalClose}>Close</Button>
            </DialogActions>
         </Dialog>
      );
   };

   if (isLoading) {
      return (
         <Loader />
      );
   }

   return (
      <PageWrapper>
         {renderTournamentInfoModal()}
         <div id="back-to-top-anchor"></div>

         <PageTitle text="Tournaments" />

         <Button
            variant="outlined"
            startIcon={filterButtonIcon}
            sx={{ my: 2 }}
            onClick={() => setIsShowFilters(!isShowFilters)}
         >
            {filterButtonText}
         </Button>

         {isShowFilters &&
            <Box>
               <Filters
                  fields={FILTER_FIELDS}
                  filters={filters}
                  options={options}
                  onFilterChange={onFilterChange}
                  onApplyFilterClick={onApplyFilterClick}
                  onClearFilterClick={onClearFilterClick}
               />

               <Divider sx={{ my: 3 }} />
            </Box>
         }

         {isTournamentsExist
            ? <Grid container spacing={3} >
                  {items.map(item => {
                     const { id } = item;

                     return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                           <TournamentCard
                              tournament={item}
                              school={school}
                              onTournamentInfoClick={(tournament) => {
                                 setSelectedItem(tournament);
                                 setIsTournamentModalOpen(true)
                              }}
                           />
                        </Grid>
                     );
                  })
               }
            </Grid>
            : <EmptyItems>
               <EmptyDataIcon />

               <EmptyItemsText>
                  There are no tournaments to display.
               </EmptyItemsText>
            </EmptyItems>
         }

         {(isFetching && itemsCount < allItemsCount) &&
            <Box sx={{ mt: 3 }}>
               <Loader />
            </Box>
         }

         <ScrollToTop {...props}>
            <Fab color="primary" size="small" aria-label="scroll back to top">
               <KeyboardArrowUpIcon />
            </Fab>
         </ScrollToTop>
      </PageWrapper>
   )
}
