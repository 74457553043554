import axios from 'axios';
import { EVENT_STATUS } from 'consts/event';
import { SORT, LIMIT_ALL, DEFAULT_SKIP } from 'consts/table';

export function getSchoolOngoingChallenges(activeSchoolId: string, challengeId: string, where: any = {}) {
   const config = {
      params: {
         filter: {
            where: {
               status: {
                  $in: [EVENT_STATUS.ACCEPTED]
               },
               ...where
            },
            limit: LIMIT_ALL,
            order: `startTime ${SORT.DESC}`,
            skip: DEFAULT_SKIP
         }
      }
   };

   return axios
      .get(`${window.apiBase}/public/schools/${activeSchoolId}/schoolLeagues/${challengeId}/events`, config)
      .then((response) => {
         return response.data;
      });
}

export function getSchoolPastChallenges(activeSchoolId: string, challengeId: string, where: any = {}) {
   const config = {
      params: {
         filter: {
            where: {
               status: {
                  $in: [EVENT_STATUS.FINISHED]
               },
               ...where
            },
            limit: LIMIT_ALL,
            order: `startTime ${SORT.DESC}`,
            skip: DEFAULT_SKIP
         }
      }
   };

   return axios
      .get(`${window.apiBase}/public/schools/${activeSchoolId}/schoolLeagues/${challengeId}/events`, config)
      .then((response) => {
         return response.data;
      });
}
