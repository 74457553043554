import * as propz from 'propz';
import { Tournament } from 'models/tournament';
import { SUBTYPE_OF_INDIVIDUAL_TOURNAMENTS, SUBTYPE_OF_TOURNAMENT, TOURNAMENT_TYPE } from 'consts/tournament';
import { SchoolEvent } from 'models/event';
import { KIND_SERVER_TO_CLIENT_MAPPING } from 'consts/school';

export function isIndividualTournament(tournament: Tournament): boolean {
   const tournamentType = propz.get(tournament, ['type']);

   return tournamentType === TOURNAMENT_TYPE.INDIVIDUAL_TOURNAMENT;
}

export function isAthleticSwimmingTournamentSubtype(tournament: Tournament): boolean {
   const subType = propz.get(tournament, ['subType'], '');

   return subType === SUBTYPE_OF_INDIVIDUAL_TOURNAMENTS.ATHLETIC_SWIMMING;
}

export function isRegularIndividualTournamentSubtype(tournament: Tournament): boolean {
   const subType = propz.get(tournament, ['subType'], '');

   return subType === SUBTYPE_OF_INDIVIDUAL_TOURNAMENTS.REGULAR_INDIVIDUAL;
}

export function getSeason(startTime: string): string {
   const tournamentStartTime = new Date(startTime);
   let startYear = tournamentStartTime.getFullYear();
   const seasonStart = new Date(startYear, 8, 1);

   if (seasonStart > tournamentStartTime) {
      // the season starts on September 1
      startYear = startYear - 1;
   }
   const end = startYear + 1;
   const season = startYear + '/' + end;

   return season;
}

export function filterTournamentsByUpcoming(tournaments: Tournament[]) {
   const now = new Date();
   const todayMidnight = new Date(now).setHours(0, 0, 0);

   return tournaments.filter(item => {
      const startTime = new Date(item.startTime);
      const currentTournamentMidnight = new Date(startTime).setHours(0, 0, 0);
      const isTournamentUpcoming = startTime >= now || todayMidnight === currentTournamentMidnight;

      return isTournamentUpcoming;
   });
}

export function filterTournamentsByPast(tournaments: Tournament[]) {
   const now = new Date();

   return tournaments.filter(item => {
      const startTime = new Date(item.startTime);
      const isTournamentPast = startTime < now;

      return isTournamentPast;
   });
}

export function getAllTournamentsStartTimeDates(tournaments: Tournament[]): Date[] {
   return [...tournaments]
      .filter(tournament => typeof tournament.startTime !== 'undefined')
      .map(tournament => new Date(tournament.startTime))
      .sort((tournamentStartDate1, tournamentStartDate2) => {
         return Number(tournamentStartDate2) - Number(tournamentStartDate1);
      });
};

export function getTournamentBookingLink(tournamentId: string) {
   const subDomains = document.location.host.split('.');
   subDomains[0] = 'app2';
   const domainStr = subDomains.join('.');
   const url = `//${domainStr}/tournament/invite?tournament=${tournamentId}`;

   return url;
}

export function isDuplexTournamentEvent(event: SchoolEvent): boolean {
   return event.invitedSchools
      .every(invitedSchool => invitedSchool.kind === KIND_SERVER_TO_CLIENT_MAPPING.SCHOOL_UNION);
};

export function isTriathlonTournament(tournament: Tournament): boolean {
   const tournamentSubType = propz.get(tournament, ['subType'], '');

   return tournamentSubType === SUBTYPE_OF_INDIVIDUAL_TOURNAMENTS.TRIATHLON;
};

export function isSailingTournament(tournament: Tournament): boolean {
   const tournamentSubType = propz.get(tournament, ['subType'], '');

   return tournamentSubType === SUBTYPE_OF_TOURNAMENT.SAILING;
};
