import { SchoolEvent } from 'models/event';
import { Tournament } from 'models/tournament';
import * as propz from 'propz';

export function sortByNameAsc(item1: any, item2: any) {
   switch (true) {
      case item1.name > item2.name:
         return 1;
      case item1.name < item2.name:
         return -1;
      default:
         return 0;
   }
}

export function sortByFirstLetter(item1: any, item2: any) {
   switch (true) {
      case item1 > item2:
         return 1;
      case item1 < item2:
         return -1;
      default:
         return 0;
   }
}

export function isSortedArraysOfNumbersEqual(array1: number[], array2: number[]) {
   if (array1.length !== array2.length) return false;

   let sortedArray1 = array1.sort();
   let sortedArray2 = array2.sort();

   for (let i = 0; i < array1.length; i++) {
      if (sortedArray1[i] !== sortedArray2[i]) return false;
   }

   return true;
}

export function sortByFieldDesc(field: any) {
   return (object1: any, object2: any) => object2[field] - object1[field];
}

export function sortTournamentsBySportName(currentItem: Tournament, nextItem: Tournament) {
   const currentItemSportName: string = propz.get(currentItem, ['sport', 'name'], '')
   const nextItemSportName: string = propz.get(nextItem, ['sport', 'name'], '');

   const isCurrentItemSportNameExist = currentItemSportName !== '';
   const isNextItemSportNameExist = nextItemSportName !== '';

   const currentItemSportNameFirstLetter = isCurrentItemSportNameExist
      ? currentItemSportName[0].toLowerCase()
      : '';
   const nextItemSportNameFirstLetter = isNextItemSportNameExist
      ? nextItemSportName[0].toLowerCase()
      : '';

   switch (true) {
      case currentItemSportNameFirstLetter < nextItemSportNameFirstLetter:
         return -1;

      case currentItemSportNameFirstLetter > nextItemSportNameFirstLetter:
         return 1;
   
      default:
         return 0;
   }
}

export function getCurrentSeasonStartYear() {
   const now = new Date();
   const currentMonth = now.getMonth();
   const currentDay = now.getDate();
   const isSeasonFirstHalf = currentMonth > 8 || (currentMonth === 8 && currentDay > 0);
   
   return isSeasonFirstHalf
      ? now.getFullYear()
      : now.getFullYear() - 1;
};

export function getEventSeasonStrartYear(event: SchoolEvent) {
   const { startTime } = event;
   
   const startTimeDate = new Date(startTime);
   const eventStartTimeMonth = startTimeDate.getMonth();
   const eventStartTimeDay = startTimeDate.getDate();
   const isEventFromFirstHalf = eventStartTimeMonth > 8 || (eventStartTimeMonth === 8 && eventStartTimeDay > 0);
   
   return isEventFromFirstHalf
      ? startTimeDate.getFullYear()
      : startTimeDate.getFullYear() - 1;
};

export function findClosestNumber(number: number, array: number[]) {
   let closestHigher = Number.POSITIVE_INFINITY;
   let closestLower = Number.NEGATIVE_INFINITY;

   const isArrayEmpty = array.length === 0;
   const firstItem = isArrayEmpty ? 0 : array[0];
   
   for (let i = 0; i < array.length; i++) {
      const item = array[i];

      switch (true) {
         case item === number:
            return item;

         case item > number && item < closestHigher:
            closestHigher = item;

            break;

         case item < number && item > closestLower:
            closestLower = item;

            break;
      };
   }
   
   if (closestHigher !== Number.POSITIVE_INFINITY) {
      return closestHigher;
   } else {
      return closestLower === Number.NEGATIVE_INFINITY ? firstItem : closestLower;
   }
}
