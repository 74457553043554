export const TOURNAMENT_SEASONS_MAX = 2023;
export const TOURNAMENT_SEASONS_MIN = 1986;

export const TOURNAMENT_TYPE = {
   TEAM_TOURNAMENT: 'TEAM_TOURNAMENT',
   INDIVIDUAL_TOURNAMENT: 'INDIVIDUAL_TOURNAMENT',
   SINGLE_DOUBLE_TOURNAMENT: 'SINGLE_DOUBLE_TOURNAMENT',
   MINI_TEAM_TOURNAMENT:  'MINI_TEAM_TOURNAMENT'
};

export const TOURNAMENT_DETAILS_TABS = {
   DETAILS: 'Details',
   GOVERNINIG_BODIES: 'Governing bodies',
   DOCUMENTS: 'Documents'
};

export const TOURNAMENT_DETAILS_TABS_ARRAY = [
   TOURNAMENT_DETAILS_TABS.DETAILS,
   TOURNAMENT_DETAILS_TABS.GOVERNINIG_BODIES,
   TOURNAMENT_DETAILS_TABS.DOCUMENTS
];

export const SUBTYPE_OF_TOURNAMENT = {
   FOOTBALL: 'FOOTBALL',
   CRICKET: 'CRICKET',
   SAILING: 'SAILING',
   RUGBY: 'RUGBY',
   NETBALL: 'NETBALL',
   WINNERS_ONLY: 'WINNERS_ONLY',
   BADMINTON_MT: 'BADMINTON_MT'
};

export const PUBLIC_SITE_STATUS_OPTIONS = {
   DISABLED: 'DISABLED',
   PUBLIC_AVAILABLE: 'PUBLIC_AVAILABLE',
   PROTECTED: 'PROTECTED'
};

export const SUBTYPE_OF_INDIVIDUAL_TOURNAMENTS = {
   REGULAR_INDIVIDUAL: 'REGULAR_INDIVIDUAL', // for such sports like athletic, swimming, etc.
   TRIATHLON: 'TRIATHLON',
   NATIONAL_TRIATHLON: 'NATIONAL_TRIATHLON',
   CROSS_COUNTRY: 'CROSS_COUNTRY',
   NATIONAL_CROSS_COUNTRY: 'NATIONAL_CROSS_COUNTRY',
   ATHLETIC_SWIMMING: 'ATHLETIC_SWIMMING',
   PSEUDO_TWO_LEVEL: 'PSEUDO_TWO_LEVEL',
   PSEUDO_TWO_LEVEL_RUNTIME: 'PSEUDO_TWO_LEVEL_RUNTIME'
};

export const TEAM_TOURNAMENT_DEFAULT_SCORING = [
   3, // points for win games
   2, // points for draw with goals games
   1, // points for draw without goals games
   0 // points for lose games
];
