import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import * as propz from 'propz';
import { parse } from 'query-string';
import { styled, experimental_sx as sx } from '@mui/system';
import { Promise } from 'bluebird';
import { School } from 'models/school';
import { FilterField } from 'models/filter';
import { Sport } from 'models/sport';
import { SchoolForm } from 'consts/form';
import { FILTER_TYPE } from 'consts/table';
import { getSchoolEvents } from 'services/school';
import { getSchoolSports } from 'services/school';
import { getAllSchoolForms } from 'services/forms';
import { getClubEventsForDayFilter, getEventsForWeekFilter } from 'helpers/calendar';
import { getServerFieldSectionWhere } from 'helpers/table';
import { getFilters, checkIsFilterExist, getSearchFilter } from 'helpers/filters';
import {
   getSelectOptionForAge,
   getSelectOptionForClubGender,
   getSelectOptionForSport
} from 'helpers/select';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Loader from 'components/loader';
import Filters from 'components/Filters';
import Schedule from 'components/Schedule';
import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';

interface Props {
   school: School;
}

const FILTER_FIELDS: FilterField[] = [
   {
      text: 'Sports / Activities',
      field: 'sport',
      type: FILTER_TYPE.AUTOCOMPLETE
   },
   {
      text: 'Ages',
      field: 'eventAges',
      type: FILTER_TYPE.MULTISELECT
   },
   {
      text: 'Gender',
      field: 'gender',
      type: FILTER_TYPE.SELECT
   }
];

export default function ClubEvents(props: Props) {
   const location = useLocation();
   const navigate = useNavigate();

   const [searchParams, setSearchParams] = useSearchParams();

   const { school } = props;
   const { id: schoolId } = school;

   const now = new Date();

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [isShowFilters, setIsShowFilters] = useState<boolean>(false);
   const [items, setItems] = useState<any>([]);
   const [currentWeek, setCurrentWeek] = useState<Date>(now);
   const [sports, setSports] = useState<Sport[]>([]);
   const [forms, setForms] = useState<SchoolForm[]>([]);
   const [filters, setFilters] = useState({});

   const itemsFormatted = items.map((item: any) => {
      const officialGeneratedName = propz.get(item, ['generatedNames', 'official'], '');
      const title = propz.get(item, ['generatedNames', schoolId], officialGeneratedName);
      const startDate = propz.get(item, ['startTime'], '');
      const endDate = propz.get(item, ['endTime'], '');
      const id = propz.get(item, ['id'], '');
      
      return {
         title,
         startDate,
         endDate,
         id
      }
   });

   const ageGroupName = propz.get(school, ['ageGroupsNaming'], '');

   const filterButtonIcon = isShowFilters ? <FilterAltOffIcon /> : <FilterAltIcon />;
   const filterButtonText = isShowFilters ? 'Hide Filters' : 'Show Filters';

   const options = {
      eventAges: getSelectOptionForAge(forms, ageGroupName),
      gender: getSelectOptionForClubGender(),
      sport: getSelectOptionForSport(sports)
   };

   useEffect(() => {
      setIsLoading(true);

      const search = parse(location.search);
      const filters = getFilters(FILTER_FIELDS, search);

      const where = getServerFieldSectionWhere(filters);

      const eventsFilter = getEventsForWeekFilter(currentWeek, schoolId, where);
      const clubEventsFilter = getClubEventsForDayFilter(eventsFilter);

      const promises = [
         getSchoolEvents(schoolId, clubEventsFilter),
         getSchoolSports(school),
         getAllSchoolForms(schoolId)
      ];

      Promise.all(promises)
         .then(([clubEvents, sports, forms]) => {
            setItems(clubEvents);
            setSports(sports);
            setForms(forms);
            setFilters(filters);
            setIsLoading(false);
         });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [currentWeek, searchParams]);

   const onFilterChange = (event: any, filterField: string): void => {
      const filterValue = propz.get(event, ['target', 'value']);

      const nextFilters = {
         ...filters,
         [filterField]: filterValue
      };

      setFilters(nextFilters);
   };

   const onApplyFilterClick = (): void => {
      let search = [];

      const isFilterExist = checkIsFilterExist(filters);

      if (isFilterExist) {
         search.push(getSearchFilter(filters));
      }

      const searchParams: any = parse(search.join('&'));

      setSearchParams(searchParams);
   };

   const onClearFilterClick = (): void => {
      setSearchParams('');

      const search = parse(location.search);
      const filters = getFilters(FILTER_FIELDS, search);

      setFilters(filters);
   };

   const onCurrentDateChange = (date: Date) => {
      setCurrentWeek(date);
   };

   const onEventClick = (eventId: string): void => {
      navigate(`/event/${eventId}`);
   };

   return (
      <>
         <PageWrapper>
            <PageTitle text='Club events' />

            <Button
               variant="outlined"
               startIcon={filterButtonIcon}
               sx={{ my: 2 }}
               onClick={() => setIsShowFilters(!isShowFilters)}
            >
               {filterButtonText}
            </Button>

            {isShowFilters &&
               <Box>
                  <Filters
                     fields={FILTER_FIELDS}
                     filters={filters}
                     options={options}
                     isLoading={isLoading}
                     onFilterChange={onFilterChange}
                     onApplyFilterClick={onApplyFilterClick}
                     onClearFilterClick={onClearFilterClick}
                  />

                  <Divider sx={{ my: 3 }} />
               </Box>
            }
         </PageWrapper>

         <Schedule
            isLoading={isLoading}
            items={itemsFormatted}
            onCurrentDateChange={onCurrentDateChange}
            onEventClick={onEventClick}
         />
      </>
   )
}
