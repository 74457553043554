import React from 'react';
import * as propz from 'propz';
import Moment from 'moment';
import { styled, experimental_sx as sx } from '@mui/system';
import { TournamentAnnouncement } from 'models/tournament';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import DocumentIcon from '@mui/icons-material/Announcement';
import BadgeMUI from '@mui/material/Badge';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Attachment from './Attachment';

interface Props {
   item: TournamentAnnouncement;
};

const Wrapper = styled(Box)(
   sx({
      border: '1px solid #e2e2e2',
      borderRadius: '6px'
   })
);

const DocumentPictureWrapper = styled(Box)(
   sx({
      display: 'flex',
      justifyContent: 'flex-start',
      gap: 2
   })
);

const DocumentTitle = styled(Typography)(
   sx({
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: 'bold',
      mb: 1
   })
);

const DateWrapper = styled(Box)(
   sx({
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      mb: 2,
      opacity: '0.6'
   })
);

const DocumentPicture = styled('img')(
   sx({
      width: '200px',
      borderRadius: '6px'
   })
);

const DocumentBody = styled(Box)(
   sx({
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '14px',
      wordBreak: 'break-word',
      '& p': {
         m: 0
      }
   })
);

const AttachmentsWrapper = styled(Box)(
   sx({
      p: 2
   })
);

const Badge = styled(BadgeMUI)(
   sx({
      '& .MuiBadge-badge': {
         right: -15,
         top: 12
      }
   })
);

export default function Document(props: Props) {
   const { item } = props;

   const title = propz.get(item, ['title'], '');
   const body = propz.get(item, ['body'], '');
   const date = propz.get(item, ['date'], '');
   const isDateVisible = propz.get(item, ['isDateVisible'], false);
   const dateFormatted = Moment(date).format('DD-MM-YYYY HH:mm');
   const attachments = propz.get(item, ['attachments']);
   const attachmentsImages: any[] = propz.get(attachments, ['images'], []);
   const attachmentsFiles = propz.get(attachments, ['files'], []);
   const attachmentsFilesCount = attachmentsFiles.length;
   const isAttachmentsFilesExist = attachmentsFiles.length > 0;
   const documentPictureUrl = propz.get(attachmentsImages[0], ['url'], '');
   const isDocumentPictureExist = documentPictureUrl !== '';

   const bodyContent = body.slice(body.lastIndexOf('<body>') + 6, body.indexOf('</body>'));
   const isBodyExist = typeof body !== 'undefined' && bodyContent.trim() !== '';

   return (
      <Wrapper>
         <Box sx={{p: 2}}>
            <DocumentTitle color='primary'>{title}</DocumentTitle>

            {isDateVisible &&
               <DateWrapper>
                  <AccessTimeIcon fontSize='small' />
                  <Typography variant='subtitle2'>{dateFormatted}</Typography>
               </DateWrapper>
            }

            <DocumentPictureWrapper>
               {isDocumentPictureExist
                  ? <DocumentPicture src={documentPictureUrl} alt="Document pic" />
                  : <DocumentIcon color='primary' sx={{fontSize: '82px'}} />
               }
               
               {isBodyExist &&
                  <DocumentBody dangerouslySetInnerHTML={{ __html: body }} />
               }
            </DocumentPictureWrapper>
         </Box>

         <Accordion>
            <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
               id="panel1a-header"
               sx={{ borderBottom: '1px solid #e2e2e2' }}
            >
               <Badge badgeContent={attachmentsFilesCount} color="primary" showZero>
                  <Typography>Attachments</Typography>
               </Badge>
            </AccordionSummary>

            {isAttachmentsFilesExist && (
               <AttachmentsWrapper>
                  <Grid container spacing={2}>
                     {attachmentsFiles.map(item => {
                        const { _id: itemKey } = item;

                        return (
                           <Grid item xs={3} key={itemKey}>
                              <Attachment file={item} />
                           </Grid>
                        );
                     })}
                  </Grid>
               </AttachmentsWrapper>
            )}
         </Accordion>
      </Wrapper>
   );
}
