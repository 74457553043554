import React from 'react';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { Participant as ParticipantModel } from 'models/event';
import { SchoolEvent } from 'models/event';
import { PLAYER_POSITIONS } from 'consts/player';
import {
   getParticipantImg,
   getParticipantName,
   getTextResult,
   getParticipantTeamResult,
   getParticipantTeamResultNumber,
   getParticipantExtraPointsResult,
   getParticipantAbbreviationResult,
   getHouseColors
} from 'helpers/participants';
import {
   isEventStatusFinished,
   isTeamOrTwoOnTwoSportEvent,
   getPositionName,
   isTeamSportEvent,
   getPlayersSorted,
   isMultipartyEvent,
   isLeagueEvent
} from 'helpers/event';
import { isCricket, convertCricketPoints } from 'helpers/sport/cricket'
import {
   getIndividualScoreByStudent,
   convertPointsToStringWithoutDelimeter,
   getExtraPointsByStudent,
   getAbbreviationResultByStudent,
   getAbbreviationColor
} from 'helpers/eventView';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRowMUI from '@mui/material/TableRow';

const TEAM_PLAYER_COLUMNS = [
   {
      text: '№',
      value: 'index',
      align: 'center'
   },
   {
      text: 'First name',
      value: 'firstName',
      align: 'inherit'
   },
   {
      text: 'Last name',
      value: 'lastName',
      align: 'center'
   },
   {
      text: 'Position',
      value: 'position',
      align: 'center'
   },
   {
      text: 'Captain / Sub',
      value: 'captain',
      align: 'right'
   }
];

const INDIVIDUAL_PLAYER_COLUMNS = [
   {
      text: '№',
      value: 'index',
      align: 'center'
   },
   {
      text: 'First name',
      value: 'firstName',
      align: 'left'
   },
   {
      text: 'Last name',
      value: 'lastName',
      align: 'center'
   }
];

interface Props {
   event: SchoolEvent;
   participant: ParticipantModel;
}

const TeamWrapper = styled(Box)(
   sx({
      border: '1px solid #ccc',
      borderRadius: '5px',
      flex: '0 0 auto'
   })
);

const SchoolLogo = styled(Box)(
   sx({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '80px',
      height: '80px',
      margin: '0 auto',
      textAlign: 'center',
      mb: 2,

      '& img': {
         width: '100%'
      }
   })
);

const TableRow = styled(TableRowMUI)(
   sx({
      '&:last-child td, &:last-child th': { border: 0 },
      color: '#a0a0a0'
   })
);

export default function Participant(props: Props) {
   const { participant, event } = props;

   const individualScore = propz.get(event, ['results', 'individualScore'], []);
   const teamScore = propz.get(event, ['results', 'teamScore'], []);
   const schoolScore = propz.get(event, ['results', 'schoolScore'], []);
   const players = propz.get(participant, ['teamPlayers'], []);
   const individualPlayers = propz.get(participant, ['individualPlayers'], []);

   const isLeagueEventCondition = isLeagueEvent(event);

   const isResultsExist = individualScore.some(item => {
      const result = propz.get(item, ['richScore', 'result']);

      return typeof result !== 'undefined' && result !== 0;
   });

   const isTeamResultsExist = [...teamScore, ...schoolScore].some(item => {
      const result = propz.get(item, ['richScore', 'result']);

      return typeof result !== 'undefined' && result !== 0;
   });

   const isTeamPointsExist = [...teamScore, ...schoolScore].some(item => {
      const teamPoints = propz.get(item, ['richScore', 'points']);

         return typeof teamPoints !== 'undefined' && teamPoints !== 0;
   });

   const isTeamAbbreviationResultExist = [...teamScore, ...schoolScore].some(item => {
      const teamAbbreviationResult = propz.get(item, ['richScore', 'abbreviationResult']);

      return typeof teamAbbreviationResult !== 'undefined' && teamAbbreviationResult !== '';
   });

   const isPointsExist = individualScore.some(item => {
      const points = propz.get(item, ['richScore', 'points']);

      return typeof points !== 'undefined' && points !== 0;
   });

   const isPositionsExist = individualPlayers.some(item => {
      const position = propz.get(item, ['positionId'], '');

      return position !== '';
   });

   const namePlural = propz.get(event, ['sport', 'points', 'namePlural'], '');
   const namePluralFormatted = namePlural.slice(0, 1).toUpperCase() + namePlural.slice(1);
   const isNamePluralExist = namePlural !== '';

   const participantImg = getParticipantImg(participant);
   const participantName = getParticipantName(event, participant);

   const isEventFinished = isEventStatusFinished(event);
   const isTeamOrTwoOnTwoEvent = isTeamOrTwoOnTwoSportEvent(event);

   const textResult = getTextResult(event);
   const teamResult = isTeamOrTwoOnTwoEvent
      ? getParticipantTeamResult(event, participant)
      : '';

   const teamExtraPointsResult = getParticipantExtraPointsResult(event, participant);
   const teamAbbreviationResult = getParticipantAbbreviationResult(event, participant);
   
   const leaguePositionsCount = propz.get(event, ['leaguePositionsCount'], 0);
   const isLeagueAndPositionsCountExist = isLeagueEventCondition && leaguePositionsCount !== 0;
   const individualPlayersSorted = isLeagueAndPositionsCountExist
      ? getPlayersSorted(event, individualPlayers.slice(0, leaguePositionsCount))
      : getPlayersSorted(event, individualPlayers);

   const isPlayersExist = players.length > 0 || individualPlayers.length > 0;

   const teamResultNumber = isTeamOrTwoOnTwoEvent
      ? getParticipantTeamResultNumber(event, participant)
      : 0;

   const isShowResult = (isEventFinished && isTeamOrTwoOnTwoEvent) || Boolean(teamResultNumber);
   const getIndividualPlayerColumns = () => {
      let columns = [...INDIVIDUAL_PLAYER_COLUMNS];

      if (isPositionsExist) {
         columns = [
            ...columns,
            {
               text: 'Position',
               value: 'position',
               align: 'center'
            }
         ];
      }

      if (isResultsExist) {
         columns = [
            ...columns,
            {
               text: 'Result',
               value: 'result',
               align: isPointsExist ? 'center' : 'right'
            }
         ];
      };

      if (isPointsExist) {
         columns = [
            ...columns,
            {
               text: 'Points',
               value: 'points',
               align: 'right'
            }
         ];
      };

      return columns;
   };
   
   const getTeamPlayerColumns = () => {
      let columns = [...TEAM_PLAYER_COLUMNS];

      if (isTeamResultsExist) {
         columns = [
            ...columns,
            {
               text: isNamePluralExist ? namePluralFormatted : 'Result',
               value: 'result',
               align: 'right'
            }
         ];
      };

      return columns;
   };

   const renderIndividualPlayerColumns = () => {
      let columns = getIndividualPlayerColumns();

      return (
         <TableRowMUI>
            {columns.map((item: any) => {
               return (
                  <TableCell key={item.value} align={item.align} sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                     {item.text}
                  </TableCell>
               );
            })}
         </TableRowMUI>
      );
   };

   const renderTeamPlayerColumns = () => {
      const columns = getTeamPlayerColumns();

      return (
         <TableRowMUI>
            {columns.map((item: any) => {
               return (
                  <TableCell key={item.value} align={item.align} sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                     {item.text}
                  </TableCell>
               );
            })}
         </TableRowMUI>
      );
   };

   const renderTeamPlayerRows = () => {
      const columns = getTeamPlayerColumns();

      const playersData = players.map((player: any, index: number) => {
         const { positionId, firstName, lastName, id, userId, permissionId } = player;
         const isPositionIdExist = typeof positionId !== 'undefined';

         const playerPoints = getIndividualScoreByStudent(event, userId, permissionId);
         let playerPointsStr;

         if (isCricket(event) && !isMultipartyEvent(event)) {
            const { runs, wickets } = convertCricketPoints(playerPoints);
            
            playerPointsStr = `${runs} / ${wickets}`;
          } else {
            playerPointsStr = convertPointsToStringWithoutDelimeter(event, playerPoints);
          }

         let position = '';

         if (isPositionIdExist) {
            const playerTeamId: string = player.teamId;
            const team = event.embeddedTeams.find(embeddedTeam => embeddedTeam._id === playerTeamId);
            const teamPositionName: string | undefined = propz.get(team, ['positionName'], undefined);
            const playerPositionName: string = getPositionName(positionId, event);

            if (typeof teamPositionName !== 'undefined') {
               position = `${playerPositionName} / ${teamPositionName}`;
            } else {
               position = `${playerPositionName}`;
            }
         };

         const sub: boolean = propz.get(player, ['sub']) || false;
         const isCaptain: boolean = propz.get(player, ['isCaptain']) || false;

         let playersCaptainOrSubText;

         switch (true) {
            case isCaptain && sub:
               playersCaptainOrSubText = `${PLAYER_POSITIONS.CAPTAIN} ${PLAYER_POSITIONS.SUB}`;
               break;
            case isCaptain:
               playersCaptainOrSubText = PLAYER_POSITIONS.CAPTAIN;
               break;
            case sub:
               playersCaptainOrSubText = PLAYER_POSITIONS.SUB;
               break;
            default:
               playersCaptainOrSubText = '';
         };

         return {
            index: index + 1,
            firstName,
            lastName,
            position,
            captain: playersCaptainOrSubText,
            result: playerPointsStr
         };
      });

      const playersDataSortedByPosition = [...playersData].sort((currentData: any, secondData: any) => {
         return currentData.position - secondData.position
      });

      return (playersDataSortedByPosition.map((tableCellData: any, id) => {
         return (
            <TableRow key={id}>
               {columns.map(item => {
                  const { value, align } = item;

                  const isIndex = value === 'index';
                  const isResult = value === 'result';
                  const isCaptain = value === 'captain';

                  let cellTextColor;

                  switch (true) {
                     case isIndex:
                        cellTextColor = '#000';

                        break;

                     case isResult:
                        cellTextColor = '#1976d2';
                        
                        break;

                     case isCaptain:
                        cellTextColor = '#1976d2';
                        
                        break;
                  
                     default:
                        cellTextColor = '#a0a0a0'

                        break;
                  }

                  return (
                     <TableCell key={value} sx={{
                        textAlign: align,
                        color: cellTextColor
                     }}>
                        {tableCellData[value]}
                     </TableCell>
                  );
               })}
            </TableRow>
         );
      }))
   };

   const renderIndividualPlayerRows = () => {
      const columns = getIndividualPlayerColumns();
      const hasAbbreviationResults = individualPlayersSorted.some(player => {
         const abbreviationResult = getAbbreviationResultByStudent(event, player.userId, player.permissionId);
         return Boolean(abbreviationResult);
     });
 
     if (hasAbbreviationResults) {
         columns.push({
             text: '', 
             value: 'abbreviation',
             align: 'center'
         });
     }
      return individualPlayersSorted.map((player, index) => {
         const { firstName, lastName, id, userId, permissionId } = player;
         const positionId = propz.get(player, ['positionId'], '');

         const isPositionIdExist = positionId !== '';

         let position = '';

         if (isPositionIdExist) {
            const playerTeamId: string = player.teamId;
            const team = event.embeddedTeams.find(embeddedTeam => embeddedTeam._id === playerTeamId);
            const teamPositionName: string | undefined = propz.get(team, ['positionName'], undefined);
            const playerPositionName: string = getPositionName(positionId, event);

            if (typeof teamPositionName !== 'undefined') {
               position = `${playerPositionName} / ${teamPositionName}`;
            } else {
               position = `${playerPositionName}`;
            }
         };

         const playerPoints = getIndividualScoreByStudent(event, userId, permissionId);
         const playerPointsStr = convertPointsToStringWithoutDelimeter(event, playerPoints);
         const extraPoints = getExtraPointsByStudent(event, userId);
         const extraPointsStr = String(extraPoints);
         const abbreviationResult = getAbbreviationResultByStudent(event, userId, permissionId); 
         const abbreviationColor = getAbbreviationColor(event, abbreviationResult); 

         const tableCellData: any = {
            index: index + 1,
            firstName,
            lastName,
            position,
            points: extraPointsStr,
            result: playerPointsStr,
            abbreviation: abbreviationResult ? (
               <span style={{ color: abbreviationColor, fontWeight: 'bold' }}>
                   {abbreviationResult}
               </span>
           ) : ''
         };

         return (
            <TableRow key={id}>
               {columns.map(item => {
                  const { value, align } = item;

                  const isIndex = value === 'index';
                  const isResult = value === 'result';
                  const isPoints = value === 'points';

                  let cellTextColor;

                  switch (true) {
                     case isIndex:
                        cellTextColor = '#000';

                        break;

                     case isResult:
                     case isPoints:
                        cellTextColor = '#1976d2';
                        
                        break;
                  
                     default:
                        cellTextColor = '#a0a0a0'

                        break;
                  }

                  return (
                     <TableCell key={value} sx={{
                        textAlign: align,
                        color: cellTextColor
                     }}>
                        {tableCellData[value]}
                     </TableCell>
                  );
               })}
            </TableRow>
         )
      });
   };

   const renderPlayersTable = () => {
      const rows = isTeamSportEvent(event)
         ? renderTeamPlayerRows()
         : renderIndividualPlayerRows();

      const columns = isTeamSportEvent(event)
         ? renderTeamPlayerColumns()
         : renderIndividualPlayerColumns()

      return (
         <TableContainer>
            <Table>
               <TableHead>
                  {columns}
               </TableHead>
               <TableBody>
                  {rows}
               </TableBody>
            </Table>
         </TableContainer>
      );
   };

   const houseColors = getHouseColors(participant);

   return (
      <TeamWrapper>
         <Box sx={{ p: 2 }}>
            <SchoolLogo>
               <img
                  src={participantImg}
                  srcSet={participantImg}
                  alt={participantImg}
                  loading="lazy"
                  width='100%'
               />
            </SchoolLogo>

            <Typography variant='body2' align='center'>
               {participantName}
               {houseColors.length > 0 && (
                  <span className="house-colors">
                  {houseColors.map((color, index) => (
                     <span
                        key={index}
                        style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        backgroundColor: color,
                        borderRadius: '50%',
                        marginLeft: '5px'
                        }}
                     ></span>
                  ))}
                  </span>
               )}
            </Typography>

            {isShowResult && (
               <Typography
                  variant='body2'
                  align='center'
                  color='primary'
                  sx={{ fontWeight: 'bold' }}
               >
                  {`${textResult} ${teamResult}`}
                  {teamExtraPointsResult !== '' && ` | Points: ${teamExtraPointsResult}`}
                  {teamAbbreviationResult && (
                     <>
                        {' | '}
                        <span
                           style={{
                              fontWeight: 'bold',
                              color: getAbbreviationColor(event, teamAbbreviationResult),
                           }}
                        >
                           {`${teamAbbreviationResult}`}
                        </span>
                     </>
                  )}
               </Typography>
            )}
         </Box>

         <Divider />

         {isPlayersExist
            ? renderPlayersTable()
            : <Typography
                  variant='subtitle2'
                  align='center'
                  color='primary'
                  sx={{ fontStyle: 'italic', p: 2 }}
               >
                  No team players have been added yet.
               </Typography>
         }
      </TeamWrapper>
   )
}
