export const ACTIVITIES_BLOCK_TYPE = {
   FIXTURES: 'fixtures',
   RESULTS: 'results',
   TOURNAMENTS: 'tournaments',
   TOURNAMENT_EVENTS: 'tournament events',
   TOURNAMENT_RESULTS: 'tournament results',
   EVENTS: 'events',
   ONGOING_CHALLENGES: 'ongoing challenges',
   PAST_CHALLENGES: 'past challenges',
   NEWS: 'news'
};
