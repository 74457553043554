import React from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import { Swiper as SwiperSlider, SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade, Autoplay } from 'swiper';

interface Props {
   imageUrls: string[];
}

const Swiper = styled(SwiperSlider)(
   sx({
      width: '100%',
      overflow: 'hidden'
   })
);

const Slide = styled(SwiperSlide)(
   sx({
      borderRadius: {
         md: '6px'
      },
      position: 'relative',
      pb: '33.333%',
      overflow: 'hidden',

      '& img': {
         position: 'absolute',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         objectFit: 'cover',
      }
   })
);

export default function HomeSlider(props: Props) {
   const { imageUrls } = props;

   return (
      <Swiper
         pagination={{clickable: true}}
         modules={[Pagination, EffectFade, Autoplay]}
         autoplay={{
            delay: 2500,
            disableOnInteraction: false,
         }}
         sx={{ pb: imageUrls.length === 1 ? '0' : '40px'}}
         effect={'fade'}
         loop
      >
         {imageUrls.map(url => {
            return (
               <Slide key={url}>
                  <img src={url} alt='slider_image' />
               </Slide>
            );
         })}
      </Swiper>
   );
};
