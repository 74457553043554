import React from 'react';
import { SchoolEvent } from 'models/event';
import { EVENT_RESULTS_MODE } from 'consts/event';
import { convertCricketPoints } from 'helpers/sport/cricket';
import { getParticipantTeamResult } from 'helpers/participants';
import { isShowIndividualScoreForTeam } from 'helpers/score';
import Box from '@mui/material/Box';

interface Props {
   event: SchoolEvent;
   rival: any;
   plainPoints: number;
   player?: any;
   isPlayerScore?: boolean;
}

export default function CricketScore(props: Props) {
   const {
      event,
      rival,
      plainPoints,
      player = undefined,
      isPlayerScore = false
   } = props;

   const renderScore = () => {
      const cricketResults = convertCricketPoints(plainPoints);
      const { runs, wickets } = cricketResults;

      let typeResultText = '';

      switch (event.resultsMode) {
         case EVENT_RESULTS_MODE.PLACES:
            typeResultText = 'Place: ';
            break;

         case EVENT_RESULTS_MODE.POINTS:
            typeResultText = 'Points: ';
            break;

         default:
            typeResultText = '';
            break;
      }

      const result = getParticipantTeamResult(event, rival) || '';
      const isShowIndividualScore = isShowIndividualScoreForTeam(event, player);

      return (
         <Box>
            {!isPlayerScore
               ? <Box>
                     {isShowIndividualScore && <span>{`Runs: ${runs} / Wickets: ${wickets}`}</span>}
                  </Box>
               : <Box>{typeResultText} {result}</Box>
            }
         </Box>
      );
   };

   return renderScore();
};
