import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as propz from 'propz';
import { Promise } from 'bluebird';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { SchoolHouse } from 'models/house';
import { Photo } from 'models/photo';
import { SchoolEvent } from 'models/event';
import { ACTIVITIES_BLOCK_TYPE } from 'consts/common';
import {
   getSchoolHouses,
   getSchoolPhotos,
   getSchoolEvents
} from 'services/school';
import {
   getFilterForSchoolFixturesForHomePage,
   getFilterForSchoolResultsForHomePage
} from 'helpers/filters';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TwitterWidget from 'components/TwitterWidget';
import Loader from 'components/loader';
import ActivitiesBlock from 'components/ActivitiesBlock';
import HomeSlider from 'components/HomeSlider';
import SchoolInfo from './SchoolInfo';
import SchoolHouses from './SchoolHouses';
import defaultLeaguePhoto from 'assets/default_league_photo.jpeg';

interface Props {
   school: School;
}

const SliderWrapper = styled(Box)(
   sx({
      p: {
         sm: 0,
         md: 3
      }
   })
);

export default function Home(props: Props) {
   const [isLoading, setLoading] = useState<boolean>(true);
   const [houses, setHouses] = useState<SchoolHouse[]>([]);
   const [schoolPhotos, setSchoolPhotos] = useState<Photo[]>([]);
   const [fixtures, setFixtures] = useState<SchoolEvent[]>([]);
   const [finishedEvents, setFinishedEvents] = useState([]);
   const [schoolTournaments, setSchoolTournaments] = useState([]);
   const [schoolGallery, setSchoolGallery] = useState([]);
   const [schoolNews, setSchoolNews] = useState([]);

   const isSchoolPhotosExist = schoolPhotos.length > 0;

   const isSchoolTournamentsExist = schoolTournaments.length > 0;
   const isSchoolGalleryExist = schoolGallery.length > 0;
   const isSchoolNewsExist = schoolNews.length > 0;

   const navigate = useNavigate();

   const { school } = props;
   const {
      defaultAlbumId,
      id: schoolId
   } = school;

   const isDefaultAlbumIdExist = typeof defaultAlbumId !== 'undefined' && defaultAlbumId !== '';

   const integrations = propz.get(school, ['integrations'], []);
   const schoolTwitterProfileName = integrations[0];
   const isTwitterProfileExist = typeof schoolTwitterProfileName !== 'undefined' && schoolTwitterProfileName !== '';

   const getSchoolDataPromises = useCallback(() => {
      const promises = [
         getSchoolHouses(school),
         getSchoolEvents(schoolId, getFilterForSchoolFixturesForHomePage(schoolId)),
         getSchoolEvents(schoolId, getFilterForSchoolResultsForHomePage())
      ];

      if (isDefaultAlbumIdExist) {
         promises.push(getSchoolPhotos(school, defaultAlbumId))
      };

      return promises;

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [school, defaultAlbumId, schoolId]);

   useEffect(() => {
      setLoading(true);

      const promises = getSchoolDataPromises();

      Promise.all(promises).then(([
         houses,
         fixtures,
         finishedEvents,
         photos = []
      ]) => {
         setHouses(houses);
         setSchoolPhotos(photos);
         setFixtures(fixtures);
         setFinishedEvents(finishedEvents);
         setLoading(false);
      });
   }, [getSchoolDataPromises]);

   const onEventClick = (event: SchoolEvent): void => {
      const {id: eventId} = event;

      navigate(`/event/${eventId}`);
   };

   const schoolPhotosUrls: string[] = isSchoolPhotosExist
      ? schoolPhotos.map((item: Photo) => item.picUrl)
      : [defaultLeaguePhoto];

   const isTwitterVisible = propz.get(school, ['publicSite', 'isTwitterVisible'], false);
   const isShowAggregatedHouseLeagueOnPublicWebsite = propz.get(school, ['isShowAggregatedHouseLeagueOnPublicWebsite'], false);
   const isShowWidgets = (isTwitterVisible && isTwitterProfileExist) || isShowAggregatedHouseLeagueOnPublicWebsite;

   if (isLoading) {
      return <Loader />
   }

   return (
      <Box>
         <SliderWrapper>
            <HomeSlider imageUrls={schoolPhotosUrls} />
         </SliderWrapper>

         <Grid container spacing={4} p={3}>
            <Grid item xs={12} lg={isShowWidgets ? 8 : 12}>
               <ActivitiesBlock
                  title='Fixtures'
                  type={ACTIVITIES_BLOCK_TYPE.FIXTURES}
                  items={fixtures}
                  showMoreLink='/fixtures'
                  school={school}
                  onItemClick={event => onEventClick(event as SchoolEvent)}
               />
               <ActivitiesBlock
                  title='Results'
                  type={ACTIVITIES_BLOCK_TYPE.RESULTS}
                  items={finishedEvents}
                  showMoreLink='/results'
                  school={school}
                  onItemClick={event => onEventClick(event as SchoolEvent)}
               />
               {isSchoolTournamentsExist &&
                  <ActivitiesBlock
                     title='Tournaments'
                     items={[]}
                     showMoreLink=''
                     school={school}
                     onItemClick={() => { }}
                  />
               }
               {isSchoolNewsExist &&
                  <ActivitiesBlock
                     title='News'
                     items={[]}
                     showMoreLink=''
                     school={school}
                     onItemClick={() => { }}
                  />
               }
               
               <SchoolInfo school={school} />
            </Grid>

            <Grid item xs={12} lg={isShowWidgets ? 4 : 12}>
               {isShowAggregatedHouseLeagueOnPublicWebsite &&
                  <SchoolHouses items={houses} events={finishedEvents} />
               }

               {isTwitterVisible && isTwitterProfileExist &&
                  <Box sx={{ mt: 2 }}>
                     <TwitterWidget profileName={schoolTwitterProfileName} />
                  </Box>
               }
            </Grid>
         </Grid>
      </Box>
   )
};
