export const KIND_SERVER_TO_CLIENT_MAPPING = {
   SCHOOL: 'School',
   SCHOOL_UNION: 'SchoolUnion'
};

export const PUBLIC_SCHOOL_STATUS = {
   PUBLIC_AVAILABLE: 'PUBLIC_AVAILABLE',
   PROTECTED: 'PROTECTED',
   DISABLED: 'DISABLED'
};

export const AGE_GROUPS = {
   ENGLISH: [
      'Reception', //0
      'Y1', //1
      'Y2', //2
      'Y3', //3
      'Y4', //4
      'Y5', //5
      'Y6', //6
      'Y7', //7
      'Y8', //8
      'Y9', //9
      'Y10', //10
      'Y11', //11
      'Y12', //12
      'Y13', //13
      'Y14', //14
      'N1', //15
      'N2', //16
      'Post-19' //17
   ],
   SCOTTISH: [
      'Nursery', // 0
      'P1', //1
      'P2', //2
      'P3', //3
      'P4', //4
      'P5', //5
      'P6', //6
      'P7', //7
      'S1', //8
      'S2', //9
      'S3', //10
      'S4', //11
      'S5', //12
      'S6' //13
   ],
   'U5-U18': [
      'U5', //0
      'U6', //1
      'U7', //2
      'U8', //3
      'U9', //4
      'U10', //5
      'U11', //6
      'U12', //7
      'U13', //8
      'U14', //9
      'U15', //10
      'U16', //11
      'U17', //12
      'U18', //13
      'U19', //14
      'U3', //15
      'U4' //16
   ],
   US: [
      'Pre-kindergarten', //0
      'Kindergarten', //1
      '1st Grade', //2
      '2nd Grade', //3
      '3rd Grade', //4
      '4th Grade', //5
      '5th Grade', //6
      '6th Grade', //7
      '7th Grade', //8
      '8th Grade', //9
      '9th Grade', //10
      '10th Grade', //11
      '11th Grade', //12
      '12th Grade' //13
   ]
};

export const AGE_GROUPS_SORTED = {
   ENGLISH: [
      'N1', //15
      'N2', //16
      'Reception', //0
      'Y1', //1
      'Y2', //2
      'Y3', //3
      'Y4', //4
      'Y5', //5
      'Y6', //6
      'Y7', //7
      'Y8', //8
      'Y9', //9
      'Y10', //10
      'Y11', //11
      'Y12', //12
      'Y13', //13
      'Y14', //14
      'Post-19' //17
   ],
   SCOTTISH: [
      'Nursery', // 0
      'P1', //1
      'P2', //2
      'P3', //3
      'P4', //4
      'P5', //5
      'P6', //6
      'P7', //7
      'S1', //8
      'S2', //9
      'S3', //10
      'S4', //11
      'S5', //12
      'S6' //13
   ],
   'U5-U18': [
      'U3', //15
      'U4', //16
      'U5', //0
      'U6', //1
      'U7', //2
      'U8', //3
      'U9', //4
      'U10', //5
      'U11', //6
      'U12', //7
      'U13', //8
      'U14', //9
      'U15', //10
      'U16', //11
      'U17', //12
      'U18', //13
      'U19' //14
   ],
   US: [
      'Pre-kindergarten', //0
      'Kindergarten', //1
      '1st Grade', //2
      '2nd Grade', //3
      '3rd Grade', //4
      '4th Grade', //5
      '5th Grade', //6
      '6th Grade', //7
      '7th Grade', //8
      '8th Grade', //9
      '9th Grade', //10
      '10th Grade', //11
      '11th Grade', //12
      '12th Grade' //13
   ]
};
