import React from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Wrapper = styled(Box)(
   sx({
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      width: '80%',
      margin: '0 auto',
      p: 3,
      textAlign: 'center'
   })
);

export default function NotAvailableSchool() {
  return (
    <Wrapper>
      <Typography color='#007aff' variant='h4'>
         It seems that the website with the domain name you entered cannot be found. 
      </Typography>

      <Typography variant='h5'>
         Please double-check the spelling of the domain and try again.
      </Typography>

      <Typography>
         If you believe this is an error or if you have any questions, please don't hesitate to contact our support team. You can reach us by sending an email to <Typography variant='body2' color='#007aff'>support@squadintouch.com.</Typography> Our dedicated support staff will be happy to assist you with any issues you may be experiencing.
      </Typography>
    </Wrapper>
  );
};
