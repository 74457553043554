import React, { useState, useEffect } from 'react';
import Lazy from 'lazy.js';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { FilterField } from 'models/filter';
import { FILTER_TYPE } from 'consts/table';
import { getFilters } from 'helpers/filters';
import { getFootballTournamentStagesScoreData } from 'helpers/score';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Filters from 'components/Filters';
import PageTitle from 'components/PageTitle';
import { checkAreFinishedEventsExist } from 'helpers/event';

const FILTER_FIELDS: FilterField[] = [
   {
      text: 'Group',
      field: 'group',
      type: FILTER_TYPE.SELECT
   }
];

interface Props {
   events: any[]
   ids: { id: string, kind: string, isSchoolIdInTeam?: boolean }[]
   activeGroupId?: string
   title?: string
   subTypeTournament: string
   teams: any[]
   scoring?: string
   tournamentId?: string
   tournamentScoring: number[]
   groups?: any[]
};

const TableWrapper = styled(Box)(
   sx({
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '6px',
      overflow: 'hidden'
   })
);

const TableHeadCell = styled(TableCell)(
   sx({
      fontWeight: 'bold'
   })
);

export default function Stage(props: Props) {
   const { activeGroupId, ids, events, teams, title, tournamentScoring, subTypeTournament } = props;

   const [results, setResults] = useState<any>([]);
   const [resultsFiltered, setResultsFiltered] = useState<any>([]);
   const [filters, setFilters] = useState<any>({});

   const getGroupOptions = () => {
      const groups = (Lazy(events) as any)
         .map((event: any) => event.groupWithAge)
         .uniq()
         .sort()
         .toArray();

      return groups
         .filter((group: any) => typeof group !== 'undefined')
         .map((group: any) => ({ text: group, value: group }));
   }

   const groupOptions = getGroupOptions() || [];

   const options = {
      group: groupOptions
   };

   const isResultsExist = results.length > 0;
   const columnsData = isResultsExist ? Object.keys(results[0]) : [];

   const sortResultsByPoints = (results: any[]) => {
      return [...results].sort((currentResult: any, nextResult: any) => {
         return nextResult['Points'] - currentResult['Points'];
      })
   }

   const sortResultsByName = (results: any[]) => {
      return [...results].sort((currentResult: any, nextResult: any) => {
         const partsA = currentResult.schoolName.split(', ');
         const partsB = nextResult.schoolName.split(', ');

         const firstPartComparison = partsA[0].localeCompare(partsB[0]);

         if (firstPartComparison !== 0) {
            return firstPartComparison;
         }

         const secondPartA = partsA[1];
         const secondPartB = partsB[1];

         const secondPartComparison = secondPartA[0].localeCompare(secondPartB[0]);

         if (secondPartComparison !== 0) {
            return secondPartComparison;
         }

         return secondPartA.localeCompare(secondPartB);
      })
   }

   useEffect(() => {
      const filters = getFilters(FILTER_FIELDS, {
         group: groupOptions[0].value || ''
      });

      const results = getFootballTournamentStagesScoreData(
         ids,
         events,
         teams,
         tournamentScoring,
         subTypeTournament
      );

      const eventsFiltered = events.filter((event: any) => event.groupWithAge === filters.group);
      const isFinishedEventsExist = checkAreFinishedEventsExist(eventsFiltered);
      const resultsFiltered = getFootballTournamentStagesScoreData(
         ids,
         eventsFiltered,
         teams,
         tournamentScoring,
         subTypeTournament
      );

      const resultsFilteredAndSorted = isFinishedEventsExist
         ? sortResultsByPoints(resultsFiltered)
         : sortResultsByName(resultsFiltered);

      setResults(results);
      setResultsFiltered(resultsFilteredAndSorted);
      setFilters(filters);

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const columns = columnsData.map((column) => {
      const columnText = column === 'schoolName' ? 'School name' : column;

      return <TableHeadCell key={`table_column_${column}`}>{columnText}</TableHeadCell>;
   });

   const rows = resultsFiltered.map((row: any, index: number) => {
      const rowCells = columnsData.map((column: string) => {
         const tableCellContent = row[column];

         return <TableCell key={column}>{tableCellContent}</TableCell>;
      });

      return (
         <TableRow key={`table_row_${index}`}>
            <TableCell>{index + 1}</TableCell>

            {rowCells}
         </TableRow>
      );
   });

   const onFilterChange = (event: React.FormEvent<HTMLSelectElement>, filterField: string): void => {
      const filterValue = propz.get(event, ['target', 'value']);

      if (filterField === 'group') {
         const eventsFiltered = events.filter((event: any) => event.groupWithAge === filterValue);
         const isFinishedEventsExist = checkAreFinishedEventsExist(eventsFiltered);
         const resultsFiltered = getFootballTournamentStagesScoreData(
            ids,
            eventsFiltered,
            teams,
            tournamentScoring,
            subTypeTournament
         );

         const resultsFilteredAndSorted = isFinishedEventsExist
            ? sortResultsByPoints(resultsFiltered)
            : sortResultsByName(resultsFiltered);;

         setResultsFiltered(resultsFilteredAndSorted);
      }

      const nextFilters = {
         ...filters,
         [filterField]: filterValue
      };

      setFilters(nextFilters);
   };

   return (
      <Box>
         <PageTitle text={title || ''} />

         <Box>
            <Filters
               fields={FILTER_FIELDS}
               filters={filters}
               options={options}
               onFilterChange={onFilterChange}
            />
            <Divider sx={{ my: 3 }} />
         </Box>

         <TableWrapper>
            <TableContainer>
               <Table>
                  <TableHead>
                     <TableRow>
                        <TableHeadCell>#</TableHeadCell>
                        {columns}
                     </TableRow>
                  </TableHead>

                  <TableBody>{rows}</TableBody>
               </Table>
            </TableContainer>
         </TableWrapper>
      </Box>
   );
}
