/** Returns api endpoint based on given domain name */
export function apiSelector(domainName: string): { main: string; img: string; file: string, baseFile: string } {
  const { env } = parseDomainName(domainName);
  switch (true) {
    case env === 'stage1':
      return {
        main: '//api.stage1.squadintouch.com',
        img: '//img.stage1.squadintouch.com',
        file: '//files.stage1.squadintouch.com',
        baseFile: '//files.squadintouch.s3.amazonaws.com/'
      };
    case env === 'dev':
      return {
        main: 'https://api.stage1.squadintouch.com',
        img: 'https://img.stage1.squadintouch.com',
        file: 'https://files.stage1.squadintouch.com',
        baseFile: '//files.squadintouch.s3.amazonaws.com/'
      };
    case env === 'local':
      return {
        main: '//localhost:3000',
        img: '//localhost:3001',
        file: '//localhost:3003',
        baseFile: '//files.squadintouch.s3.amazonaws.com/'
      };
    default:
      return {
        main: '//api2' + (env ? '.' + env : '') + '.squadintouch.com',
        img: '//images' + (env ? '.' + env : '') + '.squadintouch.com',
        file: '//files' + (env ? '.' + env : '') + '.squadintouch.com',
        baseFile: '//squad-in-touch-files.s3.amazonaws.com/'
      };
  }
}

/** Parses domain name to structure */
export function parseDomainName(domainName: string) {
  // http://manager.squard.com → ["manager.squard.com", "manager", undefined|stage, "squard"]
  const external = domainName.match(
    /([A-z0-9-]+)+(?:.(local|dev|stage|stage1|prod|preprod))?.(squadintouch)\.(com|co\.uk)/
  ) || [];

  const isExternalExist = external.length > 0;

  let model: string = '';
  if (isExternalExist) {
    if (external[1].substring(0, 10) === 'bigscreen_') {
      model = 'bigscreen';
    } else if (external[1].substring(0, 3) === 'tv_') {
      model = 'tv';
    } else {
      model = external[1];
    }
  }


  return {
    fullName: external[0],
    model: model,
    isStage: external[2] === 'stage' || external[2] === 'stage1',
    rootDomain: external[3],
    env: external[2]
  };
}
