import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as propz from 'propz';
import Lazy from 'lazy.js';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { SchoolEvent } from 'models/event';
import { Tournament } from 'models/tournament';
import { CLUB_GENDER_SERVER_TO_CLIENT_MAPPING } from 'consts/club';
import {
   convertPoints,
   getConvertedScore,
   getPlayersAdditionalData,
   getSchoolsAdditionalData,
   zeroPrint
} from 'helpers/score';
import {
   isDuplexTournamentEvent,
   isIndividualTournament,
   isTriathlonTournament
} from 'helpers/tournament';
import { getAgeRangeFromSingleAgeEvent, isIndividualTournamentAndTeamSport, isTeamOrTwoOnTwoSportEvent } from 'helpers/event';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainerMUI from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const TABLE_COLUMNS: TableColumn[] = [
   {
      text: 'Name',
      value: 'name',
      align: 'left'
   },
   {
      text: 'Events',
      value: 'events',
      align: 'center'
   },
   {
      text: 'Result',
      value: 'result',
      align: 'center'
   },
   {
      text: 'Position',
      value: 'position',
      align: 'center'
   },
   {
      text: 'Points',
      value: 'points',
      align: 'center'
   },
];

type ColumnValue = 'name' | 'events' | 'result' | 'position' | 'points';

interface TableColumn {
   text: string;
   value: ColumnValue;
   align: string;
};

interface Props {
   activeSchool: School;
   school: School;
   events: SchoolEvent[];
   tournament: Tournament;
};

const TableWrapper = styled(Box)(
   sx({
      overflowY: 'hidden'
   })
);

const EventWrapper = styled(Typography)(
   sx({
      cursor: 'pointer'
   })
);

const TableContainer = styled(TableContainerMUI)(
   sx({})
);

const TableHeadCell = styled(TableCell)(
   sx({
      fontWeight: 'bold'
   })
);

export default function SchoolResultsTable(props: Props) {
   const navigate = useNavigate();
   const location = useLocation();

   const { activeSchool, school, events, tournament } = props;

   const eventsFilteredByPlayersFromSelectedSchool = [...events].filter((event: any) => {
      const schoolResluts = propz.get(event, ['results', 'schoolScore'], []);
      const selectedSchoolResults = schoolResluts.find((schoolResult: any) => schoolResult.schoolId === school.id);
      const isSelectedSchoolResultsExist = typeof selectedSchoolResults !== 'undefined';

      if (isTeamOrTwoOnTwoSportEvent(event)) {
         const isPlayersExist =
            event.players.some((player: any) => player.schoolId === school.id);

         return isPlayersExist || isSelectedSchoolResultsExist;
      } else {
         const isIndividualsDataExist =
            event.individualsData.some((individual: any) => individual.schoolId === school.id);

         return isIndividualsDataExist || isSelectedSchoolResultsExist;
      }
   });

   const ageGroupsNaming = propz.get(activeSchool, ['ageGroupsNaming']);
   const defaultSport = propz.get(events[0], ['sport']);

   const schoolData = getSchoolsAdditionalData(eventsFilteredByPlayersFromSelectedSchool, school.id, school.name);
   const playersData = getPlayersAdditionalData(eventsFilteredByPlayersFromSelectedSchool, school.id, school.name);
   
   const results = [...schoolData, ...playersData];

   let totalPoints = 0;

   const isTriathlon = isTriathlonTournament(tournament);
   const isDuplexTournament = events.every((event) => isDuplexTournamentEvent(event));

   const checkIsTournamentSchoolsIncludeStudentSchool = (event: SchoolEvent) => {
      const tournamentSchoolIds = propz.get(event, ['tournament', 'schoolIds'], []);
      const tournamentSchoolIdsFormatted = tournamentSchoolIds.map((item: any) => item.schoolId);

      return tournamentSchoolIdsFormatted.includes(school.id);
   }

   if (isDuplexTournament || isIndividualTournament(tournament)) {
      totalPoints = (Lazy(results) as any)
         .map((student: any) => {
            const isStudentPointExist = typeof student.point !== 'undefined';
            const isTournamentSchoolsIncludeStudentSchool =
               student.events.every((event: SchoolEvent) => {
                  return checkIsTournamentSchoolsIncludeStudentSchool(event);
               });

            if (isStudentPointExist && !student.isTeamResultFromEvents[0]) {
               return Number(student.point);
            };

            if (
               isStudentPointExist &&
               student.isTeamResultFromEvents[0] &&
               isTournamentSchoolsIncludeStudentSchool
            ) {
               return Number(student.point);
            };

            return 0;
         })
         .sum();
   };

   const pointsColumnIndex = TABLE_COLUMNS.findIndex(column => column.value === 'points');
   
   if (isTriathlon) TABLE_COLUMNS.splice(pointsColumnIndex, 1);

   const sortStudents = (student1: any, student2: any) => {
      const placeTotal1 = student1.placesFromEvents.reduce((acc: number, score: number) => {
         return acc + score;
      }, 0);
      const placeTotal2 = student2.placesFromEvents.reduce((acc: number, score: number) => {
         return acc + score;
      }, 0);

      switch (true) {
         case isTriathlon:
            return placeTotal1 - placeTotal2;

         case student1.point < student2.point:
            return 1;

         case student1.point > student2.point:
            return -1;

         case student1.age < student2.age:
            return -1;

         case student1.age > student2.age:
            return 1;

         default:
            return 0;
      }
   };

   const getStudentName = (student: any) => {
      let name;

      if (Array.isArray(student.firstName) && Array.isArray(student.lastName)){
         name = student.firstName.map((firstName: string, index: number) => {
            return <Box key={index}>{`${firstName} ${student.lastName[index]}`}</Box>;
         });
      } else {
         name = `${student.firstName} ${student.lastName}`;
      };

      return name;
   };

   const getStudentResults = (student: any) => {
      return student.scoreFromEvents.map((score: number, index: number) => {
         if (isTriathlon) {
            const pointsConverted = convertPoints(score, student.pointTypeFromEvents[index]);
            const convertedHours = propz.get(pointsConverted, ['h']);
            const convertedMinutes = propz.get(pointsConverted, ['min']);
            const convertedSeconds = propz.get(pointsConverted, ['sec']);
            const pointsConvertedTriathlon =
               `${zeroPrint(convertedHours)}:${zeroPrint(convertedMinutes)}:${zeroPrint(convertedSeconds)}`;

            return (
               <Box key={index}>
                  {student.isTeamResultFromEvents[index]
                     ? `${pointsConvertedTriathlon} [Team Result]`
                     : `${pointsConvertedTriathlon}`
                  }
               </Box>
            );
         };

         const event = student.events[index];
         const sport = propz.get(event, ['sport'], defaultSport);
         const pointsConverted = getConvertedScore(score, sport);

         return (
            <Box key={index}>
               {student.isTeamResultFromEvents[index]
                  ? `${pointsConverted} [Team Result]`
                  : `${pointsConverted}`
               }
            </Box>
         );
      });
   };

   const getStudentPlaces = (student: any) => {
      return student.placesFromEvents.map((place: number, index: number) => {
         return <Box key={index}>{place}</Box>;
      });
   };

   const getStudentPoints = (student: any, event: SchoolEvent) => {
      return student.pointsFromEvents.map((score: number, index: number) => {
         const isTournamentSchoolsIncludeStudentSchool = checkIsTournamentSchoolsIncludeStudentSchool(event);

         const teamResultText = isTournamentSchoolsIncludeStudentSchool
            ? `${score} [Team Result]`
            : '[Team Result]';

         return (
            <Box key={index}>
               {student.isTeamResultFromEvents[index] ? teamResultText : score}
            </Box>
         );
      });
   };

   const columns = TABLE_COLUMNS.map((column) => {
      const { text, value, align } = column;

      return (
         <TableHeadCell key={value} sx={{ textAlign: align }}>
            {text}
         </TableHeadCell>
      );
   });

   const resultsFilteredAndSorted = results
      .filter((student: any) => {
         return isTriathlon
            ? student.placesFromEvents.every((place: number) => place !== 0)
            : true;
      })
      .sort(sortStudents);

   const rows = resultsFilteredAndSorted
      .map((student: any, index) => {
         let fieldNumbering = index + 1;

         const studentEventRows = student.events.map((event: SchoolEvent, index: number) => {
            const eventName = propz.get(event, ['generatedNames', 'official']);
            const age = getAgeRangeFromSingleAgeEvent(event, ageGroupsNaming) !== 'undefined'
               ? getAgeRangeFromSingleAgeEvent(event, ageGroupsNaming)
               : '';
            const eventGender = CLUB_GENDER_SERVER_TO_CLIENT_MAPPING[event.gender];

            const studentName = getStudentName(student);
            const studentResults = getStudentResults(student);
            const studentPlaces = getStudentPlaces(student);
            const studentPoints = getStudentPoints(student, event);

            const rowData = {
               name: studentName,
               events: (
                  <EventWrapper color='primary' onClick={() => onEventClick(event)}>
                     {`${age} ${eventGender} ${eventName}`}
                  </EventWrapper>
               ),
               result: studentResults[index],
               position: studentPlaces[index],
               points: studentPoints[index],
            };

            const rowCells = TABLE_COLUMNS.map((column: TableColumn) => {
               const { value, align } = column;

               const isScoreValueExist = typeof rowData[value] !== 'undefined' && rowData[value] !== '';
               const tableCellContent = isScoreValueExist ? rowData[value] : '-';

               return (
                  <TableCell key={value} sx={{ textAlign: align }}>
                     {tableCellContent}
                  </TableCell>
               );
            });

            return (
               <TableRow key={event.id}>
                  <TableCell>{fieldNumbering}</TableCell>

                  {rowCells}
               </TableRow>
            );
         });

         return studentEventRows
      });

   const onEventClick = (event: SchoolEvent) => {
      const { id: eventId } = event;
      const { pathname } = location;

      navigate(`/event/${eventId}`, {
         state: {
            selectedSchool: school,
            prevPath: pathname
         }
      });
   };

   return (
      <TableWrapper>
         <TableContainer>
            <Table stickyHeader>
               <TableHead>
                  <TableRow>
                     <TableHeadCell>#</TableHeadCell>

                     {columns}
                  </TableRow>
               </TableHead>

               <TableBody>{rows}</TableBody>
            </Table>
         </TableContainer>

         {!isTriathlon &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3, mr: 3 }}>
               <Typography sx={{ fontWeight: 'bold' }}>Total:</Typography>
               <Typography>{ totalPoints }</Typography>
            </Box>
         }
      </TableWrapper>
   );
}
