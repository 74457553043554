import React from 'react';
import * as propz from 'propz';
import { SchoolEvent } from 'models/event';
import { DEFAULT_DISTANCE_MASK, DEFAULT_TIME_MASK } from 'consts/calendar';
import { SPORT_POINTS_TYPE } from 'consts/sport';
import { convertPoints, plainPointsToDistanceString, plainPointsToTimeString } from 'helpers/score';
import Box from '@mui/material/Box';

interface Props {
   event: SchoolEvent;
   isRecord: boolean;
   plainPoints: number;
   plainExtraPoints?: any;
   isPlayerScore?: boolean;
}

export default function AthleticScore(props: Props) {
   const {
      event,
      plainPoints,
      plainExtraPoints,
      isPlayerScore = false
   } = props;

   const pointsType = propz.get(event, ['sport', 'points', 'display']);
   const pointsStep = propz.get(event, ['sport', 'points', 'pointsStep']);
   const pointsMask = propz.get(event, ['sport', 'points', 'inputMask']);

   const renderScore = () => {
      let mainPoints, mask;

      switch (pointsType) {
         case SPORT_POINTS_TYPE.PLAIN:
            mainPoints = convertPoints(plainPoints, pointsType).str;
            break;

         case SPORT_POINTS_TYPE.TIME:
            mask = pointsMask ? pointsMask : DEFAULT_TIME_MASK;
            mainPoints = plainPointsToTimeString(plainPoints, mask);
            break;

         case SPORT_POINTS_TYPE.DISTANCE:
            mask = pointsMask ? pointsMask : DEFAULT_DISTANCE_MASK;
            mainPoints = plainPointsToDistanceString(plainPoints, mask);
            break;
      }

      return (
         <Box>
            {`${mainPoints}`}
         </Box>
      );
   };

   return renderScore();
};
