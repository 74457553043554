import React from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import Box from '@mui/material/Box';

interface Props {
   children: React.ReactNode;
}

const Wrapper = styled(Box)(
   sx({
      p: {
         xs: 1,
         sm: 2,
         md: 3
      }
   })
);

export default function PageWrapper(props: Props) {
   return (
      <Wrapper>{props.children}</Wrapper>
   )
}
