import axios from 'axios';
import * as propz from 'propz';
import { SORT, DEFAULT_SKIP, LIMIT_ALL } from 'consts/table';

export function getSchoolLeagues(schoolId: string, filter?: any) {
   const where = propz.get(filter, ['where'], {});
   const skip = propz.get(filter, ['skip'], DEFAULT_SKIP);
   const limit = propz.get(filter, ['limit'], LIMIT_ALL);
   const order = propz.get(filter, ['order'], SORT.ASC);

   const config = {
      params: {
         filter: {
            where,
            skip,
            limit,
            order
         }
      }
   };

   return axios.get(`${window.apiBase}/public/schools/${schoolId}/schoolLeagues`, config).then(response => response.data);
}
