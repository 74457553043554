import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as propz from 'propz';
import Lazy from 'lazy.js';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { SchoolEvent } from 'models/event';
import { Record } from 'models/record';
import { Tournament } from 'models/tournament';
import { FilterField } from 'models/filter';
import { FILTER_TYPE } from 'consts/table';
import { AGE_GROUPS } from 'consts/school';
import { getAllTournamentOngoingEvents, getAllTournamentFinishedEvents } from 'services/Tournaments/events';
import { getRecords } from 'services/getRecords';
import { getBestPerformanceData, getEventsWithResultForWinnersTable } from 'helpers/score';
import { getFilters } from 'helpers/filters';
import { getSelectOptionForClubGender } from 'helpers/select';
import { sortByNameAsc } from 'helpers/sorting';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainerMUI from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Divider from '@mui/material/Divider';
import Filters from 'components/Filters';
import PageTitle from 'components/PageTitle/PageTitle';
import PageWrapper from 'components/PageWrapper';
import Loader from 'components/loader/loader';

interface Props {
   school: School;
   tournament: Tournament;
}

const FILTER_FIELDS: FilterField[] = [
   {
      text: 'Sport',
      field: 'sport',
      type: FILTER_TYPE.SELECT
   },
   {
      text: 'Gender',
      field: 'gender',
      type: FILTER_TYPE.SELECT
   },
   {
      text: 'Age',
      field: 'age',
      type: FILTER_TYPE.SELECT
   }
];

const TableWrapper = styled(Box)(
   sx({
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '6px',
      overflowY: 'hidden'
   })
);

const TableContainer = styled(TableContainerMUI)(sx({}));

const EventWrapper = styled(Typography)(
   sx({
      cursor: 'pointer'
   })
);

const TableHeadCell = styled(TableCell)(
   sx({
      fontWeight: 'bold'
   })
);

export default function WinnersTable(props: Props) {
   const navigate = useNavigate();
   const location = useLocation();

   const { school, tournament } = props;
   const { id: schoolId } = school;
   const { id: tournamentId } = tournament;

   const { ageGroupsNaming } = school;
   const ageGroup = AGE_GROUPS[ageGroupsNaming];

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [isShowFilters, setIsShowFilters] = useState<boolean>(false);
   const [ongoingEvents, setOngoingEvents] = useState<SchoolEvent[]>([]);
   const [finishedEvents, setFinishedEvents] = useState<SchoolEvent[]>([]);
   const [filteredEvents, setFilteredEvents] = useState<SchoolEvent[]>([]);
   const [records, setRecords] = useState<Record[]>([]);
   const [filters, setFilters] = useState<any>({});

   const eventsWithResults = getEventsWithResultForWinnersTable([...ongoingEvents, ...finishedEvents]);

   const agesForOptions = Lazy(eventsWithResults)
      .map(event => event.ages)
      .flatten()
      .uniq()
      .sort()
      .toArray();

   const sportsFromEvents = eventsWithResults.map((event) => event.sport);
   const sportsFromEventsUniq = Lazy(sportsFromEvents)
      .uniq('id')
      .sort(sortByNameAsc)
      .toArray();

   const ageOptions = agesForOptions.map((item: number) => {
      const age = ageGroup[item];

      return {
         text: age,
         value: String(item)
      };
   });

   const sportOptions = sportsFromEventsUniq.map((sport) => {
      return {
         text: sport.name,
         value: sport.id
      };
   });

   const options = {
      age: ageOptions,
      sport: sportOptions,
      gender: getSelectOptionForClubGender()
   };

   const tableData = getBestPerformanceData(filteredEvents, records);

   const tableDataWithResults = tableData.filter((item) => item['Result'] !== '0');

   const tableDataFirstItem = tableDataWithResults[0];
   const isTableDataFirstItemExist = typeof tableDataFirstItem !== 'undefined';
   const tableColumns = isTableDataFirstItemExist ? Object.keys(tableDataFirstItem) : [];
   const tableColumnsFiltered = tableColumns.filter((column) => column !== 'EventId');

   const filterButtonIcon = isShowFilters ? <FilterAltOffIcon /> : <FilterAltIcon />;
   const filterButtonText = isShowFilters ? 'Hide Filters' : 'Show Filters';

   const columns = tableColumnsFiltered.map((column) => {
      return <TableHeadCell key={column}>{column}</TableHeadCell>;
   });

   const rows = tableDataWithResults.map((row: any, index: number) => {
      const rowCells = tableColumnsFiltered.map((column) => {
         const isEventColumn = column === 'Event';
         const tableCellContent = isEventColumn
            ? (
               <EventWrapper color='primary' onClick={() => onEventClick(row['EventId'])}>
                  {row[column]}
               </EventWrapper>
            )
            : row[column];

         return (
            <TableCell key={column}>{tableCellContent}</TableCell>
         )
;
      });

      return (
         <TableRow key={`table_row_${index}`}>
            <TableCell>{index + 1}</TableCell>

            {rowCells}
         </TableRow>
      );
   });

   useEffect(() => {
      setIsLoading(true);

      const locationState = location.state || {};
      const locationStateFilters = propz.get(locationState, ['filters'], {});
      const filters = getFilters(FILTER_FIELDS, locationStateFilters);

      const promises = [
         getAllTournamentOngoingEvents(schoolId, tournamentId),
         getAllTournamentFinishedEvents(schoolId, tournamentId),
         getRecords(schoolId)
      ];

      Promise.all(promises).then(([ongoingEvents, finishedEvents, records]) => {
         const eventsWithResults = getEventsWithResultForWinnersTable([...ongoingEvents, ...finishedEvents]);

         setOngoingEvents(ongoingEvents);
         setFinishedEvents(finishedEvents);
         setFilteredEvents([...ongoingEvents, ...finishedEvents]);
         setRecords(records);
         setFilters(filters);
         setIsLoading(false);

         filterResults(filters, eventsWithResults);
      });


      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const onFilterChange = (event: React.FormEvent<HTMLSelectElement>, filterField: string): void => {
      const filterValue = propz.get(event, ['target', 'value']);

      const nextFilters = {
         ...filters,
         [filterField]: filterValue
      };

      filterResults(nextFilters, eventsWithResults);
      setFilters(nextFilters);
   };

   const filterResults = (filters: any, events: SchoolEvent[]) => {
      const eventsFiltered = events.reduce((result: SchoolEvent[], event) => {
         const { gender, ages, sportId } = event;

         const filterActiveFields = Object.entries(filters).filter(([field, value]) => value !== '');

         const isAddToFilter: boolean = filterActiveFields.every(([field, value]) => {
            switch (true) {
               case field === 'sport':
                  return value === sportId;

               case field === 'gender':
                  return value === gender;

               case field === 'age':
                  return ages.includes(Number(value));

               default:
                  return true;
            }
         });

         return isAddToFilter ? [...result, event] : result;
      }, []);

      setFilteredEvents(eventsFiltered);
   };

   const onClearFilterClick = (): void => {
      const filters = getFilters(FILTER_FIELDS, {});

      const eventsWithResults = getEventsWithResultForWinnersTable([...ongoingEvents, ...finishedEvents]);

      navigate(location.pathname, {});
      setFilteredEvents(eventsWithResults);
      setFilters(filters);
   };

   const onEventClick = (eventId: string) => {
      const { pathname } = location;

      navigate(`/event/${eventId}`, { state: { filters, prevPath: pathname } });
   };

   if (isLoading) {
      return <Loader />;
   }

   return (
      <PageWrapper>
         <PageTitle text="The winners table" />

         <Button
            variant="outlined"
            startIcon={filterButtonIcon}
            sx={{ my: 2 }}
            onClick={() => setIsShowFilters(!isShowFilters)}
         >
            {filterButtonText}
         </Button>

         {isShowFilters &&
            <Box>
               <Filters
                  fields={FILTER_FIELDS}
                  filters={filters}
                  options={options}
                  onFilterChange={onFilterChange}
                  onClearFilterClick={onClearFilterClick}
               />

               <Divider sx={{ my: 3 }} />
            </Box>
         }

         <TableWrapper>
            <TableContainer>
               <Table stickyHeader>
                  <TableHead>
                     <TableRow>
                        <TableHeadCell>#</TableHeadCell>

                        {columns}
                     </TableRow>
                  </TableHead>

                  <TableBody>{rows}</TableBody>
               </Table>
            </TableContainer>
         </TableWrapper>
      </PageWrapper>
   );
}
