import React from 'react';
import { Point } from 'models/venue';
import { DEFAULT_POINT } from 'consts/venue';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { GOOGLE_MAP_API_KEY } from 'consts/api';
import Loader from 'components/loader';

interface Props {
   point: Point;
};

const containerStyle = {
   width: '100%',
   height: '400px'
};

export default function Map(props: Props) {
   const { point = DEFAULT_POINT } = props;
   const { coordinates } = point;
   const [ lng, lat ] = coordinates;

   const { isLoaded, loadError: isLoadError } = useJsApiLoader({
      googleMapsApiKey: GOOGLE_MAP_API_KEY
   })

   const center = {
      lat,
      lng
   };

   if (isLoadError) {
      return <div>Map cannot be loaded right now, sorry.</div>
   }

   return (
      isLoaded
         ? <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={14}
         >
            <Marker position={center} />
         </GoogleMap>
         : <Loader />
   )
}
