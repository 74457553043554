import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Lazy from 'lazy.js';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { Promise } from 'bluebird';
import { School } from 'models/school';
import { SchoolHouse } from 'models/house';
import { SchoolEvent, SchoolEventIndividualData } from 'models/event';
import { SchoolLeague } from 'models/league';
import { FilterField } from 'models/filter';
import { FILTER_TYPE } from 'consts/table';
import { AGE_GROUPS_SORTED } from 'consts/school';
import { ACTIVITIES_BLOCK_TYPE } from 'consts/common';
import { getSchoolHouses } from 'services/school';
import { getSchoolOngoingChallenges, getSchoolPastChallenges } from 'services/schoolChallenges/events';
import { getFilters } from 'helpers/filters';
import { getSelectOptionForGender, getSelectOptionForHouses } from 'helpers/select';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Filters from 'components/Filters';
import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';
import HomeSlider from 'components/HomeSlider';
import ActivitiesBlock from 'components/ActivitiesBlock';
import SchoolHouses from 'views/School/Home/SchoolHouses';
import LeaderboardTable from './LeaderboardTable';
import Loader from 'components/loader';
import defaultLeaguePhoto from 'assets/default_league_photo.jpeg';

const ACTIVITIES_COUNT_TO_SHOW = 3;

const FILTER_FIELDS: FilterField[] = [
   {
      text: 'Age',
      field: 'age',
      type: FILTER_TYPE.SELECT
   },
   {
      text: 'House',
      field: 'house',
      type: FILTER_TYPE.SELECT
   },
   {
      text: 'Gender',
      field: 'gender',
      type: FILTER_TYPE.SELECT
   }
];

interface Props {
   school: School;
   schoolChallenge: SchoolLeague;
}

const SliderWrapper = styled(Box)(
   sx({
      p: {
         sm: 0,
         md: 3
      }
   })
);

export default function Home(props: Props) {
   const navigate = useNavigate();

   const { school, schoolChallenge } = props;
   const { id: activeSchoolId } = school;
   const { id: challengeId, photos } = schoolChallenge;

   const isDisplayHousePoints = propz.get(schoolChallenge, ['isDisplayHousePoints'], false);

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [isShowFilters, setIsShowFilters] = useState<boolean>(false);
   const [ongoingChallenges, setOngoingChallenges] = useState<SchoolEvent[]>([]);
   const [pastChallenges, setPastChallenges] = useState<SchoolEvent[]>([]);
   const [allParticipants, setAllParticipants] = useState<SchoolEventIndividualData[]>([]);
   const [allParticipantsFiltered, setAllParticipantsFiltered] = useState<SchoolEventIndividualData[]>([]);
   const [houses, setHouses] = useState<SchoolHouse[]>([]);
   const [filters, setFilters] = useState<any>({});

   const { ageGroupsNaming } = school;
   const ageGroup = AGE_GROUPS_SORTED[ageGroupsNaming];

   const allChallenges = [...ongoingChallenges, ...pastChallenges];

   const allResult = Lazy(allChallenges)
      .map((event) => event.results.individualScore)
      .flatten()
      .toArray();

   const activitiesBlockOngoingChallenges = ongoingChallenges.slice(0, ACTIVITIES_COUNT_TO_SHOW);
   const activitiesBlockPastChallenges = pastChallenges.slice(0, ACTIVITIES_COUNT_TO_SHOW);

   const filterButtonIcon = isShowFilters ? <FilterAltOffIcon /> : <FilterAltIcon />;
   const filterButtonText = isShowFilters ? 'Hide Filters' : 'Show Filters';

   const participantUniqAges = (Lazy(allParticipants) as any)
      .map((participant: any) => participant.form.age)
      .uniq()
      .sort()
      .toArray();

   const ageOptions = participantUniqAges.map((age: number) => ({
      text: ageGroup[age],
      value: String(age)
   }));

   const anyOption = {
      text: 'Any',
      value: ''
   }

   const housesOptions = getSelectOptionForHouses(houses);
   const genderOptions = getSelectOptionForGender();

   const options = {
      age: [anyOption, ...ageOptions],
      house: [anyOption, ...housesOptions],
      gender: [anyOption, ...genderOptions]
   };

   const isChallengePhotosExist = photos.length > 0;

   const challengePhotosUrls: string[] = isChallengePhotosExist
      ? photos.map((item) => item.picUrl)
      : [defaultLeaguePhoto];

   useEffect(() => {
      setIsLoading(true);

      const filters = getFilters(FILTER_FIELDS, {});

      const promises = [
         getSchoolOngoingChallenges(activeSchoolId, challengeId),
         getSchoolPastChallenges(activeSchoolId, challengeId),
         getSchoolHouses(school)
      ];

      Promise.all(promises).then(([ongoingChallenges, pastChallenges, houses]) => {
         const allParticipants = Lazy([...ongoingChallenges, ...pastChallenges])
            .map((event) => event.individualsData)
            .flatten()
            .toArray();

         setAllParticipants(allParticipants);
         setAllParticipantsFiltered(allParticipants);

         setOngoingChallenges(ongoingChallenges);
         setPastChallenges(pastChallenges);
         setHouses(houses);
         setFilters(filters);
         setIsLoading(false);
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const onFilterChange = (event: React.FormEvent<HTMLSelectElement>, filterField: string): void => {
      const filterValue = propz.get(event, ['target', 'value']);

      const nextFilters = {
         ...filters,
         [filterField]: filterValue
      };

      const allParticipantsFiltered = allParticipants.reduce(
         (result: SchoolEventIndividualData[], participant: SchoolEventIndividualData) => {
            const { gender, form, houseId } = participant;
            const { age } = form;

            const filterActiveFields = Object.entries(nextFilters).filter(([field, value]) => value !== '');

            const isAddToFilter: boolean = filterActiveFields.every(([field, value]) => {
               switch (field) {
                  case 'house':
                     return value === houseId;

                  case 'gender':
                     return value === gender;

                  case 'age':
                     return value === String(age);

                  default:
                     return true;
               }
            });

            return isAddToFilter ? [...result, participant] : result;
         },
         []
      );

      setAllParticipantsFiltered(allParticipantsFiltered);
      setFilters(nextFilters);
   };

   const onClearFilterClick = () => {
      const filters = getFilters(FILTER_FIELDS, {});

      setAllParticipantsFiltered(allParticipants);
      setFilters(filters);
   };

   const onEventClick = (event: SchoolEvent) => {
      const { id: eventId } = event;

      navigate(`/event/${eventId}`);
   };

   if (isLoading) {
      return <Loader />;
   }

   return (
      <>
         <SliderWrapper>
            <HomeSlider imageUrls={challengePhotosUrls} />
         </SliderWrapper>

         <PageWrapper>
            <PageTitle text="Leaderboard" />

            <Button
               variant="outlined"
               startIcon={filterButtonIcon}
               sx={{ my: 2 }}
               onClick={() => setIsShowFilters(!isShowFilters)}
            >
               {filterButtonText}
            </Button>

            {isShowFilters && (
               <Box>
                  <Filters
                     fields={FILTER_FIELDS}
                     filters={filters}
                     options={options}
                     onFilterChange={onFilterChange}
                     onClearFilterClick={onClearFilterClick}
                  />

                  <Divider sx={{ my: 3 }} />
               </Box>
            )}

            <LeaderboardTable
               school={school}
               results={allResult}
               participants={allParticipantsFiltered}
               houses={houses}
            />

            <Grid container spacing={4} mt={3}>
               <Grid item xs={12} lg={isDisplayHousePoints ? 8 : 12}>
                  <ActivitiesBlock
                     title="Ongoing challenges"
                     type={ACTIVITIES_BLOCK_TYPE.ONGOING_CHALLENGES}
                     items={activitiesBlockOngoingChallenges}
                     showMoreLink="/ongoing"
                     school={school}
                     onItemClick={(event) => onEventClick(event as SchoolEvent)}
                  />

                  <ActivitiesBlock
                     title="Past challenges"
                     type={ACTIVITIES_BLOCK_TYPE.PAST_CHALLENGES}
                     items={activitiesBlockPastChallenges}
                     showMoreLink="/past"
                     school={school}
                     onItemClick={(event) => onEventClick(event as SchoolEvent)}
                  />
               </Grid>

               <Grid item xs={12} lg={isDisplayHousePoints ? 4 : 12}>
                  {isDisplayHousePoints &&
                     <SchoolHouses items={houses} events={pastChallenges} />
                  }
               </Grid>
            </Grid>
         </PageWrapper>
      </>
   );
}
