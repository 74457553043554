import React from 'react';
import * as propz from 'propz';
import { School } from 'models/school';
import { SchoolEvent } from 'models/event';
import { EVENT_STATUS } from 'consts/event';
import { SUBTYPE_OF_INDIVIDUAL_TOURNAMENTS } from 'consts/tournament';
import {
   isHousesEvent,
   isIndividualSportEvent,
   isIndividualTournamentAndTeamSport,
   isOneOnOneSportEvent,
   isTeamSportEvent
} from 'helpers/event';
import { getRivalPoints } from 'helpers/rivals';
import { isCricket } from 'helpers/sport/cricket';
import { isTriathlon } from 'helpers/sport/sport';
import AthleticScore from './AthleticScore';
import CricketScore from './CricketScore';
import DefaultScore from './DefaultScore';

interface Props {
   rival: any;
   event: SchoolEvent;
   school: School;
};

export default function PointsCellData(props: Props) {
   const { rival, event, school } = props;
   const { id: activeSchoolId } = school;

   const tournament = propz.get(event, ['tournament']);
   const tournamentSubtype = propz.get(tournament, ['subType']);

   const isTeamSportCondition = isTeamSportEvent(event);
   const isIndividualSportCondition = isIndividualSportEvent(event);
   const isOneOnOneSportCondition = isOneOnOneSportEvent(event);
   const isHousesEventCondition = isHousesEvent(event);

   const isFinishedEvent = event.status === EVENT_STATUS.FINISHED;
   // const isClosingMode = this.props.mode === 'closing';

   const isPseudoLevelRunTime = tournamentSubtype === SUBTYPE_OF_INDIVIDUAL_TOURNAMENTS.PSEUDO_TWO_LEVEL_RUNTIME;
   const isPseudoLevel = tournamentSubtype === SUBTYPE_OF_INDIVIDUAL_TOURNAMENTS.PSEUDO_TWO_LEVEL;
   const isPseudoLevelOrRunTime = isPseudoLevelRunTime || isPseudoLevel;

   const getSettings = () => {
      return event.settings.find(setting => setting.schoolId === activeSchoolId);
   }

   const isDisplayResultsOnPublic = () => {
      const settings = getSettings();

      return propz.get(settings, ['isDisplayResultsOnPublic'], true);
   }

   const getIsNewRecordBySchoolId = (event: SchoolEvent, schoolId: string) => {
      const schoolScoreDataIndex = event.results.schoolScore.findIndex(schoolScoreData => schoolScoreData.schoolId === schoolId);
      if (schoolScoreDataIndex === -1) {
         return false;
      } else {
         const isRecord = propz.get(event, ['results', 'schoolScore', schoolScoreDataIndex, 'isRecord'], false);

         return isRecord;
      }
   };

   const getIsNewRecordByTeamId = (event: SchoolEvent, teamId: string) => {
      const teamScoreDataIndex = event.results.teamScore.findIndex(teamScoreData => teamScoreData.teamId === teamId);
      if (teamScoreDataIndex === -1) {
         return false;
      } else {
         const isRecord = propz.get(event, ['results', 'teamScore', teamScoreDataIndex, 'isRecord'], false);

         return isRecord;
      }
   };

   const renderCountPoints = () => {
      const points = getRivalPoints(event, rival, true);

      const schoolId = propz.get(rival, ['school', 'id']);
      const teamId = propz.get(rival, ['team', 'id']);

      //For cricket we use separate component (because cricket no usual game, with very strange rules)
      //We save score in format {number}: <Runs>999.<Wickets>9|10 (example 200.05, mean Runs: 200, Wickets: 5)

      switch (true) {
         case isCricket(event):
            return (
               <CricketScore
                  plainPoints = { points }
                  event = { event }
                  rival = { rival }
               />
            );

         case isIndividualTournamentAndTeamSport(event) && !isTriathlon(event.sport.name):
            const teamPoints = getRivalPoints(event, rival, true);

            const isRecord = typeof teamId !== 'undefined'
               ? getIsNewRecordByTeamId(event, teamId)
               : getIsNewRecordBySchoolId(event, schoolId)

            return (
               <AthleticScore
                  plainPoints = { teamPoints }
                  isRecord = { isRecord }
                  event = { event }
               />
            );
      
         default:
            return (
               <DefaultScore
                  plainPoints = { points }
                  event = { event }
               />
            );
      }
   }

   let score = null;

   if (
      // Doesn't show scores if it's a public site and isDisplayResultsOnPublic === false
      isDisplayResultsOnPublic() &&
      (isTeamSportCondition || isOneOnOneSportCondition || isIndividualSportCondition) &&
      (
         isFinishedEvent &&
         (!isHousesEventCondition || (isPseudoLevelOrRunTime && isTeamSportCondition && !isFinishedEvent))
      )
   ) {
      score = renderCountPoints();
   } else {
      score = '-';
   }

   return <div>
      {score}
   </div>
};
