import React from 'react';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { getFileIcon } from 'helpers/file';
import RouterLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';

interface Props {
   file: any;
}

const AttachmentWrapper = styled(RouterLink)(
   sx({
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      gap: '16px',
      flexDirection: 'column',
      p: 1,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      overflow: 'hidden',
      borderRadius: '4px',
      background: '#fff',
      flex: {
         sx: '0 1 auto',
         md: '0 1 300px'
      },
      cursor: 'pointer',
      transition: '0.2s ease-in-out',
      
      '&:hover': {
         borderColor: '#1976d2',
      },

      '& .resource-card__download-icon': {
         position: 'absolute',
         top: '5px',
         right: '5px'
      }
   })
);

const AttachmentTitle = styled(Typography)(
   sx({
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: '12px'
   })
);

export default function Attachment(props: Props) {
   const { file } = props;
   const name = propz.get(file, ['name'], '');
   const url = propz.get(file, ['url']);
   const link = `${window.apiBaseFile}${url}`;

   const fileIcon = getFileIcon(url);

   return (
      <AttachmentWrapper
         target="_blank"
         href={link}
         underline='none'
         color='black'
         title={name}
      >
         {fileIcon}
         <DownloadIcon color='action' fontSize='small' className='resource-card__download-icon' />

         <AttachmentTitle>{name}</AttachmentTitle>
      </AttachmentWrapper>
   );
};
