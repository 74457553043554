import * as propz from 'propz';
import Moment from 'moment';
import { DEFAULT_LIMIT } from 'consts/table';
import { DATE_FORMAT } from 'consts/date';
import { SchoolForm } from 'models/form';

const LAST_HOUR = 23;
const LAST_MINUTE = 59;

export function getServerFieldSectionWhere(filters: any, options?: any) {
   let where: any = {
      $and: []
   };

   const isExcludeClubEvents = propz.get<boolean>(options, ['isExcludeClubEvents'], false);
   const isExcludeTournamentEvents = propz.get<boolean>(options, ['isExcludeTournamentEvents'], false);
   const isOnlyTeamEvents = propz.get<boolean>(options, ['isOnlyTeamEvents'], false);
   const isOnlyIndividualEvents = propz.get<boolean>(options, ['isOnlyIndividualEvents'], false);
   const ninEventIds = propz.get<string[]>(options, ['ninEventIds'], []);

   for (let filterField in filters) {
      const isEmptyString = filters[filterField] === '';
      const isEmptyArray = Array.isArray(filters[filterField]) && filters[filterField].length === 0;
      const filterValue = filters[filterField];

      if (!isEmptyString && !isEmptyArray) {
         switch (true) {
            case filterField === 'ages': {
               let formIds;
               if (Array.isArray(filterValue)) {
                  formIds = options.forms
                     .filter((form: SchoolForm) => filterValue.some(value => Number(value) === form.age))
                     .map((form: SchoolForm) => form.id);
               } else {
                  formIds = options.forms
                     .filter((form: SchoolForm) => form.age === Number(filterValue))
                     .map((form: SchoolForm) => form.id);
               }

               if (formIds.length > 0) {
                  where['formId'] = { $in: formIds };
               }
               break;
            }

            case filterField === 'agesRecord':
            case filterField === 'teamAges':
            case filterField === 'clubAges':
            case filterField === 'tournamentAges': {
               where['ages'] = { $in: filterValue };
               break;
            }

            case filterField === 'eventAges': {
               const filterFieldNumber = filterValue.map((value: any) => Number(value));
               where['ages'] = { $in: filterFieldNumber };
               break;
            }

            case filterField === 'eventType': {
               where['eventType'] = filterValue;
               break;
            }

            case filterField === 'venueType': {
               where['venue.venueType'] = filterValue;
               break;
            }

            case filterField === 'sport':
            case filterField === 'eventSport': {
               where['sportId'] = { $in: filterValue };
               break;
            }

            case filterField === 'aggregationType': {
               where['aggregationType'] = { $in: filterValue };
               break;
            }

            case filterField === 'startTime': {
               if (typeof filters[filterField].to !== 'undefined' && filters[filterField].to !== '') {
                  const toFormatted = Moment(filters[filterField].to, DATE_FORMAT).toDate();
                  toFormatted.setHours(LAST_HOUR);
                  toFormatted.setMinutes(LAST_MINUTE);
                  propz.set(where, ['startTime', '$lte'], toFormatted.toISOString());
               }
               if (typeof filters[filterField].from !== 'undefined' && filters[filterField].from !== '') {
                  const fromFormatted = Moment(filters[filterField].from, DATE_FORMAT).toDate();
                  propz.set(where, ['startTime', '$gte'], fromFormatted.toISOString());
               }
               break;
            }

            case filterField === 'endTime': {
               if (typeof filters[filterField].to !== 'undefined' && filters[filterField].to !== '') {
                  const toFormatted = Moment(filters[filterField].to, DATE_FORMAT).toDate();
                  toFormatted.setHours(LAST_HOUR);
                  toFormatted.setMinutes(LAST_MINUTE);
                  propz.set(where, ['endTime', '$lte'], toFormatted.toISOString());
               }
               if (typeof filters[filterField].from !== 'undefined' && filters[filterField].from !== '') {
                  const fromFormatted = Moment(filters[filterField].from, DATE_FORMAT).toDate();
                  propz.set(where, ['endTime', '$gte'], fromFormatted.toISOString());
               }
               break;
            }

            case filterField === 'startDate': {
               if (typeof filters.startDate.to !== 'undefined' && filters.startDate.to !== '') {
                  const toFormatted = Moment(filters.startDate.to, DATE_FORMAT).toDate();
                  toFormatted.setHours(LAST_HOUR);
                  toFormatted.setMinutes(LAST_MINUTE);
                  propz.set(where, ['schedule.startDate', '$lte'], toFormatted.toISOString());
               }
               if (typeof filters.startDate.from !== 'undefined' && filters.startDate.from !== '') {
                  const fromFormatted = Moment(filters.startDate.from, DATE_FORMAT).toDate();
                  propz.set(where, ['schedule.startDate', '$gte'], fromFormatted.toISOString());
               }
               break;
            }

            case filterField === 'finishDate': {
               if (typeof filters.finishDate.to !== 'undefined' && filters.finishDate.to !== '') {
                  const toFormatted = Moment(filters.finishDate.to, DATE_FORMAT).toDate();
                  toFormatted.setHours(LAST_HOUR);
                  toFormatted.setMinutes(LAST_MINUTE);
                  propz.set(where, ['schedule.finishDate', '$lte'], toFormatted.toISOString());
               }
               if (typeof filters.finishDate.from !== 'undefined' && filters.finishDate.from !== '') {
                  const fromFormatted = Moment(filters.finishDate.from, DATE_FORMAT).toDate();
                  propz.set(where, ['schedule.finishDate', '$gte'], fromFormatted.toISOString());
               }
               break;
            }

            case filterField === 'date': {
               if (typeof filters[filterField].to !== 'undefined' && filters[filterField].to !== '') {
                  const toFormatted = Moment(filters[filterField].to, DATE_FORMAT).toDate();
                  toFormatted.setHours(23);
                  toFormatted.setMinutes(59);
      
                  where[filterField] = { ...where[filterField] };
                  where[filterField].$lte = toFormatted.toISOString();
               }
               if (typeof filters[filterField].from !== 'undefined' && filters[filterField].from !== '') {
                  const fromFormatted = Moment(filters[filterField].from, DATE_FORMAT).toDate();
                  where[filterField] = { ...where[filterField] };
                  where[filterField].$gte = fromFormatted.toISOString();
               }
               break;
               }

            case filterField === 'eventStartTime': {
               if (typeof filters[filterField].to !== 'undefined' && filters[filterField].to !== '') {
                  const toFormatted = Moment(filters[filterField].to, DATE_FORMAT).toDate();
                  toFormatted.setHours(LAST_HOUR);
                  toFormatted.setMinutes(LAST_MINUTE);
                  propz.set(where, ['startTime', '$lte'], toFormatted.toISOString());
               }
               if (typeof filters[filterField].from !== 'undefined' && filters[filterField].from !== '') {
                  const fromFormatted = Moment(filters[filterField].from, DATE_FORMAT).toDate();
                  propz.set(where, ['startTime', '$gte'], fromFormatted.toISOString());
               }
               break;
            }

            case filterField === 'forms':
            case filterField === 'userFormName': {
               let formIds;
               if (Array.isArray(filterValue)) {
                  formIds = filterValue;
               } else {
                  formIds = [filterValue];
               }

               if (formIds.length > 0) {
                  where['formId'] = { $in: formIds };
               }
               break;
            }

            case filterField === 'houses':
            case filterField === 'userHouseName':
            case filterField === 'studentHouseName': {
               let houseIds;
               if (Array.isArray(filterValue)) {
                  houseIds = filterValue;
               } else {
                  houseIds = [filterValue];
               }

               if (houseIds.length > 0) {
                  where['houseId'] = { $in: houseIds };
               }
               break;
            }

            case filterField === 'upcomingTournamentSeasons': {
               const currentYear = new Date().getFullYear();

               const startTimeFrom = currentYear - 1 === Number(filterValue)
                  ? Moment().toISOString()
                  : `${Number(filterValue)}-09-01T00:00:00.000Z`;

               const startTimeTo = currentYear + 1 === Number(filterValue)
                  ? `${filterValue}-08-31T23:59:00.000Z`
                  : `${Number(filterValue) + 1}-08-31T23:59:00.000Z`;

               if (filterValue.length === 1) {
                  where['startTime'] = {
                     $gte: startTimeFrom,
                     $lte: startTimeTo
                  };
               } else {
                  where['$or'] = filterValue.map((value: any) => {
                     const startTimeFrom = currentYear + 1 === Number(value)
                        ? `${Number(value)}-09-01T00:00:00.000Z`
                        : Moment().toISOString();

                     const startTimeTo = currentYear + 1 === Number(value)
                        ? `${Number(value) - 1}-08-31T23:59:00.000Z`
                        : `${Number(value) + 1}-08-31T23:59:00.000Z`;

                     return {
                        startTime: {
                           $gte: startTimeFrom,
                           $lte: startTimeTo
                        }
                     };
                  });
               }
               break;
            }

            case filterField === 'tournamentSeasons': {
               const currentYear = new Date().getFullYear();
               const startTimeTo =
                  currentYear - 1 === Number(filterValue)
                     ? Moment()
                        .hour(0)
                        .minute(0)
                        .second(-1)
                        .toISOString()
                     : `${Number(filterValue) + 1}-08-31T23:59:00.000Z`;

               if (filterValue.length === 1) {
                  where['startTime'] = {
                     $gte: `${filterValue}-09-01T00:00:00.000Z`,
                     $lte: startTimeTo
                  };
               } else {
                  where['$or'] = filterValue.map((value: any) => {
                     const startTimeTo =
                        currentYear - 1 === Number(value)
                           ? Moment()
                              .hour(0)
                              .minute(0)
                              .second(1)
                              .toISOString()
                           : `${Number(value) + 1}-08-31T23:59:00.000Z`;

                     return {
                        startTime: {
                           $gte: `${value}-09-01T00:00:00.000Z`,
                           $lte: startTimeTo
                        }
                     };
                  });
               }
               break;
            }

            case filterField === 'gender': {
               let gender;
               if (Array.isArray(filterValue)) {
                  gender = filterValue;
               } else {
                  gender = [filterValue];
               }

               if (gender.length > 0) {
                  where['gender'] = { $in: gender };
               }
               break;
            }

            case filterField === 'ageGroup': {
               let ageGroup;
               if (Array.isArray(filterValue)) {
                  ageGroup = filterValue.map(value => Number(value));
               } else {
                  ageGroup = [Number(filterValue)];
               }

               if (ageGroup.length > 0) {
                  where['age'] = { $in: ageGroup };
               }
               break;
            }

            case filterField === 'userFirstName': {
               where['studentFirstName'] = { like: filterValue, options: 'i' };
               break;
            }

            case filterField === 'userLastName': {
               where['studentLastName'] = { like: filterValue, options: 'i' };
               break;
            }

            case filterField === 'clubName': {
               where['clubName'] = { like: filterValue, options: 'i' };
               break;
            }

            case filterField === 'schoolId': {
               where['schoolId'] = { $in: filterValue };
               break;
            }

            case filterField === 'teamPlayerForm': {
               where['forms'] = { $in: filterValue };
               break;
            }

            case filterField === 'childIdList': {
               where['childIdList'] = filterValue;
               break;
            }

            default: {
               where[filterField] = { like: filterValue, options: 'i' };
            }
         }
      }
   }

   if (where['$and'].length === 0) {
      delete where['$and'];
   }

   if (isExcludeClubEvents) {
      propz.set(where, ['clubId'], { $exists: false });
   }

   if (isExcludeTournamentEvents) {
      propz.set(where, ['tournamentId'], { $exists: false });
   }

   if (isOnlyIndividualEvents) {
      propz.set(where, ['isOnlyIndividualEvents'], true);
   }

   if (isOnlyTeamEvents) {
      propz.set(where, ['isOnlyTeamEvents'], true);
   }

   if (ninEventIds.length > 0) {
      propz.set(where, ['id'], { $nin: ninEventIds });
   }

   return where;
}

export function getServerQueryFilter(where: any, limit = DEFAULT_LIMIT) {
   const isFilter = Object.keys(where).length > 0;

   switch (true) {
      case isFilter: {
         return {
            limit,
            where: where
         };
      }

      default:
         return {
            limit
         };
   }
}
