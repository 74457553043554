import React from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import TextField from '@mui/material/TextField';
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { CalendarPickerSkeleton } from '@mui/x-date-pickers/CalendarPickerSkeleton';
import { checkIsEventsExistInDate } from '../../helpers/calendar';
import { CALENDAR_MIN_DATE, CALENDAR_MAX_DATE } from '../../consts/calendar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Loader from 'components/loader';

interface DatePickerProps {
   value: Date;
   onChangeDate: (newValue: Date) => void;
   onMonthChange: (value: Date) => void;
   onCurrentDateClick: () => void;
   eventDates: Date[];
   isLoading: boolean;
};
//TODO: fix problem with type
type CustomPickersDayProps = PickersDayProps<Date> & {
   isEventExistInDate: boolean;
};


const CustomPickersDay = styled(PickersDay, {
   shouldForwardProp: (prop) => prop !== 'isEventExistInDate'
})<CustomPickersDayProps>(({ isEventExistInDate }) => ({
   '&.MuiPickersDay-root': {
      transition: '0s',
   },
   ...(isEventExistInDate && {
      '&.MuiPickersDay-root': {
         borderColor: '#6cb6ff',
         transition: '0s'
      }
   }),
})) as React.ComponentType<CustomPickersDayProps>;

const DatePickerWrapper = styled(Box)(
   sx({
      position: 'relative',
      padding: '5px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      overflow: 'hidden'
   })
);

const DatePickerOverlay = styled(Box)(
   sx({
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: '10',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0, 0.15)',
      transform: 'scale(1.1)'
   })
);

const CurrentDateButtonWrapper = styled(Box)(
   sx({
      display: 'flex',
      justifyContent: 'flex-end',
      pr: 3,
      pb: 3
   })
);

export default function DatePicker(props: DatePickerProps) {
   const { value, onChangeDate, eventDates, onMonthChange, onCurrentDateClick, isLoading } = props;

   const renderDay = (
      date: Date,
      selectedDates: Array<Date | null>,
      pickersDayProps: PickersDayProps<Date>
   ) => {
      if (!value) {
         return <PickersDay {...pickersDayProps} />;
      }

      const isEventExistInDate = checkIsEventsExistInDate(new Date(date), eventDates);

      return (
         <CustomPickersDay
            {...pickersDayProps}
            isEventExistInDate={isEventExistInDate}
         />
      );
   };
   //TODO: fix problem with types in onChange
   return (
      <DatePickerWrapper>
         {isLoading &&
            <DatePickerOverlay>
               <Loader />
            </DatePickerOverlay>
         }

         <LocalizationProvider dateAdapter={DateAdapter} sx={{ mt: 3 }}>
            <StaticDatePicker
               className="calendar"
               views={['year', 'month', 'day']}
               displayStaticWrapperAs="desktop"
               value={value}
               showDaysOutsideCurrentMonth
               onChange={(newValue) => newValue !== null && onChangeDate(newValue)}
               renderDay={renderDay}
               minDate={new Date(CALENDAR_MIN_DATE)}
               maxDate={new Date(CALENDAR_MAX_DATE)}
               onMonthChange={(value) => onMonthChange(value)}
               renderInput={(params) => <TextField {...params} />}
            />
         </LocalizationProvider>

         <CurrentDateButtonWrapper>
            <Button
               variant="outlined"
               size="small"
               onClick={onCurrentDateClick}
            >
               Today
            </Button>
         </CurrentDateButtonWrapper>

      </DatePickerWrapper>

   )
}
