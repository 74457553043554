import React from 'react';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { getFileIcon } from 'helpers/file';
import RouterLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';

interface Props {
   file: any;
}

const FileWrapper = styled(RouterLink)(
   sx({
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '16px',
      flexDirection: 'column',
      p: 2,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      overflow: 'hidden',
      borderRadius: '4px',
      background: '#fff',
      flex: {
         sx: '0 1 auto',
         md: '0 1 300px'
      },
      cursor: 'pointer',
      transition: '0.2s ease-in-out',
      
      '&:hover': {
         borderColor: '#1976d2',
      },

      '& .resource-card__download-icon': {
         position: 'absolute',
         top: '5px',
         right: '5px'
      }
   })
);

export default function FileCard(props: Props) {
   const { file } = props;
   const title = propz.get(file, ['title'], '');
   const url = propz.get(file, ['url']);

   const fileIcon = getFileIcon(url);

   return (
      <FileWrapper
         target="_blank"
         href={url}
         underline='none'
         color='black'
      >
         {fileIcon}
         <DownloadIcon color='action' fontSize='small' className='resource-card__download-icon' />

         <Typography>{title}</Typography>
      </FileWrapper>
   );
};
