import Lazy from 'lazy.js';
import * as propz from 'propz';
import { SelectOption } from 'models/table';
import { Sport } from 'models/sport';
import { School } from 'models/school';
import { Tournament } from 'models/tournament';
import { SchoolHouse } from 'models/house';
import { SchoolEventSchool } from 'models/event';
import { SCHOOL_AGE_GROUPS_NAMING_TYPE } from 'types/school';
import { SchoolForm } from 'consts/form';
import { TOURNAMENT_SEASONS_MIN, TOURNAMENT_SEASONS_MAX } from 'consts/tournament';
import { CLUB_GENDER, CLUB_GENDER_SERVER_TO_CLIENT_MAPPING, CLUB_SCHEDULE_HOURS_INTERVALS_OPTIONS } from 'consts/club';
import {
   CALENDAR_FILTER,
   CALENDAR_FILTER_SERVER_TO_CLIENT_MAPPING,
} from 'consts/calendar';
import {
   EVENT_TYPES,
   EVENT_TYPES_SERVER_TO_CLIENT_MAPPING,
} from 'consts/event';
import {
   AGE_GROUPS,
   AGE_GROUPS_SORTED
} from 'consts/school';
import { USER_GENDER, USER_GENDER_SERVER_TO_CLIENT_MAPPING } from 'consts/user';
import {
   LEAGUE_AGGREGATION_TYPE,
   LEAGUE_AGGREGATION_TYPE_WITHOUT_INDIVIDUAL,
   LEAGUE_AGGREGATION_TYPE_SERVER_TO_CLIENT_MAPPING
} from 'consts/league';
import { sortTournamentsBySportName } from 'helpers/common';
import { sortAges } from 'helpers/event';
import { getAllTournamentsStartTimeDates } from 'helpers/tournament';

export function getSelectOptionForAge(forms: SchoolForm[], ageGroupsNaming: string): SelectOption[] {
   const ages = (Lazy(forms) as any)
      .map((form: SchoolForm) => {
         const ageGroup = propz.get(AGE_GROUPS, [ageGroupsNaming, form.age]);

         return {
            value: `${form.age}`,
            text: ageGroup
         };
      })
      .uniq('value')
      .sort((selectOption1: SelectOption, selectOption2: SelectOption) => {
         const ageGroup = propz.get(AGE_GROUPS_SORTED, [ageGroupsNaming]);
         const ageIndex1 = ageGroup.indexOf(selectOption1.text);
         const ageIndex2 = ageGroup.indexOf(selectOption2.text);

         return ageIndex1 - ageIndex2;
      })
      .toArray();

   return ages;
}

export function getSelectOptionForGender(): SelectOption[] {
   let gender = [];

   for (let key in USER_GENDER) {
      gender.push({
         text: USER_GENDER_SERVER_TO_CLIENT_MAPPING[key as keyof typeof USER_GENDER_SERVER_TO_CLIENT_MAPPING],
         value: key
      });
   }

   return gender;
}

export function getSelectOptionForClubGender(): SelectOption[] {
   let gender = [];

   for (let key in CLUB_GENDER) {
      gender.push({
         text: CLUB_GENDER_SERVER_TO_CLIENT_MAPPING[key as keyof typeof CLUB_GENDER_SERVER_TO_CLIENT_MAPPING],
         value: key
      });
   }

   return gender;
}

export function getSelectOptionForCalendarFilter(): SelectOption[] {
   let eventTypes = [];

   for (let key in CALENDAR_FILTER) {
      eventTypes.push({
         text: CALENDAR_FILTER_SERVER_TO_CLIENT_MAPPING[key as keyof typeof CALENDAR_FILTER_SERVER_TO_CLIENT_MAPPING],
         value: CALENDAR_FILTER[key as keyof typeof CALENDAR_FILTER]
      });
   }

   return eventTypes;
}

export function getSelectOptionForEventType(): SelectOption[] {
   let eventTypes = [];

   for (let key in EVENT_TYPES) {
      eventTypes.push({
         text: EVENT_TYPES_SERVER_TO_CLIENT_MAPPING[key as keyof typeof EVENT_TYPES_SERVER_TO_CLIENT_MAPPING],
         value: EVENT_TYPES[key as keyof typeof EVENT_TYPES]
      });
   }

   return eventTypes;
}

export function getSelectOptionForClubsScheduleHoursIntervals(): SelectOption[] {
   let hoursIntervals = [];

   for (let key in CLUB_SCHEDULE_HOURS_INTERVALS_OPTIONS) {
      hoursIntervals.push({
         text: CLUB_SCHEDULE_HOURS_INTERVALS_OPTIONS[key as keyof typeof CLUB_SCHEDULE_HOURS_INTERVALS_OPTIONS],
         value: key
      });
   }

   return hoursIntervals;
}

export function getSelectOptionForSport(items: Sport[]): SelectOption[] {
   const sports = (Lazy(items) as any)
      .map((item: Sport) => {
         const sportName = propz.get(item, ['name'], '');
         const sportId = propz.get(item, ['id'], '');
         return {
            value: sportId,
            text: sportName
         };
      })
      .uniq('value')
      .toArray();

   return sports;
}

export function getSelectOptionForAges(ageGroupsNaming: SCHOOL_AGE_GROUPS_NAMING_TYPE): SelectOption[] {
   const ages = AGE_GROUPS[ageGroupsNaming];
   const agesSorted = AGE_GROUPS_SORTED[ageGroupsNaming];

   const options = agesSorted.map((age: string) => {
      return {
         text: age,
         value: ages.findIndex(item => age === item)
      }
   })

   return options;
}

export function getSelectOptionForHouses(houses: SchoolHouse[]): SelectOption[] {
   const options = houses.map((house: SchoolHouse) => {
      return {
         text: house.name,
         value: house.id
      }
   })

   return options;
}

export function getSelectOptionForAllTournamentSeasons(): SelectOption[] {
   const minYear = TOURNAMENT_SEASONS_MIN;
   let maxYear = TOURNAMENT_SEASONS_MAX;

   let result = [];

   while (maxYear > minYear) {
      result.push({
         text: `${maxYear - 1}-${maxYear}`,
         value: `${maxYear - 1}`
      });

      maxYear--;
   }

   return result;
}

export function getSelectOptionForLeagueType(): SelectOption[] {
   let leagueType = [];

   for (let key in LEAGUE_AGGREGATION_TYPE_WITHOUT_INDIVIDUAL) {
      leagueType.push({
         text: LEAGUE_AGGREGATION_TYPE_SERVER_TO_CLIENT_MAPPING[
            key as keyof typeof LEAGUE_AGGREGATION_TYPE_SERVER_TO_CLIENT_MAPPING
         ],
         value: LEAGUE_AGGREGATION_TYPE[key as keyof typeof LEAGUE_AGGREGATION_TYPE]
      });
   }

   return leagueType;
}

export function getSelectOptionForTournamentAvailableSports(tournaments: Tournament[]): SelectOption[] {
   const tournamentsUniq = (Lazy(tournaments) as any)
      .uniq((item: Tournament) => {
        const sportId = propz.get(item, ['sport', 'id'], '');

        return sportId;
      })
      .sort(sortTournamentsBySportName)
      .toArray();

   return tournamentsUniq.map((item: Tournament) => {
      const text = propz.get(item, ['sport', 'name'], '');
      const value = propz.get(item, ['sport', 'id'], '');

      return {
         text,
         value
      }
   });
};

export function getSelectOptionForTournamentsAges(tournaments: Tournament[], ageGroupsNaming: string): SelectOption[] {
   const eventAgesGroup = propz.get(AGE_GROUPS_SORTED, [ageGroupsNaming], []);

   const allTournamentAges = tournaments.reduce((result: number[], item: Tournament) => [...result, ...item.ages], []);

   const ages = Lazy(allTournamentAges)
      .uniq()
      .sort((prevAge, nextAge) => sortAges(prevAge, nextAge, ageGroupsNaming))
      .toArray();

   const options = ages.map(item => {
         return {
            text: eventAgesGroup[item],
            value: item
         }
      });

   return options;
};

export function getSelectOptionForSchoolUnion(school: School, unionSubSchools: School[]) {
   const allSchools = [school, ...unionSubSchools];

   const result = allSchools.map(item => {
      return {
         text: item.name,
         value: item.id
      }
   });

   return [
      {
         text: 'All areas',
         value: 'ALL'
      },
      ...result
   ]
};

export function getSelectOptionForTournamentSeasons(tournaments: Tournament[]): SelectOption[] {
   const dates = getAllTournamentsStartTimeDates(tournaments);
   const maxYear = new Date(Math.max.apply(null, dates as any)).getFullYear() + 1;
   let seasons: any = {};

   dates.forEach(date => {
      const dateMonth = date.getMonth();
      const dateDay = date.getDate();

      let start, end, key;

      if (dateMonth > 8 || (dateMonth === 8 && dateDay > 0)) {
         start = new Date(date.getFullYear(), 8, 1);
         end = new Date(start.getFullYear() + 1, 8, 0);
         key = `${start.getFullYear()}/${end.getFullYear()}`;
      } else {
         start = new Date(date.getFullYear() - 1, 8, 1);
         end = new Date(start.getFullYear() + 1, 8, 0);
         key = `${start.getFullYear()}/${end.getFullYear()}`;
      }

      if (end.getFullYear() <= maxYear) {
         seasons[key] = start;
      }
   });

   const options = Object.entries(seasons).map(([text, seasonStart]: any) => {
      const value = new Date(seasonStart).getFullYear();

      return {
         value,
         text
      }
   });

   return options;
};
