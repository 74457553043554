import * as propz from 'propz';
import { SchoolEvent } from 'models/event';
import { POINTS_DISPLAY } from 'consts/sport';
import { EVENT_VENUE, VENUE_SERVER_TO_CLIENT_MAPPING } from 'consts/venue';
import { DEFAULT_TIME_MASK } from 'consts/calendar';
import { isClubEvent } from './event';
import { plainPointsToTimeString, plainPointsToDistanceString } from './score';

export function getTeamScoreByTeamId(event: SchoolEvent, teamId: string) {
   const teamScoreDataIndex = event.results.teamScore.findIndex(teamScoreData => teamScoreData.teamId === teamId);

   return teamScoreDataIndex === -1 ? 0 : event.results.teamScore[teamScoreDataIndex].score;
}

export function getHouseScoreByHouseId(event: SchoolEvent, houseId: string) {
   const houseScoreDataIndex = event.results.houseScore.findIndex(houseData => houseData.houseId === houseId);

   return houseScoreDataIndex === -1 ? 0 : event.results.houseScore[houseScoreDataIndex].score;
}

export function getSchoolScoreBySchoolId(event: SchoolEvent, schoolId: string) {
   const schoolScoreDataIndex = event.results.schoolScore.findIndex(schoolData => schoolData.schoolId === schoolId);

   return schoolScoreDataIndex === -1 ? 0 : event.results.schoolScore[schoolScoreDataIndex].score;
}

export function getIndividualScoreByStudent(event: SchoolEvent, userId: string, permissionId: string): number {
   const userScoreDataIndex = event.results.individualScore.findIndex(
      userScoreData => userScoreData.userId === userId && userScoreData.permissionId === permissionId
   );

   if (userScoreDataIndex === -1) {
      return 0;
   } else {
      if (typeof event.results.individualScore[userScoreDataIndex].score !== 'undefined') {
         return event.results.individualScore[userScoreDataIndex].score;
      } else {
         return 0;
      }
   }
}

export function getExtraPointsByStudent(event: SchoolEvent, userId: string): number {
   const userScoreDataIndex = event.results.individualScore.findIndex(userScoreData => userScoreData.userId === userId);

   if (userScoreDataIndex === -1) {
      return 0;
   } else {
      const extraScore = propz.get(event, ['results', 'individualScore', userScoreDataIndex, 'richScore', 'points']);
      if (typeof extraScore !== 'undefined') {
         return extraScore;
      } else {
         return 0;
      }
   }
}

export function convertPointsToStringWithoutDelimeter(event: SchoolEvent, points: any): string {
   const pointType = propz.get(event, ['sport', 'points', 'display'], POINTS_DISPLAY.TIME);
   const mask = propz.get(event, ['sport', 'points', 'inputMask'], DEFAULT_TIME_MASK);

   switch (true) {
      case pointType === POINTS_DISPLAY.PLAIN:
         return String(points);
      case pointType === POINTS_DISPLAY.TIME:
         return plainPointsToTimeString(points, mask).replace(/[hmsc]/g, '');
      case pointType === POINTS_DISPLAY.DISTANCE:
         return plainPointsToDistanceString(points, mask).replace(/[kmc]/g, '');
      default:
         console.error('Can not find point type!');
         return '';
   }
}

export function getTeamExtraPointsByTeamId(event: SchoolEvent, teamId: string): string {
   const teamScoreDataIndex = event.results.teamScore.findIndex(teamScoreData => teamScoreData.teamId === teamId);

   if (teamScoreDataIndex !== -1) {
      const extraScore = propz.get(event, ['results', 'teamScore', teamScoreDataIndex, 'richScore', 'points']);
      return typeof extraScore !== 'undefined' ? extraScore.toString() : '';
   }
   return '';
}

export function getSchoolExtraPointsBySchoolId(event: SchoolEvent, schoolId: string): string {
   const schoolScoreDataIndex = event.results.schoolScore.findIndex(schoolScoreData => schoolScoreData.schoolId === schoolId);

   if (schoolScoreDataIndex !== -1) {
      const extraScore = propz.get(event, ['results', 'schoolScore', schoolScoreDataIndex, 'richScore', 'points']);
      return typeof extraScore !== 'undefined' ? extraScore.toString() : '';
   }
   return '';
}

export function getAbbreviationResultByStudent(event: SchoolEvent, userId: string, permissionId: string): string {
   const userScoreDataIndex = event.results.individualScore.findIndex(
     userScoreData => userScoreData.userId === userId && userScoreData.permissionId === permissionId
   );
 
   if (userScoreDataIndex !== -1) {
     const abbreviationResult = event.results.individualScore[userScoreDataIndex].richScore?.abbreviationResult;
     return abbreviationResult || '';
   }
 
   return '';
 }

export function getTeamAbbreviationResultByTeamId(event: SchoolEvent, teamId: string) {
   const teamAbbreviationResultDataIndex = event.results.teamScore.findIndex(
   teamScoreData => teamScoreData.teamId === teamId
);

return teamAbbreviationResultDataIndex === -1
   ? ''
   : event.results.teamScore[teamAbbreviationResultDataIndex].richScore.abbreviationResult;
}
   
export function getSchoolAbbreviationResultBySchoolId(event: SchoolEvent, schoolId: string) {
   const schoolAbbreviationResultDataIndex = event.results.schoolScore.findIndex(
   schoolData => schoolData.schoolId === schoolId
);

return schoolAbbreviationResultDataIndex === -1
   ? ''
   : event.results.schoolScore[schoolAbbreviationResultDataIndex].richScore.abbreviationResult;
}

export function getAbbreviationColor(event: SchoolEvent, abbreviationCode: string): string {
   const abbreviation = event.sport.abbreviations?.find(abbreviation => abbreviation.code === abbreviationCode);
return abbreviation ? abbreviation.color : '#000000';
}
 
export function getSchoolEventLocation(
   event: SchoolEvent,
   activeSchoolId: string,
   schoolPostcodeId: string
): string {
   const venue = event.venue;
   const inviterVenue = propz.get(venue, ['venueType'], EVENT_VENUE.TBD);

   let eventLocation = '';

   if (isClubEvent(event)) {
      const venueName = propz.get(venue, ['placeData', 'name']);

      switch (true) {
         case typeof venueName !== 'undefined':
            eventLocation = `${venueName} `;
            break;
         case schoolPostcodeId !== '' && schoolPostcodeId === venue.postcodeId:
            eventLocation = 'School,';
            break;
         case inviterVenue === EVENT_VENUE.TBD:
            eventLocation = EVENT_VENUE.TBD;
            break;
      }

      const postcode = propz.get(venue, ['postcodeData', 'postcode']);
      if (typeof postcode !== 'undefined') {
         eventLocation += `(${postcode})`;
      }

      return eventLocation;
   } else {
      let eventLocationServer;
      const isActiveSchoolInviter = activeSchoolId === event.inviterSchoolId;

      if (isActiveSchoolInviter) {
         eventLocationServer = inviterVenue;
      } else {
         switch (inviterVenue) {
            case EVENT_VENUE.HOME:
               eventLocationServer = EVENT_VENUE.AWAY;
               break;
            case EVENT_VENUE.AWAY:
               eventLocationServer = EVENT_VENUE.HOME;
               break;
            default:
               eventLocationServer = inviterVenue;
               break;
         }
      }

      eventLocation = VENUE_SERVER_TO_CLIENT_MAPPING[eventLocationServer];

      const postcode = propz.get(venue, ['postcodeData', 'postcode']);

      if (typeof postcode !== 'undefined') {
         eventLocation += `, ${postcode}`;
      }

      const venueName = propz.get(venue, ['placeData', 'name']);

      if (typeof venueName !== 'undefined') {
         eventLocation += `, ${venueName}`;
      }

      return eventLocation;
   }
}