import React from 'react';

export default function MedalForThirdPlaceIcon() {
   return (
      <svg
         id="fi_4692913"
         enableBackground="new 0 0 512 512"
         height="32"
         width="32"
         viewBox="0 0 512 512"
         xmlns="http://www.w3.org/2000/svg"
      >
         <g>
            <g>
               <g>
                  <path
                     d="m223.764 310.253 100.242 191.747 28.393-79.496 81.481 22.056.356-.186-100.242-191.747z"
                     fill="#80b6ff"
                  ></path>
                  <path
                     d="m288.236 310.253-100.242 191.747-28.393-79.496-81.481 22.056-.356-.186 100.242-191.747z"
                     fill="#80b6ff"
                  ></path>
                  <ellipse
                     cx="256"
                     cy="196.595"
                     fill="#ffa96b"
                     rx="186.595"
                     ry="186.595"
                     transform="matrix(.707 -.707 .707 .707 -64.033 238.601)"
                  ></ellipse>
                  <circle cx="256" cy="196.595" fill="#ffd4b5" r="136.595"></circle>
               </g>
            </g>
            <g>
               <path d="m223.619 167.209c-1.064 5.419 2.466 10.676 7.885 11.74 5.422 1.066 10.676-2.465 11.74-7.885 1.191-6.066 6.557-10.47 12.756-10.47 7.168 0 13 5.832 13 13 0 7.169-5.832 13-13 13-5.523 0-10 4.477-10 10s4.477 10 10 10c7.168 0 13 5.832 13 13 0 7.169-5.832 13-13 13-6.451 0-11.985-4.804-12.875-11.176-.083-.596-.125-1.209-.125-1.824 0-5.523-4.477-10-10-10s-10 4.477-10 10c0 1.537.107 3.082.317 4.59 2.26 16.196 16.311 28.41 32.683 28.41 18.196 0 33-14.804 33-33 0-8.938-3.577-17.051-9.368-23 5.791-5.948 9.368-14.062 9.368-23 0-18.196-14.804-33-33-33-15.733.001-29.352 11.194-32.381 26.615z"></path>
               <path d="m390.747 339.609c38.053-35.874 61.848-86.714 61.848-143.014 0-44.811-14.685-87.017-42.468-122.055-26.95-33.988-64.852-58.416-106.722-68.784-5.354-1.324-10.782 1.942-12.11 7.303-1.327 5.361 1.942 10.783 7.304 12.111 78.895 19.536 133.996 90.029 133.996 171.425 0 97.375-79.22 176.595-176.595 176.595s-176.595-79.22-176.595-176.595c0-81.395 55.102-151.888 133.996-171.425 5.361-1.327 8.631-6.749 7.304-12.11-1.328-5.361-6.755-8.63-12.11-7.303-41.871 10.369-79.771 34.796-106.722 68.784-27.782 35.039-42.468 77.244-42.468 122.055 0 56.299 23.795 107.14 61.848 143.014l-52.355 100.126c-2.554 4.883-.676 10.911 4.198 13.48l.36.19c2.236 1.18 4.839 1.466 7.276.806l72.558-19.645 25.281 70.795c1.337 3.741 4.761 6.337 8.725 6.613.233.016.465.024.696.024 3.701 0 7.126-2.053 8.858-5.366l59.15-113.12 59.148 113.121c1.732 3.314 5.157 5.367 8.858 5.366.232 0 .464-.008.696-.024 3.964-.275 7.388-2.872 8.725-6.613l25.281-70.795 72.558 19.645c2.439.662 5.039.374 7.276-.806l.36-.19c4.874-2.569 6.752-8.597 4.198-13.48zm-201.027 137.501-20.702-57.973c-1.755-4.914-6.994-7.652-12.031-6.289l-59.946 16.23 39.842-76.197c27.639 21.117 61.017 35.077 97.351 39.098zm165.294-64.262c-5.037-1.364-10.276 1.375-12.031 6.289l-20.703 57.973-44.514-85.131c36.335-4.021 69.712-17.981 97.351-39.098l39.842 76.197z"></path>
               <circle cx="256" cy="10" r="10"></circle>
               <path d="m109.405 196.595c0 80.832 65.762 146.595 146.595 146.595s146.595-65.762 146.595-146.595-65.762-146.595-146.595-146.595-146.595 65.762-146.595 146.595zm273.19 0c0 69.805-56.79 126.595-126.595 126.595s-126.595-56.79-126.595-126.595 56.79-126.595 126.595-126.595 126.595 56.79 126.595 126.595z"></path>
            </g>
         </g>
      </svg>
   );
}
