import axios from 'axios';
import { LIMIT_ALL } from 'consts/table';

export function getRecords(activeSchoolId: string) {
   const config = {
      params: {
         filter: {
            limit: LIMIT_ALL
         }
      }
   };

   return axios
      .get(`${window.apiBase}/public/schools/${activeSchoolId}/records`, config)
      .then((response) => {
         return response.data;
      });
};