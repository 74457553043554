import React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

interface Props {
   isTabletScreenSize: boolean;
   toggleDrawer: () => void;
};

export default function BurgerButton(props: Props) {
   const { toggleDrawer, isTabletScreenSize } = props;

   return (
      <IconButton
         color='inherit'
         aria-label='open drawer'
         onClick={toggleDrawer}
         edge='start'
         sx={{ marginRight: isTabletScreenSize ? '16px' : '36px' }}
      >
         <MenuIcon />
      </IconButton>
   );
};
