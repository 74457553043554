export type SCORING_TYPE =
   | 'MORE_SCORES'
   | 'LESS_SCORES'
   | 'MORE_TIME'
   | 'LESS_TIME'
   | 'MORE_RESULT'
   | 'LESS_RESULT'
   | 'FIRST_TO_N_POINTS';

export const SPORT_GENDER = {
   MALE: 'MALE',
   FEMALE: 'FEMALE',
   MIXED: 'MIXED'
};

export const SPORT_GENDER_SERVER_TO_CLIENT_MAPPING = {
   MALE: 'Male',
   FEMALE: 'Female',
   MIXED: 'Mixed'
};

export const SPORT_FORMAT = {
   '1X1': '1X1',
   '2X2': '2X2',
   INDIVIDUAL: 'INDIVIDUAL',
   TEAM: 'TEAM'
};

export const POINTS_DISPLAY = {
   PLAIN: 'PLAIN',
   TIME: 'TIME',
   DISTANCE: 'DISTANCE',
   PRESENCE_ONLY: 'PRESENCE_ONLY'
};

export const CRICKET_WICKETS = 10;

export const CRICKET_NAME_ARRAY = ['cricket', 'cricket t20', 'cricket sixes', 'kwick cricket', 'kwik cricket'];

export const SCORING: {
   MORE_SCORES: SCORING_TYPE;
   LESS_SCORES: SCORING_TYPE;
   MORE_TIME: SCORING_TYPE;
   LESS_TIME: SCORING_TYPE;
   MORE_RESULT: SCORING_TYPE;
   LESS_RESULT: SCORING_TYPE;
   FIRST_TO_N_POINTS: SCORING_TYPE;
} = {
   MORE_SCORES: 'MORE_SCORES',
   LESS_SCORES: 'LESS_SCORES',
   MORE_TIME: 'MORE_TIME',
   LESS_TIME: 'LESS_TIME',
   MORE_RESULT: 'MORE_RESULT',
   LESS_RESULT: 'LESS_RESULT',
   FIRST_TO_N_POINTS: 'FIRST_TO_N_POINTS'
};

export const SPORT_POINTS_TYPE = {
   'PLAIN': 'PLAIN',
   'TIME': 'TIME',
   'DISTANCE': 'DISTANCE',
   'PRESENCE_ONLY': 'PRESENCE_ONLY'
};
