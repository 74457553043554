import * as propz from 'propz';
import Lazy from 'lazy.js';
import { SchoolEvent, SchoolEventSchoolScore, SchoolEventTeamScore } from 'models/event';
import {
   Participant,
   SchoolEventSchool,
   SchoolEventIndividualData
} from 'models/event';
import { School, PublicSchoolShort } from 'models/school';
import { SportPosition } from 'models/sport';
import { EVENT_RESULTS_MODE } from 'consts/event';
import { EVENT_TYPES, EVENT_STATUS } from 'consts/event';
import { SCORING, SPORT_FORMAT, SPORT_POINTS_TYPE } from 'consts/sport';
import { KIND_SERVER_TO_CLIENT_MAPPING ,AGE_GROUPS_SORTED, AGE_GROUPS } from 'consts/school';
import { sortHouses } from './house';
import { getCurrentSeasonStartYear, getEventSeasonStrartYear, sortByNameAsc } from './common';
import { sortPlayersByLastNameAsc } from './players';
import {
   getTeamScoreByTeamId,
   getSchoolScoreBySchoolId,
   getIndividualScoreByStudent,
   getExtraPointsByStudent
} from './eventView';
import { sortIndividualPlayersByExtraPointsOrByScoreOrByLastName } from './players';
import { sortTeams } from './team';
import { TOURNAMENT_TYPE } from 'consts/tournament';

export function isClubEvent(event: SchoolEvent): boolean {
   return typeof event.clubId !== 'undefined';
}

export function isIndividualSportEvent(event: SchoolEvent): boolean {
   const { sport } = event;
   return sport.players === SPORT_FORMAT.INDIVIDUAL;
}

export function isTeamSportEvent(event: SchoolEvent): boolean {
   const { sport } = event;
   return sport.players === SPORT_FORMAT.TEAM;
}

export function isOneOnOneSportEvent(event: SchoolEvent): boolean {
   const { sport } = event;
   return sport.players === SPORT_FORMAT['1X1'];
}

export function isTeamOrTwoOnTwoSportEvent(event: SchoolEvent): boolean {
   return isTeamSportEvent(event) || isTwoOnTwoSportEvent(event);
}

export function isTwoOnTwoSportEvent(event: SchoolEvent): boolean {
   const { sport } = event;
   return sport.players === SPORT_FORMAT['2X2'];
}

export function isTournamentEvent(event: SchoolEvent): boolean {
   return typeof event.tournamentId !== 'undefined';
}

export function isLeagueEvent(event: SchoolEvent): boolean {
  return typeof event.schoolLeagueId !== 'undefined';
}

export function getEventGeneratedName(event: SchoolEvent, activeSchoolId: string): string {
   return propz.get(event, ['generatedNames', activeSchoolId], '');
}

export function getCalendarEventGeneratedName(event: SchoolEvent, activeSchoolId: string): string {
   return propz.get(event, ['generatedNames', activeSchoolId], '');
}

export function isResultsModePlaces(event: SchoolEvent): boolean {
   const resultsMode = propz.get(event, ['resultsMode'], EVENT_RESULTS_MODE.RESULTS);
   return resultsMode === EVENT_RESULTS_MODE.PLACES;
}

export function isResultsModeResults(event: SchoolEvent): boolean {
   const resultsMode = propz.get(event, ['resultsMode'], EVENT_RESULTS_MODE.RESULTS);
   return resultsMode === EVENT_RESULTS_MODE.RESULTS;
}

export function isNonTeamSportEvent(event: SchoolEvent): boolean {
   return isIndividualSportEvent(event) || isOneOnOneSportEvent(event);
}

export function isResultsModePoints(event: SchoolEvent): boolean {
   const resultsMode = propz.get(event, ['resultsMode'], EVENT_RESULTS_MODE.RESULTS);
   return resultsMode === EVENT_RESULTS_MODE.POINTS;
}

export function isInterSchoolsEvent(event: SchoolEvent): boolean {
   const { eventType } = event;
   return eventType === EVENT_TYPES.EXTERNAL_SCHOOLS;
}

export function isDistanceOrTimeTournamentEvent(event: SchoolEvent) {
   const isTournamentEvent = typeof event.tournamentId !== 'undefined';
   const sportPoints = propz.get(event, ['sport', 'points', 'display'], '');
   const isTimeEvent = sportPoints === SPORT_POINTS_TYPE.TIME;
   const isDistanceEvent = sportPoints === SPORT_POINTS_TYPE.DISTANCE;

   return (isDistanceEvent || isTimeEvent) && isTournamentEvent;
}

export function isIndividualTournamentAndTeamSport(event: SchoolEvent) {
   const tournamentType = propz.get(event, ['tournament', 'type']);

   return tournamentType === TOURNAMENT_TYPE.INDIVIDUAL_TOURNAMENT && isTeamSportEvent(event)
}

export function isInterSchoolsEventForIndividualSport(event: SchoolEvent) {
   return isInterSchoolsEvent(event) && isIndividualSportEvent(event);
}

export function isHousesEvent(event: SchoolEvent): boolean {
   const { eventType } = event;

   return eventType === EVENT_TYPES.INTERNAL_HOUSES;
}

export function isInterSchoolsEventForTeamSport(event: SchoolEvent) {
   return isInterSchoolsEvent(event) && isTeamSportEvent(event);
}

export function isHousesEventForTeamSport(event: SchoolEvent) {
   return isHousesEvent(event) && isTeamSportEvent(event);
}

export function isInternalEventForTeamSport(event: SchoolEvent) {
   return isInternalEvent(event) && isTeamSportEvent(event);
}

export function isInternalEvent(event: SchoolEvent): boolean {
   const { eventType } = event;

   return eventType === EVENT_TYPES.INTERNAL_TEAMS;
}

export function isEventStatusFinished(event: SchoolEvent): boolean {
   const status = propz.get(event, ['status']);

   return status === EVENT_STATUS.FINISHED;
}

export function isEventStatusCanceled(event: SchoolEvent): boolean {
   const status = propz.get(event, ['status']);

   return status === EVENT_STATUS.CANCELED;
}

export function isEventStatusRejected(event: SchoolEvent): boolean {
   const status = propz.get(event, ['status']);

   return status === EVENT_STATUS.REJECTED;
}

export function isMultipartyEvent(event: SchoolEvent): boolean {
   let multiparty = false;

   if (typeof propz.get(event, ['sport', 'multiparty']) !== 'undefined') {
      multiparty = propz.get(event, ['sport', 'multiparty']);
   }

   if (typeof propz.get(event, ['sportModel', 'multiparty']) !== 'undefined') {
      multiparty = propz.get(event, ['sportModel', 'multiparty']);
   }

   return multiparty;
}

export function isCricket(sportName: string): boolean {
   const cricketNames = ['cricket', 'cricket t20', 'cricket sixes', 'kwick cricket', 'kwik cricket'];

   return cricketNames.some(cricketName => cricketName === sportName.toLowerCase());
};

export function checkAreFinishedEventsExist(events: SchoolEvent[]): boolean {
   if (events.length === 0) return false;

   return events.some(event => event.status === EVENT_STATUS.FINISHED);
}

export function isOpponentHasOneTeam(event: SchoolEvent): boolean {
   const { invitedSchoolIds, teamsData } = event;

   const invitedSchoolTeams = teamsData.filter(team => {
      const teamSchoolId = propz.get(team, ['schoolId'], '');

      return invitedSchoolIds.includes(teamSchoolId);
   });

   return invitedSchoolTeams.length === 1;
}

export function isOpponentHasSeveralTeams(event: SchoolEvent): boolean {
   const { invitedSchoolIds, teamsData } = event;

   const invitedSchoolTeams = teamsData.filter(team => {
      const teamSchoolId = propz.get(team, ['schoolId'], '');

      return invitedSchoolIds.includes(teamSchoolId);
   });

   return invitedSchoolTeams.length > 1;
}

export function isSchoolHasOneTeam(event: SchoolEvent): boolean {
   const { inviterSchoolId, teamsData } = event;

   const inviterSchoolTeams = teamsData.filter(team => {
      const teamSchoolId = propz.get(team, ['schoolId'], '');

      return inviterSchoolId === teamSchoolId;
   });

   return inviterSchoolTeams.length === 1;
}

export function isSchoolHasSeveralTeams(event: SchoolEvent): boolean {
   const { inviterSchoolId, teamsData } = event;

   const inviterSchoolTeams = teamsData.filter(team => {
      const teamSchoolId = propz.get(team, ['schoolId'], '');

      return inviterSchoolId === teamSchoolId;
   });

   return inviterSchoolTeams.length > 1;
}

export function getAllSchoolIdsFromEvent(event: SchoolEvent): string[] {
   const { inviterSchoolId, invitedSchoolIds } = event;

   return Lazy([inviterSchoolId, ...invitedSchoolIds])
      .uniq()
      .toArray();
}

export function getPositionName(positionId: string, event: SchoolEvent): string {
   const positions: SportPosition[] = propz.get(event, ['sport', 'field', 'positions'], []);
   const position = positions.find(p => p._id === positionId);
   return propz.get<string>(position, ['name'], '');
}

export function getEventParticipants(
   event: SchoolEvent,
   activeSchool: School,
   schools: PublicSchoolShort[]
): Participant[] {
   switch (true) {
      case isTeamOrTwoOnTwoSportEvent(event):
         return getTeamEventParticipants(event, activeSchool, schools);
      case isNonTeamSportEvent(event):
         return getIndividualEventParticipants(event, activeSchool, schools);
      default:
         console.error('Type of event incorrect');
         return [];
   }
}

export function getTeamEventParticipants(
   event: SchoolEvent,
   activeSchool: School,
   schools: PublicSchoolShort[]
): Participant[] {
   switch (true) {
      case isInterSchoolsEvent(event):
         return getInterSchoolsTeamEventParticipants(event, activeSchool);
      case isHousesEvent(event):
         return getHousesTeamEventParticipants(event, schools);
      case isInternalEvent(event):
         return getInternalTeamEventParticipants(event, schools);
      default:
         console.error('Event type not defined');
         return [];
   }
}

export function getIndividualEventParticipants(
   event: SchoolEvent,
   activeSchool: School,
   schools: PublicSchoolShort[]
): any[] {
   switch (true) {
      case isInterSchoolsEvent(event):
         return getInterSchoolsIndividualEventParticipants(event, activeSchool);
      case isHousesEvent(event):
         return getHousesIndividualEventParticipants(event, schools);
      case isInternalEvent(event):
         return getInternalIndividualEventParticipants(event, schools);
      default:
         console.error('Event type not defined');
         return [];
   }
}

export function getInterSchoolsTeamEventParticipants(
   event: SchoolEvent,
   activeSchool: School
): Participant[] {
   const { teamsData, invites } = event;
   let participants: Participant[] = [];

   const isEventFinished = isEventStatusFinished(event);
   const schoolsSorted = getSortedSchoolsFromEvent(activeSchool, event);

   schoolsSorted.forEach((school: any) => {
      const teams = teamsData.filter(team => team.schoolId === school.id);

      const invite = [...invites]
         .sort((invite1, invite2) => {
            const inviteCreatedDate1 = new Date(invite1.createdAt);
            const inviteCreatedDate2 = new Date(invite2.createdAt);
            return Number(inviteCreatedDate2) - Number(inviteCreatedDate1);
         })
         .find(invite => invite.invitedSchoolId === school.id);

      const isTeamsExist = teams.length > 0;

      if (isTeamsExist) {
         // school can have many teams
         [...teams].sort(sortByNameAsc).forEach(team => {
            participants.push({
               team: team,
               school: school,
               invite: invite,
               teamPlayers: [...team.players].sort(sortPlayersByLastNameAsc)
            });
         });
      } else {
         participants.push({
            team: undefined,
            school: school,
            invite: invite
            });
      }
   });

   if (isEventFinished && isMultipartyEvent(event)) {
      const sortedParticipants = [...participants].sort((participant1, participant2) => {
         const isTeamExist1 = typeof participant1.team !== 'undefined';
         const isTeamExist2 = typeof participant2.team !== 'undefined';

         const { team: firstTeam } = participant1;
         const { team: secondTeam } = participant2;

         const isFirstTeamExist = typeof firstTeam !== 'undefined';
         const isSecondTeamExist = typeof secondTeam !== 'undefined';

         const firstTeamId = isFirstTeamExist ? firstTeam.id : '';
         const secondTeamId = isSecondTeamExist ? secondTeam.id : '';

         const score1 = isTeamExist1
            ? getTeamScoreByTeamId(event, firstTeamId)
            : getSchoolScoreBySchoolId(event, participant1.school.id);
         const score2 = isTeamExist2
            ? getTeamScoreByTeamId(event, secondTeamId)
            : getSchoolScoreBySchoolId(event, participant2.school.id);

         const isMultiparty = isMultipartyEvent(event);

         if (isMultiparty) {
            const isPlaces = isResultsModePlaces(event);
            const isPoints = isResultsModePoints(event);

            switch (true) {
               case isPlaces:
                  return score1 - score2;
               case isPoints:
                  return score2 - score1;
               default:
                  console.error('Can not find results mode');
                  return 0;
            }
         };

         return 0;
      });

      return sortedParticipants;
   }

   return participants;
}

export function getHousesTeamEventParticipants(event: SchoolEvent, schools: PublicSchoolShort[]) {
   const { housesData, teamsData } = event;

   const [school] = schools;

   const participants = housesData
      .sort((house1, house2) => sortHouses(event, house1, house2))
      .map(house => {
         const { id } = house;
         const team = teamsData.find(team => team.houseId === id);
         const isTeamExist = typeof team !== 'undefined';

         if (isTeamExist) {
            return {
               team: team,
               invite: undefined,
               house: house,
               school: school,
               teamPlayers: [...team.players].sort(sortPlayersByLastNameAsc)
            };
         } else {
            return {
               team: undefined,
               invite: undefined,
               house: house,
               school: school
            };
         }
      });

   return participants;
}

export function getInternalTeamEventParticipants(
   event: SchoolEvent,
   schools: PublicSchoolShort[]
): Participant[] {
   const { teamsData } = event;

   const [school] = schools;

   const participants = [...teamsData]
      .sort((team1, team2) => sortTeams(event, team1, team2))
      .map(team => {
         return {
            team: team,
            teamPlayers: [...team.players].sort(sortPlayersByLastNameAsc),
            invite: undefined,
            school: school
         };
      });

   return participants;
}

export function getInterSchoolsIndividualEventParticipants(
   event: SchoolEvent,
   activeSchool: School
): Participant[] {
   let participants: Participant[] = [];

   const schoolsSorted = getSortedSchoolsFromEvent(activeSchool, event);

   const { individualsData, invites } = event;

   schoolsSorted.forEach((school: any) => {
      const invite = [...invites]
         .sort((invite1, invite2) => {
            const inviteCreatedDate1 = new Date(invite1.createdAt);
            const inviteCreatedDate2 = new Date(invite2.createdAt);
            return Number(inviteCreatedDate2) - Number(inviteCreatedDate1);
         })
         .find(invite => invite.invitedSchoolId === school.id || invite.inviterSchoolId === school.id);

      let individualPlayers: SchoolEventIndividualData[] = [];

      const schoolFound = getSchoolFromEventBySchoolId(event, school.id);
      const schoolIds = propz.get(schoolFound, ['schoolIds']);

      individualsData.forEach(individual => {
         const { isRemovedFromSU } = individual;
         const isSchoolIdEqualIndividualSchoolId = individual.schoolId === school.id;

         //tournament condition
         const isSchoolIdsExist = typeof schoolIds !== 'undefined';
         const isSomeSchoolIdsEqualIndividualSchoolId =
            isSchoolIdsExist && schoolIds.some((schoolId: string) => schoolId === individual.schoolId);

         if (isSchoolIdEqualIndividualSchoolId || isRemovedFromSU || isSomeSchoolIdsEqualIndividualSchoolId) {
            individualPlayers.push(individual);
         }
      });

      participants.push({
         team: undefined,
         school: school,
         invite: invite,
         individualPlayers: getPlayersSorted(event, individualPlayers)
      });
   });

   return participants;
}

export function getPlayersSorted(
   event: SchoolEvent,
   individualPlayers: SchoolEventIndividualData[]
): SchoolEventIndividualData[] {
   const playersWithScoreOrExtraPoints = individualPlayers.filter(individualPlayer => {
      const score = getIndividualScoreByStudent(event, individualPlayer.userId, individualPlayer.permissionId);
      const extraPoints = getExtraPointsByStudent(event, individualPlayer.userId);
      return score !== 0 || extraPoints !== 0;
   });

   const playersWithOutScoreAndExtraPoints = individualPlayers.filter(individualPlayer => {
      const score = getIndividualScoreByStudent(event, individualPlayer.userId, individualPlayer.permissionId);
      const extraPoints = getExtraPointsByStudent(event, individualPlayer.userId);
      return score === 0 && extraPoints === 0;
   });

   const playersWithScoreSorted = [...playersWithScoreOrExtraPoints].sort((player1, player2) =>
      sortIndividualPlayersByExtraPointsOrByScoreOrByLastName(event, player1, player2)
   );

   const playersWithOutScoreSorted = [...playersWithOutScoreAndExtraPoints].sort((player1, player2) =>
      sortPlayersByLastNameAsc(player1, player2)
   );

   return [...playersWithScoreSorted, ...playersWithOutScoreSorted];
}

function getSchoolFromEventBySchoolId(event: SchoolEvent, schoolId: string) {
   const { inviterSchool, invitedSchools } = event;

   return [inviterSchool, ...invitedSchools].find(school => school.id === schoolId);
}

export function getHousesIndividualEventParticipants(
   event: SchoolEvent,
   schools: PublicSchoolShort[]
): Participant[] {
   const [school] = schools;

   const { housesData, individualsData } = event;

   const participants = housesData
      .map(house => {
         let individualPlayers: any = [];

         individualsData.forEach(individual => {
            const isHouseIdEqualIndividualHouseId = individual.houseId === house.id;

            if (isHouseIdEqualIndividualHouseId) {
               individualPlayers.push(individual);
            }
         });

         return {
            school: school,
            house: house,
            individualPlayers: getPlayersSorted(event, individualPlayers),
            team: undefined,
            invite: undefined
         };
      })
      .sort((participant1, participant2) => {
         if (participant1.house.name < participant2.house.name) {
            return -1;
         }

         if (participant1.house.name > participant2.house.name) {
            return 1;
         }

         return 0;
      });

   return participants;
}

export function getInternalIndividualEventParticipants(
   event: SchoolEvent,
   schools: PublicSchoolShort[]
): Participant[] {
   const [school] = schools;

   const { individualsData } = event;

   const individualPlayers = [...individualsData];

   return [
      {
         school: school,
         house: undefined,
         individualPlayers: getPlayersSorted(event, individualPlayers),
         team: undefined,
         invite: undefined
      }
   ];
}

//school id not exist in individual score, but exist in players
export function addSchoolIdInIndividualScore(event: SchoolEvent) {
   const individualsData = event.players;
   const individualsScore = event.results.individualScore;

   return individualsScore
      .map(individualScoreData => {
         const individual = individualsData.find(individual => individual.userId === individualScoreData.userId);
         if (typeof individual !== 'undefined') {
            return {
               ...individualScoreData,
               schoolId: individual.schoolId
            }
         }

         return individualScoreData;
      })
      .filter(individualScoreData => typeof individualScoreData !== 'undefined');
};

function uniqArray(arr: string[]) {
   const obj: any = {};

   for (let i = 0; i < arr.length; i++) {
      const str = arr[i];
      obj[str] = true;
   }

   return Object.keys(obj);
}

function getSortedScoreData (
   scoring: string,
   scoreData: ((SchoolEventTeamScore & {schoolId: string}) | SchoolEventSchoolScore )[]
): ((SchoolEventTeamScore & {schoolId: string}) | SchoolEventSchoolScore )[] {
   return [...scoreData].sort((scoreData1: any, scoreData2: any) => {
      const score1 = scoreData1.points;
      const score2 = scoreData2.points;

      switch (scoring) {
         case SCORING.LESS_SCORES:
         case SCORING.LESS_TIME:
         case SCORING.LESS_RESULT:
            if (score1 < score2) {
               return -1;
            } else if (score1 > score2) {
               return 1;
            } else {
               return 0;
            }
         case SCORING.MORE_SCORES:
         case SCORING.MORE_TIME:
         case SCORING.MORE_RESULT:
         case SCORING.FIRST_TO_N_POINTS:
            if (score1 < score2) {
               return 1;
            } else if (score1 > score2) {
               return -1;
            } else {
               return 0;
            }
         default:
            return 0;
      }
   });
}

export function getSortedScoreArrayForInterSchoolsMultipartyTeamEvent (event: SchoolEvent) {
   const	teamsData = event.teamsData;
   const teamScore = event.results.teamScore;
   const schoolScore = event.results.schoolScore;
   const scoring = propz.get(event, ['sport', 'scoring'], '');

   const teamScoreWithSchoolId: any[] =
      teamScore
         .map(teamResult => {
            const team = teamsData.find(teamData => teamData.id === teamResult.teamId);

            if (typeof team !== 'undefined') {
               return {
                  ...teamResult,
                  schoolId: team.schoolId
               }
            }

            return teamResult;
         })
         .filter(teamResult => typeof teamResult !== 'undefined');

   let score: ( (SchoolEventTeamScore & {schoolId: string}) | SchoolEventSchoolScore )[] =
      (Lazy(teamScoreWithSchoolId) as any)
         .concat(schoolScore)
         .flatten()
         .toArray();

   score = getSortedScoreData(scoring, score);

   return score;
}

export function getSortedScoreArrayForInterSchoolsMultipartyIndividualEvent(event: SchoolEvent) {
   const schoolIdList = event.players.map(player => player.schoolId);
   const schoolIdListUniq = uniqArray(schoolIdList);

   const individualsScoreAdditionalData = addSchoolIdInIndividualScore(event);

   let schoolScore: any = [];

   schoolIdListUniq.forEach(schoolId => {
      let points = 0;

      individualsScoreAdditionalData.forEach((individualScore: any) => {
         if (individualScore.schoolId === schoolId) {
            points += individualScore.richScore.points;
         }
      });

      schoolScore.push({
         schoolId: schoolId,
         score: points
      });
   });

   schoolScore = getSortedScoreData('MORE_SCORES', schoolScore); // for individual event sort always DESC

   return schoolScore;
}

export function getSortedPlaceArrayForInterSchoolsMultipartyTeamEvent(event: SchoolEvent) {
   const places: any = [];
   let scoreArray;

   switch (true){
      case isMultipartyEvent(event) && isInterSchoolsEventForTeamSport(event):
         scoreArray = getSortedScoreArrayForInterSchoolsMultipartyTeamEvent(event);
         break;

      case isMultipartyEvent(event) && isInterSchoolsEventForIndividualSport(event):
         scoreArray = getSortedScoreArrayForInterSchoolsMultipartyIndividualEvent(event);
         break;

      default:
         scoreArray = [];
   }

   const isMultipartyInterSchoolsEventForTeamSport = isMultipartyEvent(event) && isInterSchoolsEventForTeamSport(event);

   const isDistanceOrTimeTournamentEventCondition = isDistanceOrTimeTournamentEvent(event);

   if (isDistanceOrTimeTournamentEventCondition) {
      scoreArray = scoreArray.filter((score: any) => score.score !== 0);
   }

   if (scoreArray.length !== 0) {
      for (let i = 0; i < scoreArray.length; i++) {
         let currentScoreValue = scoreArray[0].score;

         if (currentScoreValue === scoreArray[i].score) {
            const placeIndex = places.findIndex((place: any) => place.score === currentScoreValue);

            if (placeIndex !== -1) {
               places[placeIndex].schoolIds.push(scoreArray[i].schoolId);

               if (isMultipartyInterSchoolsEventForTeamSport){
                  places[placeIndex].teamIds.push(scoreArray[i].teamId);
               }
            } else {
               if (isMultipartyInterSchoolsEventForTeamSport){
                  places.push({
                     place: places.length + 1,
                     score: scoreArray[i].score,
                     schoolIds: [scoreArray[i].schoolId],
                     teamIds: [scoreArray[i].teamId]
                  });
               } else {
                  places.push({
                     place: places.length + 1,
                     score: scoreArray[i].score,
                     schoolIds: [scoreArray[i].schoolId]
                  });
               }
            }
         } else {
            currentScoreValue = scoreArray[i].score;
            if (isMultipartyInterSchoolsEventForTeamSport) {
               places.push({
                  place: places.length + 1,
                  score: scoreArray[i].score,
                  schoolIds: [scoreArray[i].schoolId],
                  teamIds: [scoreArray[i].teamId]
               });
            } else {
               places.push({
                  place: places.length + 1,
                  score: scoreArray[i].score,
                  schoolIds: [scoreArray[i].schoolId]
               });
            }
         }
      }
   }

   return places;
}

export function getSortedSchoolsFromEvent(school: School, event: SchoolEvent): SchoolEventSchool[] {
   const { invitedSchools, inviterSchool, eventCreatorId } = event;
   const schoolsData = [...invitedSchools, inviterSchool];
   const { id: activeSchoolId } = school;
   const activeSchool = schoolsData.find(school => school.id === activeSchoolId);
   const restSchools = [...schoolsData].filter(school => school.id !== activeSchoolId).sort(sortByNameAsc);
   const schools = typeof activeSchool !== 'undefined' ? [activeSchool, ...restSchools] : restSchools;

   const schoolsSorted = schools.filter(school => {
      const isSchoolFoundKindSchoolUnion = school.kind === KIND_SERVER_TO_CLIENT_MAPPING.SCHOOL_UNION;
      const isSchoolFoundEventCreator = school.id === eventCreatorId;

      return !(isSchoolFoundKindSchoolUnion && isSchoolFoundEventCreator);
   });

   return schoolsSorted;
}

export function getSchoolEventAges(event: SchoolEvent, activeSchool: School): string {
   const ageGroupsNaming = propz.get(activeSchool, ['ageGroupsNaming']);
   const { ages } = event;

   return ages.length > 0
      ? [...ages]
         .sort()
         .map(elem => propz.get(AGE_GROUPS_SORTED, [ageGroupsNaming, elem]))
         .join(', ')
      : 'All ages';
}

export function getAgesGroupFormatted(event: any, activeSchool: School): string {
  const eventAgesGroupName = propz.get(activeSchool, ['ageGroupsNaming'], '');
  const eventAgesGroup = propz.get(AGE_GROUPS, [eventAgesGroupName], []);
  const { ages: ageIndexes } = event;
  const agesCount = ageIndexes.length;

  const sortedAgeIndexes = ageIndexes.sort((prev: number, next: number) => {
    return sortAges(prev, next, eventAgesGroupName);
  });

  const firstAgeIndex = sortedAgeIndexes[0];
  const lastAgeIndex = sortedAgeIndexes[agesCount - 1];

  const isAgeIndexesSerial = sortedAgeIndexes.every((ageIndex: number, index: number, array: number[]) => {
    const arrayLastIndex = array.length - 1;
    const increasedAgeIndex = ageIndex + 1;
    const decrementedAgeIndex = ageIndex - 1;

    const prevIndex = array[index - 1];
    const nextIndex = array[index + 1];

    switch (true) {
      case eventAgesGroup[ageIndex] === 'N1' && eventAgesGroup[nextIndex] === 'N2':
        return true;

      case eventAgesGroup[ageIndex] === 'N2' && eventAgesGroup[nextIndex] === 'Reception':
        return true;

      case (eventAgesGroup[ageIndex] === 'Y14' && eventAgesGroup[nextIndex] === 'Post-19')
        || (eventAgesGroup[ageIndex] === 'Post-19' && eventAgesGroup[prevIndex] === 'Y14'):
        return true;

      case increasedAgeIndex === nextIndex:
        return true;

      case index === arrayLastIndex:
        return decrementedAgeIndex === prevIndex;
    
      default:
        return false;
    }
  });
  
  const getNotSerialAgesString = (ageIndexes: number[]): string => {
    let resultAgesString = '';
    let arraysOfSerialOrSingleAgesIndexes: any[] = [];
    let firstSerialIndex = 0;

    ageIndexes.forEach((ageIndex, index, array) => {
      const increasedAgeIndex = ageIndex + 1;
      const nextAgeIndex = array[index + 1];
      const nextIndex = index + 1;

      const isPreSchoolAge =
        (eventAgesGroup[ageIndex] === 'N2' && eventAgesGroup[nextAgeIndex] === 'Reception') ||
        (eventAgesGroup[ageIndex] === 'N1' && eventAgesGroup[nextAgeIndex] === 'N2');

      if (increasedAgeIndex !== nextAgeIndex && !isPreSchoolAge) {
        const serialAgesIndexes: number[] = array.slice(firstSerialIndex, nextIndex);
        arraysOfSerialOrSingleAgesIndexes.push(serialAgesIndexes);
        firstSerialIndex = nextIndex;
      }
    });
    
    arraysOfSerialOrSingleAgesIndexes.forEach((serialOrSingleAgesIndexes, index, array) => {
      const isSingleAgeIndex = serialOrSingleAgesIndexes.length === 1;
      const isLastArrayIteration = index === array.length - 1;

      const firstSerialAgeIndex = serialOrSingleAgesIndexes[0];
      const lastSerialAgeIndex = serialOrSingleAgesIndexes[serialOrSingleAgesIndexes.length - 1];
      const firstEventAge = eventAgesGroup[firstSerialAgeIndex];
      const lastEventAge = eventAgesGroup[lastSerialAgeIndex];

      switch (true) {
        case isLastArrayIteration && isSingleAgeIndex:
          resultAgesString += `${firstEventAge}`;
          break;

        case isLastArrayIteration && !isSingleAgeIndex:
          resultAgesString += `${firstEventAge} - ${lastEventAge}`;
          break;

        case !isLastArrayIteration && isSingleAgeIndex:
          resultAgesString += `${firstEventAge}, `;
          break;

        case !isLastArrayIteration && !isSingleAgeIndex:
          resultAgesString += `${firstEventAge} - ${lastEventAge}, `;
          break;
      }
    });

    return resultAgesString;
  };

  const firstEventAge = eventAgesGroup[firstAgeIndex];
  const lastEventAge = eventAgesGroup[lastAgeIndex];

  switch (true) {
    case agesCount === 0 || agesCount === 18: {
      return 'All ages';
    }

    case agesCount === 1: {
      return firstEventAge;
    }

    case agesCount >= 2 && isAgeIndexesSerial: {
      return `${firstEventAge} - ${lastEventAge}`;
    }

    case agesCount === 2: {
      return `${firstEventAge}, ${lastEventAge}`;
    }

    case agesCount > 2 && !isAgeIndexesSerial: {
      return getNotSerialAgesString(sortedAgeIndexes);
    }

    default: {
      return `${firstEventAge} - ${lastEventAge}`;
    }
  }
}

export function sortAges(prev: number, next: number, ageGroupsNaming: string): number {
  const sortedAgeGroup: string[] = propz.get(AGE_GROUPS_SORTED, [ageGroupsNaming]);
  const ageGroup: string[] = propz.get(AGE_GROUPS, [ageGroupsNaming]);

  const prevAge: string = ageGroup[prev];
  const nextAge: string = ageGroup[next];

  const ageIndex1: number = sortedAgeGroup.indexOf(prevAge);
  const ageIndex2: number = sortedAgeGroup.indexOf(nextAge);

  return ageIndex1 - ageIndex2;
}

export function filterEventsByCurrentSeason(allEvents: SchoolEvent[]) {
   return allEvents.filter(event => {
      const currentSeasonStartYear = getCurrentSeasonStartYear();
      const eventSeasonStartYear = getEventSeasonStrartYear(event);

      return eventSeasonStartYear === currentSeasonStartYear
   });
};

export function getAgeRangeFromSingleAgeEvent(event: SchoolEvent, ageGroupsNaming: string): string {
   const { ages: ageIndexes } = event;
   const ageGroup: string[] = propz.get(AGE_GROUPS, [ageGroupsNaming]);

   const sortedAgeIndexes = ageIndexes.sort((prev: number, next: number) => {
      return sortAges(prev, next, ageGroupsNaming);
   });

   const agesLastItemIndex = sortedAgeIndexes.length - 1;
   const agesLastItem = sortedAgeIndexes[agesLastItemIndex];

   let agesRange;
   const firstAge = ageGroup[sortedAgeIndexes[0]];

   if (sortedAgeIndexes.length >= 2) {
      const lastAge = ageGroup[agesLastItem];
      agesRange = `${firstAge}-${lastAge}`
   } else {
      agesRange = firstAge;
   }

   return `${agesRange}`;
};
