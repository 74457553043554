import React from 'react';

function SquadInTouchLogo() {
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         width='120'
         height='50'
         version='1.1'
         viewBox='0 0 120 50'
      >
         <g transform='translate(-357.316 -491.685)'>
            <g transform='matrix(3.08166 0 0 3.08166 2104.917 470.562)'>
               <rect
                  width='130.597'
                  height='58.507'
                  x='-578.806'
                  y='-29.828'
                  fill='#000'
                  fillOpacity='0'
                  ry='6.269'
               ></rect>
            </g>
            <g transform='matrix(.9869 0 0 .9869 357.36 516.935)'>
               <path
                  fill='#164170'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0v18.691l6.23 6.23h6.231L6.23 0H0z'
               ></path>
            </g>
            <g transform='matrix(.9869 0 0 .9869 394.252 516.935)'>
               <path
                  fill='#235413'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0v18.691l-6.23 6.23h-6.231L-6.23 0H0z'
               ></path>
            </g>
            <g transform='matrix(.9869 0 0 .9869 357.316 510.786)'>
               <path
                  fill='#007dbe'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0l4.405-4.404L8.811.001 4.406 4.406 0 0z'
               ></path>
            </g>
            <g transform='matrix(.9869 0 0 .9869 371.414 496.687)'>
               <path
                  fill='#f26522'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0l4.405-4.405L8.811 0 4.406 4.405 0 0z'
               ></path>
            </g>
            <g transform='matrix(.9869 0 0 .9869 385.512 510.786)'>
               <path
                  fill='#66952e'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0l4.405-4.405L8.811 0 4.406 4.405 0 0z'
               ></path>
            </g>
            <g transform='matrix(.9869 0 0 .9869 378.88 541.53)'>
               <path
                  fill='#9e2718'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0l6.23-37.382h-18.691L-6.23 0H0z'
               ></path>
            </g>
            <g transform='matrix(.9869 0 0 .9869 357.36 516.935)'>
               <path
                  fill='#007dbe'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0v15.576L6.23 6.23l12.461 3.116L6.23 0H0z'
               ></path>
            </g>
            <path
               fill='#66952e'
               fillOpacity='1'
               fillRule='nonzero'
               stroke='none'
               d='M394.252 532.307v-15.372l-6.149 9.223-12.297-3.075 12.297 9.224h6.149z'
            ></path>
            <g transform='matrix(.9869 0 0 .9869 366.582 504.637)'>
               <path
                  fill='#f26522'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0H18.691L9.346 6.23v9.346L0 0z'
               ></path>
            </g>
            <g transform='matrix(.9869 0 0 .9869 413.618 515.804)'>
               <path
                  fill='#333132'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0c0 .673-.064 1.278-.191 1.817a3.722 3.722 0 01-.63 1.391 2.881 2.881 0 01-1.136.897c-.465.209-1.043.314-1.733.314-.63 0-1.177-.09-1.642-.269a3.033 3.033 0 01-1.147-.752 3.008 3.008 0 01-.664-1.133A4.481 4.481 0 01-7.357.852l1.772-.359c0 .3.03.58.09.842.059.261.16.497.303.706.141.21.336.374.583.494.247.119.549.179.908.179.449 0 .804-.071 1.066-.213.261-.142.456-.336.583-.583.126-.247.205-.535.235-.864.03-.328.045-.673.045-1.031a7.7 7.7 0 00-.067-1.133 1.817 1.817 0 00-.292-.763 2.005 2.005 0 00-.639-.572 9.268 9.268 0 00-1.11-.538c-.733-.314-1.32-.632-1.761-.953-.441-.322-.782-.658-1.021-1.01a2.954 2.954 0 01-.471-1.121 7.069 7.069 0 01-.112-1.312c0-.598.075-1.148.225-1.649.15-.501.379-.934.687-1.301.307-.366.693-.65 1.158-.852.465-.202 1.005-.303 1.62-.303.675 0 1.241.105 1.699.314.457.21.825.483 1.102.819.278.336.473.706.585 1.11.112.404.169.8.169 1.189l-1.772.449c0-.703-.142-1.245-.426-1.627-.284-.381-.771-.572-1.458-.572-.389 0-.699.075-.931.225-.232.149-.415.34-.549.572-.135.232-.225.49-.269.774a5.898 5.898 0 00-.012 1.693c.038.247.139.479.304.695.166.217.41.43.732.64.323.209.777.426 1.363.65.676.269 1.212.549 1.611.841.397.292.701.617.911.976.211.359.346.762.406 1.211C-.03-1.077 0-.568 0 0'
               ></path>
            </g>
            <g transform='matrix(.9869 0 0 .9869 421.41 508.472)'>
               <path
                  fill='#333132'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0c0-.268-.019-.544-.056-.828A2.33 2.33 0 00-.292-1.6a1.502 1.502 0 00-.549-.582c-.247-.157-.572-.235-.976-.235-.449 0-.793.108-1.032.324a2.066 2.066 0 00-.538.771 3.06 3.06 0 00-.213.949c-.023.336-.034.615-.034.839V8.02c0 .224.023.462.068.715.044.254.134.492.269.715a1.6 1.6 0 00.549.548c.232.141.535.212.909.212.388 0 .702-.067.942-.202l-1.324-1.099.875-1.054 1.212.987A4.28 4.28 0 000 7.723V0zm1.772 7.496c0 1.032-.143 1.877-.426 2.535l1.256 1.009-.875 1.077-1.211-.965c-.584.464-1.361.696-2.333.696-1.286 0-2.206-.352-2.759-1.055-.553-.702-.83-1.629-.83-2.781V.319c0-.553.049-1.091.146-1.615.097-.523.277-.99.539-1.402a2.84 2.84 0 011.087-.987c.463-.246 1.069-.37 1.817-.37.777 0 1.402.109 1.873.325.471.217.834.52 1.088.909.254.389.422.845.504 1.368.082.524.124 1.099.124 1.727v7.222z'
               ></path>
            </g>
            <g transform='matrix(.9869 0 0 .9869 432.94 504.625)'>
               <path
                  fill='#333132'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0v11.394c0 1.391-.269 2.464-.807 3.219-.539.755-1.466 1.132-2.782 1.132-1.286 0-2.206-.351-2.759-1.054-.553-.703-.829-1.629-.829-2.781V0h1.772v11.913c0 .225.022.463.067.717.045.254.134.493.269.717.135.224.317.407.55.548.231.142.534.213.908.213s.68-.075.919-.224a1.63 1.63 0 00.561-.571c.135-.231.228-.496.281-.795.052-.299.078-.605.078-.918V0H0z'
               ></path>
            </g>
            <g transform='matrix(.9869 0 0 .9869 439.182 507.769)'>
               <path
                  fill='#333132'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0l-.65 3.634-.741 3.655H1.48l-.762-3.7L.09 0H0zm2.512 12.403l-.718-3.476h-3.476l-.695 3.476h-1.705l3.319-15.61h1.66l3.365 15.61h-1.75z'
               ></path>
            </g>
            <g transform='matrix(.9869 0 0 .9869 451.008 508.45)'>
               <path
                  fill='#333132'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0c0-.701-.15-1.254-.449-1.657-.299-.403-.83-.604-1.592-.604h-1.593v12.336h1.593c.733 0 1.256-.201 1.57-.605.314-.402.471-.977.471-1.723V0zm-1.783-3.898c.924 0 1.654.16 2.191.482.537.322.909.863 1.118 1.626.164.628.246 1.406.246 2.333v6.605c0 .89-.097 1.641-.291 2.254-.283.883-.734 1.488-1.353 1.817-.618.329-1.323.493-2.112.493h-3.422v-15.61h3.623z'
               ></path>
            </g>
            <path
               fill='#333132'
               fillOpacity='1'
               fillRule='nonzero'
               stroke='none'
               d='M406.559 526.124h1.748v15.406h-1.748v-15.406z'
            ></path>
            <g transform='matrix(.9869 0 0 .9869 412.838 530.994)'>
               <path
                  fill='#333132'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0v10.676h-1.772v-15.61h1.683L3.14 2.983l1.077 2.849.089-.023V-4.934h1.772v15.61H4.419l-3.23-7.85L.068 0H0z'
               ></path>
            </g>
            <g transform='matrix(.9869 0 0 .9869 430.082 527.74)'>
               <path
                  fill='#333132'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0v13.973h-1.772V0h-2.646v-1.637h7.065V0H0z'
               ></path>
            </g>
            <g transform='matrix(.9869 0 0 .9869 440.003 529.99)'>
               <path
                  fill='#333132'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0c0-.268-.019-.544-.056-.827a2.354 2.354 0 00-.235-.771 1.51 1.51 0 00-.55-.581c-.247-.156-.572-.235-.976-.235-.448 0-.793.108-1.032.324a2.066 2.066 0 00-.538.771 3.094 3.094 0 00-.213.95 13.31 13.31 0 00-.034.838v7.554c0 .224.023.463.068.716.045.253.134.491.269.715.134.224.317.406.549.547.232.142.535.213.909.213.373 0 .68-.075.92-.224a1.63 1.63 0 00.56-.569c.135-.231.228-.496.28-.794.053-.298.079-.603.079-.917V0zm1.772 7.5c0 1.39-.273 2.464-.819 3.218-.546.756-1.477 1.133-2.792 1.133-1.287 0-2.202-.351-2.748-1.054-.546-.703-.818-1.63-.818-2.781V.322c0-.553.048-1.091.145-1.614a3.9 3.9 0 01.539-1.402 2.84 2.84 0 011.087-.987c.464-.247 1.069-.37 1.817-.37.778 0 1.402.108 1.873.325.471.217.834.52 1.088.908.254.389.422.845.505 1.369.081.523.123 1.099.123 1.727V7.5z'
               ></path>
            </g>
            <g transform='matrix(.9869 0 0 .9869 451.482 526.146)'>
               <path
                  fill='#333132'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0v11.394c0 1.391-.269 2.464-.807 3.219-.539.755-1.466 1.132-2.782 1.132-1.286 0-2.205-.351-2.758-1.054-.554-.703-.83-1.629-.83-2.781V0h1.772v11.913c0 .225.022.463.067.717a2.1 2.1 0 00.269.717c.135.224.318.407.55.548.231.142.534.213.908.213s.68-.075.92-.224c.238-.149.426-.339.56-.571.135-.231.228-.496.281-.795.052-.299.078-.605.078-.918V0H0z'
               ></path>
            </g>
            <g transform='matrix(.9869 0 0 .9869 457.589 525.991)'>
               <path
                  fill='#333132'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0c1.211 0 2.089.329 2.635.987.546.658.819 1.518.819 2.579l-1.772.314c.015-.687-.109-1.233-.37-1.637-.262-.404-.707-.606-1.334-.606-.36 0-.651.083-.875.247-.225.165-.4.37-.527.617s-.213.52-.258.818a5.76 5.76 0 00-.068.853v7.873c0 .224.023.467.068.728.045.262.131.502.258.718.127.217.298.4.516.55.216.149.504.224.863.224.583 0 1.013-.179 1.29-.538.276-.359.422-.927.437-1.705l1.817.314c-.03 1.122-.333 1.996-.909 2.624-.576.628-1.439.942-2.59.942-1.182 0-2.064-.328-2.647-.987-.583-.657-.875-1.584-.875-2.781V4.419c0-.553.049-1.096.146-1.627.097-.53.273-1.001.527-1.413A2.848 2.848 0 01-1.772.381C-1.309.127-.718 0 0 0'
               ></path>
            </g>
            <g transform='matrix(.9869 0 0 .9869 468.791 541.53)'>
               <path
                  fill='#333132'
                  fillOpacity='1'
                  fillRule='nonzero'
                  stroke='none'
                  d='M0 0v-7.2h-3.634V0h-1.771v-15.611h1.771v6.864H0v-6.864h1.772V0H0z'
               ></path>
            </g>
         </g>
      </svg>
   );
}

export default SquadInTouchLogo;
