import React, { useState } from 'react';
import Moment from 'moment';
import { styled, experimental_sx as sx } from '@mui/system';
import { DEFAULT_BOX_SHADOW } from 'consts/styles';
import { DEFAULT_ROWS_PER_PAGE_COUNT, ROWS_PER_PAGE_COUNT_OPTIONS } from 'consts/table';
import { EVENT_STATUS_SERVER_TO_CLIENT_MAPPING } from 'consts/event';
import { SchoolEvent } from 'models/event';
import { Tournament } from 'models/tournament';
import { getEventGeneratedName } from 'helpers/event';
import { getSignEventType, getEventResultForCalendar, getEventStartTimeForCalendar } from 'helpers/calendar';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import NoRowsOverlay from './NoRowsOverlay';

interface EventsAndTournamentsTableProps {
   isLoading: boolean;
   events: SchoolEvent[];
   activeSchoolId: string;
   tournaments: Tournament[];
   onEventClick: (eventId: string) => void;
}

interface DataGridWrapperProps {
   isRowsExist: boolean;
}

const DataGridWrapper = styled(Paper, {
   shouldForwardProp: (prop) => prop !== 'isRowsExist'
})<DataGridWrapperProps>(
   ({ isRowsExist }) => sx({
      width: '100%',
      overflow: 'hidden',
      height: 480,
      '& .MuiDataGrid-overlay': {
         backgroundColor: isRowsExist ? 'rgba(0,0,0, 0.2)' : '',
         zIndex: '10'
      },
      '& .MuiDataGrid-row': {
         cursor: 'pointer'
      },
      '& .MuiDataGrid-cell': {
         whiteSpace: 'normal',
         textAlign: 'center',
         '&:focus': {
            outline: 'none'
         }
      },
      '& .MuiDataGrid-cell[data-field="type"]': {
         color: '#1976D2',
         fontWeight: 'bold'
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
         transition: '.3s',
         '&:hover': {
            backgroundColor: '#7ca8d3'
         }
      },
      '& .MuiDataGrid-columnHeader': {
         '& .MuiDataGrid-columnSeparator': {
            display: 'none'
         },
         '& .MuiDataGrid-sortIcon': {
            fill: '#fff'
         },
         '&. MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold'
         }
      },
      '& .MuiDataGrid-columnHeadersInner': {
         backgroundColor: '#1976D2',
         color: '#fff'
      },
      '& .MuiDataGrid-menuIconButton': {
         '& svg': {
            fill: '#fff'
         }
      },
      '& .eEventCanceled': {
         color: '#ff5454',
         textDecoration: 'line-through'
      }
   })
);

const columns: GridColDef[] = [
   { field: 'type', headerName: 'Type', minWidth: 120, headerAlign: 'center', align: 'center' },
   { field: 'event', headerName: 'Event', minWidth: 280, flex: 1, headerAlign: 'center', align: 'center' },
   { field: 'startTime', headerName: 'Start time', minWidth: 150, headerAlign: 'center', align: 'center' },
   { field: 'result', headerName: 'Result', minWidth: 250, headerAlign: 'center', align: 'center' },
];

export default function EventsAndTournamentsTable(props: EventsAndTournamentsTableProps) {
   const { isLoading, events, activeSchoolId, tournaments, onEventClick } = props;

   const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE_COUNT);

   const isRowsExist = events.length > 0 || tournaments.length > 0;

   const getCellClass = (params: GridCellParams) => {
      return params.field === 'event' && params.row.status === EVENT_STATUS_SERVER_TO_CLIENT_MAPPING.CANCELED
         ? 'eEventCanceled'
         : '';
   }

   const getRowData = (event: SchoolEvent) => {
      return {
         id: event.id,
         type: getSignEventType(event),
         event: getEventGeneratedName(event, activeSchoolId),
         startTime: getEventStartTimeForCalendar(event),
         status: EVENT_STATUS_SERVER_TO_CLIENT_MAPPING[event.status],
         result: getEventResultForCalendar(event, activeSchoolId)
      }
   };

   const rows = events.map(event => getRowData(event));

   const onChangePageSizeClick = (value: number) => {
      setPageSize(value);
   };

   return (
      <DataGridWrapper isRowsExist={isRowsExist} elevation={DEFAULT_BOX_SHADOW}>
         <DataGrid
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={ROWS_PER_PAGE_COUNT_OPTIONS}
            onPageSizeChange={onChangePageSizeClick}
            density='comfortable'
            loading={isLoading}
            isRowSelectable={() => false}
            onRowClick={(event) => onEventClick(`${event.id}`)}
            getCellClassName={(params: GridCellParams) => getCellClass(params)}
            components={{NoRowsOverlay}}
         />
      </DataGridWrapper>
   )
}
