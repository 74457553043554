import { isInterSchoolsEvent } from 'helpers/event';
import { EVENT_VENUE, VENUE_SERVER_TO_CLIENT_MAPPING } from 'consts/venue';
import { SchoolEvent } from 'models/event';

export function getVenueView(event: SchoolEvent, schoolId: string): string {
   switch (true) {
      case isInterSchoolsEvent(event):
         return getInterSchoolsVenue(event, schoolId);
      default:
         return VENUE_SERVER_TO_CLIENT_MAPPING[event.venue.venueType];
   }
};

export function getInterSchoolsVenue(event: SchoolEvent, schoolId: string): string {
   const venueType = event.venue.venueType;
   const isVenueTypeHome = venueType === EVENT_VENUE.HOME;
   const isVenueTypeAway = venueType === EVENT_VENUE.AWAY;
   const isVenueTypeCustom = venueType === EVENT_VENUE.CUSTOM;
   const isVenueTypeTBD = venueType === EVENT_VENUE.TBD;

   switch (true) {
      case isVenueTypeHome && event.inviterSchoolId === schoolId:
         return VENUE_SERVER_TO_CLIENT_MAPPING[EVENT_VENUE.HOME];
      case isVenueTypeHome && event.inviterSchoolId !== schoolId:
         return VENUE_SERVER_TO_CLIENT_MAPPING[EVENT_VENUE.AWAY];
      case isVenueTypeAway && event.inviterSchoolId === schoolId:
         return VENUE_SERVER_TO_CLIENT_MAPPING[EVENT_VENUE.AWAY];
      case isVenueTypeAway && event.inviterSchoolId !== schoolId:
         return VENUE_SERVER_TO_CLIENT_MAPPING[EVENT_VENUE.HOME];
      case isVenueTypeCustom:
         return VENUE_SERVER_TO_CLIENT_MAPPING[EVENT_VENUE.CUSTOM];
      case isVenueTypeTBD:
         return VENUE_SERVER_TO_CLIENT_MAPPING[EVENT_VENUE.TBD];
      default:
         return '';
   }
}
