import axios from 'axios';
import { DEFAULT_FILTER, DEFAULT_SKIP, LIMIT_ALL } from 'consts/table';

export function getNews(activeSchoolId: string, filter?: any) {
  let config;

  if (typeof filter !== 'undefined') {
    config = {
      params: {
          filter: {
            where: {
                ...filter.where
            },
            skip: filter.skip,
            limit: filter.limit,
            order: filter.order
          }
      }
    };
  } else {
    config = {
      params: {
        filter: {
          skip: DEFAULT_SKIP,
          limit: LIMIT_ALL,
          order: 'date DESC'
        }
    }
    };
  }

  return axios.get(`${window.apiBase}/public/schools/${activeSchoolId}/news`, config).then(response => {
    return response.data;
  });
}

export function getNewsCount(activeSchoolId: string, filter?: any) {
  let config;

  if (typeof filter !== 'undefined') {
    config = {
        params: {
          filter: {
              where: {
                ...filter.where
              }
          }
        }
    };
  } else {
    config = {};
  }

  return axios.get(`${window.apiBase}/public/schools/${activeSchoolId}/news/count`, config).then(response => {
    return response.data;
  });
}
