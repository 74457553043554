import React from 'react';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { SchoolHouse } from 'models/house';
import { SchoolEvent } from 'models/event';
import { School } from 'models/school';
import { EVENT_STATUS } from 'consts/event';
import { getPlayersSorted, isHousesEvent, isInterSchoolsEvent } from 'helpers/event';
import {
   getIndividualScoreByStudent,
   convertPointsToStringWithoutDelimeter,
   getExtraPointsByStudent,
   getAbbreviationResultByStudent,
   getAbbreviationColor
} from 'helpers/eventView';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { type TableCellProps } from '@mui/material/TableCell';
import TableContainerMUI from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRowMUI from '@mui/material/TableRow';
import MedalForFirstPlaceIcon from 'components/SvgIcons/MedalForFirstPlaceIcon';
import MedalForSecondPlaceIcon from 'components/SvgIcons/MedalForSecondPlaceIcon';
import MedalForThirdPlaceIcon from 'components/SvgIcons/MedalForThirdPlaceIcon';

const RESULT_COLUMNS: TableColumn[] = [
   {
      text: '№',
      value: 'index',
      align: 'left'
   },
   {
      text: 'First name',
      value: 'firstName',
      align: 'center'
   },
   {
      text: 'Last name',
      value: 'lastName',
      align: 'center'
   },
   {
      text: 'School',
      value: 'school',
      align: 'center'
   },
   {
      text: 'House',
      value: 'house',
      align: 'center'
   },
   {
      text: 'Points',
      value: 'points',
      align: 'center'
   },
   {
      text: 'Results',
      value: 'results',
      align: 'center'
   }
];

const MAX_INDEX_OF_WINNERS = 2;

interface Props {
   event: SchoolEvent;
   school: School;
}

interface TableCellData {
   index: number;
   firstName: string;
   lastName: string;
   school: string;
   house: string;
   points: string;
   results: string | JSX.Element;
   abbreviation?: JSX.Element;
}

type ColumnValueType = 'index' | 'firstName' | 'lastName' | 'school' | 'house' | 'points' | 'results';

interface TableColumn {
   text: string;
   value: string;
   align: string;
}

const TableContainer = styled(TableContainerMUI)(
   sx({
      maxWidth: '650px',
      border: '1px solid #ccc',
      borderRadius: '4px'
   })
);

const TableRow = styled(TableRowMUI)(
   sx({
      '&:last-child td, &:last-child th': { border: 0 },
      color: '#a0a0a0'
   })
);

const ResultsTableCellContent = styled(Box)(
   sx({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontWeight: 'bold'
   })
);

export default function IndividualResultsTable(props: Props) {
   const { event, school } = props;

   const isInterSchoolsEventCondition = isInterSchoolsEvent(event);
   const isHousesEventCondition = isHousesEvent(event);

   const eventStatus = propz.get(event, ['status'], '');
   const isEventFinished = eventStatus === EVENT_STATUS.FINISHED;

   const housesData = propz.get(event, ['housesData'], []);
   const individualsData = propz.get(event, ['individualsData'], []);
   const individualScore = propz.get(event, ['results', 'individualScore'], []);
   const individualScoreFirstItem = individualScore[0];

   const isPointsExist = individualScore.some(item => {
      const points = propz.get(item, ['richScore', 'points']);
      return typeof points !== 'undefined' && points !== 0;
  });

   const individualsDataSorted = getPlayersSorted(event, individualsData);
 
   const isAbbreviationResultExist = individualsData.some(({ userId, permissionId }) =>
   Boolean(getAbbreviationResultByStudent(event, userId, permissionId))
   );

   const columnsFiltered = RESULT_COLUMNS.filter((column) => {
      const { value } = column;

      switch (value) {
         case 'school':
            return isInterSchoolsEventCondition;

         case 'house':
            return isHousesEventCondition;

         case 'points':
            return isEventFinished && isPointsExist;

         case 'results':
            return isEventFinished;

         default:
            return true;
      }
   });

   if (isAbbreviationResultExist) {
      columnsFiltered.push({
        text: '', 
        value: 'abbreviation', 
        align: 'center' as const
      });
    }

    const getResultsTableColumns = () => {
      return (
         <TableRowMUI>
            {columnsFiltered.map((item: any) => {
               return (
                  <TableCell key={item.value} align={item.align} sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                     {item.text}
                  </TableCell>
               );
            })}
         </TableRowMUI>
      );
   };

   const getResultTableRows = () => {
      return individualsDataSorted.map((item, index) => {
         const { id, firstName, lastName, userId, permissionId } = item;

         const schoolName = propz.get(item, ['schoolName'], '');
         let houseName = '';

         if (isHousesEventCondition) {
            const houseId = propz.get(item, ['houseId'], '');
            const currentHouse = housesData.find((house: SchoolHouse) => house.id === houseId);
            const currentHouseName = propz.get(currentHouse, ['name'], '');

            houseName = currentHouseName;
         }

         const playerPoints = getIndividualScoreByStudent(event, userId, permissionId);
         const playerPointsStr = convertPointsToStringWithoutDelimeter(event, playerPoints);
         const extraPoints = getExtraPointsByStudent(event, userId);
         const extraPointsStr = String(extraPoints);
         const abbreviationResult = getAbbreviationResultByStudent(event, userId, permissionId); 
         const abbreviationColor = getAbbreviationColor(event, abbreviationResult); 

         const getResultsCellContent = () => {
            let medalIcon = null;
            const isRenderMedal = index <= MAX_INDEX_OF_WINNERS && playerPoints !== 0;

            switch (index) {
               case 0:
                  medalIcon = <MedalForFirstPlaceIcon />;
                  break;

               case 1:
                  medalIcon = <MedalForSecondPlaceIcon />;
                  break;

               case 2:
                  medalIcon = <MedalForThirdPlaceIcon />;
                  break;
            }

            return (
               <ResultsTableCellContent>
                  {playerPointsStr}
                  {isRenderMedal && medalIcon}
            </ResultsTableCellContent>
          );
        };

         const tableCellData: TableCellData = {
            index: index + 1,
            firstName,
            lastName,
            school: schoolName,
            house: houseName,
            points: extraPointsStr,
            results: getResultsCellContent(),
            abbreviation: abbreviationResult ? (
               <span style={{ color: abbreviationColor, fontWeight: 'bold' }}>
                 {abbreviationResult}
               </span>
             ) : undefined
         };

         return (
            <TableRow key={id}>
               {columnsFiltered.map((item) => {
                  const { value, align } = item;

                  return (
                     <TableCell key={value} sx={{ textAlign: align }}>
                        {tableCellData[value as ColumnValueType]}
                     </TableCell>
                  );
               })}
            </TableRow>
         );
      });
   };

   const renderResultsTable = () => {
      const columns = getResultsTableColumns();
      const rows = getResultTableRows();

      return (
         <TableContainer>
            <Table>
               <TableHead>{columns}</TableHead>
               <TableBody>{rows}</TableBody>
            </Table>
         </TableContainer>
      );
   };

   return (
      <Box>
         {!isEventFinished && (
            <Typography color='primary' textAlign='center' fontWeight='bold'>
               The results have not been added yet.
            </Typography>
         )}

         {renderResultsTable()}
      </Box>
   );
}