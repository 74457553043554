import * as propz from 'propz';
import { SchoolEvent, SchoolEventIndividualScore, SchoolEventSchool } from 'models/event'
import { isHousesEvent, isNonTeamSportEvent, isTeamOrTwoOnTwoSportEvent } from './event';
import { EVENT_TYPES } from 'consts/event';

// Copied from old frontend
export function getRivalsByEvent(activeSchoolId: string, event: SchoolEvent) {
   const eventType = event.eventType;
   const { inviterSchool, invitedSchools } = event;
   const allParticipants = [inviterSchool, ...invitedSchools];

   let rivals: any[] = [];

   if (isTeamOrTwoOnTwoSportEvent(event)) {
      if (EVENT_TYPES.EXTERNAL_SCHOOLS === eventType) {
         const schoolsData = allParticipants;
         const teamsData = event.teamsData;

         schoolsData.forEach(school => {
            const schoolTeams = teamsData.filter(teamData => teamData.schoolId === school.id);

            // school can have many teams
            if (schoolTeams.length > 0) {
               schoolTeams.forEach(schoolTeam => {
                  const rival: any = {};

                  rival.school = school;
                  rival.team = schoolTeam;

                  const teamScoreObject = event.results.teamScore.find(score => score.teamId === rival.team.id);

                  if (typeof teamScoreObject !== 'undefined') {
                     rival.team.badmintonResult = propz.get(teamScoreObject, ['richScore', 'badmintonResult'], []);
                     rival.team.isWinner = propz.get(teamScoreObject, ['isWinner'], false);
                  }

                  if (Array.isArray(rival.team.players)){
                     rival.team.players.forEach((player: any) => {
                        const scores = propz.get(event, ['results', 'individualScore'], []);
                        const playerScoreObject = scores
                           .find((score: SchoolEventIndividualScore) => score.userId === player.userId);

                        if (typeof playerScoreObject !== 'undefined') {
                           const playerIsRecord = propz.get(playerScoreObject, ['isRecord']);
                           const playerScore = propz.get(playerScoreObject, ['score']);
                           const playerExtraScore = propz.get(playerScoreObject, ['richScore', 'points']);
                           
                           player.isRecord = playerIsRecord;
                           player.score = playerScore;
                           player.extraScore = playerExtraScore;
                        } else {
                           player.isRecord = false;
                           player.score = 0;
                           player.extraScore = 0;
                        }
                     });
                  }

                  let schoolInvite = undefined;

                  if (typeof event.invites !== 'undefined') {
                     schoolInvite = event.invites.find(invite => invite.invitedSchoolId === school.id);
                  }

                  if (typeof schoolInvite !== 'undefined') {
                     rival.invite = schoolInvite;
                  }

                  rivals.push(rival);
               });
            } else {
               const rival: any = {};

               rival.school = school;
               let schoolInvite = undefined;

               if (typeof event.invites !== 'undefined') {
                  schoolInvite = event.invites.find(invite => invite.invitedSchoolId === school.id);
               }

               if (typeof schoolInvite !== 'undefined') {
                  rival.invite = schoolInvite;
               }
               //add all rival except school union, who create event
               if (
                  rival.school.kind !== 'SchoolUnion' ||
                  (rival.school.kind === 'SchoolUnion' && event.eventCreatorId !== rival.school.id)
               ) {
                  rivals.push(rival);
               }
            }
         });

         rivals = rivals.sort((rival1, rival2) => {
            if (rival1.school.id === activeSchoolId && rival2.school.id !== activeSchoolId) {
               return -1;
            }
            if (rival1.school.id !== activeSchoolId && rival2.school.id === activeSchoolId) {
               return 1;
            }

            return 0;
         });
      } else if (EVENT_TYPES.INTERNAL_HOUSES === eventType) {
         const schoolsData = allParticipants;
         const housesData = event.housesData;
         const teamsData = event.teamsData;

         // iterate all schools
         housesData.forEach(house => {
            const rival: any = {};

            rival.school = schoolsData[0];
            rival.house = house;

            // search all teams for current house
            teamsData.forEach(t => {
               if(t.houseId === house.id) {
                  rival.team = t;
               }
            });

            rivals.push(rival);
         });

         rivals = rivals.sort((rival1, rival2) => {
            if(rival1.house.name < rival2.house.name) {
               return -1;
            }
            if(rival1.house.name > rival2.house.name) {
               return 1;
            }

            return 0;
         });
      } else if (EVENT_TYPES.INTERNAL_TEAMS === eventType) {
         const	schoolsData	= allParticipants,
               teamsData	= event.teamsData;

         // iterate all schools
         teamsData.forEach(team => {
            const rival: any = {};

            rival.school = schoolsData[0];
            rival.team = team;

            rivals.push(rival);
         });

         rivals = rivals.sort((rival1, rival2) => {
            if(rival1.team.name < rival2.team.name) {
               return -1;
            }
            if(rival1.team.name > rival2.team.name) {
               return 1;
            }

            return 0;
         });
      }
   } else if (isNonTeamSportEvent(event)) {
      if (EVENT_TYPES.EXTERNAL_SCHOOLS === eventType) {
         const schoolsData = allParticipants;
         const players = event.individualsData;
         const scores = propz.get(event, ['results', 'individualScore']);
         // iterate all schools
         schoolsData.forEach((school: SchoolEventSchool) => {
            const rival: any = {};

            rival.school = school;
            let schoolInvite = undefined;
            if (typeof event.invites !== 'undefined') {
               schoolInvite = event.invites.find(invite => invite.invitedSchoolId === school.id);
            }

            if (typeof schoolInvite !== 'undefined') {
               rival.invite = schoolInvite;
            }

            // search all players for current school
            rival.players = [];
            players.forEach((player: any) => {
               const playerScoreObject = scores.find((score: SchoolEventIndividualScore) => score.userId === player.userId);

               if (typeof playerScoreObject !== 'undefined') {
                  const	playerScore = propz.get(playerScoreObject, ['score']);
                  const playerExtraScore = propz.get(playerScoreObject, ['richScore', 'points']);
                  const playerIsRecord = propz.get(playerScoreObject, ['isRecord']);
                  const playerIsWinner = propz.get(playerScoreObject, ['isWinner'], false);
                  const playerBadmintonResult = propz.get(playerScoreObject, ['richScore', 'badmintonResult'], []);

                  player.score = playerScore;
                  player.extraScore = playerExtraScore;
                  player.isRecord = playerIsRecord;
                  player.badmintonResult = playerBadmintonResult;
                  player.isWinner = playerIsWinner;
               } else {
                  player.score = 0;
                  player.extraScore = 0;
                  player.isRecord = false;
               }
               
               if (
                  player.schoolId === school.id ||
                  player.isRemovedFromSU ||
                  (typeof school.schoolIds !== 'undefined' && school.schoolIds.some(schoolId => schoolId === player.schoolId))
               ) {
                  rival.players.push(player);
               }
            });
            
            //add all rival except school union, who create event
            if (
               rival.school.kind !== 'SchoolUnion' ||
               (rival.school.kind === 'SchoolUnion' && event.eventCreatorId !== rival.school.id)
            ) {
               rivals.push(rival);
            }
         });
      } else if (EVENT_TYPES.INTERNAL_HOUSES === eventType) {
         const schoolsData = allParticipants;
         const housesData = event.housesData;
         const players = event.individualsData;
         const scores = propz.get(event, ['results', 'individualScore']);
         // iterate all schools
         housesData.forEach(house => {
            const rival: any = {};

            rival.school = schoolsData[0];
            rival.house = house;

            // search all players for current school
            rival.players = [];
            players.forEach((player: any) => {
               const playerScoreObject = scores.find((score: SchoolEventIndividualScore) => score.userId === player.userId);

               if (typeof playerScoreObject !== 'undefined') {
                  const playerScore = propz.get(playerScoreObject, ['score']);
                  const playerExtraScore = propz.get(playerScoreObject, ['richScore', 'points']);
                  const playerIsRecord = propz.get(playerScoreObject, ['isRecord']);

                  player.score = playerScore;
                  player.extraScore = playerExtraScore;
                  player.isRecord = playerIsRecord;
               } else {
                  player.score = 0;
                  player.extraScore = 0;
                  player.isRecord = false;
               }

               if (player.houseId === house.id) {
                  rival.players.push(player);
               }
            });

            rivals.push(rival);
         });
      } else if (EVENT_TYPES.INTERNAL_TEAMS === eventType) {
         const rival: any = {};
         const players = event.individualsData;
         const scores = propz.get(event, ['results', 'individualScore']);

         rival.school = {};
         rival.players = [];
         players.forEach((player: any) => {
            const playerScoreObject = scores.find((score: SchoolEventIndividualScore) => score.userId === player.userId);
            const eventPlayer = event.players.find(eventPlayer => eventPlayer.userId === player.userId);

            if (typeof playerScoreObject !== 'undefined') {
               const	playerScore = propz.get(playerScoreObject, ['score']);
               const playerExtraScore = propz.get(playerScoreObject, ['richScore', 'points']);
               const playerIsRecord = propz.get(playerScoreObject, ['isRecord']);

               player.score = playerScore;
               player.extraScore = playerExtraScore;
               player.isRecord = playerIsRecord;
            } else {
               player.score = 0;
               player.extraScore = 0;
               player.isRecord = false;
            }

            let school = allParticipants.find(school => school.id === player.schoolId);
            
            if (typeof school === 'undefined'){
               const playerData = event.players.find(playerData => playerData.userId === player.userId);
               if (typeof playerData !== 'undefined') {
                  school = allParticipants.find(school => school.id === playerData.schoolId);
               } else {
                  console.error(`Can't find player in event player data: ${JSON.stringify(player, null, 2)}`);
               }
            }
            
            player.school = school;
            player.tournamentNumber = propz.get(eventPlayer, ['tournamentNumber']);
            player.tournamentTeamCode = propz.get(eventPlayer, ['tournamentTeamCode']);

            rival.players.push(player);

         });

         rivals.push(rival);
      } else if (EVENT_TYPES.INTERNAL_HOUSES === eventType) {
         const rival: any = {};
         const schoolsData = allParticipants;
         const housesData = event.housesData;
         const players = event.individualsData;
         const scores = propz.get(event, ['results', 'individualScore']);

         rival.school = schoolsData[0];
         rival.players = [];
         players.forEach((player: any) => {
            const playerScoreObject = scores.find((score: SchoolEventIndividualScore) => score.userId === player.userId);

            if (typeof playerScoreObject !== 'undefined') {
               const playerScore = propz.get(playerScoreObject, ['score']);
               const playerExtraScore = propz.get(playerScoreObject, ['richScore', 'points']);

               player.score = playerScore;
               player.extraScore = playerExtraScore;
            } else {
               player.score = 0;
               player.extraScore = 0;
            }

            player.school = rival.school;
            player.house = housesData.find(house => house.id === player.houseId);

            rival.players.push(player);
         });

         rivals.push(rival);
      }
   }

   rivals.forEach(rival => {
      rival.id = getRandomString();
   });

   return rivals;
}

const addSchoolIdInIndividualScore = (event: SchoolEvent) => {
   const individualsData = event.players;
   const individualsScore = event.results.individualScore;

   return individualsScore
      .map(individualScoreData => {
         const individual = individualsData.find(individual => individual.userId === individualScoreData.userId);

         if (typeof individual !== 'undefined') {
            return {
               ...individualScoreData,
               schoolId: individual.schoolId
            }
         }

         return undefined;
      })
      .filter(individualScoreData => typeof individualScoreData !== 'undefined');
};

export const getRivalPoints = (event: SchoolEvent, rival: any, preferTeamPoints?: any) => {
   const { schoolScore, teamScore, houseScore} = event.results;

   const teamId = propz.get(rival, ['team', 'id']);
   const houseId = propz.get(rival, ['house', 'id']);
   const schoolId = propz.get(rival, ['school', 'id']);

   const isHousesEventCondition = isHousesEvent(event);

   const schoolScoreData: any = schoolScore.find(scoreData => scoreData.schoolId === schoolId);
   const teamScoreData: any = teamScore.find(scoreData => scoreData.teamId === teamId);
   const houseScoreData: any = houseScore.find(scoreData => scoreData.houseId === houseId);
   const individualsScoreAdditionalData = addSchoolIdInIndividualScore(event);

   let individualScoreDataFiltered: any, individualScoreData: any;

   if (isHousesEventCondition) {
      individualScoreDataFiltered =
         individualsScoreAdditionalData.filter((scoreData: any) => scoreData.teamId === teamId);
      individualScoreData =
         individualsScoreAdditionalData.find((scoreData: any) => scoreData.teamId === teamId);
   } else {
      individualScoreDataFiltered =
         individualsScoreAdditionalData.filter((scoreData: any) => scoreData.schoolId === schoolId);
      individualScoreData =
         individualsScoreAdditionalData.find((scoreData: any) => scoreData.schoolId === schoolId);
   }

   let points = 0;

   if (preferTeamPoints === true && typeof teamId !== 'undefined' && typeof teamScoreData !== 'undefined') {
      return teamScoreData.score;
   }

   switch (true) {
      case typeof teamId !== 'undefined' && typeof teamScoreData !== 'undefined':
         points = teamScoreData.score;
         break;

      case typeof individualScoreData !== 'undefined':
         if (individualScoreDataFiltered.length > 1) {
            individualScoreDataFiltered.forEach((scoreData: any) => {
               points += scoreData.score;
            });
         } else {
            points = individualScoreData.score;
         }
         break;

      case typeof houseId !== 'undefined' && typeof houseScoreData !== 'undefined':
         points = houseScoreData.score;
         break;

      case typeof schoolScoreData !== 'undefined':
         points = schoolScoreData.score;
         break;
   }

   return points;
};

const getRandomString = () => {
   return String(+ new Date()) + String(getRandomInt(10, 1000000));
};

const getRandomInt = (min: number, max: number) => {
   return Math.floor(Math.random() * (max - min)) + min;
}
