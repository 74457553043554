import React from 'react';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { Tournament } from 'models/tournament';
import { School } from 'models/school';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinkMUI from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/OpenInNew';
import DocumentIcon from '@mui/icons-material/Announcement';

interface Props {
   tournament: Tournament;
   school: School;
};

const Wrapper = styled(Box)(
   sx({
      p: 2
   })
);

const GoverningBodiesWrapper = styled(Box)(
   sx({
      display: 'flex',
      flexDirection: 'column',
      gap: 2
   })
);

const TitleWrapper = styled(Box)(
   sx({
      mb: 2
   })
);

const SubTitle = styled(Typography)(
   sx({
      fontSize: '10px',
      fontWeight: 'bold'
   })
);

const Link = styled(LinkMUI)(
   sx({
      position: 'relative',
      display: 'flex',
      gap: '16px',
      p: 1,
      pr: 3,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      overflow: 'hidden',
      borderRadius: '4px',
      background: '#fff',
      cursor: 'pointer',
      transition: '0.2s ease-in-out',
      
      '&:hover': {
         borderColor: '#1976d2',
      },

      '& .governing-body__link-icon': {
         position: 'absolute',
         top: '5px',
         right: '5px'
      }
   })
);

const GoverningBodiesImg = styled('img')(
   sx({
      width: '100px',
      height: '50px',
      objectFit: 'contain',
      borderRadius: '3px'
   })
);

const GoverningBodiesTitle = styled(Typography)(
   sx({
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
   })
);

export default function GoverningBodies(props: Props) {
   const { tournament} = props;

   const nationalGoverningBodies = propz.get(tournament, ['nationalGoverningBodies'], []);

   const renderNationalGoverningBodies = () => {
      return nationalGoverningBodies.map((item) => {
         const { link, picUrl, _id, name } = item;

         const isPicUrlExist = typeof picUrl !== 'undefined' && picUrl !== '';

         return (
            <Link
               href={link}
               key={_id}
               target='_blank'
               underline='none'
               color='black'
               title={name}
            >
               {isPicUrlExist
                  ? <GoverningBodiesImg src={picUrl} alt="Governing bodies" />
                  : <DocumentIcon color='primary' sx={{fontSize: '36px'}} />
               }

               <LinkIcon color='action' fontSize='small' className='governing-body__link-icon' />

               <GoverningBodiesTitle>{name}</GoverningBodiesTitle>
            </Link>
         );
      });
   };

   return (
      <Wrapper>
         <TitleWrapper>
            <Typography color='primary'>NATIONAL GOVERNING BODIES</Typography>
            
            <SubTitle>
               * Click on a logo to be directed to the corresponding organisation's website.
            </SubTitle>
         </TitleWrapper>

         <GoverningBodiesWrapper>
            {renderNationalGoverningBodies()}
         </GoverningBodiesWrapper>
      </Wrapper>
   )
}
