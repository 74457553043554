import axios from 'axios';
import { SORT, DEFAULT_SKIP, LIMIT_ALL } from 'consts/table';

export function getAllSchoolUnionFiles(schoolId: string, where: any) {
   const config = {
      params: {
         filter: {
            where: {
               ...where
            },
            skip: DEFAULT_SKIP,
            limit: LIMIT_ALL,
            order: `startTime ${SORT.DESC}`
         }
      }
   };

   return axios.get(`${window.apiBase}/public/schools/${schoolId}/files`, config)
      .then(response => {
         return response.data;
      });
};
