import React, { useState } from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import { COOKIES_POLICY_LINK } from 'consts/api';
import { StorageHelper } from 'helpers/storage';
import SnackbarMUI from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import CookieIcon from '@mui/icons-material/Cookie';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinkMUI from '@mui/material/Link';

const Snackbar = styled(SnackbarMUI)(
   sx({
      mr: 3,
      '& .MuiSnackbarContent-root': {
         display: 'flex',
         flexDirection: 'column',
         gap: 2
      }
   })
);

const CookiePolicyMessageWrapper = styled(Box)(
   sx({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 2
   })
);

const CookiePolicyMessageTitle = styled(Typography)(
   sx({
      mb: 2
   })
);

const Link = styled(LinkMUI)(
   sx({
      cursor: 'pointer'
   })
);

export default function CookieConsent() {
   const [isOpen, setIsOpen] = useState(true);

   const onCookiePolicyClick = () => {
      window.open(COOKIES_POLICY_LINK, '_blank');
   };

   const onOkClick = () => {
      StorageHelper.LocalStorage.set('isCookiesAccepted', true);

      setIsOpen(false);
   };

   const message = (
      <CookiePolicyMessageWrapper>
         <CookieIcon color='info' fontSize='large' />

         <Box>
            <CookiePolicyMessageTitle variant='h6'>
               We use cookies to provide you the best experience on our Website.
            </CookiePolicyMessageTitle>
            <Typography variant='body2'>
               By continuing to browse our Website, you are agreeing to our use of cookies.
               Please read our <Link onClick={onCookiePolicyClick}>Cookie policy</Link> to find out more.
            </Typography>
         </Box>
      </CookiePolicyMessageWrapper>
   );

   return (
      <Snackbar
         open={isOpen}
         message={message}
         action={
            <Button
               color='primary'
               size='medium'
               variant='outlined'
               onClick={onOkClick}
            >
               OK
            </Button>
         }
      />
   );
};
