import React from 'react';
import { SchoolEvent } from 'models/event';
import { School } from 'models/school';
import { isIndividualSportEvent, isTeamSportEvent } from 'helpers/event';
import Box from '@mui/material/Box';
import IndividualResultsTable from './IndividualResultsTable';
import TeamResultsTable from './TeamResultsTable';

interface Props {
   event: SchoolEvent;
   school: School;
}

export default function EventResults(props: Props) {
   const { event, school } = props;

   const isIndividualEvent = isIndividualSportEvent(event);
   const isTeamEvent = isTeamSportEvent(event);

   const renderResults = () => {
      switch (true) {
         case isIndividualEvent:
            return <IndividualResultsTable event={event} school={school} />;

         case isTeamEvent:
            return <TeamResultsTable event={event} school={school} />;
      
         default:
            return null;
      }
   }

   return (
      <Box>
         {renderResults()}
      </Box>
   );
}
