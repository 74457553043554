import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, experimental_sx as sx } from '@mui/system';
import { SchoolEvent } from 'models/event';
import { Participant as ParticipantModel } from 'models/event';
import { SCREEN_SIZE } from 'consts/responsive';
import {
   isEventStatusFinished,
   isMultipartyEvent
} from 'helpers/event';
import { isCricket, getCricketResults } from 'helpers/sport/cricket';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import Participant from './Participant';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonMUI from '@mui/material/Button';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface Props {
   event: SchoolEvent;
   participants: ParticipantModel[];
   activeSchoolId: string;
};

const SwiperNavigation = styled(Box)(
   sx({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: 2
   })
);

const SwiperNavigationButtons = styled(Box)(
   sx({
      border: '1px solid #ccc',
      borderRadius: '5px',
   })
);

const Button = styled(ButtonMUI)(
   sx({
      borderRadius: 0,

      '&.swiper-button-disabled': {
         '& svg': {
            fill: '#ccc'
         }
      }
   })
);

export default function EventParticipants(props: Props) {
   const { event, participants, activeSchoolId } = props;

   const isTabletScreenSize = useMediaQuery(SCREEN_SIZE.TABLET);

   const isCricketResultShow = isEventStatusFinished(event)
      && isCricket(event)
      && !isMultipartyEvent(event);

   const renderParticipants = () => {
      return participants.map((participant: ParticipantModel, index: number) => {
         return (
            <SwiperSlide key={index}>
               <Participant
                  participant={participant}
                  event={event}
               />
            </SwiperSlide>
         );
      });
   };

   return (
      <>
         {isCricketResultShow &&
            <Typography color='primary' sx={{ fontWeight: 'bold' }}>
               {getCricketResults(event, activeSchoolId)}
            </Typography>
         }

         <SwiperNavigation>
            <SwiperNavigationButtons>
               <Button className='swiper-prev-button'>
                  <ArrowLeftIcon />
               </Button>

               <Button className='swiper-next-button'>
                  <ArrowRightIcon />
               </Button>
            </SwiperNavigationButtons>

            <Typography className='swiper-pages' sx={{ width: 'auto' }}></Typography>
         </SwiperNavigation>

         <Swiper
            slidesPerView={isTabletScreenSize ? 1 : 2}
            modules={[Navigation, Pagination]}
            spaceBetween={20}
            pagination={{
               type: 'fraction',
               el: '.swiper-pages'
            }}
            navigation={{
               enabled: true,
               prevEl: '.swiper-prev-button',
               nextEl: '.swiper-next-button'
            }}
         >
            {renderParticipants()}
         </Swiper>
      </>
   )
}
