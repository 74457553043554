import React, { useState, useEffect } from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import * as propz from 'propz';
import Moment from 'moment';
import { SchoolEvent } from 'models/event';
import { School } from 'models/school';
import { GENDER_TYPE } from 'types/common';
import { SPORT_GENDER_TYPE } from 'types/sport';
import { CLUB_GENDER_SERVER_TO_CLIENT_MAPPING, CLUB_STAFF } from 'consts/club';
import { DEFAULT_EVENT_DETAIL_ROWS, EVENT_DETAIL_ROWS, CLUB_EVENT_DETAIL_ROWS } from 'consts/eventView';
import { SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'consts/sport';
import { getSchoolEventDetails } from 'services/school';
import { isClubEvent, getEventGeneratedName, getAgesGroupFormatted } from 'helpers/event';
import { getVenueView } from 'helpers/venue';
import { getSchoolEventLocation } from 'helpers/eventView';
import Loader from 'components/loader';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCellMUI from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import IconButton from '@mui/material/IconButton';
import Map from 'components/Map';

interface Props {
   activeSchoolId: string;
   event: SchoolEvent;
   school: School;
}

const TableCellTitle = styled(TableCellMUI)(
   sx({
      fontWeight: 'bold',
      whiteSpace: 'nowrap'
   })
);

const TableCell = styled(TableCellMUI)(
   sx({
      'ol, ul': {
         pl: 2
      }
   })
);

export default function EventDetails(props: Props) {
   const [isLoading, setIsLoading] = useState(false);
   const [isMapOpen, setIsMapOpen] = useState(false);
   const [eventDetails, setEventsDetails] = useState({});

   const { event, activeSchoolId, school } = props;
   
   const schoolPostcodeId = propz.get(school, ['postcodeId']);

   const teamDeparts = propz.get(eventDetails, ['teamDeparts']);
   const teamReturns = propz.get(eventDetails, ['teamReturns']);
   const meetTime = propz.get(eventDetails, ['meetTime']);
   const teaTime = propz.get(eventDetails, ['teaTime']);
   const lunchTime = propz.get(eventDetails, ['lunchTime']);
   const postcode = propz.get(event, ['venue', 'postcodeData', 'postcode'], '');
   const point = propz.get(event, ['venue', 'point']);
   const gender: GENDER_TYPE = propz.get(event, ['gender']);
   const eventGender = CLUB_GENDER_SERVER_TO_CLIENT_MAPPING[gender] ||
      SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[gender as SPORT_GENDER_TYPE];
   const isShowMap = propz.get(event, ['venue', 'venueType']) !== 'TBD';

   const isTeamDepartsExist = typeof teamDeparts !== 'undefined' && teamDeparts !== '';
   const isTeamReturnsExist = typeof teamReturns !== 'undefined' && teamReturns !== '';
   const isMeetTimeExist = typeof meetTime !== 'undefined' && meetTime !== '';
   const isTeaTimeExist = typeof teaTime !== 'undefined' && teaTime !== '';
   const isLunchTimeExist = typeof lunchTime !== 'undefined' && lunchTime !== '';

   useEffect(() => {
      setIsLoading(true);

      getSchoolEventDetails(activeSchoolId, event.id).then((details: any) => {
         setEventsDetails(details);
         setIsLoading(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const getVenue = () => {
      const venue = getVenueView(event, activeSchoolId);
      const isPostcodeExist = postcode !== '';

      return (
         <Box>
            <Box>
               {isPostcodeExist
                  ? `${venue}, ${postcode}`
                  : `${venue}`
               }

               {isShowMap &&
                  <IconButton
                     onClick={() => setIsMapOpen(!isMapOpen)}
                     sx={{ml: 1}}
                     color="primary"
                  >
                     <LocationOnIcon />
                  </IconButton>
               }
            </Box>
            
            {isMapOpen &&
               <Box>
                  <Map point={point} />
               </Box>
            }
         </Box>
      );
   };

   const rows = isClubEvent(event) ? [...CLUB_EVENT_DETAIL_ROWS] : [...EVENT_DETAIL_ROWS];
   const rowsFiltered = rows.filter(row => {
      const field = propz.get(eventDetails, [row.field]);
      const isFieldExist = typeof field !== 'undefined' && field !== '';
      
      return isFieldExist;
   })

   const rowsToRender = [...DEFAULT_EVENT_DETAIL_ROWS, ...rowsFiltered].map((row: any, index: number) => {
      let contentCell: any;
      const field = row.field;
      const defaultDate = new Date();
      defaultDate.setHours(0);
      defaultDate.setMinutes(0);
      const location = propz.get(event, ['location']);
      const isLocationExist = typeof location !== 'undefined' && location !== '';

      switch (field) {
         case 'name':
            contentCell = getEventGeneratedName(event, activeSchoolId);

            break;

         case 'activity':
            contentCell = propz.get(event, ['sport', 'name'], '');

            break;

         case 'ages':
            contentCell = getAgesGroupFormatted(event, school);

            break;

         case 'gender':
            contentCell = eventGender;

            break;

         case 'sportDescription':
            contentCell = propz.get(event, ['sport', 'description'], '');

            break;

         case 'venue':
            contentCell = getVenue();

            break;

         case 'location':
            contentCell =
               isLocationExist
                  ? `${getSchoolEventLocation(event, activeSchoolId, schoolPostcodeId)}, ${location}`
                  : getSchoolEventLocation(event, activeSchoolId, schoolPostcodeId);

            break;

         case 'description':
            const description = propz.get(eventDetails, ['description'], '');
            contentCell = <div dangerouslySetInnerHTML={{ __html: description }} />;
            
            break;

         case 'shortDescription':
            contentCell = propz.get(eventDetails, ['shortDescription'], '');
            
            break;

         case 'kitNotes':
            const kitNotes = propz.get(eventDetails, ['kitNotes'], '');
            contentCell = <div dangerouslySetInnerHTML={{ __html: kitNotes }} />;

            break;

         case 'comments':
            const comments = propz.get(eventDetails, ['comments'], '');
            contentCell = <div dangerouslySetInnerHTML={{ __html: comments }} />;
            
            break;

         case 'membersOfStaff':
            const staffs = propz
               .get(eventDetails, ['staff'], [])
               .filter(staff => {
                  const staffRole = propz.get(staff, ['staffRole'], '');

                  return staffRole === CLUB_STAFF.MEMBER_OF_STAFF
               });

            contentCell = staffs
               .map(staff => {
                  const staffFirstName = propz.get(staff, ['firstName'], '');
                  const staffLastName = propz.get(staff, ['lastName'], '');

                  return `${staffFirstName} ${staffLastName}`;
               })
               .join('\n');

            break;

         case 'coaches':
            const coaches = propz.get(eventDetails, ['staff'], [])
               .filter(coach => {
                  const staff = propz.get(coach, ['staffRole'], '');

                  return staff === CLUB_STAFF.COACH
               });

            contentCell = coaches
               .map(coach => {
                  const coachFirstName = propz.get(coach, ['firstName'], '');
                  const coachLastName = propz.get(coach, ['lastName'], '');

                  return `${coachFirstName} ${coachLastName}`;
               })
               .join('\n');

            break;

         case 'teamDeparts':
            const departsDate = new Date(propz.get(eventDetails, ['teamDeparts'], defaultDate));
            const departsTime = Moment(departsDate, 'hh:mm A').format('hh:mm A');
            contentCell = isTeamDepartsExist ? departsTime : '--:--';

            break;

         case 'teamReturns':
            const returnsDate = new Date(propz.get(eventDetails, ['teamReturns'], defaultDate));
            const returnsTime = Moment(returnsDate, 'hh:mm A').format('hh:mm A');
            contentCell = isTeamReturnsExist ? returnsTime : '--:--';

            break;

         case 'meetTime':
            const meetDate = new Date(propz.get(eventDetails, ['meetTime'], defaultDate));
            const meetTime = Moment(meetDate, 'hh:mm A').format('hh:mm A');
            contentCell = isMeetTimeExist ? meetTime : '--:--';

            break;

         case 'teaTime':
            const teaDate = new Date(propz.get(eventDetails, ['teaTime'], defaultDate));
            const teaTime = Moment(teaDate, 'hh:mm A').format('hh:mm A');
            contentCell = isTeaTimeExist ? teaTime : '--:--';

            break;

         case 'lunchTime':
            const lunchDate = new Date(propz.get(eventDetails, ['lunchTime'], defaultDate));
            const lunchTime = Moment(lunchDate, 'hh:mm A').format('hh:mm A');
            contentCell = isLunchTimeExist ? lunchTime : '--:--';

            break;

         default:
            contentCell = '';
      }

      return (
         <TableRow key={`event_details_summary_${index}`}>
            <TableCellTitle>{row.title}</TableCellTitle>
            <TableCell>{contentCell}</TableCell>
         </TableRow>
      );
   });

   if (isLoading) {
      return (
         <Loader />
      );
   }

   return (
      <TableContainer>
         <Table>
            <TableBody>
               {rowsToRender}
            </TableBody>
         </Table>
      </TableContainer>
   )
}
