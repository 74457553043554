import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';

interface Props {
  onClick: () => void;
}

export default function ProfileIcon(props: Props) {
   const { onClick } = props;

   return (
      <Box>
         <IconButton
            size='large'
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={onClick}
            color='inherit'
         >
            <AccountCircle />
         </IconButton>
      </Box>
   );
};
