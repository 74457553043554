import React, { useState, useEffect } from 'react';
import * as propz from 'propz';
import {
   DRAWER_WIDTH,
   SCHOOL_LEAGUE_NAVIGATION,
   SCHOOL_UNION_NAVIGATION,
   SCHOOL_TOURNAMENT_NAVIGATION,
   SCHOOL_NAVIGATION,
   SCHOOL_CHALLENGE_NAVIGATION,
   SCHOOL_FOOTBALL_TOURNAMENT_NAVIGATION
} from 'consts/sidebar';
import { KIND_SERVER_TO_CLIENT_MAPPING } from 'consts/school';
import { NavLink } from 'react-router-dom';
import { styled, Theme, CSSObject, experimental_sx as sx } from '@mui/material/styles';
import { School } from 'models/school';
import { Navigation } from 'consts/sidebar';
import { SCREEN_SIZE } from 'consts/responsive';
import { LAYOUT_TYPE } from 'consts/layout';
import { getAllSchoolsIncludedInUnion } from 'services/schoolUnion/schools';
import { getPublicSiteLink } from 'helpers/link';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import MuiListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import SchoolIcon from '@mui/icons-material/School';
import LoginIcon from 'components/LoginIcon';
import BurgerButton from 'components/BurgerButton';
import UrlLink from '@mui/material/Link';
import LaunchIcon from '@mui/icons-material/Launch';
import AreaLinksButton from './AreaLinksButton';

interface DrawerProps {
   school: School;
   titleText?: string;
   type?: string;
}

interface AppBarProps extends MuiAppBarProps {
   isOpen?: boolean;
   isTabletOrPhoneScreenSize?: boolean;
}

const openedMixin = (theme: Theme): CSSObject => ({
   width: DRAWER_WIDTH,
   transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
   }),
   overflowX: 'hidden',
   zIndex: '10000'
});

const closedMixin = (theme: Theme): CSSObject => ({
   transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
   }),
   overflowX: 'hidden',
   width: `calc(${theme.spacing(7)} + 1px)`,
   [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(7)} + 1px)`,
   },
});

const DrawerHeader = styled(Box)(({ theme }) => ({
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   padding: theme.spacing(0, 1),
   'img': {
      width: '45px',
      height: '45px',
      borderRadius: '4px',
      objectFit: 'contain'
   },
   ...theme.mixins.toolbar,
}));

const Toolbar = styled(MuiToolbar)(() => sx({
   display: 'flex',
   justifyContent: 'space-between'
}));

const ListItemButton = styled(MuiListItemButton)(() => sx({
   my: '5px',
   maxHeight: '48px',
   lineHeight: '6px'
}));

const ListItemText = styled(MuiListItemText)(() => sx({
   whiteSpace: 'normal'
}));

const SchoolLogoIcon = styled(SchoolIcon)(() => sx({
   height: '40px',
   fontSize: '40px'
}));

const Header = styled(MuiAppBar, {
   shouldForwardProp: (prop) => prop !== 'isOpen' && prop !== 'isTabletOrPhoneScreenSize'
})<AppBarProps>(
   ({ theme, isOpen, isTabletOrPhoneScreenSize }) => ({
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.leavingScreen,
      }),
      width: isTabletOrPhoneScreenSize ? '100%': 'calc(100% - 58px)',
      ...(isOpen && {
         marginLeft: DRAWER_WIDTH,
         width: `calc(100% - ${DRAWER_WIDTH}px)`,
         transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
         })
      })
   }),
);

const DesktopDrawer = styled(MuiDrawer, {
   shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
   width: DRAWER_WIDTH,
   flexShrink: 0,
   whiteSpace: 'nowrap',
   boxSizing: 'border-box',
   ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
   }),
   ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
   }),
   '& .MuiListItemIcon-root': {
      minWidth: '46px'
   }
}),
);

const MobileDrawer = styled(MuiDrawer)({
   zIndex: 1210,
   '& .MuiListItemIcon-root': {
      minWidth: '46px'
   }
});

const Link = styled(NavLink)(
   ({ theme }) => ({
      textDecoration: 'none',
      color: '#000',
      '&.active .MuiListItemIcon-root': {
         background: '#F5F5F5',
         '& .MuiSvgIcon-root': {
            fill: theme.palette.primary.main
         },
         '& svg': {
            '& path': {
               fill: theme.palette.primary.main
            }
         }
      }
   })
);

const ToolbarContentWrapper = styled(Box)({
   width: '90%',
   display: 'flex',
   alignItems: 'center'
});

const SchoolTitle = styled(Typography)({
   textTransform: 'uppercase',
   fontWeight: 'bold',
   letterSpacing: '4px'
});

const ChallengeTitle = styled(Typography)({
   textTransform: 'uppercase',
   fontWeight: 'bold',
   letterSpacing: '4px',
   overflow: 'hidden',
   whiteSpace: 'nowrap',
   textOverflow: 'ellipsis'
});

const LeagueTitleWrapper = styled(Box)({
   display: 'flex',
   flexDirection: 'column'
});

const LeagueTitle = styled(Typography)({
   display: 'flex',
   alignItems: 'center',
   'a': {
      display: 'flex',
      alignItems: 'center',
      color: '#fff',
      fontSize: '14px',
      fontStyle: 'italic',

      '&:hover': {
         textDecoration: 'none',
         boxShadow: '0px 1px 0px 0px rgb(255,255,255)'
      },

      'svg': {
         fontSize: '16px'
      }
   },
   'span': {
      fontSize: '14px',
      fontStyle: 'italic'
   },
   fontSize: '14px',
   fontStyle: 'italic'
});

export default function Drawer(props: DrawerProps) {
   const { school, type, titleText } = props;
   const { name: schoolName, pic: schoolLogoUrl } = school;
   const isSchoolLogoExist = typeof schoolLogoUrl !== 'undefined';
   
   const [isOpen, setIsOpen] = useState<boolean>(false);
   const [schoolsIncludedInUnion, setSchoolsIncludedInUnion] = useState<School[]>([]);

   const schoolUnions = schoolsIncludedInUnion.filter(item => item.kind === KIND_SERVER_TO_CLIENT_MAPPING.SCHOOL_UNION)

   const isTabletOrPhoneScreenSize = useMediaQuery(SCREEN_SIZE.TABLET);
   
   const isSchoolType = type === LAYOUT_TYPE.SCHOOL;
   const isSchoolLeagueType = type === LAYOUT_TYPE.SCHOOL_LEAGUE;
   const isSchoolUnionType = type === LAYOUT_TYPE.SCHOOL_UNION;
   const isSchoolChallengeType = type === LAYOUT_TYPE.SCHOOL_CHALLENGE;
   const isSchoolTournamentType = type === LAYOUT_TYPE.TOURNAMENT;
   const isSchoolFootballTournamentType = type === LAYOUT_TYPE.FOOTBALL_TOURNAMENT;
   
   const domain = propz.get(school, ['domain'], '');
   const publicSiteLink = getPublicSiteLink(domain);

   let navigation: Navigation[];

   switch (true) {
      case type === LAYOUT_TYPE.SCHOOL:
         navigation = SCHOOL_NAVIGATION;

         break;

      case type === LAYOUT_TYPE.SCHOOL_UNION:
         navigation = SCHOOL_UNION_NAVIGATION;
         
         break;
   
      case type === LAYOUT_TYPE.SCHOOL_LEAGUE:
         navigation = SCHOOL_LEAGUE_NAVIGATION;

         break;

      case type === LAYOUT_TYPE.TOURNAMENT:
         navigation = SCHOOL_TOURNAMENT_NAVIGATION;

         break;

      case type === LAYOUT_TYPE.FOOTBALL_TOURNAMENT:
         navigation = SCHOOL_FOOTBALL_TOURNAMENT_NAVIGATION;

         break;

      case type === LAYOUT_TYPE.SCHOOL_CHALLENGE:
         navigation = SCHOOL_CHALLENGE_NAVIGATION;
         
         break;
   }

   useEffect(() => {
      if (isSchoolUnionType) {
         getAllSchoolsIncludedInUnion(school.id)
            .then((schools) => {
               setSchoolsIncludedInUnion(schools);
            });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const toggleDrawer = () => {
      setIsOpen(!isOpen);
   };

   const renderDrawerHeader = () => {
      const schoolLogo = isSchoolLogoExist
         ? <img src={schoolLogoUrl} alt="Logo" />
         : <SchoolLogoIcon color='primary' />;

      return isSchoolTournamentType
         ? (
            <UrlLink href={publicSiteLink} target="_blank" sx={{ml: '4px'}}>
               <DrawerHeader>
                  {schoolLogo}
               </DrawerHeader>
            </UrlLink>
         )
         : (
            <DrawerHeader>
               {schoolLogo}
            </DrawerHeader>
         );
   };

   const onLoginClick = () => {
      //this is a redirect for login to the admin panel on the second front
      const subDomains = window.apiBase.split('.');
      subDomains[0] = 'console';
      const domain = subDomains.join('.');

      window.open(
         `//${domain}/login`,
         '_blank'
      );
   };

   const renderNavigation = () => {
      return (
         <List>
            {navigation.map((item) => {
               const { text, icon, to } = item;

               return (
                  <Link to={to} key={text} onClick={() => isTabletOrPhoneScreenSize && toggleDrawer()}>
                     <ListItemButton>
                        <ListItemIcon>
                           {icon}
                        </ListItemIcon>

                        <ListItemText primary={text} />
                     </ListItemButton>
                  </Link>
               );
            })}
         </List>
      );
   };

   const renderHeaderContent = () => {
      const titleVariant = isTabletOrPhoneScreenSize ? 'body1' : 'h6';

      switch (true) {
         case isSchoolType:
            return <SchoolTitle variant={titleVariant}>{schoolName}</SchoolTitle>;

         case isSchoolUnionType:
            return <AreaLinksButton school={school} schoolUnions={schoolUnions} />;

         case isSchoolLeagueType:
            return (
               <LeagueTitleWrapper>
                  <LeagueTitle>
                     <Typography component='span'>School:</Typography>

                     <UrlLink href={publicSiteLink} target="_blank" sx={{ml: '4px'}}>
                        {schoolName}
                        <LaunchIcon fontSize='small' sx={{ml: 1}} />
                     </UrlLink>
                  </LeagueTitle>

                  <LeagueTitle>League: {titleText}</LeagueTitle>
               </LeagueTitleWrapper>
            );

         case isSchoolChallengeType:
            return <ChallengeTitle variant={titleVariant}>{titleText}</ChallengeTitle>;

         case isSchoolTournamentType || isSchoolFootballTournamentType:
            return <ChallengeTitle title={titleText} variant={titleVariant}>{titleText}</ChallengeTitle>;
      }
   };

   const renderHeader = () => {
      const isAppBarOpen = isTabletOrPhoneScreenSize ? false : isOpen;

      return (
         <Header
            position='fixed'
            isOpen={isAppBarOpen}
            isTabletOrPhoneScreenSize={isTabletOrPhoneScreenSize}
         >
            <Toolbar>
               <ToolbarContentWrapper>
                  <BurgerButton
                     isTabletScreenSize={isTabletOrPhoneScreenSize}
                     toggleDrawer={toggleDrawer}
                  />

                  {renderHeaderContent()}
               </ToolbarContentWrapper>

               <LoginIcon onClick={onLoginClick} />
            </Toolbar>
         </Header>
      );
   };

   const renderSidebar = () => {
      const sidebar = isTabletOrPhoneScreenSize
         ? <MobileDrawer
               anchor='bottom'
               variant='temporary'
               open={isOpen}
               onClose={toggleDrawer}
            >
               {renderNavigation()}
            </MobileDrawer>
         : <DesktopDrawer variant='permanent' open={isOpen} >
               {renderDrawerHeader()}
               {renderNavigation()}
            </DesktopDrawer>

      return sidebar;
   };

   return (
      <>
         {renderHeader()}

         {renderSidebar()}
      </>
   );
}
