import * as propz from 'propz';
import Moment from 'moment';
import { EVENT_TYPES, EVENT_STATUS } from 'consts/event';
import { SchoolEvent } from 'models/event';
import { LIMIT_ALL } from 'consts/table';
import { EVENT_SIGN } from 'consts/calendar';
import {
   isClubEvent,
   isTournamentEvent,
   isResultsModePlaces,
   isResultsModePoints,
   isHousesEvent,
   isInternalEvent,
   isEventStatusFinished,
   isEventStatusCanceled,
   isEventStatusRejected,
   isMultipartyEvent,
   isResultsModeResults,
   isNonTeamSportEvent,
   isInterSchoolsEvent,
   isIndividualSportEvent,
   isTeamSportEvent,
} from 'helpers/event';
import { isCricket, getCricketResults } from 'helpers/sport/cricket';
import { EVENT_RESULTS_VIEW } from 'consts/calendar';
import { sortByNameAsc } from './sorting';
import { EVENT_STATUS_SERVER_TO_CLIENT_MAPPING } from 'consts/event';
import { CALENDAR_FILTER } from 'consts/calendar';
import { CALENDAR_FILTER_TYPE } from 'types/calendar';
import {
   SchoolEventSchoolScore,
   SchoolEventHouseScore,
   SchoolEventTeamScore,
   SchoolEventTeamData
} from 'models/event';

export function checkIsEventsExistInDate(date: Date, eventDates: Date[]): boolean {
   const dateStr = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

   return eventDates.some(eventDate => {
      const eventDateStr = `${eventDate.getFullYear()}-${eventDate.getMonth()}-${eventDate.getDate()}`;

      return dateStr === eventDateStr;
   });
}

export function getEventsForDayFilter(date: Date, activeSchoolId: string, where: any) {
   const dayStart = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
   const dayEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0);

   return {
      limit: LIMIT_ALL,
      where: {
         startTime: {
            $gte: dayStart,
            $lt: dayEnd
         },
         $or: [
            {
               // internal events are always shown no matter what
               eventType: { $in: [EVENT_TYPES.INTERNAL_HOUSES, EVENT_TYPES.INTERNAL_TEAMS] }
            },
            {
               // external events created by me always visible with any status
               eventType: { $in: [EVENT_TYPES.EXTERNAL_SCHOOLS] },
               inviterSchoolId: activeSchoolId
            },
            {
               // external events where I'm invited shown only in some special statuses
               eventType: { $in: [EVENT_TYPES.EXTERNAL_SCHOOLS] },
               inviterSchoolId: { $ne: activeSchoolId },
               invitedSchoolIds: activeSchoolId,
               status: {
                  $in: [
                     EVENT_STATUS.INVITES_SENT,
                     EVENT_STATUS.COLLECTING_INVITE_RESPONSE,
                     EVENT_STATUS.ACCEPTED,
                     EVENT_STATUS.REJECTED,
                     EVENT_STATUS.FINISHED,
                     EVENT_STATUS.CANCELED,
                     EVENT_STATUS.COLLECTING_INVITE_RESPONSE
                  ]
               }
            }
         ],
         ...where
      }
   };
}

export function getEventsForWeekFilter(date: Date, activeSchoolId: string, where: any) {
   const currentWeek = getCurrentWeek(date);

   const weekStart = currentWeek[0];
   const weekEnd = currentWeek[currentWeek.length - 1];

   return {
      limit: LIMIT_ALL,
      where: {
         startTime: {
            $gte: weekStart,
            $lt: weekEnd
         },
         $or: [
            {
               // internal events are always shown no matter what
               eventType: { $in: [EVENT_TYPES.INTERNAL_HOUSES, EVENT_TYPES.INTERNAL_TEAMS] }
            },
            {
               // external events created by my school always visible with any status
               eventType: { $in: [EVENT_TYPES.EXTERNAL_SCHOOLS] },
               inviterSchoolId: activeSchoolId
            },
            {
               // external events where I'm invited shown only in some special statuses
               eventType: { $in: [EVENT_TYPES.EXTERNAL_SCHOOLS] },
               inviterSchoolId: { $ne: activeSchoolId },
               invitedSchoolIds: activeSchoolId,
               status: {
                  $in: [
                     EVENT_STATUS.INVITES_SENT,
                     EVENT_STATUS.COLLECTING_INVITE_RESPONSE,
                     EVENT_STATUS.ACCEPTED,
                     EVENT_STATUS.REJECTED,
                     EVENT_STATUS.FINISHED,
                     EVENT_STATUS.CANCELED,
                     EVENT_STATUS.COLLECTING_INVITE_RESPONSE
                  ]
               }
            }
         ],
         ...where
      }
   };
}

export function getEventDatesForMonthFilter(date: Date, activeSchoolId: string, where: any) {
   const monthStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
   const monthEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);

   const filter = {
      limit: LIMIT_ALL,
      where: {
         startTime: {
            $gte: monthStartDate,
            $lt: monthEndDate
         },
         $or: [
            {
               // internal events are always shown no matter what
               eventType: { $in: [EVENT_TYPES.INTERNAL_HOUSES, EVENT_TYPES.INTERNAL_TEAMS] }
            },
            {
               // external events created by me always visible with any status
               eventType: { $in: [EVENT_TYPES.EXTERNAL_SCHOOLS] },
               inviterSchoolId: activeSchoolId
            },
            {
               // external events where I'm invited shown only in some special statuses
               eventType: { $in: [EVENT_TYPES.EXTERNAL_SCHOOLS] },
               inviterSchoolId: { $ne: activeSchoolId },
               invitedSchoolIds: activeSchoolId,
               status: {
                  $in: [
                     EVENT_STATUS.INVITES_SENT,
                     EVENT_STATUS.COLLECTING_INVITE_RESPONSE,
                     EVENT_STATUS.ACCEPTED,
                     EVENT_STATUS.REJECTED,
                     EVENT_STATUS.FINISHED,
                     EVENT_STATUS.CANCELED
                  ]
               }
            }
         ],
         ...where
      }
   };

   return filter;
}

export function getFixtureEventDatesForMonthFilter(filter: any) {
   return {
      ...filter,
      where: {
         ...filter.where,
         clubId: { $exists: false },
         tournamentId: { $exists: false }
      }
   };
}

export function getFixtureEventsForDayFilter(filter: any) {
   return {
      ...filter,
      where: {
         ...filter.where,
         clubId: { $exists: false },
         tournamentId: { $exists: false }
      }
   };
}

export function getClubEventDatesForMonthFilter(filter: any) {
   return {
      ...filter,
      where: {
         ...filter.where,
         clubId: { $exists: true }
      }
   };
}

export function getClubEventsForDayFilter(filter: any) {
   return {
      ...filter,
      where: {
         ...filter.where,
         clubId: { $exists: true }
      }
   };
}

export function getTournamentEventDatesForMonthFilter(filter: any) {
   return {
      ...filter,
      where: {
         ...filter.where,
         tournamentId: { $exists: true }
      }
   };
}

export function getTournamentEventsForDayFilter(filter: any) {
   return {
      ...filter,
      where: {
         ...filter.where,
         tournamentId: { $exists: true }
      }
   };
}

export function getTournamentDatesForMonthFilter(date: Date) {
   const monthStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
   const monthEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);

   return {
      limit: LIMIT_ALL,
      where: {
         isParticipant: true,
         startTime: {
            $gte: monthStartDate,
            $lt: monthEndDate
         }
      }
   };
}

export function getTournamentsForDayFilter(date: Date) {
   const dayStart = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
   const dayEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0);

   return {
      limit: LIMIT_ALL,
      order: 'startedAt DESC',
      where: {
         isParticipant: true,
         startTime: {
            $gte: dayStart,
            $lt: dayEnd
         }
      }
   };
}

export function isAllFilter(filter: CALENDAR_FILTER_TYPE): boolean {
   return filter === CALENDAR_FILTER.ALL;
}

export function isSportEventsFilter(filter: CALENDAR_FILTER_TYPE): boolean {
   return filter === CALENDAR_FILTER.SPORT_EVENTS;
}

export function isClubEventsFilter(filter: CALENDAR_FILTER_TYPE): boolean {
   return filter === CALENDAR_FILTER.CLUB_EVENTS;
}

export function isTournamentEventsFilter(filter: CALENDAR_FILTER_TYPE): boolean {
   return filter === CALENDAR_FILTER.TOURNAMENT_EVENTS;
}

export function isTournamentsFilter(filter: CALENDAR_FILTER_TYPE): boolean {
   return filter === CALENDAR_FILTER.TOURNAMENTS;
}

export function getSignEventType(event: SchoolEvent) {
   switch (true) {
      case isClubEvent(event):
         return EVENT_SIGN.CLUB;
      case isTournamentEvent(event):
         return EVENT_SIGN.TOURNAMENT;
      default:
         return EVENT_SIGN.DEFAULT;
   }
}

export function getEventResultForCalendar(event: SchoolEvent, activeSchoolId: string): string {
   const { teamsData } = event;
   const isResultsModePlacesOrPoints = isResultsModePlaces(event) || isResultsModePoints(event);
   const isInterHousesOrInternal = isHousesEvent(event) || isInternalEvent(event);
   const teamsFilteredByActiveSchoolId = teamsData.filter((team: SchoolEventTeamData) => team.schoolId === activeSchoolId);
   const isMoreThanOneTeamFromActiveSchool = teamsFilteredByActiveSchoolId.length > 1;

   switch (true) {
      case !isEventStatusFinished(event):
         return isClubEvent(event) ? EVENT_RESULTS_VIEW.VIEW_DETAILS : EVENT_RESULTS_VIEW.NO_RESULTS_YET;
      case isEventStatusCanceled(event):
         return EVENT_STATUS_SERVER_TO_CLIENT_MAPPING.CANCELED;
      case isEventStatusRejected(event):
         return EVENT_STATUS_SERVER_TO_CLIENT_MAPPING.REJECTED;
      case isCricket(event) && !isMultipartyEvent(event):
         return getCricketResults(event, activeSchoolId);
      case isResultsModeResults(event):
         return isNonTeamSportEvent(event) ? EVENT_RESULTS_VIEW.VIEW_RESULTS : getResults(event, activeSchoolId);
      case isResultsModePlacesOrPoints && isInterHousesOrInternal:
      case isResultsModePlacesOrPoints && isInterSchoolsEvent(event) && isMoreThanOneTeamFromActiveSchool:
         return EVENT_RESULTS_VIEW.VIEW_RESULTS;
      case isResultsModePlacesOrPoints && isInterSchoolsEvent(event):
         return getPlacesOrPoints(event, activeSchoolId);
      default:
         return '';
   }
}

function getResults(event: SchoolEvent, activeSchoolId: string): string {
   const { eventType, results, teamsData, housesData } = event;
   const { schoolScore, houseScore, teamScore } = results;
   const teamsCount = teamsData.length;

   const housesDataSorted = [...housesData].sort(sortByNameAsc);
   const teamsDataSorted = [...teamsData].sort(sortByNameAsc);

   switch (eventType) {
      case EVENT_TYPES.EXTERNAL_SCHOOLS:
         switch (teamsCount) {
            case 0: {
               //school vs school
               const activeSchoolScore: SchoolEventSchoolScore =
                  schoolScore.find((score) => score.schoolId === activeSchoolId) as SchoolEventSchoolScore;
               const opponentSchoolScore: SchoolEventSchoolScore =
                  schoolScore.find((score) => score.schoolId !== activeSchoolId) as SchoolEventSchoolScore;
               const isScoresExist: boolean =
                  typeof activeSchoolScore !== 'undefined' && typeof opponentSchoolScore !== 'undefined';

               return isScoresExist
                  ? `${activeSchoolScore.score} : ${opponentSchoolScore.score}`
                  : 'View results';
            }
            case 1: {
               //school vs team
               const activeSchoolTeam = teamsData.find((team: SchoolEventTeamData) => team.schoolId === activeSchoolId);
               const isActiveSchoolTeamExist = typeof activeSchoolTeam !== 'undefined';

               let activeSchoolScore: SchoolEventTeamScore | SchoolEventSchoolScore;
               let opponentSchoolScore: SchoolEventTeamScore | SchoolEventSchoolScore;

               if (isActiveSchoolTeamExist) {
                  //active team score, opponent school score
                  const id = propz.get(activeSchoolTeam, ['id']);
                  activeSchoolScore = teamScore.find((score) => score.teamId === id) as SchoolEventTeamScore;
                  opponentSchoolScore = schoolScore.find((score) => score.schoolId !== activeSchoolId) as SchoolEventSchoolScore;
               } else {
                  //active school score, opponent team score
                  const opponentSchoolTeam: SchoolEventTeamData =
                     teamsData.find((team) => team.schoolId !== activeSchoolId) as SchoolEventTeamData;
                  const { id } = opponentSchoolTeam;
                  activeSchoolScore = schoolScore.find((score) => score.schoolId === activeSchoolId) as SchoolEventSchoolScore;
                  opponentSchoolScore = teamScore.find((score) => score.teamId === id) as SchoolEventTeamScore;
               }

               const isScoresExist: boolean = typeof activeSchoolScore !== 'undefined' && typeof opponentSchoolScore !== 'undefined';

               return isScoresExist
                  ? `${activeSchoolScore.score} : ${opponentSchoolScore.score}`
                  : 'View results';
            }
            case 2: {
               //team vs team
               const activeSchoolTeam: SchoolEventTeamData =
                  teamsData.find((team) => team.schoolId === activeSchoolId) as SchoolEventTeamData;
               const id = propz.get(activeSchoolTeam, ['id'], '');
               const activeSchoolScore: SchoolEventTeamScore =
                  teamScore.find((score) => score.teamId === id) as SchoolEventTeamScore;
               const opponentSchoolScore: SchoolEventTeamScore =
                  teamScore.find((score) => score.teamId !== id) as SchoolEventTeamScore;
               const isScoresExist: boolean =
                  typeof activeSchoolScore !== 'undefined' && typeof opponentSchoolScore !== 'undefined';

               return isScoresExist
                  ? `${activeSchoolScore.score} : ${opponentSchoolScore.score}`
                  : 'View results';
            }
            default: {
               return 'View results';
            }
         }
      case EVENT_TYPES.INTERNAL_HOUSES:
         switch (teamsCount) {
            case 0: {
               //house vs house
               const [house1, house2] = housesDataSorted;
               const houseScore1: SchoolEventHouseScore =
                  houseScore.find((score) => score.houseId === house1.id) as SchoolEventHouseScore;
               const houseScore2: SchoolEventHouseScore =
                  houseScore.find((score) => score.houseId === house2.id) as SchoolEventHouseScore;
               const isScoresExist: boolean = typeof houseScore1 !== 'undefined' && typeof houseScore2 !== 'undefined';

               return isScoresExist
                  ? `${houseScore1.score} : ${houseScore2.score}`
                  : 'View results';
            }
            case 1: {
               //house vs team
               const [house1, house2] = housesDataSorted;
               const team1: SchoolEventTeamData = teamsData.find((team) => team.houseId === house1.id) as SchoolEventTeamData;
               const team2: SchoolEventTeamData = teamsData.find((team) => team.houseId === house2.id) as SchoolEventTeamData;
               const isTeamHouse1Exist = typeof team1 !== 'undefined';

               let houseScore1: SchoolEventHouseScore | SchoolEventTeamScore;
               let houseScore2: SchoolEventHouseScore | SchoolEventTeamScore;

               if (isTeamHouse1Exist) {
                  //house 1 team score, house 2 house score
                  const { id } = team1;
                  houseScore1 = teamScore.find((score) => score.teamId === id) as SchoolEventTeamScore;
                  houseScore2 = houseScore.find((score) => score.houseId === house2.id) as SchoolEventHouseScore;
               } else {
                  //house 1 house score, house 2 team score
                  const { id } = team2;
                  houseScore1 = houseScore.find((score) => score.houseId === house1.id) as SchoolEventHouseScore;
                  houseScore2 = teamScore.find((score) => score.teamId === id) as SchoolEventTeamScore;
               }

               const isScoresExist: boolean = typeof houseScore1 !== 'undefined' && typeof houseScore2 !== 'undefined';

               return isScoresExist
                  ? `${houseScore1.score} : ${houseScore2.score}`
                  : 'View results';
            }
            case 2: {
               const [house1, house2] = housesDataSorted;
               const team1: SchoolEventTeamData = teamsData.find((team) => team.houseId === house1.id) as SchoolEventTeamData;
               const team2: SchoolEventTeamData = teamsData.find((team) => team.houseId === house2.id) as SchoolEventTeamData;
               const { id: houseTeamId1 } = team1;
               const { id: houseTeamId2 } = team2;
               const houseScore1: SchoolEventTeamScore = teamScore.find((score) => score.teamId === houseTeamId1) as SchoolEventTeamScore;
               const houseScore2: SchoolEventTeamScore = teamScore.find((score) => score.teamId === houseTeamId2) as SchoolEventTeamScore;
               const isScoresExist: boolean = typeof houseScore1 !== 'undefined' && typeof houseScore2 !== 'undefined';

               return isScoresExist
                  ? `${houseScore1.score} : ${houseScore2.score}`
                  : 'View results';
            }
         }
         break;
      case EVENT_TYPES.INTERNAL_TEAMS:
         const [team1, team2] = teamsDataSorted;
         switch (true) {
            case teamsDataSorted.length === 2:
               const teamScore1 = teamScore.find((score: SchoolEventTeamScore) => score.teamId === team1.id);
               const teamScore2 = teamScore.find((score: SchoolEventTeamScore) => score.teamId === team2.id);
               const score1 = propz.get(teamScore1, ['score'], '');
               const score2 = propz.get(teamScore2, ['score'], '');
               return `${score1} : ${score2}`;

            case teamsDataSorted.length === 1:
               const teamScoreSingle = teamScore.find((score: SchoolEventTeamScore) => score.teamId === team1.id);
               const score = propz.get(teamScoreSingle, ['score'], '');
               return `${score}`;

            default:
               return '';
         }

      default:
         console.error(`Can not find event type: ${eventType}`);
         return '';
   }

   return ''
}

function getPlacesOrPoints(event: SchoolEvent, activeSchoolId: string): string {
   const { results, teamsData } = event;
   const { schoolScore, teamScore } = results;
   const team = teamsData.find((team: SchoolEventTeamData) => team.schoolId === activeSchoolId);
   const isTeamExist = typeof team !== 'undefined';
   const totalScores = [...schoolScore, ...teamScore].length;

   switch (true) {
      case isResultsModePlaces(event): {
         const allResultsSortedAsc: (SchoolEventTeamScore | SchoolEventSchoolScore)[] = [...schoolScore, ...teamScore].sort((score1, score2) => {
            return score1.score - score2.score;
         });

         if (isTeamExist) {
            const teamScoreIndex = allResultsSortedAsc.findIndex(score => (score as SchoolEventTeamScore).teamId === team.id);
            return `Place ${teamScoreIndex + 1} out of ${totalScores}`;
         } else {
            const schoolScoreIndex = allResultsSortedAsc.findIndex(score => (score as SchoolEventSchoolScore).schoolId === activeSchoolId);
            return `Place ${schoolScoreIndex + 1} out of ${totalScores}`;
         }
      }
      case isResultsModePoints(event): {
         const allResultsSortedDesc: (SchoolEventTeamScore | SchoolEventSchoolScore)[] = [...schoolScore, ...teamScore].sort((score1, score2) => {
            return score2.score - score1.score;
         });

         if (isTeamExist) {
            const teamScoreIndex = allResultsSortedDesc.findIndex(score => (score as SchoolEventTeamScore).teamId === team.id);
            return `Place ${teamScoreIndex + 1} out of ${totalScores}`;
         } else {
            const schoolScoreIndex = allResultsSortedDesc.findIndex(score => (score as SchoolEventSchoolScore).schoolId === activeSchoolId);
            return `Place ${schoolScoreIndex + 1} out of ${totalScores}`;
         }
      }
   }

   return '';
}

export function getEventStartTimeForCalendar(event: SchoolEvent): string {
   return Moment(event.startTime).format('HH:mm');
}

export function getCurrentWeek(date: Date) {
   const result = [];

   const currentDate = new Date(date);
   const dayOfWeek = currentDate.getDay();
   const dayOfMonth = currentDate.getDate();
   const startDate = dayOfMonth - dayOfWeek;
   const endDate = startDate + 6;

   for (let i = startDate; i <= endDate; i++) {
      const currentWeekDayDate = new Date(currentDate.setDate(i));

      result.push(currentWeekDayDate);
   }

   return result;
}
