import React, { useState, useEffect } from 'react';
import * as propz from 'propz';
import BPromise from 'bluebird';
import { styled, experimental_sx as sx } from '@mui/system';
import { SchoolEvent } from 'models/event';
import { getSchoolEventPhotos, getSchoolEventVideos } from 'services/school';
import Loader from 'components/loader';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import { default as ImageListItemMUI } from '@mui/material/ImageListItem';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

interface Props {
   event: SchoolEvent;
   activeSchoolId: string;
};

const ImageListItem = styled(ImageListItemMUI)(
   sx({
      cursor: 'pointer',
      overflow: 'hidden',
      borderRadius: 2
   })
);

const FullScreenMediaWrapper = styled(Box)(
   sx({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80vw',
      height: '80vh',
      bgcolor: '#fff',
      borderRadius: 2,
      overflow: 'hidden',

      'img': {
         height: '100%'
      }
   })
);

export default function EventGallery(props: Props) {
   const [isLoading, setIsLoading] = useState(false);
   const [isShowFullScreenMedia, setIsShowFullScreenMedia] = useState(false);
   const [photos, setPhotos] = useState([]);
   const [videos, setVideos] = useState([]);
   const [selectedMedia, setSelectedMedia] = useState();

   const isPhotosExist = photos.length > 0;
   const isVideosExist = videos.length > 0;

   const { event, activeSchoolId } = props;
   const eventId = propz.get(event, ['id'], '');
   const selectedMediaPicUrl = propz.get(selectedMedia, ['picUrl'], '');
   const selectedMediaName = propz.get(selectedMedia, ['originalFileName'], '');

   useEffect(() => {
      setIsLoading(true);

      const promises = [
         getSchoolEventPhotos(activeSchoolId, eventId),
         getSchoolEventVideos(activeSchoolId, eventId)
      ];

      BPromise.all(promises)
         .then(([photos, videos]) => {
            setPhotos(photos);
            setVideos(videos);
            setIsLoading(false);
         })
   }, [activeSchoolId, eventId]);

   if (isLoading) {
      return (
         <Loader />
      );
   }

   return (
      <>
         <Typography sx={{ mb: 2 }} variant='h4'>Photos</Typography>

         {isPhotosExist
            ? <ImageList sx={{ width: 500 }} cols={3} rowHeight={164}>
               {photos.map((item: any) => (
                  <ImageListItem key={item.id}>
                     <img
                        src={`${item.picUrl}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${item.picUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.originalFileName}
                        loading="lazy"
                        onClick={() => {
                           setSelectedMedia(item);
                           setIsShowFullScreenMedia(true);
                        }}
                     />
                  </ImageListItem>
               ))}
            </ImageList>

            : <Typography color="primary" sx={{ fontStyle: 'italic' }}>There are no media files in the event gallery</Typography>
         }


         <Typography sx={{ mb: 2, mt: 4 }} variant='h4'>Videos</Typography>

         {isVideosExist
            ? <ImageList sx={{ width: 500 }} cols={3} rowHeight={164}>
               {videos.map((item: any) => (
                  <ImageListItem key={item.id}>
                     <img
                        src={`${item.picUrl}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${item.picUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.originalFileName}
                        loading="lazy"
                        onClick={() => {
                           setSelectedMedia(item);
                           setIsShowFullScreenMedia(true);
                        }}
                     />
                  </ImageListItem>
               ))}
            </ImageList>

            : <Typography color="primary" sx={{ fontStyle: 'italic' }}>There are no media files in the event gallery</Typography>
         }

         <Modal
            keepMounted
            open={isShowFullScreenMedia}
            onClose={() => {
               setSelectedMedia(undefined);
               setIsShowFullScreenMedia(false)
            }}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
         >
            <FullScreenMediaWrapper>
               <img
                  src={`${selectedMediaPicUrl}`}
                  srcSet={`${selectedMediaPicUrl}`}
                  alt={selectedMediaName}
                  loading="lazy"
                  onClick={() => setIsShowFullScreenMedia(true)}
               />
            </FullScreenMediaWrapper>
         </Modal>
      </>
   )
}
