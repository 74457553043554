import axios from 'axios';
import { School } from 'models/school';
import { DEFAULT_FILTER, DEFAULT_SKIP, LIMIT_ALL } from 'consts/table';
import {
   getFilterForChampionships,
   getFilterForSchoolAndSchoolUnion,
   getFilterForSchoolLeagues,
   getFilterForTournaments
} from 'helpers/filters';

const DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER = 'startTime ASC';

const DEFAULT_TOURNAMENT_PARTICIPANT_FILTER: any = {
   isParticipant: true
};

export function getSchool(domain: string) {
   const config = {
      params: {
         filter: getFilterForSchoolAndSchoolUnion(domain)
      }
   };

   return axios.get(`${window.apiBase}/public/schools`, config).then(response => response.data);
}

export function getSchoolById(schoolId: string) {
   return axios.get(`${window.apiBase}/public/schools/${schoolId}`)
      .then(response => response.data);
}

export function getTournaments(domain: string) {
   const config = {
      params: {
         filter: getFilterForTournaments(domain)
      }
   };

   return axios.get(`${window.apiBase}/public/tournaments`, config).then(response => response.data);
}

export function getChampionships(domain: string) {
   const config = {
      params: {
         filter: getFilterForChampionships(domain)
      }
   };

   return axios.get(`${window.apiBase}/public/championships`, config).then(response => response.data);
}

export function getSchoolLeagues(domain: string) {
   const config = {
      params: {
         filter: getFilterForChampionships(domain)
      }
   };

   return axios.get(`${window.apiBase}/public/schoolLeagues`, config).then(response => response.data);
}

export function getSchoolHouses(school: School) {
   const { id } = school;

   const config = {
      params: {
         limit: 100
      }
   };

   return axios.get(`${window.apiBase}/public/schools/${id}/houses`, config).then(response => response.data);;
}

export function getSchoolPhotos(school: School, albumId: string) {
   const { id } = school;

   const config = {
      params: {
         limit: 50
      }
   };

   return axios.get(`${window.apiBase}/public/schools/${id}/albums/${albumId}/photos`, config).then(response => response.data);
}

export function getSchoolEvents(activeSchoolId: string, filter: any) {
   const config = {
      params: {
         filter: {
            where: {
               ...filter.where
            },
            skip: filter.skip,
            limit: filter.limit,
            order: filter.order || DEFAULT_SCHOOL_TOURNAMENT_EVENTS_ORDER
         }
      }
   };

   return axios.get(`${window.apiBase}/public/schools/${activeSchoolId}/events`, config).then(response => {
      return response.data;
   });
}

export function getSchoolEventsCount(activeSchoolId: string, filter: any) {
   const config = {
      params: {
         filter: {
            where: {
               ...filter.where
            },
         }
      }
   };

   return axios.get(`${window.apiBase}/public/schools/${activeSchoolId}/events/count`, config).then(response => {
      return response.data;
   });
}

export function getSchoolEvent(activeSchoolId: string, eventId: string) {
   return axios.get(`${window.apiBase}/public/schools/${activeSchoolId}/events/${eventId}`).then(response => {
      return response.data;
   });
}

export function getSchoolEventDetails(activeSchoolId: string, eventId: string) {
   return axios.get(`${window.apiBase}/public/schools/${activeSchoolId}/events/${eventId}/details`).then(response => {
      return response.data;
   });
}

export function getSchoolEventPhotos(activeSchoolId: string, eventId: string) {
   const config = {
      params: {
         filter: {
            skip: DEFAULT_SKIP,
            limit: LIMIT_ALL,
         }
      }
   };

   return axios.get(`${window.apiBase}/public/schools/${activeSchoolId}/events/${eventId}/photos`, config).then(response => {
      return response.data;
   });
}

export function getSchoolEventVideos(activeSchoolId: string, eventId: string) {
   const config = {
      params: {
         filter: {
            skip: DEFAULT_SKIP,
            limit: LIMIT_ALL,
         }
      }
   };

   return axios.get(`${window.apiBase}/public/schools/${activeSchoolId}/events/${eventId}/videos`, config).then(response => {
      return response.data;
   });
}

export function getSchoolEventReport(activeSchoolId: string, eventId: string) {
   return axios.get(`${window.apiBase}/public/schools/${activeSchoolId}/events/${eventId}/report`).then(response => {
      return response.data;
   });
}

export function getSchoolEventDates(activeSchoolId: string, filter: any) {
   const config = {
      params: {
         filter: filter
      }
   };

   return axios.get(`${window.apiBase}/public/schools/${activeSchoolId}/events/dates/distinct`, config).then(response => {
      return response.data;
   });
}

export function getSchoolTournaments(activeSchoolId: string, filter = DEFAULT_TOURNAMENT_PARTICIPANT_FILTER) {
   const config = {
      params: {
         filter: {
            where: {
               ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
               ...filter.where
            },
            skip: filter.skip,
            limit: filter.limit,
            order: filter.order
         }
      }
   };

   return axios.get(`${window.apiBase}/public/schools/${activeSchoolId}/tournaments`, config).then(response => {
      return response.data;
   });
}

export function getSchoolTournamentsCount(activeSchoolId: string, filter = DEFAULT_TOURNAMENT_PARTICIPANT_FILTER) {
   const config = {
      params: {
         filter: {
            where: {
               ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
               ...filter.where
            }
         }
      }
   };

   return axios.get(`${window.apiBase}/public/schools/${activeSchoolId}/tournaments/count`, config)
      .then(response => {
         return response.data;
      });
}

export function getSchoolTournament(activeSchoolId: string, tournamentId: string) {
   return axios.get(`${window.apiBase}/public/schools/${activeSchoolId}/tournaments/${tournamentId}`).then(response => {
      return response.data;
   });
}

export function getSchoolTournamentDates(activeSchoolId: string, filter = DEFAULT_TOURNAMENT_PARTICIPANT_FILTER) {
   const config = {
      params: {
         filter: {
            where: {
               ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
               ...filter.where
            },
            skip: filter.skip,
            limit: filter.limit,
            order: filter.order
         }
      }
   };

   return axios
      .get(`${window.apiBase}/public/schools/${activeSchoolId}/tournaments/dates/distinct`, config)
      .then(response => {
         return response.data;
      });
}

export function getSchoolSports(school: School) {
   const { id: schoolId } = school;

   const config = {
      params: {
         filter: {
            limit: LIMIT_ALL
         }
      }
   };

   return axios.get(`${window.apiBase}/public/schools/${schoolId}/sports`, config).then(response => {
      return response.data;
   });
}

export function getSchoolsPublic(filter = DEFAULT_FILTER) {
   const config = {
      params: {
         filter
      }
   };

   return axios.get(`${window.apiBase}/public/schools`, config).then(response => {
      return response.data;
   });
}
