import React from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { SchoolEvent } from 'models/event';
import { LEAGUE_AGGREGATION_TYPE } from 'consts/league';
import { getHouseBasicData, getTeamsBasicData, getHouseScoresTableColumns } from 'helpers/score';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

interface Props {
   school: School;
   results: SchoolEvent[];
   resultsFiltered: SchoolEvent[];
   aggregationType: string;
}

const TableWrapper = styled(Box)(
   sx({
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '6px',
      overflow: 'hidden'
   })
);

const TableHeadCell = styled(TableCell)(
   sx({
      fontWeight: 'bold'
   })
);

export default function ScoresTable(props: Props) {
   const {
      results,
      school,
      resultsFiltered,
      aggregationType
   } = props;
   const { ageGroupsNaming } = school;

   const scores = aggregationType === LEAGUE_AGGREGATION_TYPE.HOUSE
      ? getHouseBasicData(results, resultsFiltered, ageGroupsNaming)
      : getTeamsBasicData(resultsFiltered);

   const columnsData = getHouseScoresTableColumns(scores);

   const columns = columnsData.map((column, index) => {
      return (
         <TableHeadCell key={`table_column_${index}`}>
            {column}
         </TableHeadCell>
      )
   });

   const rows = scores.map((score: any, index: number) => {
      const rowCells = columnsData.map((column: string) => {
         const tableCellContent = score[column];

         return <TableCell key={column}>{tableCellContent}</TableCell>
      });

      return (
         <TableRow key={score['Team name']}>
            <TableCell>{index + 1}</TableCell>

            {rowCells}
         </TableRow>
      );
   });

   return (
      <TableWrapper>
         <TableContainer>
            <Table>
               <TableHead>
                  <TableRow>
                     <TableHeadCell>#</TableHeadCell>
                     
                     {columns}
                  </TableRow>
               </TableHead>

               <TableBody>
                  {rows}
               </TableBody>
            </Table>
         </TableContainer>
      </TableWrapper>
   )
};
