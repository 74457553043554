import React from 'react';
import { default as AutocompleteMUI } from '@mui/material/Autocomplete';
import { SelectOption } from 'models/table';
import { ENTER_BUTTON_KEY } from '../Filters/Filters';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface Props {
   value: any;
   label: string;
   options?: SelectOption[];
   field: string;
   onChange: (event: any, field: string) => void;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Autocomplete(props: Props) {
   const {
      value,
      label,
      options = [],
      field,
      onChange
   } = props;

   const autocompleteValue = value.map((item: string) => {
      return options.find(option => option.value === item);
   });

   return (
      <AutocompleteMUI
         multiple
         limitTags={2}
         id="checkboxes-tags-demo"
         value={autocompleteValue}
         options={options}
         disableCloseOnSelect
         isOptionEqualToValue={(option, value) => option.value === value.value}
         getOptionLabel={(option) => option.text}
         openOnFocus
         onChange={(event, newValue) => {
            const result = {
               target: {
                  value: newValue.map(item => item.value)
               }
            };

            onChange(result, field)
         }}
         renderOption={(props, option, { selected }) => (
            <li {...props}>
               <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
               />
               {option.text}
            </li>
         )}
         renderInput={(params) => (
            <TextField {...params} label={label} />
         )}
      />
   );
}
