import * as Cookies from 'js-cookie';
import { Storage } from 'models/storage';

const sessionStorage = window.sessionStorage;
const localStorage = window.localStorage;

export const StorageHelper: Storage = {
   LocalStorage: {
      set(key, value) {
         value = typeof value === 'string' ? String(value) : JSON.stringify(value);
      
         try {
            localStorage.setItem(key, value);

            return true;
         } catch (errorText) {
            console.error('Out of local store');

            return false;
         }
      },
      get(key) {
         const value = localStorage.getItem(key);

         return value !== null
            ? (value.indexOf('{') !== -1 || value.indexOf('[') !== -1 ? JSON.parse(value) : value)
            : undefined;
      },

      remove(key) {
         return localStorage.removeItem(key);
      },

      clear() {
         localStorage.clear();
      }
   },

   SessionStorage: {
      set(key, value) {
         value = typeof value === 'string' ? String(value) : JSON.stringify(value);
      
         try {
            sessionStorage.setItem(key, value);

            return true;
         } catch (errorText) {
            console.error('Out of session store');

            return false;
         }
      },

      get(key) {
         const value = sessionStorage.getItem(key);

         return value !== null
            ? (value.indexOf('{') !== -1 || value.indexOf('[') !== -1 ? JSON.parse(value) : value)
            : undefined;
      },

      remove(key) {
         return sessionStorage.removeItem(key);
      },
      
      clear() {
         sessionStorage.clear();
      }
   },

   Cookie: {
      get(key) {
         let value = Cookies.get(key);
      
         // Cookies method set use JSON.stringify
         // JSON.stringify for undefined is 'undefined'
         // So convert it manually
         if (value === 'undefined' || typeof value === 'undefined') {
            value = undefined;
         // For other cases parse value
         } else {
            try {
               value = JSON.parse(value);
            } catch (err) {
               console.error('Not valid data in cookie.');
               console.error(value);
            
               this.remove(key);
               value = undefined;
            }
         };
      
         return value;
      },

      set(key, value, options) {
         if (typeof options !== 'undefined') {
            Cookies.set(key, value, options);
         } else {
            Cookies.set(key, value);
         }
      
         return true;
      },

      remove(key, options?) {
         return Cookies.remove(key, options);
      }
   }
};