import React from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import * as propz from 'propz';
import { SchoolHouse } from 'models/house';
import { SchoolEvent } from 'models/event';
import { getHousePoints } from 'helpers/house';
import { filterEventsByCurrentSeason } from 'helpers/event';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainerMUI from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRowMUI from '@mui/material/TableRow';
import PageTitle from 'components/PageTitle';

const HOUSES_COLUMNS = [
   {
      text: 'House',
      value: 'house',
      align: 'center'
   },
   {
      text: 'Points',
      value: 'points',
      align: 'center'
   }
];

interface Props {
   items: SchoolHouse[];
   events: SchoolEvent[];
}

type ColumnValue = 'house' | 'points';

const TableContainer = styled(TableContainerMUI)(
   sx({
      border: '1px solid #ccc',
      borderRadius: '5px'
   })
);

const TableRow = styled(TableRowMUI)(
   sx({
      '&:last-child td, &:last-child th': {
         border: 0
      }
   })
);

export default function SchoolHouses(props: Props) {
   const { items, events } = props;

   const eventsOfCurrentSeason = filterEventsByCurrentSeason(events);

   const renderHousesTableColumns = () => {
      const columns = HOUSES_COLUMNS.map(column => {
         const { text, value, align } = column;

         return <TableCell key={value} sx={{ textAlign: align, fontWeight: 'bold' }}>{text}</TableCell>;
      });

      return <TableRowMUI>{columns}</TableRowMUI>;
   };

   const getHousesTableRows = () => {
      return items
         .map(item => {
            const houseName = propz.get(item, ['name'], '');

            return {
               house: houseName,
               points: getHousePoints(eventsOfCurrentSeason, item)
            }
         })
         .sort((item1, item2) => item2.points - item1.points)
   }

   const renderHousesTableRows = () => {
      const rows = getHousesTableRows();
      
      return rows.map(row => {
         const { house, points } = row;
         
         const rowCellContent = {
            house,
            points
         }

         return (
            <TableRow key={house}>
               {HOUSES_COLUMNS.map(column => {
                  const { value, align } = column;

                  return (
                     <TableCell key={value} sx={{ textAlign: align }}>
                        {rowCellContent[value as ColumnValue]}
                     </TableCell>
                  )
               })}
            </TableRow>
         );
      });
   };

   return (
      <>
         <PageTitle text='House Points' />

         <TableContainer>
            <Table>
               <TableHead>
                  {renderHousesTableColumns()}
               </TableHead>

               <TableBody>
                  {renderHousesTableRows()}
               </TableBody>
            </Table>
         </TableContainer>
      </>
   );
};
