import React, { useState, useEffect, useCallback } from 'react';
import * as propz from 'propz';
import { parse } from 'query-string';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Promise } from 'bluebird';
import { styled, experimental_sx as sx } from '@mui/system';
import { SelectChangeEvent } from '@mui/material/Select';
import { School } from 'models/school';
import { FilterField } from 'models/filter';
import { Sport } from 'models/sport';
import { SchoolForm } from 'consts/form';
import { CALENDAR_FILTER_TYPE } from 'types/calendar';
import { FILTER_TYPE } from 'consts/table';
import { CALENDAR_FILTER } from 'consts/calendar';
import {
   getClubEventDatesForMonthFilter,
   getClubEventsForDayFilter,
   getEventDatesForMonthFilter,
   getEventsForDayFilter,
   getFixtureEventDatesForMonthFilter,
   getFixtureEventsForDayFilter,
   getTournamentDatesForMonthFilter,
   getTournamentEventDatesForMonthFilter,
   getTournamentEventsForDayFilter,
   getTournamentsForDayFilter,
   isAllFilter,
   isClubEventsFilter,
   isSportEventsFilter,
   isTournamentEventsFilter,
   isTournamentsFilter
} from 'helpers/calendar';
import { getServerFieldSectionWhere } from 'helpers/table';
import { getFilters, checkIsFilterExist, getSearchFilter } from 'helpers/filters';
import {
   getSelectOptionForCalendarFilter,
   getSelectOptionForAge,
   getSelectOptionForClubGender,
   getSelectOptionForSport
} from 'helpers/select';
import {
   getSchoolEvents,
   getSchoolEventDates,
   getSchoolTournamentDates,
   getSchoolTournaments
} from 'services/school';
import { getSchoolSports } from 'services/school';
import { getAllSchoolForms } from 'services/forms';
import Lazy from 'lazy.js';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Divider from '@mui/material/Divider';
import DatePicker from 'components/DatePicker';
import EventsAndTournamentsTable from 'components/EventsAndTournamentsTable';
import Filters from 'components/Filters';
import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';
import CalendarFilter from 'components/CalendarFilter';

interface Props {
   school: School;
}

const FILTER_FIELDS: FilterField[] = [
   {
      text: 'Event types',
      field: 'eventTypes',
      type: FILTER_TYPE.MULTISELECT
   },
   {
      text: 'Sports',
      field: 'sport',
      type: FILTER_TYPE.AUTOCOMPLETE
   },
   {
      text: 'Ages',
      field: 'eventAges',
      type: FILTER_TYPE.MULTISELECT
   },
   {
      text: 'Gender',
      field: 'gender',
      type: FILTER_TYPE.MULTISELECT
   }
];

const CalendarWrapper = styled(Box)(
   sx({
      display: 'flex',
      flexDirection: {
         xs: 'column',
         sm: 'column',
         md: 'row'
      },
      alignItems: {
         md: 'flex-start',
         sm: 'center'
      },
      justifyContent: 'space-between',
      gap: 3
   })
);

const DatePickerWrapper = styled(Box)(
   sx({
      width: 'auto',
      display: 'inline-block',

      '& .MuiCalendarPicker-root': {
         width: {
            xs: '100%'
         }
      },
      '& .MuiPickersDay-root': {
         border: '1px solid #ccc',
         borderRadius: '4px',
         fontSize: 14,
         '&.MuiPickersDay-today': {
            background: '#6cb6ff',
            color: '#fff',
            border: 'none'
         },
         '&.MuiPickersDay-dayOutsideMonth': {
            color: '#0000004d',
            opacity: '0.4',
            '&.Mui-selected': {
               color: '#fff'
            }
         }
      }
   })
);

export default function Calendar(props: Props) {
   const navigate = useNavigate();
   const location = useLocation();

   const [searchParams, setSearchParams] = useSearchParams();
   
   const { school } = props;
   const { id: activeSchoolId } = school;

   const ageGroupName = propz.get(school, ['ageGroupsNaming'], '');

   const now = new Date();
   const currentMonth = now.getMonth();
   const currentYear = now.getFullYear();

   const locationState = propz.get(location, ['state']);
   const isLocationStateExist = locationState !== null;
   const initialSelectedDate = isLocationStateExist ? propz.get(locationState, ['selectedDate'], now) : now;

   const initialMonthAndYear = isLocationStateExist
      ? new Date(initialSelectedDate.getFullYear(), initialSelectedDate.getMonth(), 1)
      : new Date(currentYear, currentMonth, 1);

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [isShowFilters, setIsShowFilters] = useState<boolean>(false);

   const [selectedDate, setSelectedDate] = useState<Date>(initialSelectedDate);
   const [monthAndYearDate, setMonthAndYearDate] = useState<Date>(initialMonthAndYear);

   const [dates, setDates] = useState<Date[]>([]);
   const [fixtureEvents, setFixtureEvents] = useState([]);
   const [clubEvents, setClubEvents] = useState([]);
   const [tournamentEvents, setTournamentEvents] = useState([]);
   const [tournaments, setTournaments] = useState([]);
   const [sports, setSports] = useState<Sport[]>([]);
   const [forms, setForms] = useState<SchoolForm[]>([]);

   const [filters, setFilters] = useState({});

   const options = {
      eventTypes: getSelectOptionForCalendarFilter(),
      eventAges: getSelectOptionForAge(forms, ageGroupName),
      gender: getSelectOptionForClubGender(),
      sport: getSelectOptionForSport(sports)
   };

   const getEventPromises = useCallback((filters: any, where: any) => {
      const {eventTypes} = filters;
      
      const emptyDates = { dates: [] };
      const eventDatesFilter = getEventDatesForMonthFilter(monthAndYearDate, activeSchoolId, where);
      const eventsFilter = getEventsForDayFilter(selectedDate, activeSchoolId, where);

      const sportEventDatesFilter = getFixtureEventDatesForMonthFilter(eventDatesFilter);
      const sportEventsFilter = getFixtureEventsForDayFilter(eventsFilter);

      const clubEventDatesFilter = getClubEventDatesForMonthFilter(eventDatesFilter);
      const clubEventsFilter = getClubEventsForDayFilter(eventsFilter);

      const tournamentEventDatesFilter = getTournamentEventDatesForMonthFilter(eventDatesFilter);
      const tournamentEventsFilter = getTournamentEventsForDayFilter(eventsFilter);

      const tournamentDatesFilter = getTournamentDatesForMonthFilter(monthAndYearDate);
      const tournamentsFilter = getTournamentsForDayFilter(selectedDate);

      const isAllFilterExist = eventTypes.includes(CALENDAR_FILTER.ALL);
      const isSportEventsFilterExist = eventTypes.includes(CALENDAR_FILTER.SPORT_EVENTS);
      const isClubEventsFilterExist = eventTypes.includes(CALENDAR_FILTER.CLUB_EVENTS);
      const isTournamentEventsFilterExist = eventTypes.includes(CALENDAR_FILTER.TOURNAMENT_EVENTS);
      const isTournamentsFilterExist = eventTypes.includes(CALENDAR_FILTER.TOURNAMENTS);
      
      const isAllOrSportEventsFilter = isAllFilterExist || isSportEventsFilterExist;
      const isAllOrClubEventsFilter = isAllFilterExist || isClubEventsFilterExist;
      const isAllOrTournamentEventsFilter = isAllFilterExist || isTournamentEventsFilterExist;
      const isAllOrTournamentsFilter = isAllFilterExist || isTournamentsFilterExist;

      return [
         isAllOrSportEventsFilter
            ? getSchoolEventDates(activeSchoolId, sportEventDatesFilter)
            : Promise.resolve(emptyDates),

         isAllOrSportEventsFilter
            ? getSchoolEvents(activeSchoolId, sportEventsFilter)
            : Promise.resolve([]),

         isAllOrClubEventsFilter
            ? getSchoolEventDates(activeSchoolId, clubEventDatesFilter)
            : Promise.resolve(emptyDates),

         isAllOrClubEventsFilter
            ? getSchoolEvents(activeSchoolId, clubEventsFilter)
            : Promise.resolve([]),

         isAllOrTournamentEventsFilter
            ? getSchoolEventDates(activeSchoolId, tournamentEventDatesFilter)
            : Promise.resolve(emptyDates),

         isAllOrTournamentEventsFilter
            ? getSchoolEvents(activeSchoolId, tournamentEventsFilter)
            : Promise.resolve([]),

         isAllOrTournamentsFilter
            ? getSchoolTournamentDates(activeSchoolId, tournamentDatesFilter)
            : Promise.resolve(emptyDates),

         isAllOrTournamentsFilter
            ? getSchoolTournaments(activeSchoolId, tournamentsFilter)
            : Promise.resolve([])
      ];
   }, [activeSchoolId, selectedDate, monthAndYearDate]);

   useEffect(() => {
      setIsLoading(true);

      const search = parse(location.search);
      const filters = getFilters(FILTER_FIELDS, search);

      let filtersUpdated: any;

      const isFilterExist = checkIsFilterExist(filters);

      if (isFilterExist) {
         filtersUpdated = filters;
      } else {
         filtersUpdated = {
            ...filters,
            eventTypes : ['ALL']
         };
      };

      const where = getServerFieldSectionWhere(filtersUpdated);

      delete where.eventTypes;

      const promises = getEventPromises(filtersUpdated, where);

      Promise.all(promises).then(
         ([
            fixtureEventDates,
            fixtureEvents,
            clubEventDates,
            clubEvents,
            tournamentEventDates,
            tournamentEvents,
            tournamentDates,
            tournaments
         ]) => {
            const dates = [
               ...fixtureEventDates.dates,
               ...clubEventDates.dates,
               ...tournamentEventDates.dates,
               ...tournamentDates.dates
            ];
            const datesUniq: string[] = Lazy(dates)
               .uniq()
               .toArray();

            setIsLoading(false);
            setDates(datesUniq.map(date => new Date(date)));
            setFixtureEvents(fixtureEvents);
            setClubEvents(clubEvents);
            setTournamentEvents(tournamentEvents);
            setTournaments(tournaments);
            setFilters(filtersUpdated);
         }
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [getEventPromises, monthAndYearDate, selectedDate, searchParams]);

   useEffect(() => {
      setIsLoading(true);

      const promises = [
         getSchoolSports(school),
         getAllSchoolForms(activeSchoolId)
      ];

      Promise.all(promises).then(([sports, forms]) => {
         setSports(sports);
         setForms(forms);
         setIsLoading(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const onMonthChange = (value: Date) => {
      setMonthAndYearDate(new Date(value));
   };

   const onDateChange = (newValue: Date) => {
      setSelectedDate(newValue);
   };

   const onFilterChange = (event: any, filterField: string): void => {
      const filterValue = propz.get(event, ['target', 'value']);

      const nextFilters = {
         ...filters,
         [filterField]: filterValue
      };

      setFilters(nextFilters);
   };

   const onApplyFilterClick = (): void => {
      let search = [];

      const isFilterExist = checkIsFilterExist(filters);

      if (isFilterExist) {
         search.push(getSearchFilter(filters));
      }

      const searchParams: any = parse(search.join('&'));

      setSearchParams(searchParams);
   };

   const onClearFilterClick = (): void => {
      setSearchParams('');

      const search = parse(location.search);
      const filters = getFilters(FILTER_FIELDS, search);

      setFilters(filters);
   };

   const onCurrentDateClick = (): void => {
      const inititalDateWithoutTime = now.toDateString();
      const selectedDateWithoutTime = selectedDate.toDateString();
      const initialMonthAndYearWithoutTime = initialMonthAndYear.toDateString();
      const monthAndYearDateWithoutTime = monthAndYearDate.toDateString();

      if (inititalDateWithoutTime === selectedDateWithoutTime
         && initialMonthAndYearWithoutTime === monthAndYearDateWithoutTime) return;
      
      setSelectedDate(now);
      setMonthAndYearDate(new Date(currentYear, currentMonth, 1));
   };

   const onEventClick = (eventId: string): void => {
      const { pathname } = location;

      navigate(`/event/${eventId}`, { state: { selectedDate, prevPath: pathname } });
   };

   const events = [...fixtureEvents, ...clubEvents, ...tournamentEvents];
   const eventsSorted = events.sort((event1: any, event2: any) => {
      const eventStartDate1 = new Date(event1.startTime);
      const eventStartDate2 = new Date(event2.startTime);
      return Number(eventStartDate1) - Number(eventStartDate2);
   });

   const filterButtonIcon = isShowFilters ? <FilterAltOffIcon /> : <FilterAltIcon />;
   const filterButtonText = isShowFilters ? 'Hide Filters' : 'Show Filters';

   return (
      <PageWrapper>
         <PageTitle text='Calendar' />

         <Button
            variant="outlined"
            startIcon={filterButtonIcon}
            sx={{ my: 2 }}
            onClick={() => setIsShowFilters(!isShowFilters)}
         >
            {filterButtonText}
         </Button>

         {isShowFilters &&
            <Box>
               <Filters
                  fields={FILTER_FIELDS}
                  filters={filters}
                  options={options}
                  isLoading={isLoading}
                  onFilterChange={onFilterChange}
                  onApplyFilterClick={onApplyFilterClick}
                  onClearFilterClick={onClearFilterClick}
               />

               <Divider sx={{ my: 3 }} />
            </Box>
         }

         <CalendarWrapper>
            <DatePickerWrapper>
               <DatePicker
                  value={selectedDate}
                  eventDates={dates}
                  isLoading={isLoading}
                  onCurrentDateClick={onCurrentDateClick}
                  onChangeDate={onDateChange}
                  onMonthChange={onMonthChange}
               />
            </DatePickerWrapper>

            <EventsAndTournamentsTable
               events={eventsSorted}
               activeSchoolId={activeSchoolId}
               tournaments={tournaments}
               onEventClick={onEventClick}
               isLoading={isLoading}
            />
         </CalendarWrapper>
      </PageWrapper>
   )
}
