import React from 'react';
import { SchoolEvent } from 'models/event';
import Typography from '@mui/material/Typography';

interface Props {
   event: SchoolEvent;
}

export default function EventFeed(props: Props) {
   const { event } = props;

   return (
      <Typography>Coming soon ...</Typography>
   )
}
