import React, { useState } from 'react';
import * as propz from 'propz';
import { School } from 'models/school';
import { SchoolEvent, SchoolEventIndividualScore } from 'models/event';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PointsCellData from './cellData/points/PointsCellData';
import AthleticScore from './cellData/points/AthleticScore';
import { isCricket } from 'helpers/sport/cricket';
import { isEventStatusFinished, isIndividualTournamentAndTeamSport, isInterSchoolsEventForIndividualSport } from 'helpers/event';
import { isTriathlon } from 'helpers/sport/sport';
import MedalForFirstPlaceIcon from 'components/SvgIcons/MedalForFirstPlaceIcon';
import MedalForSecondPlaceIcon from 'components/SvgIcons/MedalForSecondPlaceIcon';
import MedalForThirdPlaceIcon from 'components/SvgIcons/MedalForThirdPlaceIcon';
import { 
   getTeamAbbreviationResultByTeamId, 
   getSchoolAbbreviationResultBySchoolId,
   getAbbreviationColor
} from 'helpers/eventView';

const PLAYER_COLUMNS = [
   {
      text: '№',
      value: 'index',
      align: 'left'
   },
   {
      text: 'Player name',
      value: 'playerName',
      align: 'left'
   },
   {
      text: 'Result',
      value: 'result',
      align: 'right'
   }
];

const MAX_INDEX_OF_WINNERS = 2;

type PlayerColumnsValueType = 'index' | 'playerName' | 'result';
type ColumnValueType = 'index' | 'teamName' | 'rank' | 'results' | 'points' | 'abbreviation';

interface PlayersTableCellData {
   index: number;
   playerName: string;
   result: string | JSX.Element;
}

interface TableCellData {
   index: number;
   teamName: string;
   rank: string | JSX.Element;
   results: string | JSX.Element;
   points: number;
   abbreviation: string | JSX.Element;  
}

interface Props {
   rival: any;
   school: School;
   event: SchoolEvent;
   columns: any[];
   index: number;
}

export default function TeamResultsTableRow(props: Props) {
   const { rival, columns, index, school, event } = props;
   const [isOpen, setIsOpen] = useState(false);

   const { id } = rival;

   const participantTeamId = propz.get(rival, ['team', 'id']);
   const participantTeamName = propz.get(rival, ['team', 'name'], '');
   const participantSchoolId = propz.get(rival, ['school', 'id'], '');
   const participantSchoolName = propz.get(rival, ['school', 'name'], '');
   const isTeamNameExist = participantTeamName !== '';
   const teamName = isTeamNameExist ? participantTeamName : participantSchoolName;
   const teamPlayers = propz.get(rival, ['team', 'players'], []);

   const getAthleticExtraPointsBySchoolId = (event: SchoolEvent, schoolId: string) => {
      const schoolScoreDataIndex = event.results.schoolScore.findIndex(schoolScoreData => schoolScoreData.schoolId === schoolId);

      if (schoolScoreDataIndex === -1) {
         return 0;
      } else {
         const extraScore = propz.get(event, ['results', 'schoolScore', schoolScoreDataIndex, 'richScore', 'points'], undefined);
         if (typeof extraScore !== 'undefined') {
            return extraScore;
         } else {
            return 0;
         }
      }
   };

   const getAthleticExtraPointsByTeamId = (event: SchoolEvent, teamId: string) => {
      const teamScoreDataIndex = event.results.teamScore.findIndex(teamScoreData => teamScoreData.teamId === teamId);
      
      if (teamScoreDataIndex === -1) {
         return 0;
      } else {
         const extraScore = propz.get(event, ['results', 'teamScore', teamScoreDataIndex, 'richScore', 'points'], undefined);
         if (typeof extraScore !== 'undefined') {
            return extraScore;
         } else {
            return 0;
         }
      }
   };

   const plainExtraPoints = typeof participantTeamId !== 'undefined'
      ? getAthleticExtraPointsByTeamId(event, participantTeamId)
      : getAthleticExtraPointsBySchoolId(event, participantSchoolId);

   const abbreviationResult = (participantTeamId 
      ? getTeamAbbreviationResultByTeamId(event, participantTeamId) 
      : getSchoolAbbreviationResultBySchoolId(event, participantSchoolId)) || ''

   const abbreviationColor = getAbbreviationColor(event, abbreviationResult);

   let medalIcon: any = '';
   const isRenderMedal = isEventStatusFinished(event) && medalIcon !== null && index <= MAX_INDEX_OF_WINNERS;

   switch (index) {
      case 0:
         medalIcon = <MedalForFirstPlaceIcon />;
         break;

      case 1:
         medalIcon = <MedalForSecondPlaceIcon />;
         break;

      case 2:
         medalIcon = <MedalForThirdPlaceIcon />;
         break;
   }

   const tableCellData: TableCellData = {
      index: index + 1,
      teamName,
      rank: isRenderMedal ? medalIcon : '-',
      results: <PointsCellData rival={rival} school={school} event={event} />,
      points: plainExtraPoints,
      abbreviation: abbreviationResult ? (
         <span style={{ color: abbreviationColor, fontWeight: 'bold' }}>
            {abbreviationResult}
         </span>
      ) : ''
   };

   const playersTableColumns = PLAYER_COLUMNS.map(column => {
      const { align, value } = column;

      return (
         <TableCell sx={{ textAlign: align }} key={value}>{column.text}</TableCell>
      );
   })

   const getPointsByPlayer = (userId: string): number => {
      const individualScore = propz.get(event, ['results', 'individualScore'], []);
      const userScoreDataIndex =
         individualScore.findIndex((userScoreData: SchoolEventIndividualScore) => userScoreData.userId === userId);

      return userScoreDataIndex === -1 ? 0 : event.results.individualScore[userScoreDataIndex].score;
   }

   const getIsNewRecordByStudent = (userId: string) => {
      const userScoreDataIndex = event.results.individualScore.findIndex(userScoreData => userScoreData.userId === userId);
      if (userScoreDataIndex === -1) {
         return false;
      } else {
         const isRecord = propz.get(event, ['results', 'individualScore', userScoreDataIndex, 'isRecord'], false);
         return isRecord;
      }
   }

   const playersTableRows = teamPlayers.map((player: any, index) => {
      const playerFirstName = propz.get(player, ['firstName'], '');
      const playerLastName = propz.get(player, ['lastName'], '');
      const playerName = `${playerFirstName} ${playerLastName}`;

      let playerResult;

      switch (true) {
         case isCricket(event):
            playerResult = <Box></Box>;
            break;

         case isInterSchoolsEventForIndividualSport(event) ||
            (isIndividualTournamentAndTeamSport(event) && !isTriathlon(event.sport.name)):
            const playerPoints = getPointsByPlayer(player.userId);
            const isRecord = getIsNewRecordByStudent(player.userId);

            playerResult = (
               <AthleticScore
                  plainPoints = { playerPoints }
                  isPlayerScore
                  isRecord = { isRecord }
                  event = { event }
               />
            );
            break;
      
         default:
            playerResult = <Box></Box>;
            break;
      }

      const playersTableCellData: PlayersTableCellData = {
         index: index + 1,
         playerName,
         result: playerResult,
      };
      
      return (
         <TableRow key={player.id}>
            {PLAYER_COLUMNS.map(column => {
               const { value, align } = column;

               return (
                  <TableCell component="th" scope="row" key={value} sx={{ textAlign: align }}>
                     {playersTableCellData[value as PlayerColumnsValueType]}
                  </TableCell>
               )
            })}
         </TableRow>
      );
   })

   const renderCollapsableSection = () => {
      return (
         <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, background: '#eee' }} colSpan={6}>
               <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                     <Typography variant="h6" gutterBottom component="div">
                        Players
                     </Typography>

                     <Table size="small" aria-label="purchases">
                        <TableHead>
                           <TableRow>
                              {playersTableColumns}
                           </TableRow>
                        </TableHead>

                        <TableBody>
                           {playersTableRows}
                        </TableBody>
                     </Table>
                  </Box>
               </Collapse>
            </TableCell>
         </TableRow>
      );
   }

   return (
      <>
         <TableRow key={id}>
            <TableCell>
               <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setIsOpen(!isOpen)}
               >
                  {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
               </IconButton>
            </TableCell>

            {columns.map((item) => {
               const { value, align } = item;

               return (
                  <TableCell key={value} sx={{ textAlign: align }}>
                     {tableCellData[value as ColumnValueType]}
                  </TableCell>
               );
            })}
         </TableRow>

         {renderCollapsableSection()}
      </>
   );
}