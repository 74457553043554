import React, { useState } from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import { DateRange } from 'react-date-range';
import { SCREEN_SIZE } from 'consts/responsive';
import { DATE_FORMAT } from 'consts/date';
import Moment from 'moment';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface Props {
   value: any;
   field: string;
   label: string;
   onChange: (event: any, field: string) => void;
}

const DateRangeInputWrapper = styled(Box)({
   position: 'relative',
   display: 'flex',
   alignItems: 'center'
});

const ButtonsWrapper = styled(Box)({
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
   padding: '20px'
});

const DateRangeWrapper = styled(Box)(({ theme }) =>
   sx({
      position: 'absolute',
      top: '50%',
      left: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '496px',
      transform: 'translate(-52%, -50%)',
      backgroundColor: '#fff',
      border: '1px solid rgba(0, 0, 0, 0.2)',
      borderRadius: '5px',
      overflow: 'hidden',
      zIndex: 100000,
      [theme.breakpoints.down('sm')]: {
         width: '90%'
      },
      '& .rdrDateRangeWrapper': {
         [theme.breakpoints.down('sm')]: {
            width: '100%'
         },
         '& .rdrMonth': {
            fontFamily: 'sans-serif',
            [theme.breakpoints.down('sm')]: {
               width: '96%'
            }
         }
      }
   })
);

const DateRangeLabel = styled(Typography)(
   sx({
      position: 'absolute',
      top: '-25px',
      fontSize: 14,
      fontWeight: 'bold',
      color: 'rgba(0, 0, 0, 0.6)',
      mb: 1
   })
);

const DateRangeOverlay = styled(Box)(
   sx({
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      zIndex: 99999
   })
);

export default function DateInterval(props: Props) {
   const { value, field, label, onChange } = props;
   const { from, to } = value;

   const fromCurrentFormat = Moment(from, DATE_FORMAT).format('YYYY-MM-DD');
   const toCurrentFormat = Moment(to, DATE_FORMAT).format('YYYY-MM-DD');

   const [currentValue, setСurrentValue] = useState<any>([
      {
         startDate: from !== ''
            ? Moment(fromCurrentFormat, 'YYYY-MM-DD').toDate()
            : undefined,
         endDate: to !== ''
            ? Moment(toCurrentFormat, 'YYYY-MM-DD').toDate()
            : undefined,
         key: 'selection'
      }
   ]);
   const [isDateRangeShow, setIsDateRangeShow] = useState<boolean>(false);

   const isTabletScreenSize = useMediaQuery(SCREEN_SIZE.TABLET);

   const onDateRangeOverlayClick = (event: React.MouseEvent) => {
      const target = event.target as HTMLDivElement;
      const isDateRangeOverlay = target.classList.contains('dateRangeOverlay');

      if (isDateRangeOverlay) {
         setIsDateRangeShow(false);
      }
   };

   const onCloseClick = () => {
      setIsDateRangeShow(false);
   };

   const onResetClick = () => {
      setСurrentValue([
         {
            startDate: undefined,
            endDate: undefined,
            key: 'selection'
         }
      ]);
   };

   const onSelectClick = () => {
      const [value] = currentValue;

      const toValue = value.endDate === value.startDate || typeof value.endDate === 'undefined'
         ? ''
         : Moment(value.endDate).format(DATE_FORMAT);

      const fromValue = typeof value.startDate === 'undefined'
         ? ''
         : Moment(value.startDate).format(DATE_FORMAT);

      const updatedValue = {
         target: {
            value: {
               from: fromValue,
               to: toValue
            }
         }
      }

      onChange(updatedValue, field);

      setIsDateRangeShow(false);
   };

   return (
      <Box sx={{ position: 'relative' }}>
         <DateRangeLabel>{label}</DateRangeLabel>

         <DateRangeInputWrapper>
            <TextField
               variant='outlined'
               label='From'
               placeholder='mm/dd/yyyy'
               value={value.from}
               onFocus={() => setIsDateRangeShow(true)}
               inputProps={
                  { readOnly: true, }
               }
               sx={{ width: '100%', mr: 1 }}
            />
            <TextField
               variant='outlined'
               label='To'
               placeholder='mm/dd/yyyy'
               value={value.to}
               onFocus={() => setIsDateRangeShow(true)}
               inputProps={
                  { readOnly: true, }
               }
               sx={{ width: '100%' }}
            />
            {isDateRangeShow &&
               <>
                  <DateRangeOverlay className='dateRangeOverlay' onClick={onDateRangeOverlayClick} >
                     <DateRangeWrapper>
                        <DateRange
                           onChange={(newValue) => setСurrentValue([newValue.selection])}
                           moveRangeOnFirstSelection={false}
                           ranges={currentValue}
                           months={2}
                           direction={isTabletScreenSize ? 'vertical' : 'horizontal'}
                           startDatePlaceholder='From'
                           endDatePlaceholder='To'
                        />

                        <ButtonsWrapper>
                           <Button variant="text" size={isTabletScreenSize ? 'small' : 'medium'} onClick={onResetClick}>Reset</Button>

                           <Box>
                              <Button variant="outlined" size={isTabletScreenSize ? 'small' : 'medium'} sx={{ mr: 1 }} onClick={onCloseClick}>Close</Button>
                              <Button variant="contained" size={isTabletScreenSize ? 'small' : 'medium'} onClick={onSelectClick}>Select date</Button>
                           </Box>
                        </ButtonsWrapper>
                     </DateRangeWrapper>
                  </DateRangeOverlay>
               </>
            }
         </DateRangeInputWrapper >
      </Box >
   )
}
