import * as propz from 'propz';
import { School } from 'models/school';
import { AGE_GROUPS } from 'consts/school';

export function getAgesGroupFormatted(event: any, school: School): string {
   const eventAgesGroupName = propz.get(school, ['ageGroupsNaming'], '');
   const eventAgesGroup = propz.get(AGE_GROUPS, [eventAgesGroupName], []);
   const { ages: ageIndexes } = event;
   const agesCount = ageIndexes.length;

   const sortedAgeIndexes = ageIndexes.sort((a: number, b: number) => a - b);
   const firstAgeIndex = sortedAgeIndexes[0];
   const lastAgeIndex = sortedAgeIndexes[agesCount - 1];

   const isAgeIndexesSerial = sortedAgeIndexes.every((ageIndex: number, index: number, array: number[]) => {
      const arrayLastIndex = array.length - 1;
      const increasedAgeIndex = ageIndex + 1;
      const decrementedAgeIndex = ageIndex - 1;

      const prevIndex = array[index - 1];
      const nextIndex = array[index + 1];

      if (index === arrayLastIndex) {
         return decrementedAgeIndex === prevIndex;
      }

      return increasedAgeIndex === nextIndex;
   });

   const getNotSerialAgesString = (ageIndexes: number[]): string => {
      let resultAgesString = '';
      let arraysOfSerialOrSingleAgesIndexes: number[][] = [];
      let firstSerialIndex = 0;

      ageIndexes.forEach((ageIndex, index, array) => {
         const increasedAgeIndex = ageIndex + 1;
         const nextAgeIndex = array[index + 1];
         const nextIndex = index + 1;

         if (increasedAgeIndex !== nextAgeIndex) {
            const serialAgesIndexes = array.slice(firstSerialIndex, nextIndex);
            arraysOfSerialOrSingleAgesIndexes.push(serialAgesIndexes);
            firstSerialIndex = nextIndex;
         }
      });

      arraysOfSerialOrSingleAgesIndexes.forEach((serialOrSingleAgesIndexes, index, array) => {
         const isSingleAgeIndex = serialOrSingleAgesIndexes.length === 1;
         const isLastArrayIteration = index === array.length - 1;

         const firstSerialAgeIndex = serialOrSingleAgesIndexes[0];
         const lastSerialAgeIndex = serialOrSingleAgesIndexes[serialOrSingleAgesIndexes.length - 1];
         const firstEventAge = eventAgesGroup[firstSerialAgeIndex];
         const lastEventAge = eventAgesGroup[lastSerialAgeIndex];

         switch (true) {
            case isLastArrayIteration && isSingleAgeIndex:
               resultAgesString += `${firstEventAge}`;
               break;

            case isLastArrayIteration && !isSingleAgeIndex:
               resultAgesString += `${firstEventAge} - ${lastEventAge}`;
               break;

            case !isLastArrayIteration && isSingleAgeIndex:
               resultAgesString += `${firstEventAge}, `;
               break;

            case !isLastArrayIteration && !isSingleAgeIndex:
               resultAgesString += `${firstEventAge} - ${lastEventAge}, `;
               break;
         }
      });

      return resultAgesString;
   };

   const firstEventAge = eventAgesGroup[firstAgeIndex];
   const lastEventAge = eventAgesGroup[lastAgeIndex];

   switch (true) {
      case agesCount === 0 || agesCount === 14: {
         return 'All ages';
      }

      case agesCount === 1: {
         return firstEventAge;
      }

      case agesCount === 2 && isAgeIndexesSerial: {
         return `${firstEventAge} - ${lastEventAge}`;
      }

      case agesCount === 2: {
         return `${firstEventAge}, ${lastEventAge}`;
      }

      case agesCount > 2 && !isAgeIndexesSerial: {
         return getNotSerialAgesString(sortedAgeIndexes);
      }

      default: {
         return `${firstEventAge} - ${lastEventAge}`;
      }
   }
}
