import React, { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, alpha, experimental_sx as sx } from '@mui/material/styles';
import { School } from 'models/school';
import { SCREEN_SIZE } from 'consts/responsive';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItemMUI from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import LaunchIconMUI from '@mui/icons-material/Launch';

interface Props {
   school: School;
   schoolUnions: School[];
}

interface AreaTitleProps {
   isTabletScreenSize: boolean;
}

const MenuItem= styled(MenuItemMUI)(
   sx({
      display: 'flex',
      justifyContent: 'space-between',
      gap: 2
   })
);

const LaunchIcon= styled(LaunchIconMUI)(
   sx({
      m: '0 !important'
   })
);

const AreaTitle = styled(Typography, {
   shouldForwardProp: (prop) => prop !== 'isTabletScreenSize'
})<AreaTitleProps>(
   ({ isTabletScreenSize }) => sx({
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: isTabletScreenSize ? '2px' : '4px',
      fontSize: isTabletScreenSize ? '14px' : '16px'
   })
);

const StyledMenu = styled((props: MenuProps) => (
   <Menu
     elevation={0}
     anchorOrigin={{
       vertical: 'bottom',
       horizontal: 'left',
     }}
     transformOrigin={{
       vertical: 'top',
       horizontal: 'left',
     }}
     {...props}
   />
 ))(({ theme }) => ({
   '& .MuiPaper-root': {
     borderRadius: 6,
     marginTop: theme.spacing(1),
     minWidth: 180,
     color:
       theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
     boxShadow:
       'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
     '& .MuiMenu-list': {
       padding: '4px 0',
     },
     '& .MuiMenuItem-root': {
       '& .MuiSvgIcon-root': {
         fontSize: 18,
         color: theme.palette.text.secondary,
         marginRight: theme.spacing(1.5),
       },
       '&:active': {
         backgroundColor: alpha(
           theme.palette.primary.main,
           theme.palette.action.selectedOpacity,
         ),
       },
     },
   },
 }));

export default function AreaLinksButton(props: Props) {
   const { school, schoolUnions } = props;
   const { name } = school;

   const isSchoolUnionsExist = schoolUnions.length > 0;

   const isTabletScreenSize = useMediaQuery(SCREEN_SIZE.TABLET);

   const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
   const isOpen = Boolean(anchorElement);

   const onSchoolUnionLinkClick = (schoolUnionDomain: string) => {
      const isSchoolUnionDomainExist = typeof schoolUnionDomain !== 'undefined' && schoolUnionDomain !== '';

      if (isSchoolUnionDomainExist) {
         const subDomains = document.location.host.split('.');
         subDomains[0] = schoolUnionDomain;
         const domain = subDomains.join('.');
   
         window.open(`//${domain}`, '_blank');
      };

      onMenuClose();
   };

   const onMenuButtonClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(event.currentTarget);
   };

   const onMenuClose = () => {
      setAnchorElement(null);
   };

   const renderMenuItems = () => {
      return schoolUnions.map(school => {
         const { domain } = school;
         const isSchoolUnionDomainExist = typeof domain !== 'undefined' && domain !== '';

         return (
            <MenuItem
               onClick={() => onSchoolUnionLinkClick(domain)}
               disableRipple
               disabled={!isSchoolUnionDomainExist}
               key={school.id}
            >
               {school.name}

               <LaunchIcon />
            </MenuItem>
         );
      });
   }
   
   return (
      <div>
         <Button
            variant="contained"
            disableElevation
            onClick={onMenuButtonClick}
            endIcon={<KeyboardArrowDownIcon />}
         >
            <AreaTitle isTabletScreenSize={isTabletScreenSize}>
               {name}
            </AreaTitle>
         </Button>

         <StyledMenu
            anchorEl={anchorElement}
            open={isSchoolUnionsExist && isOpen}
            onClose={onMenuClose}
         >
            {renderMenuItems()}
         </StyledMenu>
      </div>
   )
};
