import React from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SchoolNews } from 'models/news';

interface Props {
   news: SchoolNews;
}

interface MediaProps {
   backgroundImage: string;
}

const Wrapper = styled(Box)(
   sx({
      p: 1
   })
);

const Media = styled(Box)<MediaProps>(
   ({ backgroundImage }) => sx({
      height: '250px',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      my: 3,
      mx: -3
   })
);

export default function NewsContent(props: Props) {
   const { news } = props;
   const { body, title, picUrl } = news;
   const isPicUrlExist = picUrl !== '' && typeof picUrl !== 'undefined';

   return (
      <Wrapper>
         <Typography variant="h6">{title}</Typography>

         {isPicUrlExist && <Media backgroundImage={picUrl}></Media>}
         
         <Box dangerouslySetInnerHTML={{ __html: body }}></Box>
      </Wrapper>
   )
};
