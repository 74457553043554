import React from 'react';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { Tournament } from 'models/tournament';
import { sortByDateAsc } from 'helpers/sorting';
import Box from '@mui/material/Box';
import Document from './Document';

interface Props {
   tournament: Tournament;
};

const Wrapper = styled(Box)(
   sx({
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 2
   })
);


export default function Documents(props: Props) {
   const { tournament } = props;

   const announcements = propz.get(tournament, ['announcements'], []);
   const isAnnouncementsExist = announcements.length > 0;
   const announcementsSorted = [...announcements].sort(sortByDateAsc);

   return (
      <Wrapper>
         {isAnnouncementsExist &&
            announcementsSorted.map(item => {
               const { _id: itemKey } = item;

               return <Document item={item} key={itemKey} />;
            })}
      </Wrapper>
   )
}
