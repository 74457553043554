import axios from 'axios';
import { SORT, LIMIT_ALL, DEFAULT_SKIP } from 'consts/table';
import { EVENT_STATUS } from 'consts/event';

export function getSchoolLeagueEvents(activeSchoolId: string, leagueId: string, filter?: any) {
   let config;

   if (typeof filter !== 'undefined') {
      config = {
         params: {
            filter: {
               limit: filter.limit,
               order: filter.order,
               skip: filter.skip
            }
         }
      };
   } else {
      config = {
         params: {
            filter: {
               limit: LIMIT_ALL,
               order: SORT.ASC,
               skip: DEFAULT_SKIP
            }
         }
      };
   }

   return axios
      .get(`${window.apiBase}/public/schools/${activeSchoolId}/schoolLeagues/${leagueId}/events`, config)
      .then((response) => {
         return response.data;
      });
}

export function getSchoolLeagueResults(activeSchoolId: string, leagueId: string, where: any = {}) {
   const config = {
      params: {
         filter: {
            where: {
               status: {
                  $in: [EVENT_STATUS.FINISHED]
               },
               ...where
            },
            limit: LIMIT_ALL,
            order: SORT.ASC,
            skip: DEFAULT_SKIP
         }
      }
   };

   return axios
      .get(`${window.apiBase}/public/schools/${activeSchoolId}/schoolLeagues/${leagueId}/events`, config)
      .then((response) => {
         return response.data;
      });
}

export function getSchoolLeagueFixtures(activeSchoolId: string, leagueId: string, where: any = {}) {
   const config = {
      params: {
         filter: {
            where: {
               status: {
                  $in: [EVENT_STATUS.ACCEPTED, EVENT_STATUS.CANCELED, EVENT_STATUS.INVITES_SENT]
               },
               ...where
            },
            limit: LIMIT_ALL,
            order: SORT.ASC,
            skip: DEFAULT_SKIP
         }
      }
   };

   return axios
      .get(`${window.apiBase}/public/schools/${activeSchoolId}/schoolLeagues/${leagueId}/events`, config)
      .then((response) => {
         return response.data;
      });
}
