import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, experimental_sx as sx } from '@mui/system';
import { SCREEN_SIZE } from 'consts/responsive';
import Typography from '@mui/material/Typography';

interface Props {
   text: string;
}

const Title = styled(Typography)(
   sx({
      mb: {
         xs: 1,
         sm: 2
      },
      textTransform: 'uppercase',
      letterSpacing: '5px',
      fontWeight: 'bold',
      color: '#147ce2',
      background: 'linear-gradient(#a0c3e6, #147ce2)',
      'WebkitBackgroundClip': 'text',
      'WebkitTextFillColor': 'transparent'
   })
);

export default function PageTitle(props: Props) {
   const { text } = props;

   const isTabletScreenSize = useMediaQuery(SCREEN_SIZE.TABLET);

   return (
      <Title variant={isTabletScreenSize ? 'h5' : 'h4'}>{text}</Title>
   )
}
