import React, { useState } from 'react';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { Tournament } from 'models/tournament';
import { School } from 'models/school';
import { TOURNAMENT_DETAILS_TABS_ARRAY, TOURNAMENT_DETAILS_TABS } from 'consts/tournament';
import Box from '@mui/material/Box';
import TabsMUI from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Details from './Details';
import GoverningBodies from './GoverningBodies';
import Documents from './Documents';

interface Props {
   tournament: Tournament;
   school: School;
}

const Tabs = styled(TabsMUI)(
   sx({
      background: '#1976d230',
      '& .Mui-selected': {
         background: '#1976d229'
      }
   })
);

export default function TournamentDetails(props: Props) {
   const [tabIndex, setTabIndex] = useState<number>(0);

   const { tournament, school } = props;

   const onTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
   };

   const renderTabs = () => {
      return TOURNAMENT_DETAILS_TABS_ARRAY.map((tab, index) => (
         <Tab
            key={`vertical-tab-${index}`}
            sx={{ alignItems: 'center' }}
            label={tab}
            id={`vertical-tab-${index}`}
            aria-controls={`vertical-tabpanel-${index}`}
         />
      )
      )
   };

   const renderTabContent = () => {
      const currentTab = TOURNAMENT_DETAILS_TABS_ARRAY[tabIndex];

      let tabContent;

      switch (true) {
         case currentTab === TOURNAMENT_DETAILS_TABS.DETAILS:
            tabContent = <Details tournament={tournament} school={school} />

            break;

         case currentTab === TOURNAMENT_DETAILS_TABS.GOVERNINIG_BODIES:
            tabContent = <GoverningBodies tournament={tournament} school={school} />

            break;

         case currentTab === TOURNAMENT_DETAILS_TABS.DOCUMENTS:
            tabContent = <Documents tournament={tournament} />

            break;
      }

      return (
         <Box
            role='tabpanel'
            id={`vertical-tabpanel-${tabIndex}`}
            aria-labelledby={`vertical-tab-${tabIndex}`}
         >
            {tabContent}
         </Box>
      );
   }

   return (
      <Box>
         <Tabs
            value={tabIndex}
            onChange={onTabChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
         >
            {renderTabs()}
         </Tabs>

         {renderTabContent()}
      </Box>
   )
}
