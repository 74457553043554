import { SchoolEvent } from 'models/event';
import * as propz from 'propz';

export function sortByNameAsc(item1: any, item2: any) {
   switch (true) {
      case item1.name > item2.name:
         return 1;
      case item1.name < item2.name:
         return -1;
      default:
         return 0;
   }
};

export function sortByDateAsc(item1: any, item2: any) {
   switch (true) {
      case new Date(item1.date) < new Date(item2.date):
         return 1;
      case new Date(item1.date) > new Date(item2.date):
         return -1;
      default:
         return 0;
   }
};

export function sortByStartTimeDesc(item1: any, item2: any) {
   switch (true) {
      case new Date(item1.startTime) < new Date(item2.startTime):
         return -1;
      case new Date(item1.startTime) > new Date(item2.startTime):
         return 1;
      default:
         return 0;
   }
};

export function sortSingleAgeEventsByAge(event1: SchoolEvent, event2: SchoolEvent) {
   switch (true) {
      case event1.ages[0] < event2.ages[0]:
         return -1;
      case event1.ages[0] > event2.ages[0]:
         return 1;
      default:
         return 0;
   }
};
