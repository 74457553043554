import { Point } from 'models/venue'

export const EVENT_VENUE: any = {
   HOME: 'HOME',
   AWAY: 'AWAY',
   CUSTOM: 'CUSTOM',
   TBD: 'TBD'
};

export const VENUE_SERVER_TO_CLIENT_MAPPING: any = {
   HOME: 'Home',
   AWAY: 'Away',
   CUSTOM: 'Away',
   TBD: 'TBD'
};

export const POINT = 'Point';
export const DEFAULT_LATITUDE = 50.832949;
export const DEFAULT_LONGITUDE = -0.246722;
export const DEFAULT_COORDINATES: [number, number] = [DEFAULT_LONGITUDE, DEFAULT_LATITUDE];

export const DEFAULT_POINT: Point = {
   coordinates: DEFAULT_COORDINATES,
   lat: DEFAULT_LATITUDE,
   lng: DEFAULT_LONGITUDE,
   type: POINT
};
