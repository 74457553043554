import React from 'react';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { SchoolLeague } from 'models/league';
import { getPublicSiteLink } from 'helpers/link';
import RouterLink from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LeaguesIcon from '@mui/icons-material/EmojiEvents';

interface Props {
   league: SchoolLeague;
}

const LeagueCardWrapper = styled(RouterLink)(
   sx({
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      p: 2,
      border: 1,
      borderColor: '#d7d7d7',
      borderRadius: '4px',
      overflow: 'hidden',
      cursor: 'pointer',
      transition: '0.2s ease-in-out',
      
      '&:hover': {
         borderColor: '#1976d2',
      },

      '& img': {
         // width: '100%',
         height: '110px',
         borderRadius: '6px'
      }
   })
);

const LeagueType = styled(Typography)(
   sx({
      mb: 2,
      textAlign: 'center',
      color: '#1976D2'
   })
);

const LeagueName = styled(Typography)(
   sx({
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '16px',
      textAlign: 'center',
      height: '50px'
   })
);

const LeagueImageWrapper = styled(Box)(
   sx({
      mt: 3,
      height: '100px',
      display: 'flex',
      justifyContent: 'center'
   })
);

const LeagueImage = styled(Box)(
   sx({
      width: '100%',
      height: '100%',
      borderRadius: '6px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
   })
);

const LeagueDefaultIcon = styled(LeaguesIcon)(
   sx({
      color: '#ccc',
      height: '100%',
      fontSize: '60px'
   })
);

export default function LeagueCard(props: Props) {
   const { league } = props;

   const name = propz.get(league, ['name'], '');
   const aggregationType = propz.get(league, ['aggregationType'], '');
   const photos = propz.get(league, ['photos'], []);
   const photosFirstItem = photos[0];
   const photoUrl = propz.get(photosFirstItem, ['picUrl'], '');
   const isPhotoUrlExist = photoUrl !== '';
   const domain = propz.get(league, ['domain'], '');
   const leagueSiteLink = getPublicSiteLink(domain);

   return (
      <LeagueCardWrapper
         target="_blank"
         href={leagueSiteLink}
         underline='none'
         color='black'
      >
         <LeagueType>{aggregationType}</LeagueType>
         <LeagueName>{name}</LeagueName>

         <LeagueImageWrapper>
            {isPhotoUrlExist
               ? <LeagueImage sx={{ backgroundImage: `url(${photoUrl})` }} />
               : <LeagueDefaultIcon />
            }
         </LeagueImageWrapper>
      </LeagueCardWrapper>
   );
}
