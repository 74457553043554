import SchoolHome from 'views/School/Home';
import LeagueHome from 'views/SchoolLeague/Home';
import SchoolChallengeHome from 'views/SchoolChallenge/Home';
import SchoolUnionHome from 'views/SchoolUnion/Home';
import TournamentHome from 'views/Tournament/Home';
import SchoolCalendar from 'views/School/Calendar';
import SchoolFixtures from 'views/School/Fixtures';
import OngoingChallenges from 'views/SchoolChallenge/OngoingChallenges';
import SchoolUnionUpcomingTournaments from 'views/SchoolUnion/UpcomingTournaments';
import LeagueFixtures from 'views/SchoolLeague/Fixtures';
import SchoolResults from 'views/School/Results';
import SchoolUnionTournamentResults from 'views/SchoolUnion/TournamentResults';
import LeagueResults from 'views/SchoolLeague/Results';
import TournamentEventsSchedule from 'views/Tournament/EventsSchedule';
import TournamentResults from 'views/Tournament/Results';
import WinnersTable from 'views/Tournament/WinnersTable';
import FootballTournamentHome from 'views/FootballTournament/Home';
import FootballTournamentEventsSchedule from 'views/FootballTournament/EventsSchedule';
import FootballTournamentResults from 'views/FootballTournament/Results';
import PastChallenges from 'views/SchoolChallenge/PastChallenges';
import News from 'views/School/News';
import Tournaments from 'views/School/Tournaments';
import ClubEvents from 'views/School/ClubEvents';
import Leagues from 'views/School/Leagues';
import Challenges from 'views/School/Challenges';
import SchoolUnionFiles from 'views/SchoolUnion/Files';
import SchoolEventView from 'views/School/SchoolEventView';
import SchoolTournamentView from 'views/School/SchoolTournamentView';
import NotAvailableSchool from 'views/NotAvailableSchool';
import PageNotFound from 'views/School/PageNotFound';

interface Route {
   path: string;
   component: any;
   index: boolean;
}

export const NOT_AVAILABLE_SCHOOL_ROUTE = {
   path: '*',
   index: true,
   component: NotAvailableSchool
};

export const SCHOOL_ROUTES: Route[] = [
   {
      path: '/',
      index: true,
      component: SchoolHome
   },
   {
      path: '/calendar',
      index: false,
      component: SchoolCalendar
   },
   {
      path: '/event/:id',
      index: false,
      component: SchoolEventView
   },
   {
      path: '/tournament/:id',
      index: false,
      component: SchoolTournamentView
   },
   {
      path: '/fixtures',
      index: false,
      component: SchoolFixtures
   },
   {
      path: '/tournaments',
      index: false,
      component: Tournaments
   },
   {
      path: '/clubEvents',
      index: false,
      component: ClubEvents
   },
   {
      path: '/leagues',
      index: false,
      component: Leagues
   },
   {
      path: '/challenges',
      index: false,
      component: Challenges
   },
   {
      path: '/results',
      index: false,
      component: SchoolResults
   },
   {
      path: '/news',
      index: false,
      component: News
   },
   {
      path: '*',
      index: false,
      component: PageNotFound
   },
];

export const SCHOOL_UNION_ROUTES: Route[] = [
   {
      path: '/',
      index: true,
      component: SchoolUnionHome
   },
   {
      path: '/upcomingTournaments',
      index: false,
      component: SchoolUnionUpcomingTournaments
   },
   {
      path: '/tournamentResults',
      index: false,
      component: SchoolUnionTournamentResults
   },
   {
      path: '/files',
      index: false,
      component: SchoolUnionFiles
   },
   {
      path: '/event/:id',
      index: false,
      component: SchoolEventView
   },
   {
      path: '/tournament/:id',
      index: false,
      component: SchoolTournamentView
   },
   {
      path: '*',
      index: false,
      component: PageNotFound
   },
];

export const SCHOOL_LEAGUE_ROUTES: Route[] = [
   {
      path: '/',
      index: true,
      component: LeagueHome
   },
   {
      path: '/fixtures',
      index: false,
      component: LeagueFixtures
   },
   {
      path: '/results',
      index: false,
      component: LeagueResults
   },
   {
      path: '/event/:id',
      index: false,
      component: SchoolEventView
   },
   {
      path: '*',
      index: false,
      component: PageNotFound
   },
];

export const SCHOOL_CHALLENGE_ROUTES: Route[] = [
   {
      path: '/',
      index: true,
      component: SchoolChallengeHome
   },
   {
      path: '/ongoing',
      index: false,
      component: OngoingChallenges
   },
   {
      path: '/past',
      index: false,
      component: PastChallenges
   },
   {
      path: '/event/:id',
      index: false,
      component: SchoolEventView
   },
   {
      path: '*',
      index: false,
      component: PageNotFound
   },
];

export const TOURNAMENT_ROUTES: Route[] = [
   {
      path: '/',
      index: true,
      component: TournamentHome
   },
   {
      path: '/schedule',
      index: false,
      component: TournamentEventsSchedule
   },
   {
      path: '/winners',
      index: false,
      component: WinnersTable
   },
   {
      path: '/results',
      index: false,
      component: TournamentResults
   },
   {
      path: '/event/:id',
      index: false,
      component: SchoolEventView
   },
];

export const FOOTBALL_TOURNAMENT_ROUTES: Route[] = [
   {
      path: '/',
      index: true,
      component: FootballTournamentHome
   },
   {
      path: '/schedule',
      index: false,
      component: FootballTournamentEventsSchedule
   },
   {
      path: '/results',
      index: false,
      component: FootballTournamentResults
   },
   {
      path: '/event/:id',
      index: false,
      component: SchoolEventView
   },
];
