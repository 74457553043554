import React from 'react';
import { GridOverlay } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import EmptyDataIcon from 'components/EmptyDataIcon';
import Typography from '@mui/material/Typography';

const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
   flexDirection: 'column'
}));

const EmptyDataText = styled(Typography)(({ theme }) => ({
   mt: 1,
   fontWeight: 'bold',
   textAlign: 'center',
   color: '#989898',
   textTransform: 'uppercase'
}));

export default function NoRowsOverlay() {
   return (
      <StyledGridOverlay>
         <EmptyDataIcon />
         <EmptyDataText>There are no events for selected day</EmptyDataText>
      </StyledGridOverlay>
   );
}
