import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { Tournament } from 'models/tournament';
import { SchoolEvent } from 'models/event';
import { ACTIVITIES_BLOCK_TYPE } from 'consts/common';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EventCard from 'components/EventCard';
import PageTitle from 'components/PageTitle';
import TournamentCard from 'components/TournamentCard';
import EmptyDataIcon from 'components/EmptyDataIcon';

interface Props {
   school: School;
   title: string;
   items: (SchoolEvent | Tournament)[];
   showMoreLink: string;
   onItemClick: (data: SchoolEvent | Tournament) => void;
   type?: string;
}

const ActivitiesCardsWrapper = styled(Box)(
   sx({
      display: 'flex',
      flexDirection: {
         xs: 'column',
         md: 'row'
      },
      justifyContent: 'space-around',
      alignItems: {
         xs: 'stretch',
         md: 'baseline'
      },
      gap: '20px'
   })
);

const EmptyActivities = styled(Box)(({ theme }) =>
   sx({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      opacity: '0.4',
      mt: 3
   })
);

const ActivitiesBlockContentWrapper = styled(Box)(
   sx({
      p: {
         xs: 1,
         sm: 2,
         md: 3
      },
      mb: 5,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '6px',
      boxShadow: '0px 2px 7px 2px rgba(0,0,0,0.2)'
   })
);

const EmptyActivitiesText = styled(Typography)(
   sx({
      fontWeight: 'bold',
      textAlign: 'center'
   })
);

export default function ActivitiesBlock(props: Props) {
   const { title, type, items, showMoreLink, onItemClick, school } = props;
   const { id: schoolId } = school;

   const navigate = useNavigate();

   const isItemsExist: boolean = items.length > 0;
   let emptyActivitiesText: string = '';

   switch (true) {
      case type === ACTIVITIES_BLOCK_TYPE.FIXTURES:
         emptyActivitiesText = 'There are no upcoming fixtures to display.';
         break;

      case type === ACTIVITIES_BLOCK_TYPE.RESULTS:
         emptyActivitiesText = 'There are no recent results to display.';
         break;

      case type === ACTIVITIES_BLOCK_TYPE.TOURNAMENTS:
         emptyActivitiesText = 'There are no upcoming tournaments to display.';
         break;

      case type === ACTIVITIES_BLOCK_TYPE.TOURNAMENT_EVENTS:
         emptyActivitiesText = 'There are no events to display.';
         break;

      case type === ACTIVITIES_BLOCK_TYPE.TOURNAMENT_RESULTS:
         emptyActivitiesText = 'There are no results to display.';
         break;

      case type === ACTIVITIES_BLOCK_TYPE.ONGOING_CHALLENGES:
         emptyActivitiesText = 'There are no ongoing challenges to display.';
         break;

      case type === ACTIVITIES_BLOCK_TYPE.PAST_CHALLENGES:
         emptyActivitiesText = 'There are no past challenges to display.';
         break;
   }

   const onShowMoreClick = (route: string): void => {
      navigate(route);
   };

   return (
      <Box>
         <PageTitle text={title} />

         <ActivitiesBlockContentWrapper>
            <ActivitiesCardsWrapper>
               {isItemsExist ? (
                  items.map((item: SchoolEvent | Tournament) => {
                     const { id } = item;
                     const isTournamentEvent = type === ACTIVITIES_BLOCK_TYPE.TOURNAMENTS;

                     return isTournamentEvent ? (
                        <TournamentCard
                           key={id}
                           school={school}
                           tournament={item as Tournament}
                           onTournamentInfoClick={onItemClick}
                        />
                     ) : (
                        <EventCard
                           key={id}
                           type={type}
                           event={item as SchoolEvent}
                           schoolId={schoolId}
                           onEventClick={onItemClick}
                        />
                     );
                  })
               ) : (
                  <EmptyActivities>
                     <EmptyDataIcon />

                     <EmptyActivitiesText>{emptyActivitiesText}</EmptyActivitiesText>
                  </EmptyActivities>
               )}
            </ActivitiesCardsWrapper>

            {isItemsExist && (
               <Button
                  size="small"
                  sx={{ mt: 4 }}
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => onShowMoreClick(showMoreLink)}
               >
                  Show more
               </Button>
            )}
         </ActivitiesBlockContentWrapper>
      </Box>
   );
}
