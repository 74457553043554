import React from 'react';
import { Outlet } from 'react-router-dom';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { Tournament } from 'models/tournament';
import { SchoolLeague } from 'models/league';
import { LAYOUT_TYPE } from 'consts/layout';
import { StorageHelper } from 'helpers/storage';
import Box from '@mui/material/Box';
import Drawer from 'components/Drawer';
import Footer from 'components/Footer';
import CookieConsent from 'components/CookieConsent';

interface Props {
   school: School;
   league?: SchoolLeague;
   challenge?: SchoolLeague;
   tournament?: Tournament;
   type?: string;
}

const AppWrapper = styled(Box)({
   display: 'flex',
   height: '100%'
});

const Content = styled(Box)(
   sx({
      marginTop: {
         xs: '56px',
         sm: '62px'
      },
      display: 'flex',
      flexDirection: 'column',
      flex: 'auto',
      minHeight: '99%'
   })
);

export default function Layout(props: Props) {
   const { school, league, challenge, tournament, type = LAYOUT_TYPE.SCHOOL } = props;
   const leagueName = propz.get(league, ['name'], '');
   const challengeName = propz.get(challenge, ['name'], '');
   const tournamentName = propz.get(tournament, ['name'], '');

   const isLeague = typeof league !== 'undefined';
   const isChallenge = typeof challenge !== 'undefined';
   const isTournament = typeof tournamentName !== 'undefined';

   let title;

   switch (true) {
      case isLeague:
         title = leagueName;
         break;

      case isChallenge:
         title = challengeName;
         break;

      case isTournament:
         title = tournamentName;
         break;
   
      default:
         break;
   }

   const isCookiesAccepted = StorageHelper.LocalStorage.get('isCookiesAccepted');

   return (
      <AppWrapper>
         <Drawer school={school} type={type} titleText={title} />

         <Content id='main-content' sx={{overflowY: 'auto'}}>
            <Box sx={{ flex: '1 0 auto' }}>
               <Outlet />
            </Box>

            <Box sx={{ flex: '0 0 auto' }}>
               <Footer />
            </Box>
         </Content>

         {!isCookiesAccepted && <CookieConsent />}
      </AppWrapper>
   )
}
