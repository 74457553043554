import React, { useCallback } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import { Promise } from 'bluebird';
import { parse } from 'query-string';
import * as propz from 'propz';
import Moment from 'moment';
import { School } from 'models/school';
import { SchoolNews } from 'models/news';
import { FilterField } from 'models/filter';
import { FILTER_TYPE } from 'consts/table';
import { DEFAULT_LIMIT } from 'consts/table';
import { getNews, getNewsCount } from 'services/news';
import { getFilters, checkIsFilterExist, getSearchFilter } from 'helpers/filters';
import { getFilterForSchoolNews } from 'helpers/filters';
import { getServerFieldSectionWhere } from 'helpers/table';
import { default as CardMUI } from '@mui/material/Card';
import { default as NewsIconMUI } from '@mui/icons-material/Feed';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Filters from 'components/Filters';
import Loader from 'components/loader';
import ScrollToTop from 'components/ScrollToTop';
import NewsContent from './NewsContent';
import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';
import EmptyDataIcon from 'components/EmptyDataIcon';

const NEWS_MEDIA_HEIGHT = 190;
const MAX_TITLE_LENGHT = 15;

interface Props {
   school: School;
}

const FILTER_FIELDS: FilterField[] = [
   {
      text: 'Title',
      field: 'title',
      type: FILTER_TYPE.TEXT
   },
   {
      text: 'Date',
      field: 'date',
      type: FILTER_TYPE.DATE_INTERVAL
   }
];

const NewsMedia = styled(Box)(
   sx({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: NEWS_MEDIA_HEIGHT,
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
   })
);

const NewsIcon = styled(NewsIconMUI)(
   sx({
      width: '50px',
      height: '50px',
      opacity: '0.4'
   })
);

const Card = styled(CardMUI)(
   sx({
      border: '1px solid rgba(0, 0, 0, 0.12)',
      boxShadow: 'none',
      transition: '.2s ease-in-out',
      cursor: 'pointer',

      '&:hover': {
         borderColor: '#797979',
      }
   })
);

const EmptyItems = styled(Box)(
   sx({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      opacity: '0.4',
      mt: 5
   })
);

const EmptyItemsText = styled(Typography)(
   sx({
      fontWeight: 'bold',
      textAlign: 'center'
   })
);

export default function News(props: Props) {
   const location = useLocation();
   const [searchParams, setSearchParams] = useSearchParams();

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [isFetching, setIsFetching] = useState<boolean>(false);
   const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
   const [isShowFilters, setIsShowFilters] = useState<boolean>(false);
   const [items, setItems] = useState<SchoolNews[]>([]);
   const [allItemsCount, setAllItemsCount] = useState<number>(0);
   const [selectedItem, setSelectedItem] = useState<SchoolNews>();
   const [filters, setFilters] = useState({});
   const [currentPage, setCurrentPage] = useState<number>(1);

   const itemsCount = items.length;
   const isSelectedItemExist = typeof selectedItem !== 'undefined';

   const { school } = props;
   const { id: schoolId } = school;

   const filterButtonIcon = isShowFilters ? <FilterAltOffIcon /> : <FilterAltIcon />;
   const filterButtonText = isShowFilters ? 'Hide Filters' : 'Show Filters';

   const isNewsExist = items.length > 0;

   const options = {};

   const onScroll = useCallback((event: Event) => {
      const { target } = event;

      const scrollHeight = propz.get(target, ['scrollHeight']);
      const scrollTop = propz.get(target, ['scrollTop']);
      const windowInnerHeight = window.innerHeight;

      const isBottomOfPage = scrollHeight - (scrollTop + windowInnerHeight) < 100;

      if (isBottomOfPage && itemsCount < allItemsCount) {
         setIsFetching(true);
      };
   }, [itemsCount, allItemsCount]);

   useEffect(() => {
      setIsLoading(true);

      const search = parse(location.search);
      const filters = getFilters(FILTER_FIELDS, search);

      const where = getServerFieldSectionWhere(filters);
      
      const promises = [
         getNews(schoolId, getFilterForSchoolNews(where)),
         getNewsCount(schoolId, getFilterForSchoolNews(where))
      ];

      Promise.all(promises).then(([items, itemsCount]) => {
         setItems(items);
         setAllItemsCount(itemsCount.count);
         setFilters(filters);
         setIsLoading(false);
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchParams]);

   useEffect(() => {
      if (isFetching) {
         const where = getServerFieldSectionWhere(filters);

         const queryFilter = {
            ...getFilterForSchoolNews(where),
            skip: currentPage * DEFAULT_LIMIT
         };

         getNews(schoolId, queryFilter)
            .then((events: SchoolNews[]) => {
               setItems([...items, ...events]);
               setCurrentPage(prevState => prevState + 1);
            })
            .finally(() => setIsFetching(false));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isFetching]);

   useEffect(() => {
      const content = document.querySelector('#main-content');
      const isContentExist = content !== null;
      
      isContentExist && content.addEventListener('scroll', onScroll);

      return function () {
         isContentExist && content.removeEventListener('scroll', onScroll);
      }
   }, [onScroll]);

   const renderNews = () => {
      return items.map((item: SchoolNews) => {
         const { date, title, picUrl } = item;
         const isTitleToSplice = title.length > MAX_TITLE_LENGHT;
         const titleFormatted = isTitleToSplice
            ? title.slice(0, MAX_TITLE_LENGHT).trim() + ' ...'
            : title;
         const isPicUrlExist = picUrl !== '' && typeof picUrl !== 'undefined';
         const dateFormatted = Moment(date).format('DD-MM-YYYY, h:mm');

         return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
               <Card onClick={() => onModalOpen(item)}>
                  <CardHeader
                     title={titleFormatted}
                     subheader={dateFormatted}
                  />
                  {isPicUrlExist
                     ? <CardMedia
                        component="img"
                        height={NEWS_MEDIA_HEIGHT}
                        image={picUrl}
                        alt={title}
                     />
                     : <NewsMedia>
                        <NewsIcon />
                     </NewsMedia>
                  }
               </Card>
            </Grid>
         );
      });
   };

   const onModalOpen = (item: SchoolNews) => {
      setSelectedItem(item)
      setIsModalOpen(true);
   };

   const onModalClose = () => {
      setSelectedItem(undefined);
      setIsModalOpen(false);
   };

   const onFilterChange = (event: any, filterField: string): void => {
      const filterValue = propz.get(event, ['target', 'value']);

      const nextFilters = {
         ...filters,
         [filterField]: filterValue
      };

      setCurrentPage(1);
      setFilters(nextFilters);
   };

   const onApplyFilterClick = (): void => {
      let search = [];

      const isFilterExist = checkIsFilterExist(filters);

      if (isFilterExist) {
         search.push(getSearchFilter(filters));
      }

      const searchParams: any = parse(search.join('&'));

      setSearchParams(searchParams);
   };

   const onClearFilterClick = (): void => {
      setSearchParams('');

      const search = parse(location.search);
      const filters = getFilters(FILTER_FIELDS, search);

      setFilters(filters);
   };

   if (isLoading) {
      return <Loader />;
   }

   return (
      <PageWrapper>
         <div id="back-to-top-anchor"></div>
         <PageTitle text="News" />

         <Button
            variant="outlined"
            startIcon={filterButtonIcon}
            sx={{ my: 2 }}
            onClick={() => setIsShowFilters(!isShowFilters)}
         >
            {filterButtonText}
         </Button>

         {isShowFilters &&
            <Box>
               <Filters
                  fields={FILTER_FIELDS}
                  filters={filters}
                  options={options}
                  onFilterChange={onFilterChange}
                  onApplyFilterClick={onApplyFilterClick}
                  onClearFilterClick={onClearFilterClick}
               />

               <Divider sx={{ my: 3 }} />
            </Box>
         }

         {isNewsExist
            ? <Grid container spacing={3}>
               {renderNews()}
            </Grid>
            : <EmptyItems>
               <EmptyDataIcon />

               <EmptyItemsText>
                  There are no news to display.
               </EmptyItemsText>
            </EmptyItems>
         }

         {(isFetching && itemsCount < allItemsCount) &&
            <Box sx={{ mt: 3 }}>
               <Loader />
            </Box>
         }

         <Dialog
            open={isModalOpen}
            onClose={onModalClose}
            scroll='paper'
            fullWidth={true}
            maxWidth='sm'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
         >
            <DialogTitle id="scroll-dialog-title">News</DialogTitle>

            <DialogContent dividers={true} sx={{ p: 2 }}>
               {isSelectedItemExist &&
                  <NewsContent news={selectedItem} />
               }
            </DialogContent>

            <DialogActions>
               <Button onClick={onModalClose}>Close</Button>
            </DialogActions>
         </Dialog>

         <ScrollToTop {...props}>
            <Fab color="primary" size="small" aria-label="scroll back to top">
               <KeyboardArrowUpIcon />
            </Fab>
         </ScrollToTop>
      </PageWrapper>
   )
}
