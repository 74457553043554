import React, { useState, useEffect, useRef } from 'react';
import Lazy from 'lazy.js';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { Tournament } from 'models/tournament';
import { FilterField } from 'models/filter';
import { FILTER_TYPE } from 'consts/table';
import { SCHOOL_YEAR_MONTHS } from 'consts/calendar';
import { SORT } from 'consts/table';
import { getAllSchoolUnionTournaments } from 'services/schoolUnion/tournaments';
import { getFilters } from 'helpers/filters';
import { getCurrentSeasonStartYear, findClosestNumber } from 'helpers/common';
import {
   getSelectOptionForTournamentAvailableSports,
   getSelectOptionForTournamentSeasons,
   getSelectOptionForTournamentsAges,
   getSelectOptionForSchoolUnion
} from 'helpers/select';
import TabsMUI from '@mui/material/Tabs';
import TabMUI from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Divider from '@mui/material/Divider';
import TournamentCalendarItem from './TournamentCalendarItem';
import Filters from 'components/Filters';
import PageTitle from 'components/PageTitle';
import Typography from '@mui/material/Typography';
import Loader from 'components/loader';

interface Props {
   school: School;
   subUnions: School[];
   onTournamentInfoClick: (tournament: Tournament) => void;
}

const FILTER_FIELDS: FilterField[] = [
   {
      text: 'Areas',
      field: 'areas',
      type: FILTER_TYPE.SELECT
   },
   {
      text: 'Sports',
      field: 'sport',
      type: FILTER_TYPE.SELECT
   },
   {
      text: 'Ages',
      field: 'ages',
      type: FILTER_TYPE.SELECT
   },
   {
      text: 'Season',
      field: 'tournamentSeasons',
      type: FILTER_TYPE.SELECT
   },
];

const Wrapper = styled(Box)(
   sx({
      mb: 3
   })
);

const CalendarWrapper = styled(Box)(
   sx({
      border: '1px solid #ccc',
      overflow: 'hidden',
      borderRadius: '4px',
      boxShadow: '0px 2px 7px 2px rgba(0,0,0,0.2)'
   })
);

const TournamentsWrapper = styled(Box)(
   sx({
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      gap: '16px',
      height: '140px',
      overflowY: 'auto',
      p: 2,
      pb: '355px'
   })
);

const EmptyItems = styled(Box)(
   sx({
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
   })
);

const EmptyItemsText = styled(Typography)(
   sx({
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '24px',
      color: '#969696',
      letterSpacing: '2px'
   })
);

const Tabs = styled(TabsMUI)(
   sx({
      '& .MuiTabs-scroller': {
         borderRight: '1px solid #ccc',
         borderLeft: '1px solid #ccc',
      },

      '& .MuiTabScrollButton-root': {
         borderBottom: '2px solid #dcdcdc',
         opacity: '1',

         '&.Mui-disabled': {
            opacity: '1',
            backgroundColor: '#0000001a'
         }
      }
   })
);

const Tab = styled(TabMUI)(
   sx({
      borderBottom: '2px solid #ddd',
      flex: '1 1 auto',
      color: '#000',

      '&.MuiTabScrollButton-root': {
         borderBottom: '2px solid #ddd',

      },

      '& .Mui-disabled': {
         opacity: 1
      },

      '&.Mui-selected': {
         background: '#1976d224'
      }
   })
);

export default function TournamentCalendar(props: Props) {
   const { school, subUnions, onTournamentInfoClick } = props;
   const { id: schoolId } = school;
   const { ageGroupsNaming } = school;

   const currentSeasonStartYear = getCurrentSeasonStartYear();
   const currentMonthIndex = new Date().getMonth();

   const listRef = useRef<any>(null);

   const [tournaments, setTournaments] = useState<Tournament[]>([]);
   const [tournamentsFiltered, setTournamentsFiltered] = useState<Tournament[]>([]);
   const [schoolIdToSearch, setSchoolIdToSearch] = useState<string>(schoolId);
   const [schoolYearMonths, setSchoolYearMonths] = useState(SCHOOL_YEAR_MONTHS);
   const [activeMonth, setActiveMonth] = useState(0);
   const [filters, setFilters] = useState({});
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [isShowFilters, setIsShowFilters] = useState<boolean>(false);

   const isTournamentsExist = tournamentsFiltered.length > 0;

   const tournamentSeasonOptions = isTournamentsExist
      ? getSelectOptionForTournamentSeasons(tournaments)
      : [
         {
            value: currentSeasonStartYear,
            text: `${currentSeasonStartYear}/${currentSeasonStartYear + 1}`
         },
         ...getSelectOptionForTournamentSeasons(tournaments)
      ]

   const options = {
      areas: getSelectOptionForSchoolUnion(school, subUnions),
      sport: getSelectOptionForTournamentAvailableSports(tournaments),
      ages: getSelectOptionForTournamentsAges(tournaments, ageGroupsNaming),
      tournamentSeasons: tournamentSeasonOptions,
   };

   const filterButtonIcon = isShowFilters ? <FilterAltOffIcon /> : <FilterAltIcon />;
   const filterButtonText = isShowFilters ? 'Hide Filters' : 'Show Filters';

   const tournamentsFilteredMonthIndexes = Lazy(tournamentsFiltered)
      .map(item => new Date(item.startTime).getMonth())
      .uniq()
      .sort()
      .toArray();

   const getTournamentsFiltered = (tournaments: Tournament[], season: number) => {
      return tournaments
         .filter(item => {
            const { startTime } = item;
            const currentTournamentStartTimeDate = new Date(startTime);

            const seasonStart = new Date(season, 8, 1);
            const seasonEnd = new Date(season + 1, 8, 0);

            return currentTournamentStartTimeDate >= seasonStart && currentTournamentStartTimeDate <= seasonEnd;
         })
   };

   useEffect(() => {
      const filters = getFilters(FILTER_FIELDS, {});
      const filtersDefault = {
         ...filters,
         tournamentSeasons: currentSeasonStartYear,
         areas: schoolId
      }

      setFilters(filtersDefault);

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      setIsLoading(true);

      const allSchoolIds = [school, ...subUnions].map(item => item.id);
      const isAllSchoolIds = schoolIdToSearch === 'ALL';

      const schoolIds = isAllSchoolIds ? allSchoolIds : [schoolIdToSearch];

      getAllSchoolUnionTournaments(schoolIds)
         .then((tournaments) => {
            const tournamentsFiltered = getTournamentsFiltered(tournaments, currentSeasonStartYear);

            setTournaments(tournaments);
            setTournamentsFiltered(tournamentsFiltered);
            setIsLoading(false);
         });

      return () => {
         setTournaments([]);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [schoolIdToSearch]);

   useEffect(() => {
      const schoolYearMonthsUpdated = SCHOOL_YEAR_MONTHS.map(item => {
         const isDisabled = !tournamentsFilteredMonthIndexes.includes(item.index);

         return {
            ...item,
            isDisabled
         }
      });

      const filteredTournamentsStartTimeMonths = Lazy(tournamentsFiltered)
         .map(item => {
            const startTime = propz.get(item, ['startTime'], '');

            return new Date(startTime).getMonth();
         })
         .uniq()
         .toArray();

      const closestActiveMonthIndex = findClosestNumber(currentMonthIndex, filteredTournamentsStartTimeMonths);
      
      setTimeout(() => scrollToMonth(closestActiveMonthIndex), 1000);
      
      setActiveMonth(closestActiveMonthIndex);
      onScroll();
      setSchoolYearMonths(schoolYearMonthsUpdated);

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [tournamentsFiltered]);

   useEffect(() => {
      listRef.current.addEventListener('scroll', onScroll, { passive: true });

      return () => window.removeEventListener('scroll', onScroll);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

   const onFilterChange = (event: React.FormEvent<HTMLSelectElement>, filterField: string): void => {
      const filterValue = propz.get(event, ['target', 'value']);

      const nextFilters = {
         ...filters,
         [filterField]: filterValue
      };

      if (filterField === 'areas') {
         setSchoolIdToSearch(filterValue);
      }

      const tournamentsFiltered = tournaments
         .reduce((result: Tournament[], tournament) => {
            const { ages, sportId, startTime } = tournament;

            const filterActiveFields = Object.entries(nextFilters)
               .filter(([field, value]: any) => value !== '' && value.length !== 0);

            const isAddToFilter: boolean = filterActiveFields.every(([field, value]: any) => {
               switch (true) {
                  case field === 'sport':
                     return value === sportId;
               
                  case field === 'tournamentSeasons':
                     const valueFormatted = Number(value);
                     
                     const currentTournamentStartTimeDate = new Date(startTime);
                     const seasonStart = new Date(valueFormatted, 8, 1);
                     const seasonEnd = new Date(valueFormatted + 1, 8, 0);
            
                     return currentTournamentStartTimeDate >= seasonStart
                        && currentTournamentStartTimeDate <= seasonEnd;
      
                  case field === 'ages':
                     return ages.includes(value);
      
                  default:
                     return true;
               };
            });

            return isAddToFilter
               ? [...result, tournament]
               : result;
         }, []);

      setTournamentsFiltered(tournamentsFiltered);
      setFilters(nextFilters);
   };

   const onClearFilterClick = (): void => {
      const filters = getFilters(FILTER_FIELDS, {});
      const filtersDefault = {
         ...filters,
         tournamentSeasons: currentSeasonStartYear,
         areas: schoolId
      }

      const tournamentsFiltered = getTournamentsFiltered(tournaments, currentSeasonStartYear);

      setSchoolIdToSearch(schoolId);
      setTournamentsFiltered(tournamentsFiltered);
      setFilters(filtersDefault);
   };

   const onMonthChange = (event: React.SyntheticEvent, newValue: number) => {
      setActiveMonth(newValue);
   };

   const onScroll = () => {
      if (!listRef.current) return;
  
      const itemsList: HTMLDivElement[] = Array.from(listRef.current.children);
  
      const visibleItems = itemsList.filter((item) => {
         const itemRect = item.getBoundingClientRect();
         const containerRect = listRef.current.getBoundingClientRect();
  
         return (
            itemRect.top >= containerRect.top &&
            itemRect.bottom <= containerRect.bottom
         );
      });
  
      if (visibleItems.length > 0) {
        const firstVisibleItem = visibleItems[0];
        const firstVisibleItemStartTime = propz.get(firstVisibleItem, ['dataset', 'starttime'], '');
        const month = new Date(firstVisibleItemStartTime).getMonth();

        const firstActiveTabMonthIndex = schoolYearMonths.findIndex(item => item.index === month);
        const isFirstActiveTabMonthIndexExist = firstActiveTabMonthIndex !== -1;
  
        setActiveMonth(isFirstActiveTabMonthIndexExist ? firstActiveTabMonthIndex : 0);
      }
   };

   const scrollToMonth = (month: number) => {
      const monthEvents = tournamentsFiltered.filter(
        (event) => new Date(event.startTime).getMonth() === month
      );
  
      if (monthEvents.length > 0) {
        const firstEvent = monthEvents[0];
        const firstEventIndex = tournamentsFiltered.indexOf(firstEvent);
  
         if (listRef.current && firstEventIndex > -1) {
            const eventElement: any = listRef.current.children[firstEventIndex];

            const isEventElementExist = typeof eventElement !== 'undefined';
            
            isEventElementExist && listRef.current.scrollTo({
               top: eventElement.offsetTop - listRef.current.offsetTop,
               behavior: 'smooth'
            });
         }
      }
   };

   const renderTabs = () => {
      return schoolYearMonths.map((item, index) => {
         return (
            <Tab
               label={item.name}
               disabled={item.isDisabled}
               onClick={() => scrollToMonth(item.index)}
               id={`months-tab-${index}`}
               key={`months-tab-${index}`}
            />
         );
      });
   };

   const renderTournaments = () => {
      const { areas } = filters as any;
      const isAreasAll = areas === 'ALL';
      const isTournamentsExist = tournamentsFiltered.length > 0;

      return isTournamentsExist
         ? tournamentsFiltered.map(item => {
            return (
               <TournamentCalendarItem
                  key={item.id}
                  tournament={item}
                  onTournamentInfoClick={onTournamentInfoClick}
                  isShowSchool={isAreasAll}
               />
            );
         })
         : <EmptyItems>
            <EmptyItemsText>There are no events</EmptyItemsText>
         </EmptyItems>
   }

   return (
      <Wrapper>
         <PageTitle text="Events Calendar" />

         <Button
            variant="outlined"
            startIcon={filterButtonIcon}
            sx={{ my: 2 }}
            onClick={() => setIsShowFilters(!isShowFilters)}
         >
            {filterButtonText}
         </Button>

         {isShowFilters &&
            <Box>
               <Filters
                  fields={FILTER_FIELDS}
                  filters={filters}
                  options={options}
                  onFilterChange={onFilterChange}
                  onClearFilterClick={onClearFilterClick}
               />

               <Divider sx={{ my: 3 }} />
            </Box>
         }

         <CalendarWrapper>
            {isTournamentsExist &&
               <Tabs
                  value={activeMonth}
                  onChange={onMonthChange}
                  variant="scrollable"
                  allowScrollButtonsMobile
               >
                  {renderTabs()}
               </Tabs>
            }

            <TournamentsWrapper ref={listRef}>
               {isLoading
                  ? <Loader />
                  : renderTournaments()
               }
            </TournamentsWrapper>
         </CalendarWrapper>
      </Wrapper>
   );
};
