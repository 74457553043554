import axios from 'axios';
import { DEFAULT_SKIP, LIMIT_ALL } from 'consts/table';

export function getAllSchoolsIncludedInUnion(activeSchoolId: string) {
   const config = {
      params: {
         filter: {
            skip: DEFAULT_SKIP,
            limit: LIMIT_ALL
         }
      }
   };

   return axios.get(`${window.apiBase}/public/schools/${activeSchoolId}/schools`, config)
      .then(response => {
         return response.data;
      });
};
