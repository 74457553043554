import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function PageNotFound() {
   return (
      <Box component='div' sx={{
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         height: '100%'
      }}>
         <Typography variant='h3'>Page not found</Typography>
      </Box>
   )
}
