import FileIcon from '@mui/icons-material/InsertDriveFile';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';

type IconColor = 'action' | 'disabled' | 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

export function getFileIcon(
   url = '',
   fontSize = '40px',
   color = 'action' as IconColor
) {
   const isPdf = url.endsWith('.pdf');
   const isImage = url.endsWith('.jpeg') || url.endsWith('.jpg') || url.endsWith('.png');

   switch (true) {
      case isImage:
         return <ImageIcon sx={{ fontSize }} color={color} />

      case isPdf:
         return <PdfIcon sx={{ fontSize }} color={color} />
   
      default:
         return <FileIcon sx={{ fontSize }} color={color} />
   }
}
