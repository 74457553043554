import React from 'react';

export default function MedalForFirstPlaceIcon() {
   return (
      <svg
         id="fi_2583381"
         enableBackground="new 0 0 512 512"
         height="32"
         width="32"
         viewBox="0 0 512 512"
         xmlns="http://www.w3.org/2000/svg"
      >
         <g>
            <g>
               <path
                  d="m256 365.128 48.336 138.872 45.41-44.406 63.168 6.614-48.066-138.096c-30.133 23.206-67.875 37.016-108.848 37.016z"
                  fill="#ff4755"
               ></path>
               <path
                  d="m256 365.128c-40.973 0-78.715-13.81-108.848-37.015l-48.066 138.095 63.168-6.614 45.41 44.406z"
                  fill="#ff4755"
               ></path>
               <path
                  d="m372.515 350.14-7.667-22.028c-30.132 23.206-67.875 37.015-108.848 37.015l7.69 22.094c40.491-1.525 77.916-15.026 108.825-37.081z"
                  fill="#fc2b3a"
               ></path>
               <path
                  d="m248.31 387.221 7.69-22.094c-40.973 0-78.715-13.81-108.848-37.015l-7.667 22.028c30.909 22.055 68.334 35.556 108.825 37.081z"
                  fill="#fc2b3a"
               ></path>
               <ellipse
                  cx="256"
                  cy="186.564"
                  fill="#ffe27a"
                  rx="178.564"
                  ry="178.564"
                  transform="matrix(.707 -.707 .707 .707 -56.94 235.663)"
               ></ellipse>
               <path
                  d="m256 347.128c-95.6 0-173.651-75.128-178.339-169.564-.148 2.982-.225 5.982-.225 9 0 98.618 79.946 178.564 178.564 178.564s178.564-79.946 178.564-178.564c0-3.018-.077-6.018-.225-9-4.688 94.436-82.739 169.564-178.339 169.564z"
                  fill="#f9cf58"
               ></path>
               <ellipse
                  cx="256"
                  cy="186.564"
                  fill="#ffba57"
                  rx="140.67"
                  ry="140.67"
                  transform="matrix(.707 -.707 .707 .707 -56.94 235.663)"
               ></ellipse>
               <path
                  d="m256 309.234c-74.666 0-135.734-58.175-140.376-131.67-.188 2.977-.295 5.976-.295 9 0 77.69 62.98 140.67 140.67 140.67s140.67-62.98 140.67-140.67c0-3.024-.107-6.023-.295-9-4.64 73.495-65.708 131.67-140.374 131.67z"
                  fill="#ffac3e"
               ></path>
               <path
                  d="m264.023 278.768c-9.766 0-17.684-7.917-17.684-17.684v-107.753l-2.683 4.53c-4.977 8.403-15.825 11.178-24.228 6.201-8.403-4.978-11.179-15.824-6.202-24.228l21.803-36.803c3.184-5.375 8.968-8.671 15.215-8.671h13.779c9.766 0 17.684 7.917 17.684 17.684v149.041c0 9.766-7.918 17.683-17.684 17.683z"
                  fill="#ffe27a"
               ></path>
               <g fill="#f9cf58">
                  <path d="m243.908 139.435c-4.693 7.924-14.674 11.359-22.996 7.416-3.368-1.596-5.965-4.076-7.692-7.004-4.911 8.307-2.252 18.993 5.924 24.043 8.432 5.207 19.699 2.098 24.749-6.428l2.446-4.13v-13.23c0-1.33-1.754-1.81-2.431-.667z"></path>
                  <path d="m264.023 260.768c-9.766 0-17.684-7.917-17.684-17.684v18c0 9.767 7.918 17.684 17.684 17.684s17.684-7.917 17.684-17.684v-18c0 9.767-7.918 17.684-17.684 17.684z"></path>
               </g>
            </g>
            <g>
               <path d="m281.76 189.879c4.151 0 7.516-3.364 7.516-7.516v-70.589c0-13.916-11.321-25.236-25.236-25.236h-13.807c-8.871 0-17.191 4.742-21.713 12.375l-21.848 36.88c-7.09 11.972-3.12 27.482 8.852 34.574 7.35 4.352 16.031 4.538 23.281 1.262v89.494c0 13.915 11.321 25.237 25.236 25.237s25.236-11.321 25.236-25.237v-48.698c0-4.151-3.365-7.515-7.516-7.515s-7.516 3.364-7.516 7.515v48.698c0 5.628-4.578 10.206-10.205 10.206s-10.205-4.578-10.205-10.206v-107.976c0-3.386-2.264-6.354-5.53-7.248-3.265-.895-6.727.504-8.452 3.418l-2.688 4.54c-2.867 4.841-9.14 6.443-13.981 3.579-4.841-2.868-6.446-9.142-3.58-13.983l21.848-36.879c1.829-3.087 5.193-5.005 8.781-5.005h13.807c5.627 0 10.205 4.578 10.205 10.205v70.589c-.001 4.151 3.364 7.516 7.515 7.516z"></path>
               <path d="m404.477 186.449c0-81.87-66.606-148.477-148.477-148.477s-148.477 66.606-148.477 148.477c0 4.151 3.365 7.516 7.516 7.516s7.515-3.364 7.515-7.516c0-73.582 59.863-133.446 133.446-133.446s133.446 59.864 133.446 133.446c0 73.583-59.863 133.447-133.446 133.447-30.67 0-60.619-10.661-84.33-30.017-23.387-19.092-39.759-45.757-46.101-75.081-.878-4.057-4.875-6.643-8.934-5.757-4.057.877-6.634 4.877-5.757 8.933 7.059 32.64 25.273 62.31 51.287 83.548 26.387 21.542 59.711 33.405 93.835 33.405 81.871-.001 148.477-66.607 148.477-148.478z"></path>
               <path d="m397.572 398.8c-1.364-3.919-5.644-5.992-9.568-4.627-3.92 1.364-5.992 5.648-4.627 9.569l18.873 54.222-51.527-5.395c-2.226-.233-4.438.537-6.037 2.101l-37.042 36.223-41.174-118.295c35.041-1.949 67.527-13.62 94.807-32.352l12.21 35.08c3.773 9.468 16.995 4.182 14.195-4.942l-13.785-39.605c41.815-34.22 68.552-86.209 68.552-144.331 0-102.807-83.64-186.448-186.449-186.448s-186.449 83.641-186.449 186.449c0 58.122 26.737 110.111 68.552 144.331l-46.439 133.421c-1.699 4.412 2.195 10.65 7.881 9.945l59.786-6.26 42.979 42.029c3.298 3.479 10.532 2.526 12.352-2.902l41.338-118.767 41.338 118.768c1.587 4.728 8.112 6.893 12.352 2.902l42.979-42.029 59.786 6.26c4.967.632 9.662-4.542 7.881-9.945zm-312.99-212.351c0-94.52 76.898-171.418 171.418-171.418s171.418 76.897 171.418 171.418c0 94.52-76.898 171.419-171.418 171.419s-171.418-76.899-171.418-171.419zm119.775 304.445-37.042-36.223c-1.6-1.564-3.811-2.331-6.037-2.101l-51.527 5.395 40.973-117.718c27.28 18.732 59.766 30.403 94.807 32.352z"></path>
            </g>
         </g>
      </svg>
   );
}
