import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import { BrowserRouter as Router } from 'react-router-dom';
import { apiSelector } from 'helpers/api';

const myDomain = document.location.hostname;
const api = apiSelector(myDomain);

declare global {
  interface Window {
    apiBase: string;
    apiBaseFile: string;
  }
}

window.apiBase = api.main;
window.apiBaseFile = api.baseFile;

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
