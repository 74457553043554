import React, { useState, useEffect } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { parse } from 'query-string';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { SchoolLeague } from 'models/league';
import { SchoolEvent } from 'models/event';
import { FilterField } from 'models/filter';
import { FILTER_TYPE } from 'consts/table';
import { ACTIVITIES_BLOCK_TYPE } from 'consts/common';
import { getSchoolPastChallenges } from 'services/schoolChallenges/events';
import { getFilters, checkIsFilterExist, getSearchFilter } from 'helpers/filters';
import { getSelectOptionForAges, getSelectOptionForClubGender } from 'helpers/select';
import { getServerFieldSectionWhere } from 'helpers/table';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Grid from '@mui/material/Grid';
import EventCard from 'components/EventCard';
import Filters from 'components/Filters';
import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';
import Loader from 'components/loader/loader';
import EmptyDataIcon from 'components/EmptyDataIcon';

const FILTER_FIELDS: FilterField[] = [
   {
      text: 'Ages',
      field: 'eventAges',
      type: FILTER_TYPE.MULTISELECT
   },
   {
      text: 'Gender',
      field: 'gender',
      type: FILTER_TYPE.SELECT
   }
];

interface Props {
   school: School;
   schoolChallenge: SchoolLeague;
};

const EmptyItems = styled(Box)(
   sx({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      opacity: '0.4',
      mt: 5
   })
);

const EmptyItemsText = styled(Typography)(
   sx({
      fontWeight: 'bold',
      textAlign: 'center'
   })
);

export default function PastChallenges(props: Props) {
   const location = useLocation();
   const navigate = useNavigate();

   const [searchParams, setSearchParams] = useSearchParams();

   const { school, schoolChallenge } = props;
   const { id: activeSchoolId } = school;
   const { id: challengeId } = schoolChallenge;

   const { ageGroupsNaming } = school;

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [isShowFilters, setIsShowFilters] = useState<boolean>(false);
   const [items, setItems] = useState<SchoolEvent[]>([]);
   const [filters, setFilters] = useState<any>({});

   const isItemsExist = items.length > 0;

   const options = {
      eventAges: getSelectOptionForAges(ageGroupsNaming),
      gender: getSelectOptionForClubGender(),
   };

   const filterButtonIcon = isShowFilters ? <FilterAltOffIcon /> : <FilterAltIcon />;
   const filterButtonText = isShowFilters ? 'Hide Filters' : 'Show Filters';

   useEffect(() => {
      setIsLoading(true);

      const search = parse(location.search);
      const filters = getFilters(FILTER_FIELDS, search);

      const where = getServerFieldSectionWhere(filters);

      getSchoolPastChallenges(activeSchoolId, challengeId, where).then(ongoingChallenges => {
         setItems(ongoingChallenges);
         setFilters(filters);
         setIsLoading(false);
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchParams]);

   const onFilterChange = (event: any, filterField: string): void => {
      const filterValue = propz.get(event, ['target', 'value']);

      const nextFilters = {
         ...filters,
         [filterField]: filterValue
      };

      setFilters(nextFilters);
   };

   const onApplyFilterClick = (): void => {
      let search = [];

      const isFilterExist = checkIsFilterExist(filters);

      if (isFilterExist) {
         search.push(getSearchFilter(filters));
      }

      const searchParams: any = parse(search.join('&'));

      setSearchParams(searchParams);
   };

   const onClearFilterClick = (): void => {
      setSearchParams('');

      const search = parse(location.search);
      const filters = getFilters(FILTER_FIELDS, search);

      setFilters(filters);
   };

   const onEventClick = (event: SchoolEvent) => {
      const { id: eventId } = event;

      navigate(`/event/${eventId}`);
   };

   if (isLoading) {
      return <Loader />;
   }

   return (
      <PageWrapper>
         <PageTitle text='Past challenges' />

         <Button
            variant="outlined"
            startIcon={filterButtonIcon}
            sx={{ my: 2 }}
            onClick={() => setIsShowFilters(!isShowFilters)}
         >
            {filterButtonText}
         </Button>

         {isShowFilters && (
            <Box>
               <Filters
                  fields={FILTER_FIELDS}
                  filters={filters}
                  options={options}
                  onFilterChange={onFilterChange}
                  onApplyFilterClick={onApplyFilterClick}
                  onClearFilterClick={onClearFilterClick}
               />

               <Divider sx={{ my: 3 }} />
            </Box>
         )}

         {isItemsExist
            ? <Grid container spacing={3} >
                  {items.map(event => {
                        const { id } = event

                        return (
                           <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                              <EventCard
                                 event={event}
                                 type={ACTIVITIES_BLOCK_TYPE.RESULTS}
                                 schoolId={activeSchoolId}
                                 onEventClick={onEventClick}
                              />
                           </Grid>
                        );
                     })
                  }
               </Grid>
            : <EmptyItems>
               <EmptyDataIcon />

               <EmptyItemsText>
                  There are no past challenges to display.
               </EmptyItemsText>
            </EmptyItems>
         }
      </PageWrapper>
   );
};
