import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Lazy from 'lazy.js';
import * as propz from 'propz';
import { Promise } from 'bluebird';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { SchoolEvent } from 'models/event';
import { Tournament } from 'models/tournament';
import { Record } from 'models/record';
import { ACTIVITIES_BLOCK_TYPE } from 'consts/common';
import { KIND_SERVER_TO_CLIENT_MAPPING } from 'consts/school';
import { getAllTournamentOngoingEvents, getAllTournamentFinishedEvents } from 'services/Tournaments/events';
import { getRecords } from 'services/getRecords';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';
import HomeSlider from 'components/HomeSlider';
import ActivitiesBlock from 'components/ActivitiesBlock';
import ScoresTable from './ScoresTable';
import TwitterWidget from 'components/TwitterWidget';
import Loader from 'components/loader/loader';
import SchoolSlider from './SchoolSlider';
import defaultLeaguePhoto from 'assets/default_league_photo.jpeg';
import Typography from '@mui/material/Typography';
import { sortByNameAsc } from 'helpers/sorting';

const ACTIVITIES_COUNT_TO_SHOW = 3;

interface Props {
   school: School;
   tournament: Tournament;
}

const SliderWrapper = styled(Box)(
   sx({
      p: {
         sm: 0,
         md: 3
      }
   })
);

export default function Home(props: Props) {
   const navigate = useNavigate();
   const location = useLocation();

   const { school, tournament } = props;
   const { id: schoolId } = school;
   const { id: tournamentId, photos } = tournament;

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [ongoingEvents, setOngoingEvents] = useState<SchoolEvent[]>([]);
   const [finishedEvents, setFinishedEvents] = useState<SchoolEvent[]>([]);
   const [records, setRecords] = useState<Record[]>([]);
   const [schoolsWithParticipatePlayers, setSchoolsWithParticipatePlayers] = useState([]);
   const [schoolsWithParticipatePlayersFiltered, setSchoolsWithParticipatePlayersFiltered] = useState([]);
   const [selectedSchool, setSelectedSchool] = useState<School>();

   const allEvents = useMemo(() => [...ongoingEvents, ...finishedEvents], [finishedEvents, ongoingEvents]);

   const isOngoingEventsExist = ongoingEvents.length > 0;
   const isFinishedEventsExist = finishedEvents.length > 0;

   const allPlayersSchoolIds = Lazy(allEvents)
      .map(item => item.individualsData)
      .flatten()
      .uniq('schoolId')
      .map(item => item.schoolId)
      .toArray();

   const allSchools = propz.get(tournament, ['schools'], []);
   const schoolsWithPlayers = allSchools
      .filter((school: any) => allPlayersSchoolIds.includes(school.id));

   const activitiesBlockOngoingEvents = ongoingEvents.slice(0, ACTIVITIES_COUNT_TO_SHOW);
   const activitiesBlockFinishedEvents = finishedEvents.slice(0, ACTIVITIES_COUNT_TO_SHOW);

   const disclaimer = propz.get(tournament, ['disclaimer']);
   const isDisclaimerExist = typeof disclaimer !== 'undefined';

   const integrations = propz.get(school, ['integrations'], []);
   const schoolTwitterProfileName = integrations[0];
   const isTwitterProfileExist = typeof schoolTwitterProfileName !== 'undefined' && schoolTwitterProfileName !== '';
   const isTwitterVisible = propz.get(school, ['publicSite', 'isTwitterVisible'], false);

   const isShowWidget = isTwitterVisible && isTwitterProfileExist;

   const isTournamentPhotosExist = photos.length > 0;

   const tournamentPhotosUrls: string[] = isTournamentPhotosExist
      ? photos.map((item) => propz.get(item, ['picUrl'], defaultLeaguePhoto))
      : [defaultLeaguePhoto];

   useEffect(() => {
      setIsLoading(true);

      const locationState = propz.get(location, ['state']) || undefined;
      const selectedSchoolFromLocation = propz.get(locationState, ['selectedSchool']);
      const isSelectedSchoolFromLocationExist = typeof selectedSchoolFromLocation !== 'undefined';

      const promises = [
         getAllTournamentOngoingEvents(schoolId, tournamentId),
         getAllTournamentFinishedEvents(schoolId, tournamentId),
         getRecords(schoolId)
      ];

      Promise.all(promises).then(([ongoingEvents, finishedEvents, records]) => {
         setOngoingEvents(ongoingEvents);
         setFinishedEvents(finishedEvents);
         setRecords(records);
         setIsLoading(false);

         if (isSelectedSchoolFromLocationExist) {
            setSelectedSchool(selectedSchoolFromLocation);
   
            const mainContent: any = document.querySelector('#main-content');
   
            if (mainContent !== null) {
               mainContent.querySelector('.school-slider').scrollIntoView({ behavior: 'smooth' });
            }
         }
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const allParticipatingSchoolIds = Lazy(allEvents)
         .map(item => [...item.individualsData, ...item.teamsData])
         .flatten()
         .uniq('schoolId')
         .map(item => item.schoolId)
         .toArray();

      let allSchools: any = [];

      allEvents.forEach(event => event.invitedSchools.forEach(invitedSchool => {
         if (invitedSchool.kind === KIND_SERVER_TO_CLIENT_MAPPING.SCHOOL_UNION) {
            event.individualsData.forEach(individual => {
               allSchools.push({id: individual.schoolId, name: individual.schoolName, pic: individual.schoolPic});
            });
         } else {
            allSchools.push(invitedSchool);
         }
      }));

      const allSchoolsUniq = (Lazy(allSchools) as any)
         .uniq('id')
         .toArray();

      const schoolsWithParticipatePlayers = allSchoolsUniq
         .filter((school: any) =>
            allParticipatingSchoolIds.includes(school.id)
         )
         .sort(sortByNameAsc);

      setSchoolsWithParticipatePlayers(schoolsWithParticipatePlayers);
      setSchoolsWithParticipatePlayersFiltered(schoolsWithParticipatePlayers);

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [tournament, allEvents]);

   const onSearchSchool = (value: string) => {
      const schoolsWithParticipatePlayersFiltered = schoolsWithParticipatePlayers.filter(item => {
         const name = propz.get(item, ['name'], '');
         const nameFormatted = name.toLowerCase();
         const valueFormatted = value.toLowerCase();

         return nameFormatted.includes(valueFormatted);
      });

      setSchoolsWithParticipatePlayersFiltered(schoolsWithParticipatePlayersFiltered);
   };

   const onSelectSchoolClick = (school: School) => {
      setSelectedSchool(school);
   };

   const onCloseSelectedSchoolClick = () => {
      setSelectedSchool(undefined);
      navigate(location.pathname, {});
   };

   const onEventClick = (event: SchoolEvent) => {
      const { id: eventId } = event;

      navigate(`/event/${eventId}`);
   };

   const renderDisclaimer = () => {
      const disclaimerArray = disclaimer.split('\n');
    
      return (
        <Typography>
            {disclaimerArray.map((line: string, index: number) => (
               <React.Fragment key={index}>
                  {line}
                  <br />
               </React.Fragment>
            ))}
        </Typography>
      );
   };

   if (isLoading) {
      return <Loader />;
   }

   return (
      <>
         <SliderWrapper>
            <HomeSlider imageUrls={tournamentPhotosUrls} />
         </SliderWrapper>

         <PageWrapper>
            <PageTitle text='Team scores' />

            <Grid container spacing={4}>
               <Grid item xs={12} lg={isShowWidget ? 8 : 12}>
                  <ScoresTable events={allEvents} tournament={tournament} />
               </Grid>

               {isShowWidget &&
                  <Grid item xs={12} lg={isShowWidget ? 4 : 12}>
                     <TwitterWidget profileName={schoolTwitterProfileName} />
                  </Grid>
               }
            </Grid>

            <Box sx={{ mt: 4 }}>
               {isOngoingEventsExist &&
                  <ActivitiesBlock
                     title='Events schedule'
                     type={ACTIVITIES_BLOCK_TYPE.EVENTS}
                     items={activitiesBlockOngoingEvents}
                     showMoreLink='/schedule'
                     school={school}
                     onItemClick={(event) => onEventClick(event as SchoolEvent)}
                  />
               }

               {isFinishedEventsExist &&
                  <ActivitiesBlock
                     title='Results'
                     type={ACTIVITIES_BLOCK_TYPE.RESULTS}
                     items={activitiesBlockFinishedEvents}
                     showMoreLink='/results'
                     school={school}
                     onItemClick={(event) => onEventClick(event as SchoolEvent)}
                  />
               }
            </Box>

            <Box sx={{ mt: 4 }}>
               <SchoolSlider
                  schools={schoolsWithParticipatePlayersFiltered}
                  activeSchool={school}
                  events={allEvents}
                  tournament={tournament}
                  onSearch={onSearchSchool}
                  onSelectSchoolClick={onSelectSchoolClick}
                  onCloseClick={onCloseSelectedSchoolClick}
                  selectedSchool={selectedSchool}
               />
            </Box>

            {isDisclaimerExist && (
               <Box>
                  <Box sx={{ p: 2 }}>
                     {renderDisclaimer()}
                  </Box>
               </Box>
            )}
         </PageWrapper>
      </>
   );
}
