import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Promise } from 'bluebird';
import * as propz from 'propz';
import { School } from 'models/school';
import { SchoolLeague } from 'models/league';
import { Tournament } from 'models/tournament';
import {
   SCHOOL_ROUTES,
   SCHOOL_UNION_ROUTES,
   SCHOOL_LEAGUE_ROUTES,
   TOURNAMENT_ROUTES,
   FOOTBALL_TOURNAMENT_ROUTES,
   SCHOOL_CHALLENGE_ROUTES,
   NOT_AVAILABLE_SCHOOL_ROUTE
} from 'routing/routing';
import { SPORT_FORMAT } from 'consts/sport';
import { LAYOUT_TYPE } from 'consts/layout';
import { SUBTYPE_OF_TOURNAMENT } from 'consts/tournament';
import { KIND_SERVER_TO_CLIENT_MAPPING, PUBLIC_SCHOOL_STATUS } from 'consts/school';
import {
   getSchool,
   getSchoolById,
   getTournaments,
   getChampionships,
   getSchoolLeagues
} from 'services/school';
import { getDomainWithoutWWW } from 'helpers/domain';
import Box from '@mui/material/Box';
import GlobalStyles from '@mui/material/GlobalStyles';
import Error from 'components/Error';
import Loader from 'components/loader';
import Layout from 'components/Layout';

const AppGlobalStyles = (
   <GlobalStyles
      styles={{
         'html, body': {
            height: '100%',
            margin: 0
         },
         '#root': {
            height: 'calc(100% - 56px)'
         }
      }}
   />
);

function App() {
   const [isLoading, setIsLoading] = useState<boolean>(true);
   const [error, setError] = useState<string>('');

   const [schools, setSchools] = useState<School[]>([]);
   const [schoolsUnion, setSchoolsUnion] = useState<School[]>([]);
   const [schoolLeagues, setSchoolLeagues] = useState<SchoolLeague[]>([]);
   const [tournaments, setTournaments] = useState<Tournament[]>([]);
   const [schoolOfEvent, setSchoolOfEvent] = useState<School>();

   const [schoolId, setSchoolId] = useState<string>('');
   const [tournamentId, setTournamentId] = useState<string>('');
   const [championshipId, setChampionshipId] = useState<string>('');
   const [schoolLeagueId, setSchoolLeagueId] = useState<string>('');

   const isSchoolOfEventExist = typeof schoolOfEvent !== 'undefined';
   const domain: string = getDomainWithoutWWW();

   useEffect(() => {
      setIsLoading(true);

      const promises = [
         getSchool(domain),
         getTournaments(domain),
         getChampionships(domain),
         getSchoolLeagues(domain),
      ];

      Promise.all(promises)
         .then(([schools, tournaments, championships, schoolLeagues]: any[]) => {
            const schoolsCount = schools.length;
            const tournamentsCount = tournaments.length;
            const championshipsCount = championships.length;
            const schoolLeaguesCount = schoolLeagues.length;
            const commonCount = schoolsCount + tournamentsCount + championshipsCount + schoolLeaguesCount;

            const isSchoolLeaguesExist = schoolLeaguesCount > 0;
            const isSchoolTournamentsExist = tournamentsCount > 0;

            switch (true) {
               case commonCount > 1:
                  console.error('Duplicate domain name for school, tournament or league');
                  setError('Duplicate domain name for school, tournament or league');

                  break;

               case schoolsCount === 1:
                  const [school] = schools;
                  const isSchoolUnion = school.kind === KIND_SERVER_TO_CLIENT_MAPPING.SCHOOL_UNION;

                  isSchoolUnion
                     ? setSchoolsUnion(schools)
                     : setSchools(schools);
                  
                  setSchoolId(school.id);

                  break;

               case tournamentsCount === 1:
                  const [tournament] = tournaments;
                  setTournamentId(tournament.id);
                  setTournaments(tournaments);

                  break;

               case championshipsCount === 1:
                  const [championship] = championships;
                  setChampionshipId(championship.id);

                  break;

               case schoolLeaguesCount === 1:
                  const [schoolLeague] = schoolLeagues;

                  setSchoolLeagues(schoolLeagues);
                  setSchoolLeagueId(schoolLeague.id);

                  break;

               case commonCount === 0:
                  console.error('Domain not exist');
                  setError('Domain not exist');

                  break;
            }

            switch (true) {
               case isSchoolLeaguesExist: {
                  const [ schoolLeague ] = schoolLeagues;
                  const { schoolId } = schoolLeague;
   
                  return getSchoolById(schoolId);
               }
            
               case isSchoolTournamentsExist: {
                  const [ tournament ] = tournaments;
                  const { schoolId } = tournament;
   
                  return getSchoolById(schoolId);
               }
            };
         })
         .then((schoolOfEvent) => {
            const isSchoolOfEventExist = typeof schoolOfEvent !== 'undefined';

            if (isSchoolOfEventExist) {
               setSchoolOfEvent(schoolOfEvent);
            }

            setIsLoading(false);
         });
   }, [domain]);

   const getNotAvailableSchoolRoute = () => {
      const {
         path: notAvailableSchoolPath,
         index: notAvailableSchoolIndex,
         component: NotAvailableSchoolComponent
      } = NOT_AVAILABLE_SCHOOL_ROUTE;

      return (
         <Route
            path={notAvailableSchoolPath}
            index={notAvailableSchoolIndex}
            element={<NotAvailableSchoolComponent />}
         />
      );
   };

   const getRoutes = () => {
      const schoolsCount = schools.length;
      const schoolsUnionCount = schoolsUnion.length;
      const schoolLeaguesCount = schoolLeagues.length;
      const tournamentsCount = tournaments.length;

      const isSchoolExist = schoolsCount > 0 && schools[0].kind === KIND_SERVER_TO_CLIENT_MAPPING.SCHOOL;
      const isSchoolUnionExist = schoolsUnionCount > 0;
      const isSchoolLeaguesExist = schoolLeaguesCount > 0;
      const isTournamentExist = tournamentsCount > 0;
      const schoolLeagueFirstItem = schoolLeagues[0];

      const isSchoolChallengeExist = schoolLeaguesCount > 0
         && schoolLeagueFirstItem.aggregationType === SPORT_FORMAT.INDIVIDUAL;

      switch (true) {
         case isSchoolExist: {
            const [school] = schools;

            const publicSiteStatus = propz.get(school, ['publicSite', 'status'], '');
            const isSchoolAvailable = publicSiteStatus === PUBLIC_SCHOOL_STATUS.PUBLIC_AVAILABLE;

            return isSchoolAvailable
               ? (
                  <Route path='/' element={<Layout school={school} type={LAYOUT_TYPE.SCHOOL} />}>
                     {SCHOOL_ROUTES.map((route, index) => {
                        const {
                           component: RouteComponent,
                           index: routeIndex,
                           path: routePath
                        } = route;

                        return (
                           <Route
                              key={`route_${index}`}
                              path={routePath}
                              index={routeIndex}
                              element={<RouteComponent school={school} />}
                           />
                        )
                     })}
                  </Route>
               )
               : getNotAvailableSchoolRoute();
         }

         case isSchoolUnionExist: {
            const [schoolUnion] = schoolsUnion;

            const publicSiteStatus = propz.get(schoolUnion, ['publicSite', 'status'], '');
            const isSchoolUnionAvailable = publicSiteStatus === PUBLIC_SCHOOL_STATUS.PUBLIC_AVAILABLE;

            return isSchoolUnionAvailable
               ? (
                  <Route path='/' element={<Layout school={schoolUnion} type={LAYOUT_TYPE.SCHOOL_UNION} />}>
                     {SCHOOL_UNION_ROUTES.map((route, index) => {
                        const {
                           component: RouteComponent,
                           index: routeIndex,
                           path: routePath
                        } = route;

                        return (
                           <Route
                              key={`route_${index}`}
                              path={routePath}
                              index={routeIndex}
                              element={<RouteComponent school={schoolUnion} />}
                           />
                        )
                     })}
                  </Route>
               )
               : getNotAvailableSchoolRoute();
         }

         case isSchoolOfEventExist && isSchoolLeaguesExist: {
            const [schoolLeague] = schoolLeagues;
            
            const publicSiteStatus = propz.get(schoolLeague, ['publicSite', 'status'], '');
            const isSchoolLeagueAvailable = publicSiteStatus === PUBLIC_SCHOOL_STATUS.PUBLIC_AVAILABLE;

            return isSchoolLeagueAvailable
               ? (
                  <Route
                     path='/'
                     element={<Layout
                           school={schoolOfEvent as School}
                           league={schoolLeague}
                           type={LAYOUT_TYPE.SCHOOL_LEAGUE}
                        />
                     }
                  >
                     {SCHOOL_LEAGUE_ROUTES.map((route, index) => {
                        const {
                           component: RouteComponent,
                           index: routeIndex,
                           path: routePath
                        } = route;

                        return (
                           <Route
                              key={`route_${index}`}
                              path={routePath}
                              index={routeIndex}
                              element={<RouteComponent school={schoolOfEvent} schoolLeague={schoolLeague} />}
                           />
                        )
                     })}
                  </Route>
               )
               : getNotAvailableSchoolRoute();
         }

         case isSchoolOfEventExist && isTournamentExist: {
            const [tournament] = tournaments;

            const tournamentSubType = propz.get(tournament, ['subType'], '');
            const isFootballTournament = tournamentSubType === SUBTYPE_OF_TOURNAMENT.FOOTBALL;
            const publicSiteStatus = propz.get(tournament, ['publicSite', 'status'], '');
            const isTournamentAvailable = publicSiteStatus === PUBLIC_SCHOOL_STATUS.PUBLIC_AVAILABLE;

            const routes = isFootballTournament ? FOOTBALL_TOURNAMENT_ROUTES : TOURNAMENT_ROUTES;

            return isTournamentAvailable
               ? (
                  <Route
                     path='/'
                     element={<Layout
                           school={schoolOfEvent as School}
                           tournament={tournament}
                           type={isFootballTournament ? LAYOUT_TYPE.FOOTBALL_TOURNAMENT : LAYOUT_TYPE.TOURNAMENT}
                           />
                     }
                  >
                     {routes.map((route, index) => {
                        const {
                           component: RouteComponent,
                           index: routeIndex,
                           path: routePath
                        } = route;

                        return (
                           <Route
                              key={`route_${index}`}
                              path={routePath}
                              index={routeIndex}
                              element={<RouteComponent school={schoolOfEvent} tournament={tournament} />}
                           />
                        )
                     })}
                  </Route>
               )
               : getNotAvailableSchoolRoute();
         }

         case isSchoolOfEventExist && isSchoolChallengeExist: {
            const [schoolChallenge] = schoolLeagues;

            return (
               <Route
                  path='/'
                  element={<Layout
                        school={schoolOfEvent as School}
                        challenge={schoolChallenge}
                        type={LAYOUT_TYPE.SCHOOL_CHALLENGE}
                     />
                  }
               >
                  {SCHOOL_CHALLENGE_ROUTES.map((route, index) => {
                     const {
                        component: RouteComponent,
                        index: routeIndex,
                        path: routePath
                     } = route;

                     return (
                        <Route
                           key={`route_${index}`}
                           path={routePath}
                           index={routeIndex}
                           element={<RouteComponent school={schoolOfEvent} schoolChallenge={schoolChallenge} />}
                        />
                     )
                  })}
               </Route>
            );
         }
      }
   };

   if (error) {
      return (
         <Error text={error} />
      );
   }

   if (isLoading) {
      return (
         <Box sx={{ height: '100vh' }}>
            <Loader />
         </Box>
      );
   }

   return (
      <>
         {AppGlobalStyles}

         <Routes>
            {getRoutes()}
         </Routes>
      </>
   );
}

export default App;
