import { SchoolEventIndividualData, SchoolEventTeamDataPlayer } from 'models/event';
import { SchoolEvent } from 'models/event';
import { isLessScoringSport } from './sport/sport';
import { isEventStatusFinished } from './event';
import {
   getIndividualScoreByStudent,
   getExtraPointsByStudent
} from './eventView';

export function sortPlayersByLastNameAsc(
   player1: SchoolEventIndividualData | SchoolEventTeamDataPlayer,
   player2: SchoolEventIndividualData | SchoolEventTeamDataPlayer
): number {
   switch (true) {
      case player1.lastName > player2.lastName:
         return 1;
      case player1.lastName < player2.lastName:
         return -1;
      case player1.lastName === player2.lastName:
         switch (true) {
            case player1.firstName > player2.firstName:
               return 1;
            case player1.firstName < player2.firstName:
               return -1;
            default:
               return 0;
         }
      default:
         return 0;
   }
}

export function sortIndividualPlayersByExtraPointsOrByScoreOrByLastName(
   event: SchoolEvent,
   player1: SchoolEventIndividualData,
   player2: SchoolEventIndividualData
) {
   const isEventFinished = isEventStatusFinished(event);
   
   if (isEventFinished) {
      const isLessScoringEventSport = isLessScoringSport(event.sport);
      const extraPoints1 = getExtraPointsByStudent(event, player1.userId);
      const extraPoints2 = getExtraPointsByStudent(event, player2.userId);
      const score1 = getIndividualScoreByStudent(event, player1.userId, player1.permissionId);
      const score2 = getIndividualScoreByStudent(event, player2.userId, player2.permissionId);

      if (extraPoints1 === extraPoints2) {
         return isLessScoringEventSport ? score1 - score2 : score2 - score1;
      } else {
         return extraPoints2 - extraPoints1;
      }
   } else {
      return sortPlayersByLastNameAsc(player1, player2);
   }
}
