import React, { useEffect } from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import Box from '@mui/material/Box';

interface Props {
   profileName: string;
}

const WidgetWrapper = styled(Box)({
   borderRadius: '6px',
   overflow: 'hidden'
});

export default function TwitterWidget(props: Props) {
   const { profileName } = props;

   const twitterLink = `https://twitter.com/${profileName}`

   useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://platform.twitter.com/widgets.js';
      document.getElementsByClassName('twitter-embed')[0].appendChild(script);
   }, []);

   return (
      <WidgetWrapper>
         <section className='twitterContainer'>
            <div className='twitter-embed'>
               <a
                  className='twitter-timeline'
                  data-tweet-limit='3'
                  href={twitterLink}
               >
                  {`Tweets by ${profileName}`}
               </a>
            </div>
         </section>
      </WidgetWrapper>
   );
};
