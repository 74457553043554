import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Promise } from 'bluebird';
import { useEffect, useState } from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import Lazy from 'lazy.js';
import * as propz from 'propz';
import { School } from 'models/school';
import { SchoolEvent } from 'models/event';
import { SchoolLeague } from 'models/league';
import { FilterField } from 'models/filter';
import { FILTER_TYPE } from 'consts/table';
import { LEAGUE_AGGREGATION_TYPE } from 'consts/league';
import { AGE_GROUPS } from 'consts/school';
import { ACTIVITIES_BLOCK_TYPE } from 'consts/common';
import { getFilters, checkIsFilterExist } from 'helpers/filters';
import { getSelectOptionForClubGender } from 'helpers/select';
import { isSortedArraysOfNumbersEqual } from 'helpers/common';
import { getSchoolLeagueResults, getSchoolLeagueFixtures } from 'services/schoolLeague/events';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Filters from 'components/Filters';
import Loader from 'components/loader';
import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';
import ActivitiesBlock from 'components/ActivitiesBlock';
import HomeSlider from 'components/HomeSlider';
import ScoresTable from './ScoresTable';
import defaultLeaguePhoto from 'assets/default_league_photo.jpeg';

const ACTIVITIES_COUNT_TO_SHOW = 3;

interface Props {
   school: School;
   schoolLeague: SchoolLeague;
}

const HOUSE_FILTER_FIELDS: FilterField[] = [
   {
      text: 'Sport',
      field: 'sport',
      type: FILTER_TYPE.SELECT
   },
   {
      text: 'Gender',
      field: 'gender',
      type: FILTER_TYPE.SELECT
   },
   {
      text: 'Age',
      field: 'age',
      type: FILTER_TYPE.SELECT
   },
];

const TEAM_FILTER_FIELDS: FilterField[] = [
   {
      text: 'Sport',
      field: 'sport',
      type: FILTER_TYPE.SELECT
   },
   {
      text: 'Gender',
      field: 'gender',
      type: FILTER_TYPE.SELECT
   },
];

const SliderWrapper = styled(Box)(
   sx({
      p: {
         sm: 0,
         md: 3
      }
   })
);

const FilteredByText = styled(Typography)(
   sx({
      mb: 2,
      fontWeight: 'bold',
      fontStyle: 'italic'
   })
);

export default function Home(props: Props) {
   const navigate = useNavigate();

   const { school, schoolLeague } = props;
   const { id: activeSchoolId } = school;
   const { id: schoolLeagueId, aggregationType } = schoolLeague;
   const { photos } = schoolLeague;

   const pageTitle = aggregationType === LEAGUE_AGGREGATION_TYPE.HOUSE
      ? 'House Scores'
      : 'Team Scores';

   const filterFields = aggregationType === LEAGUE_AGGREGATION_TYPE.HOUSE
      ? HOUSE_FILTER_FIELDS
      : TEAM_FILTER_FIELDS

   const isLeaguePhotosExist = photos.length > 0;

   const { ageGroupsNaming } = school;
   const ageGroup = AGE_GROUPS[ageGroupsNaming];

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [isShowFilters, setIsShowFilters] = useState<boolean>(false);
   const [fixtures, setFixtures] = useState<SchoolEvent[]>([]);
   const [results, setResults] = useState<SchoolEvent[]>([]);
   const [resultsFiltered, setResultsFiltered] = useState<SchoolEvent[]>([]);
   const [filters, setFilters] = useState<any>({});

   const filterButtonIcon = isShowFilters ? <FilterAltOffIcon /> : <FilterAltIcon />;
   const filterButtonText = isShowFilters ? 'Hide Filters' : 'Show Filters';

   const isResultsExist = results.length > 0;

   const leaguePhotosUrls: string[] = isLeaguePhotosExist
      ? photos.map(item => item.picUrl)
      : [defaultLeaguePhoto];

   const resultsAgesForOptions = results.reduce((result: number[][], event) => {
      const ages: number[] = propz.get(event, ['ages'], []);

      const isSameAgesExist = result.some((item: number[]) => isSortedArraysOfNumbersEqual(ages, item));

      return isSameAgesExist ? [...result] : [...result, ages];
   }, []);

   const eventsSports = results.map(event => event.sport);
   const eventsSportsUniq = Lazy(eventsSports)
      .uniq('id')
      .toArray();

   const activitiesBlockResults = results.slice(0, ACTIVITIES_COUNT_TO_SHOW);
   const activitiesBlockFixtures = fixtures.slice(0, ACTIVITIES_COUNT_TO_SHOW);

   const ageOptions = resultsAgesForOptions.map((ages: number[]) => {
      const isMixedAges = ages.length === 0 || ages.length > 1;
      const age = ageGroup[ages[0]];

      const mixedAges = {
         text: 'Mixed ages',
         value: 'mixedAges'
      };

      switch (true) {
         case isMixedAges:
            return mixedAges;

         default:
            return {
               text: age,
               value: String(ages[0])
            };
      };
   });

   const sportOptions = eventsSportsUniq.map(sport => {
      return {
         text: sport.name,
         value: sport.id
      }
   });

   const options = {
      age: ageOptions,
      sport: sportOptions,
      gender: getSelectOptionForClubGender()
   };

   useEffect(() => {
      setIsLoading(true);

      const filters = getFilters(filterFields, {});

      const promises = [
         getSchoolLeagueResults(activeSchoolId, schoolLeagueId),
         getSchoolLeagueFixtures(activeSchoolId, schoolLeagueId)
      ];

      Promise.all(promises)
         .then(([results, fixtures]) => {
            setFixtures(fixtures);
            setResults(results);
            setResultsFiltered(results)
            setFilters(filters);
            setIsLoading(false);
         });

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const renderScoresTable = () => {
      const isFilterExist = checkIsFilterExist(filters);

      if ( isResultsExist ) {
         const filterActiveFields = Object.entries(filters)
            .filter(([field, value]) => value !== '')
            .map(([field]) => {
               const currentFilterField = filterFields.find((item) => item.field === field);
               const isCurrentFilterFieldExist = typeof currentFilterField !== 'undefined'

               return isCurrentFilterFieldExist ? currentFilterField.text : '';
            });

         const filterActiveFieldsString = filterActiveFields.join(', ');
         const scoresTableFilteredBy = `Filtered by: ${filterActiveFieldsString}`;

         return (
            <>
               {isFilterExist && <FilteredByText>{scoresTableFilteredBy}</FilteredByText>}

               <ScoresTable
                  school={school}
                  results={results}
                  aggregationType={aggregationType}
                  resultsFiltered={resultsFiltered}
               />
            </>
         );
      }
   };

   const onFilterChange = (event: React.FormEvent<HTMLSelectElement>, filterField: string): void => {
      const filterValue = propz.get(event, ['target', 'value']);

      const nextFilters = {
         ...filters,
         [filterField]: filterValue
      };

      const eventsFiltered = results.reduce((result: SchoolEvent[], event) => {
         const { gender, ages, sportId } = event;

         const isSingleAge = ages.length === 1;
         const isMixedAges = ages.length === 0 || ages.length > 1;

         const filterActiveFields = Object.entries(nextFilters)
            .filter(([field, value]) => value !== '');

         const isAddToFilter: boolean = filterActiveFields.every(([field, value]) => {
            switch (true) {
               case field === 'sport':
                  return value === sportId;
            
               case field === 'gender':
                  return value === gender;
   
               case field === 'age':
                  return (value === 'mixedAges' && isMixedAges)
                     || (value === String(ages[0]) && isSingleAge);
   
               default:
                  return true;
            };
         });

         return isAddToFilter
            ? [...result, event]
            : result;
      }, []);

      setResultsFiltered(eventsFiltered);
      setFilters(nextFilters);
   };

   const onClearFilterClick = (): void => {
      const filters = getFilters(filterFields, {});

      setResultsFiltered(results);
      setFilters(filters);
   };

   const onEventClick = (event: SchoolEvent): void => {
      const {id: eventId} = event;

      navigate(`/event/${eventId}`);
   };

   if (isLoading) {
      return <Loader />
   }

   return (
      <>
         <SliderWrapper>
            <HomeSlider imageUrls={leaguePhotosUrls} />
         </SliderWrapper>
         
         <PageWrapper>
            {isResultsExist &&
               <>
                  <PageTitle text={pageTitle} />

                  <Button
                     variant="outlined"
                     startIcon={filterButtonIcon}
                     sx={{ my: 2 }}
                     onClick={() => setIsShowFilters(!isShowFilters)}
                  >
                     {filterButtonText}
                  </Button>
               </>
            }

            {isShowFilters &&
               <Box>
                  <Filters
                     fields={filterFields}
                     filters={filters}
                     options={options}
                     onFilterChange={onFilterChange}
                     onClearFilterClick={onClearFilterClick}
                  />

                  <Divider sx={{ my: 3 }} />
               </Box>
            }

            {renderScoresTable()}

            <Grid container spacing={4} mt={3}>
               <Grid item xs={12}>
                  <ActivitiesBlock
                     title='Results'
                     type={ACTIVITIES_BLOCK_TYPE.RESULTS}
                     items={activitiesBlockResults}
                     showMoreLink='/results'
                     school={school}
                     onItemClick={event => onEventClick(event as SchoolEvent)}
                  />

                  <ActivitiesBlock
                     title='Fixtures'
                     type={ACTIVITIES_BLOCK_TYPE.FIXTURES}
                     items={activitiesBlockFixtures}
                     showMoreLink='/fixtures'
                     school={school}
                     onItemClick={event => onEventClick(event as SchoolEvent)}
                  />
               </Grid>
            </Grid>
         </PageWrapper>
      </>
   )
}
