export const CRICKET_RESULT = {
   WON_BY_RUNS: 'WON_BY_RUNS',
   WON_BY_WICKETS: 'WON_BY_WICKETS',
   WON_BY_INNINGS_AND_RUNS: 'WON_BY_INNINGS_AND_RUNS',
   TIE: 'TIE',
   DRAW: 'DRAW',
   NO_RESULT: 'NO_RESULT',
   MATCH_AWARDED: 'MATCH_AWARDED',
   TBD: 'TBD'
};

export const CRICKET_RESULT_SERVER_TO_CLIENT_MAPPING = {
   WON_BY_RUNS: 'Won by runs',
   WON_BY_WICKETS: 'Won by wickets',
   WON_BY_INNINGS_AND_RUNS: 'Won by innings and runs',
   TIE: 'Tie',
   DRAW: 'Draw',
   NO_RESULT: 'No result',
   MATCH_AWARDED: 'Match awarded',
   TBD: 'TBD'
};
