import React, { useState, useEffect } from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { getAllSchoolUnionFiles } from 'services/schoolUnion/files';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';
import EmptyDataIcon from 'components/EmptyDataIcon';
import FileCard from 'components/FileCard';
import Loader from 'components/loader';

interface Props {
   school: School;
}

const EmptyItems = styled(Box)(
   sx({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      opacity: '0.4',
      mt: 5
   })
);

const EmptyItemsText = styled(Typography)(
   sx({
      fontWeight: 'bold',
      textAlign: 'center'
   })
);

export default function Files(props: Props) {
   const { school } = props;
   const { id: activeSchoolId } = school;

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [items, setItems] = useState([]);

   const isFilesExist = items.length > 0;

   useEffect(() => {
      setIsLoading(true);

      getAllSchoolUnionFiles(activeSchoolId, {}).then((files) => {
         setItems(files);
         setIsLoading(false);
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   if (isLoading) {
      return (
         <Loader />
      );
   }

   return (
      <PageWrapper>
         <PageTitle text="Resources" />

         {isFilesExist
            ? <Grid container spacing={3} >
                  {items.map(item => {
                     const { id } = item;

                     return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                           <FileCard file={item} />
                        </Grid>
                     );
                  })
               }
            </Grid>
            : <EmptyItems>
               <EmptyDataIcon />

               <EmptyItemsText>
                  There are no resources to display.
               </EmptyItemsText>
            </EmptyItems>
         }
      </PageWrapper>
   );
};
