import React, { useState, useEffect } from 'react';
import { styled, experimental_sx as sx } from '@mui/system';
import * as propz from 'propz';
import { SchoolEvent } from 'models/event';
import { getSchoolEventReport } from 'services/school';
import Loader from 'components/loader';
import Typography from '@mui/material/Typography';

interface Props {
   event: SchoolEvent;
   activeSchoolId: string;
}

const ReportContentWrapper = styled('div')(
   sx({
      p: 2,
      borderRadius: 2,
      backgroundColor: '#e7e7e7'
   })
);

export default function EventMatchReport(props: Props) {
   const [isLoading, setIsLoading] = useState(false);
   const [report, setReport] = useState(props);

   const { event, activeSchoolId } = props;
   const eventId = propz.get(event, ['id']);

   const reportContent = propz.get(report, ['content'], '');

   useEffect(() => {
      setIsLoading(true);

      getSchoolEventReport(activeSchoolId, eventId)
         .then(report => {
            setReport(report);
            setIsLoading(false);
         })
   }, [activeSchoolId, eventId]);

   if (isLoading) {
      return (
         <Loader />
      );
   }

   return (
      <>
         <Typography variant='h4' sx={{ mb: 2 }}>Match Report</Typography>

         <ReportContentWrapper dangerouslySetInnerHTML={{ __html: reportContent }} />
      </>
   )
}
