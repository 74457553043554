import React from 'react';

export default function MedalForSecondPlaceIcon() {
   return (
      <svg
         id="fi_4692911"
         enableBackground="new 0 0 512 512"
         height="32"
         width="32"
         viewBox="0 0 512 512"
         xmlns="http://www.w3.org/2000/svg"
      >
         <g>
            <g>
               <g>
                  <path
                     d="m223.764 310.253 100.242 191.747 28.393-79.496 81.481 22.056.356-.186-100.242-191.747z"
                     fill="#80b6ff"
                  ></path>
                  <path
                     d="m288.236 310.253-100.242 191.747-28.393-79.496-81.481 22.056-.356-.186 100.242-191.747z"
                     fill="#80b6ff"
                  ></path>
                  <ellipse
                     cx="256"
                     cy="196.595"
                     fill="#cfd3df"
                     rx="186.595"
                     ry="186.595"
                     transform="matrix(.707 -.707 .707 .707 -64.033 238.601)"
                  ></ellipse>
                  <circle cx="256" cy="196.595" fill="#faf7f5" r="136.595"></circle>
               </g>
            </g>
            <g>
               <path d="m221.403 167.281c-1.064 5.419 2.466 10.675 7.885 11.74 5.418 1.063 10.675-2.466 11.74-7.885 1.2-6.108 6.601-10.541 12.843-10.541 7.034 0 12.79 5.578 13.077 12.543-.71 6.096-3.566 10.931-8.22 17.893-14.296 20.426-22.827 31.596-27.41 37.597-5.604 7.337-8.162 10.686-6.452 16.244 1.003 3.259 3.471 5.76 6.773 6.861 1.355.452 2.655.885 20.003.885 6.889 0 16.309-.068 29.268-.232 5.522-.069 9.942-4.602 9.873-10.124-.068-5.48-4.532-9.874-9.996-9.874-.043 0-.086 0-.129.001-9.294.117-19.163.192-27.255.205 5.03-6.718 12.091-16.352 21.71-30.095 6.778-10.029 10.754-17.762 11.767-27.548.932-18.652-14.393-34.404-33.009-34.355-15.776-.001-29.431 11.222-32.468 26.685z"></path>
               <path d="m390.747 339.609c38.053-35.874 61.848-86.714 61.848-143.014 0-44.811-14.685-87.017-42.468-122.055-26.95-33.988-64.852-58.416-106.722-68.784-5.355-1.324-10.782 1.942-12.11 7.303-1.327 5.361 1.942 10.783 7.304 12.111 78.895 19.536 133.996 90.029 133.996 171.425 0 97.375-79.22 176.595-176.595 176.595-97.374 0-176.595-79.22-176.595-176.595 0-81.395 55.102-151.888 133.997-171.425 5.361-1.327 8.63-6.749 7.303-12.11-1.328-5.361-6.75-8.633-12.111-7.303-41.87 10.369-79.771 34.796-106.721 68.784-27.783 35.038-42.468 77.244-42.468 122.055 0 56.299 23.795 107.14 61.847 143.013l-52.354 100.127c-2.553 4.883-.675 10.911 4.199 13.48l.36.19c2.236 1.179 4.834 1.466 7.276.806l72.558-19.644 25.282 70.795c1.336 3.741 4.761 6.337 8.724 6.613.233.016.465.024.696.024 3.702 0 7.126-2.053 8.859-5.366l59.148-113.121 59.149 113.121c1.732 3.314 5.157 5.367 8.858 5.366.232 0 .464-.008.696-.024 3.964-.275 7.388-2.872 8.725-6.613l25.281-70.795 72.558 19.645c2.439.662 5.039.374 7.276-.806l.36-.19c4.874-2.569 6.752-8.597 4.198-13.48zm-201.027 137.501-20.703-57.973c-1.755-4.914-6.996-7.655-12.031-6.289l-59.946 16.23 39.842-76.197c27.639 21.117 61.016 35.077 97.351 39.098zm165.294-64.262c-5.036-1.364-10.276 1.375-12.031 6.289l-20.703 57.973-44.514-85.131c36.335-4.021 69.712-17.981 97.351-39.098l39.842 76.197z"></path>
               <circle cx="256" cy="10" r="10"></circle>
               <path d="m109.405 196.595c0 80.832 65.762 146.595 146.595 146.595s146.595-65.762 146.595-146.595-65.762-146.595-146.595-146.595-146.595 65.762-146.595 146.595zm273.19 0c0 69.805-56.79 126.595-126.595 126.595s-126.595-56.79-126.595-126.595 56.79-126.595 126.595-126.595 126.595 56.79 126.595 126.595z"></path>
            </g>
         </g>
      </svg>
   );
}
