export const CLUB_STAFF = {
   COACH: 'COACH',
   MEMBER_OF_STAFF: 'MEMBER_OF_STAFF' // just generic value
};

export const CLUB_GENDER = {
   MALE_ONLY: 'MALE_ONLY',
   FEMALE_ONLY: 'FEMALE_ONLY',
   MIXED: 'MIXED'
};

export const CLUB_GENDER_SERVER_TO_CLIENT_MAPPING = {
   MALE_ONLY: 'Boys',
   FEMALE_ONLY: 'Girls',
   MIXED: 'Mixed'
};

export const CLUB_SCHEDULE_HOURS_INTERVALS_OPTIONS = {
   '15': '15 min.',
   '30': '30 min.',
   '60': '1 hour',
   '120': '2 hour'
}

export const CLUB_SCHEDULE_HOURS_INTERVALS = {
   QUARTER: '15',
   HALF: '30',
   HOUR: '60',
   TWO_HOURS: '120'
}

export const CLUB_EVENT_TABS = [
   'Details',
   'Gallery'
];
