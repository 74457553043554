import { Sport } from 'models/sport';
import { SCORING, SPORT_FORMAT } from 'consts/sport';

export function isLessScoringSport(sport: Sport): boolean {
   return (
      sport.scoring === SCORING.LESS_SCORES ||
      sport.scoring === SCORING.LESS_TIME ||
      sport.scoring === SCORING.LESS_RESULT
   );
};

export function isTriathlon(sportName: string): boolean {
   const triathlonNames = ['junior triathlon'];

   return triathlonNames.some(triathlonName => triathlonName === sportName.toLowerCase());
};
