import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { SchoolEvent } from 'models/event';
import { Tournament } from 'models/tournament';
import { isSailingTournament } from 'helpers/tournament';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainerMUI from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ToEventIcon from '@mui/icons-material/Launch';

const SAILING_TABLE_COLUMNS: TableColumn[] = [
   {
      text: 'Team',
      value: 'team',
      align: 'left'
   },
   {
      text: 'Captain',
      value: 'сaptain',
      align: 'center'
   },
   {
      text: 'Crew',
      value: 'crew',
      align: 'center'
   },
   {
      text: 'Race',
      value: 'race',
      align: 'center'
   },
   {
      text: 'Result',
      value: 'result',
      align: 'center'
   },
   {
      text: 'Current position',
      value: 'currentPosition',
      align: 'center'
   },
];

const TABLE_COLUMNS: TableColumn[] = [
   {
      text: 'Team 1',
      value: 'firstTeam',
      align: 'left'
   },
   {
      text: 'Team 2',
      value: 'secondTeam',
      align: 'center'
   },
   {
      text: 'Result',
      value: 'result',
      align: 'center'
   },
   {
      text: 'Group',
      value: 'group',
      align: 'center'
   },
   {
      text: 'Event',
      value: 'event',
      align: 'center'
   }
];

type ColumnValue =
   'team' |
   'сaptain' |
   'crew' |
   'race' |
   'result' |
   'currentPosition' |
   'firstTeam' |
   'secondTeam' |
   'event' |
   'group';

interface TableColumn {
   text: string;
   value: ColumnValue;
   align: string;
};

interface Props {
   activeSchool: School;
   school: School;
   events: SchoolEvent[];
   tournament: Tournament;
   teams: any[];
};

const TableWrapper = styled(Box)(
   sx({
      overflowY: 'hidden'
   })
);

const EventWrapper = styled(Typography)(
   sx({
      cursor: 'pointer'
   })
);

const TableContainer = styled(TableContainerMUI)(
   sx({})
);

const TableHeadCell = styled(TableCell)(
   sx({
      fontWeight: 'bold'
   })
);

export default function FootballSchoolResultsTable(props: Props) {
   const navigate = useNavigate();
   const location = useLocation();

   const { activeSchool, school, events, tournament, teams } = props;

   const { id: activeSchoolId } = school;
   const groups = propz.get(tournament, ['customGroups'], []);

   const eventsFiltered = events
      .filter(event => {
         const { inviterSchoolId, invitedSchoolIds } = event;
         const allSchoolIds = [inviterSchoolId, ...invitedSchoolIds];

         return allSchoolIds.some(id => id === activeSchoolId)
      });

   const isSailing = isSailingTournament(tournament);
   const tableColumns = isSailing ? SAILING_TABLE_COLUMNS : TABLE_COLUMNS;

   const columns = tableColumns.map((column) => {
      const { text, value, align } = column;

      return (
         <TableHeadCell key={value} sx={{ textAlign: align }}>
            {text}
         </TableHeadCell>
      );
   });

   const getTournamentGroupById = (groups: any[], groupId: string) => {
      const foundGroup = groups.find(group => group.id === groupId);

      if (typeof foundGroup === 'undefined') {
         console.error(`Group with id ${groupId} not found`)
      } else {
         return foundGroup;
      }
   };

   const getSailingRows = () => {
      //rich result data is equal for all events
      //this is done in order not to violate the existing data structure
      const firstEvent: any = eventsFiltered[0];
      const teamList = teams.filter(team => team.schoolId === activeSchoolId);

      //sort by position after race
      return teamList
         .filter((team: any) => {
            const teamData = firstEvent.richResult[team.id];

            return typeof propz.get(teamData, ['positionAfterRace']) !== 'undefined';
         })
         .sort((team1: any, team2: any) => {
            const teamData1 = firstEvent.richResult[team1.id];
            const teamData2 = firstEvent.richResult[team2.id];

            return teamData1.positionAfterRace - teamData2.positionAfterRace
         })
         .map((team, index) => {
            // NOTE: team and teamData are different. I don't know why. Just be carefull.
            let fieldNumbering = index + 1;
            const teamData = firstEvent.richResult[team.id];
            const eventsNameStr = teamData.eventInfoList.map((eventInfo: any) => {
               const group = getTournamentGroupById(groups, eventInfo.tournamentGroupId);

               return (
                  <div
                     onClick = {() => onEventClick(eventInfo.eventId)}
                     style = {{cursor: 'pointer'}}
                     key = {eventInfo.eventId}
                  >
                     {`${group.name || ''} Race ${eventInfo.index + 1}`}
                  </div>
               );
            });

            const eventResultsStr = teamData.eventInfoList
               .map((eventInfo: any) => {
                  return eventInfo.eventStatus === 'FINISHED' ? eventInfo.points : '-';
               })
               .join('\n');

            const teamName = typeof teamData.teamName !== 'undefined'
               ? teamData.teamName
               : team.name;
            const teamCaptain = (typeof teamData.captain === 'undefined' || teamData.captain === 'undefined undefined')
               ? '-'
               : teamData.captain;

            const rowData: any = {
               team: teamName,
               сaptain: teamCaptain,
               crew: teamData.crew,
               race: eventsNameStr,
               result: eventResultsStr,
               currentPosition: teamData.positionAfterRace,
            };
   
            const rowCells = TABLE_COLUMNS.map((column: TableColumn) => {
               const { value, align } = column;
   
               const isScoreValueExist = typeof rowData[value] !== 'undefined' && rowData[value] !== '';
               const tableCellContent = isScoreValueExist ? rowData[value] : '-';
   
               return (
                  <TableCell key={value} sx={{ textAlign: align }}>
                     {tableCellContent}
                  </TableCell>
               );
            });
   
            return (
               <TableRow key={team.id}>
                  <TableCell>{fieldNumbering}</TableCell>
   
                  {rowCells}
               </TableRow>
            );
         });
   }

   const getRows = () => {
      return eventsFiltered.map((event: any, index: number) => {
         let fieldNumbering = index + 1;
         const indexSchoolViewed = event.richResult.findIndex((result: any) => result.schoolId === activeSchoolId);
         const eventStatus = propz.get(event, ['status']);
         const result1 = eventStatus === 'FINISHED' ? event.richResult[0].textResult : '- : -';
         const result2 = eventStatus === 'FINISHED' ? event.richResult[1].textResult : '- : -';
         const group = getTournamentGroupById(groups, event.tournamentGroupId);
         const groupName = group.name;

         const rowData: any = {
            firstTeam: event.teamsFullName[indexSchoolViewed],
            secondTeam: indexSchoolViewed === 0 ? event.teamsFullName[1] : event.teamsFullName[0],
            result: indexSchoolViewed === 0 ? result1 : result2,
            group: groupName,
            event: (
               <Box
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                     cursor: 'pointer'
                  }}
                  onClick={() => onEventClick(event)}
               >
                  <ToEventIcon />
               </Box>
            )
         };

         const rowCells = TABLE_COLUMNS.map((column: TableColumn) => {
            const { value, align } = column;

            const isScoreValueExist = typeof rowData[value] !== 'undefined' && rowData[value] !== '';
            const tableCellContent = isScoreValueExist ? rowData[value] : '-';

            return (
               <TableCell key={value} sx={{ textAlign: align }}>
                  {tableCellContent}
               </TableCell>
            );
         });

         return (
            <TableRow key={event.id}>
               <TableCell>{fieldNumbering}</TableCell>

               {rowCells}
            </TableRow>
         );
      });
   }

   const rows = isSailing ? getSailingRows() : getRows();

   const onEventClick = (event: SchoolEvent) => {
      const { id: eventId } = event;
      const { pathname } = location;

      navigate(`/event/${eventId}`, {
         state: {
            selectedSchool: school,
            prevPath: pathname
         }
      });
   };

   return (
      <TableWrapper>
         <TableContainer>
            <Table stickyHeader>
               <TableHead>
                  <TableRow>
                     <TableHeadCell>#</TableHeadCell>

                     {columns}
                  </TableRow>
               </TableHead>

               <TableBody>{rows}</TableBody>
            </Table>
         </TableContainer>
      </TableWrapper>
   );
}
