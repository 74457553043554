import React from 'react';
import * as propz from 'propz';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { PUBLIC_SCHOOL_STATUS } from 'consts/school';
import { SCREEN_SIZE } from 'consts/responsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, Pagination, Navigation } from 'swiper';
import { getPublicSiteLink } from 'helpers/link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MuiSchoolIcon from '@mui/icons-material/School';
import RouterLink from '@mui/material/Link';
import PageTitle from 'components/PageTitle';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import 'swiper/swiper-bundle.min.css';

interface Props {
   schools: School[];
   onSearch: (value: string) => void;
};

const Slider = styled(Swiper)(
   sx({
      overflow: 'hidden',

      '& .swiper-wrapper': {
         pb: '40px'
      },

      '& .swiper-button-next, .swiper-button-prev': {
         height: 'calc(100% - 64px)',
         transition: 'background .25s',
         padding: '0 8px',
         top: 0,
         mt: 0,
         background: '#c7d0f082',
         backdropFilter: 'blur(1px)',

         '&:after': {
            fontSize: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
         }
      },

      '& .swiper-button-prev': {
         left: 0,
         borderTopLeftRadius: '6px',
         borderBottomLeftRadius: '6px'
      },

      '& .swiper-button-next': {
         right: 0,
         borderTopRightRadius: '6px',
         borderBottomRightRadius: '6px'
      },
   })
);

const Slide = styled(SwiperSlide)(
   sx({
      mt: '0 !important',
      mb: 3
   })
);

const Link = styled(RouterLink)(
   sx({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      py: 3,
      border: '1px solid #ccc',
      boxSizing: 'border-box',
      borderRadius: '6px',
      textAlign: 'center',
      fontSize: '18px',
      background: '#fff',
      transition: 'border-color 0.15s',

      '& img': {
         height: '50px',
         mb: 3
      },

      '&:hover': {
         borderColor: '#007aff'
      },

      '& .school-link': {
         position: 'absolute',
         top: '5px',
         right: '8px',
         opacity: '0.4'
      }
   })
);

const SchoolIcon = styled(MuiSchoolIcon)(
   sx({
      mb: 3,
      height: '50px',
      fontSize: '50px'
   })
);

export default function SchoolSlider(props: Props) {
   const { schools, onSearch } = props;

   const isTabletOrPhoneScreenSize = useMediaQuery(SCREEN_SIZE.TABLET);

   const getSchoolSlides = () => {
      return schools.map(item => {
         const { id, pic: schoolLogoUrl } = item;

         const publicSiteStatus = propz.get(item, ['publicSite', 'status'], '');
         const isSchoolAvailable = publicSiteStatus === PUBLIC_SCHOOL_STATUS.PUBLIC_AVAILABLE;

         const isSchoolLogoExist = typeof schoolLogoUrl !== 'undefined' && schoolLogoUrl !== '';
         const domain = propz.get(item, ['domain'], '');
         const publicSiteLink = getPublicSiteLink(domain);
         const isDomainExist = domain !== '';
         const isLinkAvailable = isSchoolAvailable && isDomainExist;

         return (
            <Slide key={id}>
               <Link
                  href={publicSiteLink}
                  target='_blank'
                  underline='none'
                  color='black'
                  onClick={(event) => !isLinkAvailable && event.preventDefault()}
                  sx={{
                     cursor: isLinkAvailable ? 'pointer' : 'not-allowed',
                     '&:hover': {
                        borderColor: isLinkAvailable ? '#007aff' : '#ccc'
                     }
                  }}
               >
                  {!isLinkAvailable && <LinkOffIcon className='school-link' />}
                  
                  {isSchoolLogoExist
                     ? <img src={schoolLogoUrl} alt="School logo" />
                     : <SchoolIcon color='primary' />
                  }
                  <Typography>{item.name}</Typography>
               </Link>
            </Slide>
         );
      })
   };

   return (
      <>
         <PageTitle text='Schools' />

         <TextField
            label='School name'
            variant="outlined"
            onChange={(event) => onSearch(event.target.value)}
            sx={{ my: 2 }}
         />

         <Slider
            slidesPerView={isTabletOrPhoneScreenSize ? 1 : 3}
            navigation={!isTabletOrPhoneScreenSize}
            grid={{rows: 2, fill: 'row'}}
            spaceBetween={30}
            pagination={{
              dynamicBullets: true
            }}
            modules={[Grid, Pagination, Navigation]}
         >
            {getSchoolSlides()}
         </Slider>
      </>
   );
};
