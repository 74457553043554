import React from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Zoom from '@mui/material/Zoom';

interface Props {
   children: React.ReactElement;
}

export default function ScrollTop(props: Props) {
   const { children } = props;

   const trigger = useScrollTrigger({
      target: document.querySelector('#main-content') as HTMLDivElement || undefined,
      disableHysteresis: true,
      threshold: 100,
   });

   const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
      const anchor = (
         (event.target as HTMLDivElement).ownerDocument || document
      ).querySelector('#back-to-top-anchor');

      if (anchor) {
         anchor.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
         });
      }
   };

   return (
      <Zoom in={trigger}>
         <Box
            onClick={onClick}
            role="presentation"
            sx={{ position: 'fixed', bottom: 16, right: 32 }}
         >
            {children}
         </Box>
      </Zoom>
   );
}
