export const LIMIT_ALL = 3000;
export const DEFAULT_LIMIT = 10;
export const HOME_PAGE_LIMIT = 3;
export const DEFAULT_ROWS_PER_PAGE_COUNT = 5;
export const ROWS_PER_PAGE_COUNT_OPTIONS = [5, 10, 25, 50, 100];
export const FIRST_PAGE = 1;
export const DEFAULT_SKIP = 0;

export const FILTER_TYPE = {
   NONE: 'NONE',
   TEXT: 'TEXT',
   SELECT: 'SELECT',
   MULTISELECT: 'MULTISELECT',
   CHECKBOX: 'CHECKBOX',
   DATE_INTERVAL: 'DATE_INTERVAL',
   TIME_INTERVAL: 'TIME_INTERVAL',
   AUTOCOMPLETE: 'AUTOCOMPLETE'
};

export const DATE_INTERVAL = {
   TO: 'TO',
   FROM: 'FROM'
};

export const SORT = {
   ASC: 'ASC',
   DESC: 'DESC'
};

export const DEFAULT_FILTER = {
   limit: DEFAULT_LIMIT,
   skip: DEFAULT_SKIP
};
